
import React from 'react'
import { mdx } from '@mdx-js/react'
export const readingTime = {"text":"26 min read","minutes":25.865,"time":1551899.9999999998,"words":5173}
/* @jsx mdx */
import Dialogue from "../components/Dialogue";
import Word from "../components/Word";
import Examples from "../components/Examples";
import Table from "../components/Table";
import Quiz from "../components/Quiz";
import memorizingMoments from "./memorizing-moments";
import threeSittingTalk from "../assets/images/three-sitting-talk.PNG";
import palangFamily from "../assets/images/palang-family.png";
import smelter from "../assets/images/smelter.png";
import shelves from "../assets/images/shelves.png";
import rababPlayer from "../assets/images/rabab-player.png";

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope")
  return <div {...props}/>
};

const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <h2 {...{
      "id": "dialogue-eleven-buying-cloth"
    }}>{`Dialogue eleven: Buying cloth`}</h2>
    <Dialogue audio="dialogue-11" mdxType="Dialogue">{[{
        s: "L",
        p: "د جامو د پاره کپړه شته؟",
        f: "du jaamo du paara kapRá shta?",
        e: "Do you have cloth for clothes?",
        a: "11d1"
      }, {
        s: "H",
        p: "دا ټوله کپړه د جامو د پاره ده.",
        f: "daa Tóla kapRá du jaamo du paara da.",
        e: "All of the cloth is for clothes.",
        a: "11d2"
      }, {
        s: "L",
        p: "دا مال پاکِستانے دے، او کۀ بهرنے دے؟",
        f: "daa maal paakistaanéy dey, aw ku bahranéy dey?",
        e: "Are these goods from Pakistan or from overseas?",
        a: "11d3"
      }, {
        s: "H",
        p: "دا بهرنے دے، هغه نور پاکِستانی دی.",
        f: "da bahranéy dey, hágha noor paakistaanée dee.",
        e: "This is foreign, these others are Pakistani.",
        a: "11d4"
      }, {
        s: "L",
        p: "يو ښۀ رنګ مې پکار دے.",
        f: "yo xu rang me pukáar dey.",
        e: "I need a good colour.",
        a: "11d5"
      }, {
        s: "H",
        p: ["دا ټول ښۀ رنګونه دى.", "آسمانى رنګ صحيح دے."],
        f: ["daa Tol xu rangóona dee.", "aasmaanee rang sahée dey?"],
        e: ["These are all good colours.", "Blue colour is good?"],
        a: "11d6"
      }, {
        s: "L",
        p: ["او، خو دا کپړه ډېره ډبله ښکارى.", "لږه نرۍ نشته؟"],
        f: ["aw, kho daa kapRá Déra Dabála xkáaree.", "luGa narúy nishta?"],
        e: ["Yes, but that cloth looks very thick.", "Don't you have anything thinner?"],
        a: "11d7"
      }, {
        s: "H",
        p: "نه، خو دا ټولې د اوړى د پاره دى.",
        f: "na, kho daa Tóle du oRée dupáara dee.",
        e: "No, but all of these are for summer.",
        a: "11d8"
      }, {
        s: "L",
        p: "جوړه به د څو ګزو نه جوړه شى؟",
        f: "joRá ba du tso gúzo na jóRa shee?",
        e: "How many yards will it take to make a suit from it?",
        a: "11d9"
      }, {
        s: "H",
        p: "ستا جوړه به د اوو ګزو نه جوړه شى.",
        f: "staa joRá ba du oowo guzo na jóRa shee.",
        e: "It will take seven yards to make a suit for you.",
        a: "11d10"
      }, {
        s: "L",
        p: "څو شوې؟",
        f: "tso shwe?",
        e: "How much?",
        a: "11d11"
      }, {
        s: "H",
        p: "شپږ سوه روپۍ.",
        f: "shpuG sawa roopúy.",
        e: "Six hundred rupees.",
        a: "11d12"
      }, {
        s: "L",
        p: "اووۀ ګزۀ راکه!",
        f: "oowú gúza ráaka!",
        e: "Give me seven yards!",
        a: "11d13"
      }]}</Dialogue>
    <h2 {...{
      "id": "pronunciation-drill"
    }}>{`Pronunciation Drill`}</h2>
    <ol>
      <li parentName="ol">{`Contrasting `}<Word w={{
          p: "ل",
          f: "l",
          e: "",
          a: "l-sound"
        }} mdxType="Word" /> and <Word w={{
          p: "ړ",
          f: "R",
          e: "",
          a: "rr-sound"
        }} mdxType="Word" /></li>
    </ol>
    <Examples mdxType="Examples">{[{
        p: "دا لوند دے.",
        f: "daa lóonD dey.",
        e: "This is wet.",
        a: "11p1"
      }, {
        p: "دا ړُوند دے.",
        f: "daa Roond dey.",
        e: "This is a blind person.",
        a: "11p2"
      }, {
        p: "لمدول ښۀ کار نۀ دے.",
        f: "lambdawúl xu kaar nu dey.",
        e: "It is not good to make wet.",
        a: "11p3"
      }, {
        p: "ړندول ښۀ کار نۀ دے.",
        f: "Randawúl xu kaar nu dey.",
        e: "It is not to make someone blind.",
        a: "11p4"
      }, {
        p: "دے دا تلى.",
        f: "dey daa talée.",
        e: "He is weighing it.",
        a: "11p5"
      }, {
        p: "دے دا تړى.",
        f: "dey daa taRée.",
        e: "He is tying it.",
        a: "11p6"
      }]}</Examples>
    <ol {...{
      "start": 2
    }}>
      <li parentName="ol">{`Contrasting `}<Word w={{
          p: "ے",
          f: "ey",
          e: "",
          a: "ey-sound"
        }} mdxType="Word" /> and <Word w={{
          p: "ۍ",
          f: "uy",
          e: "",
          a: "uy-sound"
        }} mdxType="Word" /></li>
    </ol>
    <Examples mdxType="Examples">{[{
        p: "دغه سپے نرے دے.",
        f: "dágha spey naréy dey.",
        e: "That dog is skinny.",
        a: "11p7"
      }, {
        p: "دغه سپۍ نرۍ ده.",
        f: "dágha spuy narúy da.",
        e: "That female dog is skinny.",
        a: "11p8"
      }, {
        p: "هغه زما نوسے دے.",
        f: "haghá zmaa nwaséy dey.",
        e: "That is my grandson.",
        a: "11p9"
      }, {
        p: "هغه زما نوسۍ ده.",
        f: "haghá zmaa nwasúy da.",
        e: "That is my granddaughter.",
        a: "11p10"
      }, {
        p: "هغه پخوانے جرمنے دے.",
        f: "haghá pakhwaanéy jirmanéy dey.",
        e: "He is an old German.",
        a: "11p11"
      }, {
        p: "هغه پخوانۍ جرمنۍ ده.",
        f: "haghá pakhwaanúy jirmanúy da.",
        e: "She is an old German.",
        a: "11p12"
      }]}</Examples>
    <h2 {...{
      "id": "the-directive-pronouns-word-w-p-را-f-raa-e--a-raa-m---word-w-p-در-f-dăr-e--a-dar-m---and-word-w-p-ور-f-wăr-e--a-war-m--"
    }}>{`The directive pronouns: `}<Word w={{
        p: "را",
        f: "raa",
        e: "",
        a: "raa-m"
      }} mdxType="Word" />, <Word w={{
        p: "در",
        f: "dăr",
        e: "",
        a: "dar-m"
      }} mdxType="Word" />, and <Word w={{
        p: "ور",
        f: "wăr",
        e: "",
        a: "war-m"
      }} mdxType="Word" /></h2>
    <p>{`The prefix `}<Word w={{
        p: "را",
        f: "raa",
        e: "",
        a: "raa-m"
      }} mdxType="Word" /> indicates movement towards me/us, the speaker. <Word w={{
        p: "ور",
        f: "wăr",
        e: "",
        a: "war-m"
      }} mdxType="Word" /> indicates movement towards a third person or place, and <Word w={{
        p: "در",
        f: "dăr",
        e: "",
        a: "dar-m"
      }} mdxType="Word" />{` indicates movement towards you (second person).`}</p>
    <ol>
      <li parentName="ol"><Word w={{
          p: "را",
          f: "raa",
          e: "",
          a: "raa-m"
        }} mdxType="Word" />:</li>
    </ol>
    <Examples mdxType="Examples">{[{
        p: "دے ما ته کتاب راکوى.",
        f: "dey maa ta kitáab raa-kawée",
        e: "He is giving the book to me.",
        a: "11e1"
      }]}</Examples>
    <ol {...{
      "start": 2
    }}>
      <li parentName="ol"><Word w={{
          p: "ور",
          f: "wăr",
          e: "",
          a: "war-m"
        }} mdxType="Word" />:</li>
    </ol>
    <Examples mdxType="Examples">{[{
        p: "دے ورته کتاب ورکوى.",
        f: "dey wăr-ta kitaab wărkawée",
        e: "He is giving the book to him.",
        a: "11e2"
      }]}</Examples>
    <p>{`  Note that `}<Word w={{
        p: "ور",
        f: "wăr",
        e: "",
        a: "war-m"
      }} mdxType="Word" /> is used in place of <Word w={{
        p: "هغهٔ",
        f: "haghú",
        e: "",
        a: ""
      }} mdxType="Word" /></p>
    <ol {...{
      "start": 3
    }}>
      <li parentName="ol"><Word w={{
          p: "در",
          f: "dăr",
          e: "",
          a: "dar-m"
        }} mdxType="Word" />:</li>
    </ol>
    <Examples mdxType="Examples">{[{
        p: "هغه تا ته کتاب درکوى.",
        f: "haghá taa ta kitáab dăr-kawée",
        e: "He is giving the book to you.",
        a: "11e3"
      }]}</Examples>
    <h4 {...{
      "id": "examples"
    }}>{`Examples:`}</h4>
    <Examples mdxType="Examples">{[{
        p: "هغه راځى.",
        f: "haghá raadzee.",
        e: "He is coming (here).",
        a: "11e4"
      }, {
        p: "هغه درځى.",
        f: "haghá dărdzee.",
        e: "He is going (towards you).",
        a: "11e5"
      }, {
        p: "هغه ورځى.",
        f: "haghá wărdzee.",
        e: "He is going (there).",
        a: "11e6"
      }, {
        p: "ورشه!",
        f: "wărsha!",
        e: "Go (to him, her, them)!",
        a: "11e7"
      }, {
        p: "راشه!",
        f: "ráash!",
        e: "Come (to me)!",
        a: "11e8"
      }]}</Examples>
    <h4 {...{
      "id": "note"
    }}>{`NOTE:`}</h4>
    <p>{`These pronouns may also be used instead of object pronouns in the oblique case. For example:`}</p>
    <Examples mdxType="Examples">{[{
        p: "راسره ځې؟",
        f: "raa-sara dze?",
        e: "Are you going with me?",
        a: "11e9"
      }, {
        p: "او، درسره ځم.",
        f: "aw, dăr-sara dzum.",
        e: "Yes, I'm going with you.",
        a: "11e10"
      }, {
        p: "مُونږ ورسره ځُو.",
        f: "moonG wăr-sara dzoo.",
        e: "We are going with them.",
        a: "11e11"
      }, {
        p: "تۀ راته څۀ راکوې؟",
        f: "tu raa-ta tsu raa-kawé?",
        e: "What are you giving to me?",
        a: "11e12"
      }, {
        p: "زۀ درته چائ درکوم.",
        f: "zu dăr-ta chaay dăr-kawúm.",
        e: "I'm giving tea to you.",
        a: "11e13"
      }, {
        p: "زۀ ورته کتاب ورکوم.",
        f: "zu wăr-ta kitáab wăr-kawum.",
        e: "I'm giving a book to them.",
        a: "11e14"
      }]}</Examples>
    <h4 {...{
      "id": "more-examples"
    }}>{`More examples:`}</h4>
    <Examples mdxType="Examples">{[{
        p: "تۀ ما ته پیسې راکوې؟",
        f: "tu maa-ta peyse raa-kawe?",
        e: "Are you giving me money?",
        a: "11e15"
      }, {
        p: "نه، زۀ تا ته ډوډۍ درکوم.",
        f: "na, zu taa-ta DoDúy dăr-kawúm.",
        e: "No, I'm giving you bread.",
        a: "11e16"
      }, {
        p: "دے کريم ته کاغذ ورکوى؟",
        f: "dey karéem ta kaaghúz wăr-kawee?",
        e: "Is he giving paper to Karim?",
        a: "11e17"
      }, {
        p: "نه، ورته يو کتاب ورکوى.",
        f: "na, wăr-ta yo kitáab wăr-kawée.",
        e: "No, he is giving him a book.",
        a: "11e18"
      }, {
        p: "تاسو ناجوړو ته څۀ ورکوئ؟",
        f: "taaso naajóRo ta tsu wăr-kawéyy?",
        e: "What are you giving to the sick?",
        a: "11e19"
      }, {
        p: "مُونږ ورته دارُو ورکوؤ.",
        f: "moonG wăr-ta daaróo wăr-kawoo.",
        e: "We are giving them medicine.",
        a: "11e20"
      }, {
        p: "زما زوئ ته مڼه ورکوې؟",
        f: "zmaa zooy ta maNá wăr-kawé?",
        e: "Are you giving an apple to my son?",
        a: "11e21"
      }, {
        p: "نه، مڼه ورته نۀ ورکوم.",
        f: "na, maNá wăr-ta nu wăr-kawúm.",
        e: "No, I'm not giving him an apple.",
        a: "11e22"
      }, {
        p: "دے به څۀ وخت راته پیسې راکوى؟",
        f: "dey ba tsu wakht raa-ta peysé raa-kawee?",
        e: "When will he give me the money?",
        a: "11e23"
      }, {
        p: "پیسې به درته نۀ درکوى.",
        f: "peyse ba dărta nu dăr-kawee.",
        e: "He won't give you the money.",
        a: "11e24"
      }]}</Examples>
    <h2 {...{
      "id": "directive-pronouns-with-word-w-p-که-f-ka-e-give-a-ka--"
    }}>{`Directive pronouns with `}<Word w={{
        p: "که",
        f: "ka",
        e: "give!",
        a: "ka"
      }} mdxType="Word" /></h2>
    <p>{`Use the pictures below to practice these sentences, and in addition, add your own. Imagine that one person in the picture is `}<Word w={{
        p: "زهٔ",
        f: "zu",
        e: "",
        a: "zu"
      }} mdxType="Word" />{`.`}</p>
    <div style={{
      display: "flex",
      justifyContent: "space-between"
    }}>
    <img src={threeSittingTalk} style={{
        width: "10rem"
      }} />
    <img src={palangFamily} style={{
        width: "8rem"
      }} />
    </div>
    <Examples mdxType="Examples">{[{
        p: "هغې له قلم ورکه!",
        f: "haghé la qalúm wăr-ka!",
        e: "Give her the pen!",
        a: "11e25"
      }, {
        p: "دا هغې له ورکه!",
        f: "daa haghé la wăr-ka!",
        e: "Give it to her!",
        a: "11e26"
      }, {
        p: "هغۀ له قلم ورکه!",
        f: "haghu la qalum wăr-ka!",
        e: "Give him the pen!",
        a: "11e27"
      }, {
        p: "دا ماله راکه!",
        f: "daa maa la raa-ka!",
        e: "Give it to me!",
        a: "11e28"
      }, {
        p: "دا هغوى له ورکه!",
        f: "daa haghwée la wăr-ka!",
        e: "Give it to them!",
        a: "11e29"
      }, {
        p: "ماله دوه کيلو راکه!",
        f: "maa la dwa keelo raa-ka!",
        e: "Give me two kilos!",
        a: "11e30"
      }]}</Examples>
    <p>{`👆 Now practice the same drill with different objects around the room `}</p>
    <h4 {...{
      "id": "more-practice-with-directive-pronouns"
    }}>{`More practice with directive pronouns`}</h4>
    <Examples mdxType="Examples">{[{
        p: "تا له پیسې نۀ درکوم، دۀ له ېیسې ورکوم.",
        f: "taa la peyse nu dăr-kawum, du la peyse wăr-kawum.",
        e: "I'm not giving money to you, I'm giving it to him.",
        a: "11e31"
      }, {
        p: "کتاب درسره شته؟",
        f: "kitáab dăr-sara shta?",
        e: "Do you have a book with you?",
        a: "11e32"
      }, {
        p: "زۀ خپل دوست له ورکوم او تاله نۀ درکوم.",
        f: "zu khpul dost la wăr-kawum aw taa la nu dăr-kawum.",
        e: "I'm giving it to my friend and not to you.",
        a: "11e33"
      }, {
        p: "کۀ زما دوست راشى زۀ به يوه پوکڼۍ ورله ورکوم.",
        f: "ku zmaa dost raashee zu ba yuwa pookaNúy wăr-la wăr-kawum.",
        e: "If my friend comes, I will give him a balloon.",
        a: "11e34"
      }, {
        p: "مُونږ ورله دوائى ورکوو.",
        f: "moonG wăr-la dawaa`ee wăr-kawóo.",
        e: "We are giving him medicine.",
        a: "11e35"
      }, {
        p: "تۀ زما زوئ له مڼه ورکوې؟",
        f: "tu zmaa zooy la maNá wăr-kawe?",
        e: "Are you (sing.) giving my son an apple?",
        a: "11e36"
      }, {
        p: "تاسو زما زوئ له مڼه ورکوئ؟",
        f: "taaso zmaa zooy la maNá wăr-kaweyy?",
        e: "Are you (plur.) giving my son an apple?",
        a: "11e37"
      }]}</Examples>
    <img src={smelter} style={{
      maxWidth: "8rem"
    }} />
    <h2 {...{
      "id": "creating-compound-verbs-from-adjectives"
    }}>{`Creating compound verbs from adjectives`}</h2>
    <Examples mdxType="Examples">{[{
        p: "اور بل دے.",
        f: "or bal dey.",
        e: "The fire is ablaze.",
        a: "11e38"
      }, {
        p: "هغه اور بلوى.",
        f: "haghá or balawee.",
        e: "He is lighting the fire.",
        a: "11e39"
      }, {
        p: "هغه ور ولې بند دے؟",
        f: "hágha war wăle band dey?",
        e: "Why is that door shut?",
        a: "11e40"
      }, {
        p: "هغه ور ولې بندوې؟",
        f: "haghá war wăle bandawe?",
        e: "Why are you closing that door?",
        a: "11e41"
      }, {
        p: "ستا موټر جوړ شو؟",
        f: "staa moTúr joR sho?",
        e: "Is your car fixed?",
        a: "11e42"
      }, {
        p: "ستا موټر جوړوم.",
        f: "staa moTúr joRawum.",
        e: "I'm fixing your car.",
        a: "11e43"
      }, {
        p: "لرګى مات دى.",
        f: "largée maat dee.",
        e: "The wood is broken.",
        a: "11e44"
      }, {
        p: "هغه اوس لرګى ماتوى.",
        f: "hagha oos largée maatawee.",
        e: "He is breaking the wood now.",
        a: "11e45"
      }, {
        p: "د هغې کوټه پاکه ده.",
        f: "du haghé koTá paaka da.",
        e: "Her room is clean.",
        a: "11e46"
      }, {
        p: "هغه کوټه پاکوى.",
        f: "haghá koTá paakawee.",
        e: "She is cleaning the room.",
        a: "11e47"
      }, {
        p: "غوښه پخه ده.",
        f: "ghwaxa pakhá da.",
        e: "The meat is cooked.",
        a: "11e48"
      }, {
        p: "خانسامه غوښه پخوى.",
        f: "khaansaamá ghwaxa pakhawee.",
        e: "The cook is cooking the meat.",
        a: "11e49"
      }, {
        p: "کړکۍ کولاؤ دى.",
        f: "kiRkúy kooláaw dee.",
        e: "The windows are open.",
        a: "11e50"
      }, {
        p: "زۀ کړکۍ کولاوم.",
        f: "zu kiRkúy koolaawum.",
        e: "I'm opening the windows.",
        a: "11e51"
      }, {
        p: "اوس چائې پخېږى.",
        f: "oos chaay pakheGee.",
        e: "Tea is cooking now.",
        a: "11e52"
      }]}</Examples>
    <h4 {...{
      "id": "notes"
    }}>{`NOTES:`}</h4>
    <ol>
      <li parentName="ol">{`Most adjectives can be combined with `}<Word w={{
          p: "کول",
          f: "kawúl",
          e: "to make",
          a: "kawul"
        }} mdxType="Word" /> to form compound transitive verbs. (sometimes called stative compounds) For example the feminine singular form of the adjective <Word w={{
          p: "پوخ",
          f: "pokh",
          e: "cooked",
          a: "pokh"
        }} mdxType="Word" /> is <Word w={{
          p: "پخه",
          f: "pakhá",
          e: "",
          a: "pakha"
        }} mdxType="Word" /> and is combined to form the verb <Word w={{
          p: "پخول",
          f: "pakhawúl",
          e: "to cook",
          a: "pakhawul"
        }} mdxType="Word" />{`:`}</li>
    </ol>
    <Examples mdxType="Examples">{[{
        p: "تاسو ساګ په اوبو کښې پخوئ؟",
        f: "táaso saag pu oobú ke pakhawéyy?",
        e: "Do you cook spinach in water?",
        a: "11e53"
      }, {
        p: "نه، په غوړو کښې یې پخوُو.",
        f: "na, pu ghwaRó ke ye pakhawóo.",
        e: "No, we cook it in oil.",
        a: "11e54"
      }]}</Examples>
    <ol {...{
      "start": 2
    }}>
      <li parentName="ol">{`For adjectives that end in a consonant, the initial `}<Word w={{
          p: "ک",
          f: "k",
          e: "",
          a: "k-sound"
        }} mdxType="Word" /> in <Word w={{
          p: "کول",
          f: "kawul",
          e: "",
          a: "kawul"
        }} mdxType="Word" />{` is dropped in the present tense (as well as for the continuous past, introduced later):`}</li>
    </ol>
    <Table bordered unset titleRow={["Adjective", "Compound Transitive Verb"]} wide mdxType="Table">{[[{
        p: "بل",
        f: "bal",
        e: "ablaze, kindled",
        a: "bal"
      }, {
        p: "بلول",
        f: "balawul",
        e: "to light, to turn on",
        a: "balawul"
      }], [{
        p: "بند",
        f: "band",
        e: "closed",
        a: "band"
      }, {
        p: "بندول",
        f: "bandawul",
        e: "to close, to shut",
        a: "bandawul"
      }], [{
        p: "پاک",
        f: "paak",
        e: "clean, tidy",
        a: "paak"
      }, {
        p: "پاکول",
        f: "paakawul",
        e: "to clean",
        a: "paakawul"
      }], [{
        p: "پوخ",
        f: "pokh",
        e: "ripe, cooked",
        a: "pokh"
      }, {
        p: "پخول",
        f: "pakhawul",
        e: "to cook",
        a: "pakhawul"
      }], [{
        p: "جوړ",
        f: "joR",
        e: "healthy, sound",
        a: "jorr"
      }, {
        p: "جوړول",
        f: "joRawúl",
        e: "to build, to fix",
        a: "jorrawul"
      }], [{
        p: "صفا",
        f: "safáa",
        e: "clean, tidy",
        a: "safaa"
      }, {
        p: "صفا کول",
        f: "safáa kawúl",
        e: "to clean",
        a: "safaa-kawul"
      }], [{
        p: "کولاؤ",
        f: "kooláaw",
        e: "open",
        a: "koolaaw"
      }, {
        p: "کولاول",
        f: "koolaawúl",
        e: "to open",
        a: "koolaawul"
      }], [{
        p: "مات",
        f: "maat",
        e: "broken",
        a: "maat"
      }, {
        p: "ماتول",
        f: "maatawúl",
        e: "to break",
        a: "maatawul"
      }]]}</Table>
    <ol {...{
      "start": 3
    }}>
      <li parentName="ol">
        <p parentName="li">{`There is `}<strong parentName="p">{`another type of compound verbs`}</strong>{` (sometimes called “dynamic compound verbs") which involve "doing” actions using `}<Word w={{
            p: "کول",
            f: "kawul",
            e: "to do",
            a: "kawul"
          }} mdxType="Word" /> to "do" a noun. The noun in these compounds is the object of the sentence, and the verb <Word w={{
            p: "کول",
            f: "kawul",
            e: "to do",
            a: "kawul"
          }} mdxType="Word" /> behaves differently than <Word w={{
            p: "کول",
            f: "kawul",
            e: "to make",
            a: "kawul"
          }} mdxType="Word" />. With these kinds of compounds the initial <Word w={{
            p: "ک",
            f: "k",
            e: "",
            a: "k-sound"
          }} mdxType="Word" /> in <Word w={{
            p: "کول",
            f: "kawul",
            e: "",
            a: "kawul"
          }} mdxType="Word" />{` is never dropped.`}</p>
 <Table bordered unset titleRow={["Noun", "Dynamic Compound"]} wide mdxType="Table">{[[{
            p: "کار",
            f: "kaar",
            e: "work"
          }, {
            p: "کار کول",
            f: "kaar kawul",
            e: "to work",
            a: "kaar-kawul"
          }], [{
            p: "خوب",
            f: "khob",
            e: "sleep"
          }, {
            p: "خوب کول",
            f: "khob kawul",
            e: "to sleep",
            a: "khob-kawul"
          }], [{
            p: "اِستری",
            f: "istrée",
            e: "ironing"
          }, {
            p: "اِستری کول",
            f: "istrée kawúl",
            e: "to iron",
            a: "istree-kawul"
          }], [{
            p: "جارُو",
            f: "jaaróo",
            e: "broom"
          }, {
            p: "جارُو کول",
            f: "jaaróo kawul",
            e: "to sweep",
            a: "jaaroo-kawul"
          }], [{
            p: "خبرې",
            f: "khabure",
            e: "speech"
          }, {
            p: "خبرې کول",
            f: "khabure kawul",
            e: "to talk",
            a: "khabure-kawul"
          }], [{
            p: "لوبې",
            f: "lóbe",
            e: "games"
          }, {
            p: "لوبې کول",
            f: "lobe kawul",
            e: "to play",
            a: "lobe-kawul"
          }], [{
            p: "وادهٔ",
            f: "waadú",
            e: "marriage"
          }, {
            p: "وادهٔ کول",
            f: "waadú kawul",
            e: "to marry",
            a: "waadu-kawul"
          }]]}</Table>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Names of professions can also be combined with the auxillary verb `}<Word w={{
            p: "کول",
            f: "kawul",
            e: "to do",
            a: "kawul"
          }} mdxType="Word" />{` to make “doing” compounds like the chart above.`}</p>
      </li>
    </ol>
    <Table unset bordered wide titleRow={["Professional", "Profession", "Compound"]} mdxType="Table">{[[{
        p: "ترکاڼ",
        f: "tarkáaN",
        e: "carpenter",
        a: "tarkaann"
      }, {
        p: "ترکاڼی",
        f: "tarkáaNee",
        e: "carpentry",
        a: "tarkaannee"
      }, {
        p: "ترکاڼی کول",
        f: "tarkaaNée kawúl",
        e: "to do carpentry",
        a: "tarkaannee-kawul"
      }], [{
        p: "غل",
        f: "ghul",
        e: "thief",
        a: "ghul"
      }, {
        p: "غلا",
        f: "ghlaa",
        e: "theft",
        a: "ghlaa"
      }, {
        p: "غلا کول",
        f: "ghlaa kawul",
        e: "to steal",
        a: "ghlaa-kawul"
      }]]}</Table>
    <ol {...{
      "start": 5
    }}>
      <li parentName="ol">{`The verb `}<Word w={{
          p: "حوړول",
          f: "joRawúl",
          e: "to fix, make, repair",
          a: "jorrawul"
        }} mdxType="Word" />{` is also used in numerous ways:`}</li>
    </ol>
    <Examples mdxType="Examples">{[{
        p: "ترکاڼ ستا د پاره څۀ جوړوى؟",
        f: "tarkáaN staa du paara tsu joRawée?",
        e: "What is the carpenter making for you?",
        a: "11e53"
      }, {
        p: "څۀ وخت خپل وېښتۀ جوړوې؟",
        f: "tsu wakht khpul wextú joRawé?",
        e: "When are you getting your hair cut?",
        a: "11e54"
      }, {
        p: "هغوى چا د پاره هغه نوے کور جوړوى؟",
        f: "haghwee chaa du paara hágha nuwey kor joRawee?",
        e: "Who are they building that new house for?",
        a: "11e55"
      }]}</Examples>
    <h2 {...{
      "id": "the-verb-word-w-p-وهل-f-wahúl-e-to-hit-beat-a-wahul--"
    }}>{`The verb `}<Word w={{
        p: "وهل",
        f: "wahúl",
        e: "to hit, beat",
        a: "wahul"
      }} mdxType="Word" /></h2>
    <Examples mdxType="Examples">{[{
        p: "دا هلکان هر وخت سپى وهى.",
        f: "da halukáan hăr wakht spee wahee.",
        e: "These boys are hitting dogs all the time.",
        a: "11e56"
      }, {
        p: "ولې هره ورځ ما وهې؟",
        f: "wăle hărawrudz maa wahe?",
        e: "Why do you hit me every day?",
        a: "11e57"
      }, {
        p: "دا هره شپه په خوب کښې چغې وهى.",
        f: "daa hăra shpa pu khob ke chughe wahee.",
        e: "She cries-out [i.e. shouts] in her sleep every night.",
        a: "11e58"
      }, {
        p: "دے په هغه سيند کښې لامبو وهى.",
        f: "dey pu hágha seend ke laambo wahee.",
        e: "He is swimming in that river.",
        a: "11e59"
      }, {
        p: "فنکار هره شپه په دې هوټل کښې رباب وهى.",
        f: "fankáar hăra shpa pu de hoTúl ke rabáab wahee.",
        e: <>The musician plays <em>rabab</em> every night at this hotel.</>,
        a: "11e60"
      }]}</Examples>
    <img src={rababPlayer} style={{
      float: "right",
      maxWidth: "150px"
    }} />
    <h4 {...{
      "id": "note-1"
    }}>{`NOTE:`}</h4>
    <p>{`The `}<Word w={{
        p: "رباب",
        f: "rabáab",
        e: "",
        a: ""
      }} mdxType="Word" />{` is a stringed instrument, popular with Pukhtuns.`}</p>
    <h2 {...{
      "id": "the-verb-word-w-p-نیول-f-neewúl-e-to-catch-take-hold-a-neewul--"
    }}>{`The verb `}<Word w={{
        p: "نیول",
        f: "neewúl",
        e: "to catch, take, hold",
        a: "neewul"
      }} mdxType="Word" /></h2>
    <Examples mdxType="Examples">{[{
        p: "دے يو نوے کور په کرايه نيولے دے.",
        f: "dey yo nuwey kor pu karaaya neewúley dey.",
        e: "He has rented a new house.",
        a: "11e61"
      }, {
        p: "مسلمانان هر کال يوه مياشت روژې نيسى.",
        f: "mUsalmaanáan hăr kaal yúwa myaasht rojze neesee.",
        e: "Muslims take the fast for one month every year.",
        a: "11e62"
      }, {
        p: "هغوى په هغه سيند کښې لوئ مهيان نيسى.",
        f: "haghwee pu hágha seend ke looy maayáan neesee.",
        e: "They are catching big fish in that river.",
        a: "11e63"
      }, {
        p: "مرغۍ نيول ښۀ خبره نۀ ده.",
        f: "marghúy neewul xu khabura nu da.",
        e: "Catching birds is not a good thing.",
        a: "11e64"
      }, {
        p: "زما کتاب ونيسه زۀ راځم.",
        f: "zmaa kitaab ooneesa, zu raadzum.",
        e: "Hold my book, I'm coming.",
        a: "11e65"
      }]}</Examples>
    <h2 {...{
      "id": "how-to-say-i-dont-know"
    }}>{`How to say “I don’t know”`}</h2>
    <Examples mdxType="Examples">{[{
        p: "ما ته پته نشته.",
        f: "maa ta páta nishta.",
        e: "I don't know (anything).",
        a: "11e66"
      }, {
        p: "پته نهٔ لګی.",
        f: "páta nú-lugee",
        e: "I don't know.",
        a: "11e67"
      }]}</Examples>
    <h2 {...{
      "id": "the-emphatic-adjective-word-w-p-خپل-f-khpul-e-own-a-khpul--"
    }}>{`The emphatic adjective `}<Word w={{
        p: "خپل",
        f: "khpul",
        e: "own",
        a: "khpul"
      }} mdxType="Word" /></h2>
    <Examples mdxType="Examples">{[{
        p: "دا د چا کتاب تاله درکوى؟",
        f: "daa du chaa kitáab taa la dăr-kawee?",
        e: "Whose book is she giving to you?",
        a: "11e68"
      }, {
        p: "دا خپل کتاب ماله راکوى.",
        f: "daa khpul kitaab maa la dăr-kawee.",
        e: "She is giving me her own book.",
        a: "11e69"
      }, {
        p: "تۀ د چا جامې اغوندې؟",
        f: "tu du chaa jaamé aghonde?",
        e: "Whose clothes are you wearing?",
        a: "11e70"
      }, {
        p: "زۀ خپلې جامې اغوندم.",
        f: "zu khpule jaamé aghondum.",
        e: "I'm wearing my own clothes.",
        a: "11e71"
      }, {
        p: "تاسو يوه قيصه چاته وايئ؟",
        f: "taaso yowa qeesa chaa ta waayeyy?",
        e: "Who are you telling a story to?",
        a: "11e72"
      }, {
        p: "زۀ يې خپلې لُور ته وايم.",
        f: "zu ye khpule loor to waayum.",
        e: "I'm reading it to my daughter.",
        a: "11e73"
      }, {
        p: "تاسو يوه قيصه چاته کوئ؟",
        f: "taaso yuwa qeesa chaata kaweyy?",
        e: "Who are you telling a story to?",
        a: "11e74"
      }, {
        p: "زۀ يې خپلې لُور ته کوم.",
        f: "zu ye khpule loor ta kawum.",
        e: "I'm reading it to my daughter.",
        a: "11e75"
      }, {
        p: "دا قميص د چا د پاره ګنډې؟",
        f: "daa qamées du chaa du paara ganDé?",
        e: "Whom are you sewing this shirt for?",
        a: "11e76"
      }, {
        p: "زۀ يې خپل ځان د پاره ګنډم.",
        f: "zu ye khpul dzaan du paara ganDúm.",
        e: "I'm sewing it for myself.",
        a: "11e77"
      }, {
        p: "هغه د چا دپاره پردې او تولائى جوړوى؟",
        f: "haghá du chaa du paara parde aw tolaa`ee joRawee?",
        e: "Who is she making the curtains and mattresses for?",
        a: "11e78"
      }, {
        p: "د خپلې ترور د پاره يې جوړوى.",
        f: "du khpule tror du paara ye joRawée.",
        e: "She is making them for her aunt.",
        a: "11e79"
      }, {
        p: "تۀ اوس چرته ځې؟",
        f: "tu oos chărta dze?",
        e: "Where are you going now?",
        a: "11e80"
      }, {
        p: "زۀ خپل کور ته ځم.",
        f: "zu khpul kor to dzum.",
        e: "I'm going to my house.",
        a: "11e81"
      }, {
        p: "هغه د چا سائيکل خرڅوى؟",
        f: "haghá du chaa saaykúl khartsawee?",
        e: "Whose bicycle is he selling?",
        a: "11e82"
      }, {
        p: "هغه خپل شائیکل خرځوی.",
        f: "haghá khpul saaykúl khartsawee.",
        e: "He's selling his own bicycle.",
        a: "11e83"
      }, {
        p: "دا د چا د پاره ډوډۍ پخوى؟",
        f: "daa du chaa du paara DoDúy pakhawee?",
        e: "Whom is she cooking food for?",
        a: "11e84"
      }, {
        p: "د خپلو ماشُومانو د پاره پخوى.",
        f: "du khpulo maashoomaano du paara pakhawee.",
        e: "She is cooking for her own children.",
        a: "11e84"
      }, {
        p: "دا ټوپۍ ستا خپله ده؟",
        f: "daa Topúy staa khpula da?",
        e: "Is this hat your own?",
        a: "11e86"
      }, {
        p: "او، دا ټوپۍ زما خپله ده.",
        f: "aw, daa Topúy zmaa khpula da.",
        e: "Yes, this is my own hat.",
        a: "11e87"
      }, {
        p: "هغوى څوک دى؟",
        f: "haghwée tsok dee?",
        e: "Who are they?",
        a: "11e88"
      }, {
        p: "هغوى زما خپل دى.",
        f: "haghwee zmaa khpul dee.",
        e: "They are my own (relatives).",
        a: "11e89"
      }]}</Examples>
    <h4 {...{
      "id": "notes-1"
    }}>{`NOTES:`}</h4>
    <ol>
      <li parentName="ol">
        <p parentName="li">{`The same subject pronoun and possessive adjective cannot be used in the same sentence. `}<Word w={{
            p: "خپل",
            f: "khpul",
            e: "",
            a: "khpul"
          }} mdxType="Word" />{` replaces the adjective.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Note the ungrammatical examples and corrections below:`}</p>
      </li>
    </ol>
    <Table bordered unset wide verticalContent titleRow={["Incorrect", "", "Correct"]} mdxType="Table">{[[{
        p: "زهٔ زما کتاب لرم.",
        f: "zu zmaa kitáab larúm",
        a: "11in1"
      }, {
        e: "I have my book"
      }, {
        p: "زه خپل کتاب لرم.",
        f: "zu khpul kitáab larúm",
        a: "11cr1"
      }], [{
        p: "تۀ ستا کور ته ځې؟",
        f: "tu staa kar ta dze?",
        a: "11in2"
      }, {
        e: "Are you going to your house?"
      }, {
        p: "تۀ خپل کور ته ځې؟",
        f: "tu khpul kor ta dze?",
        a: "11cr2"
      }], [{
        p: "دے د دۀ ښځې سره ځى.",
        f: "dey du du xúdze sara dzee",
        a: "11in3"
      }, {
        e: "He is going with his wife"
      }, {
        p: "دے خپلې ښځې سره ځى.",
        f: "dey khpule xudze sara dzee.",
        a: "11cr3"
      }]]}</Table>
    <ol {...{
      "start": 3
    }}>
      <li parentName="ol"><Word w={{
          p: "خپل",
          f: "khpul",
          e: "",
          a: "khpul"
        }} mdxType="Word" /> is inflected for number, gender and case. For example the phrase:</li>
    </ol>
    <Examples mdxType="Examples">{[{
        p: "خپلو ماشُومانو د پاره",
        f: "khpulo maashoomáano du páara",
        e: "for your children",
        a: "11e90"
      }]}</Examples>
    <h2 {...{
      "id": "memorising-moments"
    }}>{`Memorising moments`}</h2>
    <h2 {...{
      "id": "mataloona"
    }}>{`mataloona`}</h2>
    <Examples memorizingMoment mdxType="Examples">{memorizingMoments[11].slice(0, 3)}</Examples>
    <h2 {...{
      "id": "rahman-baba"
    }}>{`Rahman Baba`}</h2>
    <Examples memorizingMoment mdxType="Examples">{memorizingMoments[11].slice(3, 5)}</Examples>
    <h2 {...{
      "id": "comprehension-drill-learning-location-through-commands"
    }}>{`Comprehension drill: Learning location through commands`}</h2>
    <p>{`👆 Take a pen and quickly obey commands given by your helper without looking at your book!`}</p>
    <Examples mdxType="Examples">{[{
        p: "قلم خپل سر د پاسه ونيسه!",
        f: "qalúm khpul săr du páasa ooneesa!",
        e: "Hold the pen above your head!",
        a: "11c1"
      }, {
        p: "خپل قلم د کتاب لاندې کېږده!",
        f: "khpul qalúm du kitáab láande kéGda!",
        e: "Put your pen under the book!",
        a: "11c2"
      }, {
        p: "خپل قلم په کتاب باندې کېږده!",
        f: "khpul qalúm pu kitáab baande kéGda!",
        e: "Put your pen on the book!",
        a: "11c3"
      }, {
        p: "خپل قلم په خپله خوا کښې ونيسه!",
        f: "khpul qalúm pu khpúla khwaa ke ooneesa!",
        e: "Put your pen by your side!",
        a: "11c4"
      }, {
        p: "خپل قلم د کاغذ خواته کېږده!",
        f: "khpul qalúm du kaaghúz khwaa ta kéGda!",
        e: "Put your pen next to the paper!",
        a: "11c5"
      }]}</Examples>
    <p>{`Now add the directive pronouns `}<Word w={{
        p: "را-",
        f: "raa-",
        e: "",
        a: "raa-m"
      }} mdxType="Word" />, <Word w={{
        p: "در-",
        f: "dăr-",
        e: "",
        a: "dar-m"
      }} mdxType="Word" />, and <Word w={{
        p: "ور-",
        f: "wăr-",
        e: "",
        a: "war-m"
      }} mdxType="Word" />{` to the commands. For example, “Put the pen next to her!”, “ … next to me!”, etc.`}</p>
    <img src={shelves} />
    <h2 {...{
      "id": "exercises-for-lesson-eleven"
    }}>{`Exercises for Lesson Eleven`}</h2>
    <ol>
      <li parentName="ol">{`Substitute the following words for the `}<strong parentName="li">{`bold`}</strong>{` word in the sentence:`}</li>
    </ol>
    <Examples mdxType="Examples">{[{
        p: <><strong>جامو</strong> د پاره کپړې دی؟</>,
        f: <><strong>jáamo</strong> du paara kapRé dee?</>,
        e: "",
        a: "11qe1"
      }]}</Examples>
    <Quiz mdxType="Quiz">{[{
        q: {
          p: "پردې",
          f: "pardé",
          a: "parda"
        },
        answer: [{
          p: "پردو د پاره کپړې دی؟",
          f: "pardó du paara kapRe dee?"
        }, {
          p: "پردو دپاره کپړې دی؟",
          f: "pardó du paara kapRe dee?"
        }, {
          p: "پردو د پاره کپړې دی؟",
          f: "pardó dupaara kapRe dee?"
        }, {
          p: "پردو دپاره کپړې دی؟",
          f: "pardó dupaara kapRe dee?"
        }]
      }, {
        q: {
          p: "بالښت",
          f: "baalúxt",
          a: "baaluxt"
        },
        answer: [{
          p: "پردو د پاره کپړې دی؟",
          f: "pardó du paara kapRe dee?"
        }, {
          p: "پردو دپاره کپړې دی؟",
          f: "pardó du paara kapRe dee?"
        }, {
          p: "پردو د پاره کپړې دی؟",
          f: "pardó dupaara kapRe dee?"
        }, {
          p: "پردو دپاره کپړې دی؟",
          f: "pardó dupaara kapRe dee?"
        }]
      }, {
        q: {
          p: "تولائی",
          f: "tolaa`ee",
          a: "tolaaee"
        },
        answer: [{
          p: "تولائۍ د پاره کپړې دی؟",
          f: "tolaa`uy du paara kapRe dee?"
        }, {
          p: "تولائۍ دپاره کپړې دی؟",
          f: "tolaa`uy du paara kapRe dee?"
        }, {
          p: "تولائۍ د پاره کپړې دی؟",
          f: "tolaa`uy dupaara kapRe dee?"
        }, {
          p: "تولائۍ دپاره کپړې دی؟",
          f: "tolaa`uy dupaara kapRe dee?"
        }, {
          p: "تولائیو د پاره کپړې دی؟",
          f: "tolaa`uyo du paara kapRe dee?"
        }, {
          p: "تولائیو دپاره کپړې دی؟",
          f: "tolaa`uyo du paara kapRe dee?"
        }, {
          p: "تولائیو د پاره کپړې دی؟",
          f: "tolaa`uyo dupaara kapRe dee?"
        }, {
          p: "تولائیو دپاره کپړې دی؟",
          f: "tolaa`uyo dupaara kapRe dee?"
        }]
      }]}</Quiz>
    <ol {...{
      "start": 2
    }}>
      <li parentName="ol">{`Fill in the correct directional pronoun `}<Word w={{
          p: "را-",
          f: "raa-",
          e: "",
          a: "raa-m"
        }} mdxType="Word" />, <Word w={{
          p: "در-",
          f: "dăr-",
          e: "",
          a: "dar-m"
        }} mdxType="Word" />, or <Word w={{
          p: "ور-",
          f: "wăr-",
          e: "",
          a: "war-m"
        }} mdxType="Word" />{` in the following sentences:`}</li>
    </ol>
    <Quiz mdxType="Quiz">{[{
        beg: {
          p: "دوی مُونږ ته کتابونه",
          f: "dwee moonG to kitaabóona"
        },
        end: {
          p: "کوی.",
          f: "kawée."
        },
        answer: [{
          p: "را",
          f: "raa"
        }]
      }, {
        beg: {
          p: "هلک هلکانو ته آلوګان",
          f: "halúk malikaano ta aaloogáan"
        },
        end: {
          p: "کوی.",
          f: "kawée."
        },
        answer: [{
          p: "ور",
          f: "wăr"
        }]
      }, {
        beg: {
          p: "مُونږ تاسو ته يو پاؤ چائې",
          f: "moonG táaso ta yo paaw chaay"
        },
        end: {
          p: "کوو",
          f: "kawóo"
        },
        answer: [{
          p: "در",
          f: "dăr"
        }]
      }, {
        beg: {
          p: "پلار مې راله پیسې",
          f: "plaar me raa-la peysé"
        },
        end: {
          p: "کوی.",
          f: "kawée."
        },
        answer: [{
          p: "را",
          f: "raa"
        }]
      }, {
        beg: {
          p: "کريم تا ته ډوډۍ",
          f: "karéem taa ta DoDúy"
        },
        end: {
          p: "کوی.",
          f: "kawée."
        },
        answer: [{
          p: "",
          f: ""
        }]
      }, {
        beg: {
          p: "مُونږ ته بړستن نۀ",
          f: "moonG ta bRastún nu"
        },
        end: {
          p: "کوی.",
          f: "kawée."
        },
        answer: [{
          p: "را",
          f: "raa"
        }]
      }]}</Quiz>
    <ol {...{
      "start": 3
    }}>
      <li parentName="ol">{`Replace the inflected pronoun in the sentences below with the correct directional pronoun Fill in the correct directional pronoun `}<Word w={{
          p: "را-",
          f: "raa-",
          e: "",
          a: "raa-m"
        }} mdxType="Word" />, <Word w={{
          p: "در-",
          f: "dăr-",
          e: "",
          a: "dar-m"
        }} mdxType="Word" />, or <Word w={{
          p: "ور-",
          f: "wăr-",
          e: "",
          a: "war-m"
        }} mdxType="Word" />{` and adjust accordingly.`}</li>
    </ol>
    <Quiz mdxType="Quiz">{[{
        q: {
          p: "دے يو خط ما ته ليکى.",
          f: "dey yo khat maa ta leekee."
        },
        answer: [{
          p: "دے یو خط را ته لیکی",
          f: "dey yo khat raa ta leekee."
        }, {
          p: "دے یو خط راته لیکی",
          f: "dey yo khat raa ta leekee."
        }]
      }, {
        q: {
          p: "زۀ تاله يوه مڼه اخلم.",
          f: "zu taa la yúwa maNa akhlum."
        },
        answer: [{
          p: "زهٔ درله یوه مڼه اخلم.",
          f: "zu dăr la yúwa maNa akhlum."
        }, {
          p: "زهٔ در له یوه مڼه اخلم.",
          f: "zu dăr la yúwa maNa akhlum."
        }]
      }, {
        q: {
          p: "مُونږ تاسو له قميص ګنډو.",
          f: "moonG taaso la qamées ganDoo."
        },
        answer: [{
          p: "مُونږ در له قمیص ګنډو.",
          f: "moonG dăr la qamées ganDoo."
        }, {
          p: "مُونږ درله قمیص ګنډو.",
          f: "moonG dăr la qamées ganDoo."
        }]
      }, {
        q: {
          p: "دے هغوی نه خفه کېږی.",
          f: "dey haghwée na khufa keGee."
        },
        answer: [{
          p: "دے ور نه خفه کېږی.",
          f: "dey wăr na khufa keGee."
        }, {
          p: "دے ورنه خفه کېږی.",
          f: "dey wăr na khufa keGee."
        }]
      }, {
        q: {
          p: "تۀ مُونږ سره کورته ځې؟",
          f: "tu moong sara kor ta dze?"
        },
        answer: [{
          p: "تهٔ را سره کورته ځې",
          f: "tu raa sara kor ta dze?"
        }, {
          p: "تهٔ راسره کورته ځې",
          f: "tu raa sara kor ta dze?"
        }]
      }]}</Quiz>
    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;
export const tableOfContents = (components={}) => [
  {
    id: "dialogue-eleven-buying-cloth",
    level: 2,
    title: "Dialogue eleven: Buying cloth",
    children: [
        
      ]
  },
{
    id: "pronunciation-drill",
    level: 2,
    title: "Pronunciation Drill",
    children: [
        
      ]
  },
{
    id: "the-directive-pronouns-word-w-p-را-f-raa-e--a-raa-m---word-w-p-در-f-dăr-e--a-dar-m---and-word-w-p-ور-f-wăr-e--a-war-m--",
    level: 2,
    title: <React.Fragment>{`The directive pronouns: `}<Word w={{ p: "را", f: "raa", e: "", a: "raa-m" }} />, <Word w={{ p: "در", f: "dăr", e: "", a: "dar-m" }} />, and <Word w={{ p: "ور", f: "wăr", e: "", a: "war-m" }} /></React.Fragment>,
    children: [
        
      ]
  },
{
    id: "directive-pronouns-with-word-w-p-که-f-ka-e-give-a-ka--",
    level: 2,
    title: <React.Fragment>{`Directive pronouns with `}<Word w={{ p: "که", f: "ka", e: "give!", a: "ka" }} /></React.Fragment>,
    children: [
        
      ]
  },
{
    id: "creating-compound-verbs-from-adjectives",
    level: 2,
    title: "Creating compound verbs from adjectives",
    children: [
        
      ]
  },
{
    id: "the-verb-word-w-p-وهل-f-wahúl-e-to-hit-beat-a-wahul--",
    level: 2,
    title: <React.Fragment>{`The verb `}<Word w={{ p: "وهل", f: "wahúl", e: "to hit, beat", a: "wahul" }} /></React.Fragment>,
    children: [
        
      ]
  },
{
    id: "the-verb-word-w-p-نیول-f-neewúl-e-to-catch-take-hold-a-neewul--",
    level: 2,
    title: <React.Fragment>{`The verb `}<Word w={{ p: "نیول", f: "neewúl", e: "to catch, take, hold", a: "neewul" }} /></React.Fragment>,
    children: [
        
      ]
  },
{
    id: "how-to-say-i-dont-know",
    level: 2,
    title: "How to say “I don’t know”",
    children: [
        
      ]
  },
{
    id: "the-emphatic-adjective-word-w-p-خپل-f-khpul-e-own-a-khpul--",
    level: 2,
    title: <React.Fragment>{`The emphatic adjective `}<Word w={{ p: "خپل", f: "khpul", e: "own", a: "khpul" }} /></React.Fragment>,
    children: [
        
      ]
  },
{
    id: "memorising-moments",
    level: 2,
    title: "Memorising moments",
    children: [
        
      ]
  },
{
    id: "mataloona",
    level: 2,
    title: "mataloona",
    children: [
        
      ]
  },
{
    id: "rahman-baba",
    level: 2,
    title: "Rahman Baba",
    children: [
        
      ]
  },
{
    id: "comprehension-drill-learning-location-through-commands",
    level: 2,
    title: "Comprehension drill: Learning location through commands",
    children: [
        
      ]
  },
{
    id: "exercises-for-lesson-eleven",
    level: 2,
    title: "Exercises for Lesson Eleven",
    children: [
        
      ]
  }
]

export const frontMatter = {
  "title": "Lesson Eleven"
}

