import React from "react";

export default ({ children }) => (
    <div style={{ display: "flex", justifyContent: "space-between", flex: "1 1 0px"}}>
        {children.map((col) => (
            <div style={{ flex: "1 1 0px" }}>
                {col}
            </div>
        ))}
    </div>
);
