export default [
    {
        p: "تا سره څومره چرګې شته؟",
        f: "taa sara tsoomra churge shta?",
        e: "How many hens do you have?",
        a: "6e1",
    },
    {
        p: "ځومره کُرسۍ پکار دی؟",
        f: "tsoomra kUrsúy pukáar dee?",
        e: "How many chairs are needed?",
        a: "6e2",
    },
    {
        p: "تاسو طالبعلمانې یئ؟",
        f: "taaso taalibilmáane yeyy?",
        e: "Are you (female) students?",
        a: "6e3",
    },
    {
        p: "دوی ډاکټرانې دی؟",
        f: "dwee DakTuráane dee?",
        e: "Are they (female) doctors?",
        a: "6e4",
    },
    {
        p: "هغوی نرسانې دی.",
        f: "haghwee narsaane dee",
        e: "They are nurses.",
        a: "6e5",
    },
    {
        p: "هغه قابِله ښځه ده.",
        f: "haghá qaabíla xudza da.",
        e: "She is a capable woman.",
        a: "6e10",
    },
    {
        p: "هغوی قابِلې ښځې دی.",
        f: "haghwée qaabíle xudze dee.",
        e: "They are a capable women.",
        a: "6e11",
    },
    {
        p: "هغه اسپه مړه ده.",
        f: "hágha aspa mRa da.",
        e: "That mare is dead.",
        a: "6e12",
    },
    {
        p: "هغه اسپې مړې دی.",
        f: "hágha aspe mRe da.",
        e: "That mare is dead.",
        a: "6e13",
    },
    {
        p: "هغې سره یوه مالټه ده.",
        f: "haghe sara yuwa maalTá da.",
        e: "She has one orange.",
        a: "6e14",
    },
    {
        p: "هغې سره دوه مالټې دی.",
        f: "haghe sara dwa maalTé dee.",
        e: "She has two oranges.",
        a: "6e15",
    },
    {
        p: "زما څپلۍ خرابه ده.",
        f: "zmaa tsaplúy kharáaba da.",
        e: "My sandal is bad.",
        a: "6e16",
    },
    {
        p: "زما څپلۍ خرابه ده.",
        f: "zmaa tsaplúy kharáabe dee.",
        e: "My sandals are bad.",
        a: "6e17",
    },
    {
        p: "زما پیشو ړنده ده.",
        f: "zmaa peeshó Randá da.",
        e: "My cat is blind.",
        a: "6e18",
    },
    {
        p: "زما پیشوګانې ړندې دی.",
        f: "zmaa peeshogáane Randé dee.",
        e: "My cats are blind.",
        a: "6e19",
    },
    {
        p: "ستا غوا غټه ده.",
        f: "staa ghwaa gháTa da.",
        e: "You cow is big",
        a: "6e20",
    },
    {
        p: "ستا غواګانې غټې دی.",
        f: "staa ghwaagaane ghaTe dee.",
        e: "Your cows are big.",
        a: "6e21",
    },
    {
        p: "هغه زړه بړستن ده.",
        f: "hágha zaRá bRustún da.",
        e: "That is an old quilt.",
        a: "6e22",
    },
    {
        p: "هغه زړې بړستنې دی.",
        f: "hágha zaRé bRustúne dee.",
        e: "They are old quilts .",
        a: "6e23",
    },
    {
        p: "مشره ښځه په کور کښې شټه؟",
        f: "múshra xudza pu kor ke shta?",
        e: "Is the senior lady of the house in?",
        a: "6e29",
    },
    {
        p: "نه، دلته نشته.",
        f: "na, dălta níshta.",
        e: "No, she is not here.",
        a: "6e30",
    },
    {
        p: "اُستاذ صاحب شته؟",
        f: "Ustáaz saahíb shta?",
        e: "Is the teacher present?",
        a: "6e31",
    },
    {
        p: "او، دلته شته / دے.",
        f: "aw, dălta shta / dey.",
        e: "Yes, he is here.",
        a: "6e32",
    },
    {
        p: "په پاکِستان کښې ګرجا شته؟",
        f: "pu paakistaan ke girjáa shta?",
        e: "Is there a church in Pakistan?",
        a: "6e33",
    },
    {
        p: "او، ډېرې شته / دى.",
        f: "aw, Dere shta / dee.",
        e: "Yes, there are lots",
        a: "6e34",
    },
    {
        p: "څوک زنانه خو نشته.",
        f: "tsok zanaaná kho nishta.",
        e: "Are there no ladies?",
        a: "6e35",
    },
    {
        p: "په کور کښې څوک هم نشته.",
        f: "pu kor ke tsok hum níshta.",
        e: "There is no one at home.",
        a: "6e36",
    },
    {
        p: "پرنسپل صېب په دفتر کښې شته؟",
        f: "prinsipúl seb pu dăftúr ke shta?",
        e: "Is the principal in his office?",
        a: "6e37",
    },
    {
        p: "او، ناست دے.",
        f: "aw, naast dey.",
        e: "Yes, he is sitting.",
        a: "6e38",
    },
    {
        p: "ستا په جېب کښې څۀ شته؟",
        f: "staa pu jeb ke tsu shta?",
        e: "Is there anything in your pocket?",
        a: "6e39",
    },
    {
        p: "نه، هېڅ نشته.",
        f: "na, heets níshta.",
        e: "No, nothing.",
        a: "6e40",
    },
    {
        p: "تاسو ستړی کېږئ؟",
        f: "taaso stúRee kéGeyy?",
        e: "Are you getting tired?",
        a: "6e41",
    },
    {
        p: "نه، نۀ ستړى کېږُو.",
        f: "na, nu stúRee kéGoo.",
        e: "No, we are not getting tired.",
        a: "6e42",
    },
    {
        p: "تۀ غُصه کېږې؟",
        f: "tu ghUsa kéGe?",
        e: "Are you getting angry?",
        a: "6e43",
    },
    {
        p: "نه، نۀ غُصه کېږم.",
        f: "na, nu ghUsa keGum.",
        e: "No, I am not getting angry.",
        a: "6e44",
    },
    {
        p: "دا کار کېږى کۀ نۀ کېږى؟",
        f: "daa kaar keGee ku nu keGee?",
        e: "Will this work happen or not happen?",
        a: "6e45",
    },
    {
        p: "او کېږي.",
        f: "aw, keGee",
        e: "Yes, [it will] happen.",
        a: "6e46",
    },
    {
        p: "ماشُومان اُودۀ کېږى؟",
        f: "maashoomáan óodu keGee?",
        e: "Are the children going to sleep?",
        a: "6e47",
    },
    {
        p: "نه، نۀ اُودۀ کېږى.",
        f: "na, nu óodu keGee.",
        e: "No, they are not going to sleep.",
        a: "6e48",
    },
    {
        p: "دا وادۀ کېږى؟",
        f: "daa wáadu keGee?",
        e: "Is she getting married?",
        a: "6e49",
    },
    {
        p: "نه، نۀ وادۀ کېږى.",
        f: "na, nu wáadu keGee.",
        e: "No, she is not getting married.",
        a: "6e50",
    },
    {
        p: "تۀ به څومره موده دلته پاتې کېږې؟",
        f: "tu ba tsóomra moodá dălta paate kéGe?",
        e: "How long will you be staying here?",
        a: "6e51",
    },
    {
        p: "زۀ به دؤو کالُو پورې پاتې کېږم.",
        f: "zu ba dwuo kaalo pore paate keGum.",
        e: "I will be staying here for two years.",
        a: "6e52",
    },
    {
        p: "دا مړه کېږى؟",
        f: "daa mRa keGee?",
        e: "Is she dying?",
        a: "6e53",
    },
    {
        p: "او، هر يو کس به مړ کېږى.",
        f: "aw, hăr yo kas ba muR keGee.",
        e: "Yes, everyone will die.",
        a: "6e54",
    },
];