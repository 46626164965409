import React from "react";
import AudioPiece from "./AudioPiece";
import standardizeF from "../lib/standardize-f";
import PersistentBlank from "./persistent-blank";
import playAudio from "../lib/play-audio";
// import useInView from "../lib/useInView";

const border = "2px dotted black";

const Dialogue = ({audio, children, storePrefix}) => {
    // const ref = useRef();
    // const inView = useInView(ref);
    // let audios = {};
    // useEffect(() => {
    //     if (inView) {
    //         audios = children.reduce((o, line) => {
    //             if (line.a) {
    //                 o[line.a] = new Audio(`/audio/${line.a}.mp3`);
    //             }
    //             return o;
    //         }, {});
    //     }
    //     if (!inView) {
    //         audios = {};
    //     }
    // }, [inView]);
    return (
        <>
            <AudioPiece a={audio} />
            <table style={{ tableLayout: "auto" }}>
                <tbody>
                    {children.map((line, i) => (
                        line.answer ?
                            <tr key={i} style={{ border }}>
                                <td colSpan={2}>
                                    <div style={{ display: "flex" }}>
                                        Answer:
                                    </div>
                                    <PersistentBlank store={`${storePrefix}-${i}`} width="100%" />
                                </td>
                            </tr>
                        :
                            <tr className={line.a ? "clickable" : ""} key={i} style={{ border }} onClick={line.a ? () => playAudio(line.a) : () => null}>
                                <td dir="ltr">
                                    <div style={{ display: "flex" }}>
                                        {line.s && <div style={{ padding: "0 0.5rem 0 0.5rem" }}>
                                            {line.s}:
                                        </div>}
                                        <div>
                                            <div>
                                                {Array.isArray(line.f) ?
                                                    line.f.map((fLine) => (
                                                        <div key={fLine} className="dialogue-subline">{standardizeF(fLine)}</div>
                                                    ))
                                                :
                                                    standardizeF(line.f)
                                                }
                                            </div>
                                            <div style={{ color: "grey" }}>
                                                {Array.isArray(line.e) ?
                                                    line.e.map((eLine) => (
                                                        <div key={eLine} className="dialogue-subline">{eLine}</div>
                                                    ))
                                                :
                                                    line.e
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </td>
                                <td dir="rtl" style={{ display: "flex", flexDirection: "column", justifyContent: "space-between" }}>
                                    {Array.isArray(line.p) ?
                                        line.p.map((pLine) => (
                                            <div key={pLine} className="dialogue-subline">{pLine}</div>
                                        ))
                                    :
                                        line.p
                                    }
                                </td>
                            </tr>
                    ))}
                </tbody>
            </table>
        </>
    );
}

export default Dialogue;
