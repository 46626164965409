import React from "react";
// eslint-disable-next-line
import { BrowserRouter as Router, Link } from "react-router-dom";
import "./side-drawer.css";

const Drawer = props => {
    return (
        <div className={`side-drawer ${props.withShadow ? "side-drawer-with-shadow" : ""}`}>
            {props.sections.map(chapter => (
                <Link to={chapter.path} style={{ textDecoration: "none" }} key={chapter.slug} onClick={props.toggleDrawer}>
                    <div className="drawer-item">
                        {chapter.frontMatter.title}
                    </div>
                </Link>
            ))}

            <div style={{ height: "4rem" }}>
                {/* Filler for Firefox bottom padding issue */}
            </div>
        </div>
    )
}

export default Drawer;