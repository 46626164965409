import reactStringReplace from "react-string-replace";
import { ReactNodeArray } from "react";

export default function standardizeF(f: string | string[] | ReactNodeArray | undefined): string | ReactNodeArray {
    if (f === undefined) {
        return ""
    }
    if (Array.isArray(f) && typeof f[0] === "string") {
        // @ts-ignore
        return f.map((s) => {
            return standardizeF(s);
        });
    }
    if (typeof f === "string") {
        return f.replace(/dz/g, "z").replace(/x/g, "kh")
            .replace(/G/g, "g");
    }
    const xReplaced = reactStringReplace(f, /x/g, () => "kh");
    const dzReplaced = reactStringReplace(xReplaced, /dz/g, () => "z");
    return reactStringReplace(dzReplaced, /G/g, () => "g");
}