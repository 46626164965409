const scrollOffset = 75;

function scrollWithOffset(el) {
    const elementPosition = el.offsetTop - scrollOffset;
    window.scroll({
        top: elementPosition,
        left: 0,
        behavior: "smooth"
    });
} 

export default scrollWithOffset;
