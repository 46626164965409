import React from "react";

export default [
    [],
    [ // Lesson 1
        {
            p: "تادى کار دَ شېطان دے.",
            f: "taadee kaar du shetáan dey.",
            e: "A speedy job is of Satan",
            a: "mm-taadee-kaar",
        },
        {
            p: "کارغهٔ هوښیار دے.",
            f: "kaarghú ooxyáar dey.",
            e: "The crow is wise.",
            a: "mm-kaarghu-ooxyaar",
        },
    ],
    [ // Lesson 2
        {
            p: "په هر چا خپل وطن کشمیر دے.",
            f: "pu hăr chaa khpul watún kashmeer dey.",
            e: "For everyone his own country is Kashmir.",
            a: "mm-pu-har-chaa-khpul-watan",
        },
        {
            p: "خپل عمل د لارې مل دے.",
            f: "khpul amúl du láare mal dey.",
            e: "Your own deeds are with you on your path.",
            a: "mm-khpul-amal-du-laare-mul",
        },   
    ],
    [ // Lesson 3
        {
            p: "په پردى لاس مار وژل هم ښۀ نۀ دی.",
            f: "pu pradee laas maar wajzul hum xu nu dee.",
            e: "Even a snake shouldn't be killed by another's hand.",
            a: "mm-pu-pradee-laas",
        },
        {
            p: "د اُوښ غلا په ټيټه ملا نۀ کېږى.",
            f: "du oox ghlaa pu TéeTa mlaa nu keGee.",
            e: "A camel can't be stolen with a bent back.",
            a: "mm-du-oox-ghlaa",
        },
        {
            p: "خپل څادر سره پښې غزوه",
            f: "khpul tsaadúr sara xpe ghazawa.",
            e: "Stretch your feet according to your cover.",
            a: "mm-xpul-tsaadur-sara",
        },
    ],
    [ // Lesson 4
        {
            p: "دانه دانه چې يوځائ شى غر ترې جوړ شى.",
            f: "daana daana che yo dzaay shee ghur tre joR shee.",
            e: "A grain on a grain makes a mountain.",
            a: "daana-daana-che-yo-dzaay",
        },
        {
            p: "چې اُوښان ساتې نو دروازې به دنګې ساتې.",
            f: "che ooxáan saaté, no darwaaze ba dúnge saaté.",
            e: "If you keep camels, then you should have tall doors.",
            a: "che-ooxaan-saate",
        },
    ],
    [ // Lesson 5
        {
            p: [
                "هغه يار چې بې وفا دے څۀ يار نۀ دے،",
                "او هغه کار چې بې بقا دے څۀ کار نۀ دے.",
            ],
            f: [
                "hágha yaar che be wafáa dey tsu yaar nu dey,",
                "aw hágha kaar che be baqáa dey tsu kaar nu dey.",
            ],
            e: [
                "That friend who is unfaithful is no friend at all,",
                "And that work which is temporal is no work at all.",
            ],
            a: "5-mm-1",
        },
        {
            p: "زهٔ په طمع تهٔ بې غمه.",
            f: "zu pu túma tu be ghúma.",
            e: "I am expectant and you don't care.",
            a: "5-mm-2",
        },
        {
            p: "چې سر حیات وی ټوپۍ ډېرې",
            f: "che săr hayáat wee Topúy Dere",
            e: "If the head is living caps are many.",
            a: "5-mm-3",
        },
        {
            p: "چې څهٔ کرې هغه به رېبې.",
            f: "che tsu kare haghá ba rebe.",
            e: "As you sow so shall you reap.",
            a: "5-mm-4",
        },
    ],
    [ // Lesson 6   
        {
            p: "خُدائ چې څۀ کړى، هغه ښۀ کړى.",
            f: "khUdaay che tsu kRee, haghá xu kRee.",
            e: "What God does, he does well.",
            a: "6mm2",
        },
        {
            p: "څۀ ژرنده ورانه او څۀ دانې لمدې.",
            f: "tsu jzrandá wraana aw tsu daané lumbde.",
            e: "Partly the mill is broken, partly the grain is damp.",
            a: "6mm3",
        },
        {
            p: "د غل په ږیره خس وی.",
            f: "du ghul pu Géera khus wee.",
            e: "There's a straw in the thief's beard.",
            a: "6mm4",
        },
    ],
    [ // Lesson 7
        {
            p: "په پوښتنه پوښتنه سړے کابل ته رسيدے شى.",
            f: "pu poxtúna poxtúna saRéy kaabúl ta rasedéy shee.",
            e: "By asking and asking a man can reach Kabul.",
            a: "7mm1",
        },
        {
            p: "خر چې د خر نه کم وى غوږ يې د پرې کولو وى.",
            f: "khur che du khur na kum wee ghwuG ye du pre kawulo wee.",
            e: "If a donkey is less than a donkey it's ear should be cut.",
            a: "7mm2",
        },
        {
            p: "الوتې مرغۍ په لاس نۀ راځى.",
            f: "alwute marghúy pu laas nu raadzee.",
            e: "The bird that has flown can't come to the hand.",
            a: "7mm3",
        },
    ],
    [  // Lesson 8
        {
            p: "اول وخوره د ځان غوښې بيا وخوره د ښکار غوښې.",
            f: "awál óokhora du dzaan ghwáxe byaa óokhora du xkaar ghwáxe.",
            e: "First eat the meat of yourself then eat the meat of the hunt.",
            a: "8mm1",
        },
        {
            p: "دې ته به د چا پلار ټينګ شى!",
            f: "de ta ba du chaa plaar Teeng shee!",
            e: "Whose father can resist it!",
            a: "8mm2",
        },
        {
            p: "هر څۀ ځى خو عادت نۀ ځى.",
            f: "hăr tsu dzee kho aadăt nu dzee.",
            e: "Everything leaves but not a habit.",
            a: "8mm3",
        },
        {
            p: [
                "په ظاهر لباس غلط د سړى مۀ شه",
                "مېنځ يې ګوره له چغزې او له مټاکه.",
            ],
            f: [
                "pu zaahír libáas ghalát du saRée mu sha",
                "mendz ye góra la chughzé aw la muTáaka",
            ],
            e: [
                "Do not be fooled by the outer appearance of a man,",
                "Look in the centre to see whether he is soft or hard.",
            ],
            a: "8mm4",
        },
    ],
    [ // Lesson 9
        {
            p: "چرګه زما يې او اګۍ دَ بل کره اچوې.",
            f: "chúrga zmaa ye aw agúy du bul kára achawe.",
            e: "You are my hen and lay eggs in someone else's house.",
            a: "9mm1",
        },
        {
            p: "تۀ پردو غمونو وخوړې.",
            f: "tu pradó ghumóono óokhoRe.",
            e: "Someone else's sorrows consumed you.",
            a: "9mm2",
        },
        {
            p: [
                "د رحمان وېنا به هله در په ياد شى،",
                "چې دې يو دېوال ته مخ بل ته دې شا شى.",
            ],
            f: [
                "du rahmáan wenaa bu hala dăr pu yaad shee,",
                "che du yo deewáal ta mukh bul ta de shaa shee.",
            ],
            e: [
                "You will remember Rahman's advice,",
                "When one wall is in front of you and one behind.",
            ],
            a: "9mm3",
        },
    ],
    [   // Lesson 10
        {
            p: "په خوله خوږه او په زړۀ کښې کږه.",
            f: "pu kholá khwaGá aw pu zRu ke kaGá.",
            e: "Sweet mouth, but crooked heart.",
            a: "10mm1",
        },
        {
            p: "چې دَ خُدائ په چا نظر شى تورے خاورې ورته زر شى.",
            f: "che du khUdáay pu chaa nazár shee tóre khaawre wăr-ta zur shee.",
            e: "Black soil becomes gold for the one whom God looks at with favour.",
            a: "10mm2",
        },
        {
            p: [
                "زۀ رحمان چې غشے ولم خطا نۀ ځى.",
                "په بغداد کښې مې کام ل کام ل پيران شته.",
            ],
            f: [
                "zu rahmáan che ghúshey wálum khatáa nu dzee.",
                "pu bUghdáad ke me kaamíl kaamíl peeráan shta.",
            ],
            e: [
                <>I, Rahman don't miss when I shoot an arrow.</>,
                <>In Baghdad there are perfect, perfect <em>pirs</em>.</>,
            ],
            a: "10mm3",
        },
    ],
    [ // Lesson 11
        {
            p: "چې چرته اوسې په خوئ به د هغو شې.",
            f: "che chărta óse, pu khooy ba du haghó she",
            e: "Your manners will be the same as those you live with.",
            a: "11mm1",
        },
        {
            p: "ښۀ پوهېږم خو نۀ پوهېږم.",
            f: "xu pohéGum kho nu pohéGum",
            e: "I understand it will but don't understand it.",
            a: "11m2",
        },
        {
            p: "د ماشُوم په ژبه خپله مور پوهېږى.",
            f: "du maashóom pu jziba khpúla mor pohéGee.",
            e: "A mother knows the sound of her own child's voice.",
            a: "11m3",
        },
        {
            p: [
                "سړيتوب څۀ په دولت نۀ دے رحمانه!",
                "بت د سرو زرو کۀ جوړ شى انسان نۀ دے.",
            ],
            f: [
                "saReetób tsu pu dawlat nu dey rahmáana!",
                "bUt du sro zuro ku joR shee insaan nu dey.",
            ],
            e: [
                "Manliness is not found in wealth, Rahman!",
                "Even if an idol is made from gold, it is not human.",
            ],
            a: "11m4",
        },
        {
            p: [
                "په يو قدم تر عرشه پورې رسى",
                "ما ليدلے دے رفتار د دروېشانو.",
            ],
            f: [
                "pu yo qadúm tur ársha pore rásee",
                "maa leeduley dey riftáar du darweshaano.",
            ],
            e: [
                "They reach God's throne in a single stride",
                "I have witnessed the ways of Dervishes.",
            ],
            a: "11m5",
        },
    ],
    [ // Lesson 12
        {
            p: "مُفت شراب قاضى هم څښلى دى.",
            f: "mUft sharáab qaazée hum skúlee dee.",
            e: "Even a judge has drunk free alcohol.",
            a: "12mm1",
        },
        {
            p: "پيشو د خُدائ د پاره منږک نۀ نيسى.",
            f: "peeshó du khUdáay dupáara munGúk nu neesee.",
            e: "A cat doesn't catch mice for God.",
            a: "12mm2",
        },
        {
            p: "راتلل په اراده او تلل په اجازه.",
            f: "raatlul pu iraada aw tlul pu ijaaza.",
            e: "Coming requires intention, going requires permission.",
            a: "12mm3",
        },
        {
            p: [
                "کارغۀ د ښارو ګرځېدل زده کول،",
                "خپل ګرځېدل ترې هم هېر شو. اوس په ټوپُونو ځى.",
            ],
            f: [
                "kaarghú du xaaró gurdzedul zda kawul,",
                "khpul gurdzedul tre hum her sho. oos pu Topóono dzee.",
            ],
            e: [
                "The crow was learning to walk like a myna bird,",
                "and forgot his own way to walk. Now he goes in hops.",
            ],
            a: "12mm4",
        },
    ],
    [ // Lesson 13
        {
            p: "سودم ته شين دے.",
            f: "sodúm ta sheen dey.",
            e: <>It's clear towards <em>Sodum</em>.</>,
            a: "13mm1",
        },
        {
            p: "تور سپے خړ سپے دواړه سپى دى.",
            f: "tor spey khuR spey, dwáaRu spee dee.",
            e: "Black dog, grey dog, both are dogs.",
            a: "13mm2",
        },    
        {
            p: "د ګيدړ د سلو کالُو ژوند نه د زمرى يوه ورځ ژوند ښۀ دے.",
            f: "du geedúR du súlo kaalo jzwund du zmarée yuwáwrudz jzwund xu dey.",
            e: "One day of a lion's life is better than one hundred years of a jackal.",
            a: "13mm3",
        },
        {
            p: [
                "خوب خندا په بې غمۍ کېږى، رحمانه.",
                "چې په زړۀ يې د يار غم وى څۀ به خوب څۀ به خندا که؟",
            ],
            f: [
                "khob handáa pu be ghumúy kéGee, rahmáana",
                "che pu zRu ye du yaar ghum wee, tsu khob tsu ba khandaa ka?",
            ],
            e: [
                "Sleep and laughter is found where there is an absence of sorrow, Rahman.",
                "Where there is sorrow for a friend in one's heart, how can one sleep or laugh?",
            ],
            a: "13mm4",
        },
    ],
    [ // Lesson 14
        {
            p: "چې ځې ځې ابازو له به راځې.",
            f: "che dze dze abaazó la ba raadzé.",
            e: "However far you go you will come back to Abazai.",
            a: "14mm1",
        },    
        {
            p: "هله به خبر شې چې تارو جبې له ورشې.",
            f: "hála ba khabúr she che táaroo jíbe la wăr-she.",
            e: <>When you go to <em>Taru Jabba</em> you will know what to do.</>,
            a: "14mm2",
        },
        {
            p: "خپل بد د ولو مينځ وى.",
            f: "khpul bud du wúlo mendz wee.",
            e: "Your own weakness lies between the shoulder blades.",
            a: "14mm3",
        },
        {
            p: "نېکى برباد ګُناه لازم.",
            f: "nekee barbáad gUnáa laazím.",
            e: "Goodness went in vain and I am blamed of sin.",
            a: "14mm4",
        },
        {
            p: [
                "چې په زړۀ کښې دې څۀ عیب نۀ وى رحمانه",
                "ښُکلې مخ ته په کتو کښې ګُناه نشته.",
            ],
            f: [
                "che pu zRu ke de tsu ayb nu wee rahmáana",
                "xkÚle mukh ta pu kató ke gUnáa níshta.",
            ],
            e: [
                "If there is no flaw in your heart Rahman,",
                "Then it's no sin to look at a pretty face.",
            ],
            a: "14mm5",
        },
    ],
    [ // Lesson 15
        {
            p: "دې کښې به يې خیر وى.",
            f: "de ke ba ye kheyr wee.",
            e: "There will be good in it.",
            a: "15mm1",
        },
        {
            p: "دَ پښتُون يوه خبره وى.",
            f: "du puxtóon yuwá khabúra wee.",
            e: "Pukhtuns stick to their word.",
            a: "15mm2",
        },
        {
            p: "شپه چې تېره شى، خبره هېره شى.",
            f: "shpa che téra shee, khabúra héra shee.",
            e: "The night is gone, the talk is forgotten.",
            a: "15mm3",
        },
        {
            p: "مۀ کوه په ما وبه شى په تا.",
            f: "mu kawa pu maa, óo-ba-shee pu taa.",
            e: "Don't do it to me, it will be done to you.",
            a: "15mm4",
        },
        {
            p: [
                "کر د ګُلو کړه چې سيمه دې ګُلزار شى.",
                "اغزى مۀ کره په پښو کښې به دې خار شى.",
            ],
            f: [
                "kar du gÚlo kRa, che séema de gUlzáar shee.",
                "aghzée mu-kara pu xpo ke ba de khaar shee.",
            ],
            e: [
                "Grow flowers to make your surroundings blossom.",
                "Don't sow thorns, they will become thorns in your feet.",
            ],
            a: "15mm5",
        },
        {
            p: [
                "چې الله درسره مل نۀ وى، رحمانه،",
                "کۀ لښکرې درسره وى يک تنها يې.",
            ],
            f: [
                "che aal`laa dăr-sara mal nu wee, rahmáana,",
                "ku laxkúre dăr-sara wee, yăk tanháa ye.",
            ],
            e: [
                "If God is not your companion, Rahman,",
                "Even if you have an army you are all alone.",
            ],
            a: "15mm6",
        },
    ],
    [ // Lesson 16
        {
            p: "عقل دَ بې عقلو نه زده کېږى.",
            f: "áqul du be aqlo na zda kéGee.",
            e: "Wisdom can be learned from fools.",
            a: "16mm1",
        },
        {
            p: "دَ خېشکو نسوار دى، په وروستو زور کوى.",
            f: "du kheshko naswáar dee, pu wróosta zor kawée.",
            e: <>The <em>naswar</em> of Kheshko has its effects later.</>,
            a: "16mm2",
        },
        {
            p: "شاګرد ته ټول چل ونه ښايه، يو ترې پټ ساته.",
            f: "shaagúrd ta Tol chalóona xaaya, yo tru puT saata.",
            e: "Teach a student everything, keep one thing hidden.",
            a: "16mm3",
        },
        {
            p: [
                "رحمان هسې بې وقُوف سوداګر نۀ دے",
                "چې دَ دين متاع بدله په دينار کا.",
            ],
            f: [
                "rahmáan háse be waqóof sodagár nu dey",
                "che de deen mutáa badúla pu deenaar kaa.",
            ],
            e: [
                "Rahman is not so stupid a merchant",
                "As to exchange religion's wealth for money.",
            ],
            a: "16mm4",
        },
    ],
    [ // Lesson 17
        {
            p: "کله کله سړے د شېطان هم منى.",
            f: "kala kala saRéy shetáan hum munée.",
            e: "Sometimes a person should obey Satan.",
            a: "17mm1",
        },
        {
            p: "يو خوا ډانګ دے بل خوا پړانګ دے.",
            f: "yo khwaa Daang dey, bul khwaa pRaang dey.",
            e: "On one side there is stick on the other side a leopard.",
            a: "17mm2",
        },
        {
            p: "د ستړو ځائ شهباز ګړه ده.",
            f: "du stuRo dzaay shabáaz gáRa da.",
            e: "The tired ones' place is Shabaz Gara.",
            a: "17mm3",
        },
        {
            p: "پردے جنګ نيم اختر دے.",
            f: "pradéy jang neem akhtár dey.",
            e: "Other people's quarrels are half a festival.",
            a: "17mm4",
        },
        {
            p: "حُجره نۀ اخلم خو شپه په کښې تېروم.",
            f: "hUjrá nú-akhlum, kho shpa pu ke terawúm.",
            e: "I am not buying the hujra, but spending the night in it.",
            a: "17mm5",
        },
    ],
    [ // Lesson 18
        {
            p: "پردے کټ تر نيمې شپې وى.",
            f: "pradéy kaT tur néeme shpe wee.",
            e: "Someone else's bed is only good till half way through the night.",
            a: "18mm1",
        },
        {
            p: "اولاد چې نيازبين شى نو نۀ دَ دين شى او نۀ دَ سادين شى.",
            f: "aawláad che nyaazbéen shee no nu de deen shee aw nu du shaadéen shee.",
            e: "When a child is spoiled he becomes neither religious nor a labourer.",
            a: "18mm2",
        },
        {
            p: "پردى نۀ خپلېږى.",
            f: "pradee nu khpuléGee.",
            e: "Outside folk never become relatives.",
            a: "18mm3",
        },
        {
            p: "کۀ غر لوئ دے، خو په سر يې لار ده.",
            f: "ku ghur looy dey, kho pu săr ye laar da.",
            e: "Even if the hill is big, there is a way to the top.",
            a: "18mm4",
        },
        {
            p: "چې رښتيا راځى، دروغو به کلى وران کړى وى.",
            f: "che rixtyaa raadzée, darógho ba kUlee wraan kuRee wee.",
            e: "Before truth comes, lies will have ruined the villages.",
            a: "18mm5",
        },
    ],
    [ // Lesson 19
        {
            p: "دَ سحر ورک چې ماښام کور ته راشى نو ورک نۀ دے.",
            f: "du sahár wruk che maaxáam kor ta ráashee no wruk nu dey.",
            e: "What is lost in the morning that shows up in the evening is not lost.",
            a: "19mm1",
        },
        {
            p: "چې خُدائ يې نۀ کړى پير بابا به يې څۀ کړى.",
            f: "che khUdáay ye nu kRee peer baabaa ba ye tsu kRee.",
            e: "If God doesn't do it, then what can Pir Baba do about it?",
            a: "19mm2",
        },
        {
            p: "د پردى کلى په صبا څوک نۀ پوهېږى.",
            f: "du pradée kulee pu sabáa tsok nu pohéGee.",
            e: "No one knows the dawn rituals of a foreign village.",
            a: "19mm3",
        },
        {
            p: "اور له راغله د کور مېرمن شوه.",
            f: "or la ráaghla du kor mermán shwa.",
            e: "She came to get a burning coal and became mistress of the house.",
            a: "19mm4",
        },
        {
            p: [
                "څنګه حال دے؟",
                "د دوؤ ښځو د خاوند نه مې ښۀ حال دے.",
            ],
            f: [
                "tsunga haal dey?",
                "du dwu`o xudzo khaawánd na me xu haal dey.",
            ],
            e: [
                "How are you?",
                "I'm better off than a man with two wives.",
            ],
            a: "19mm5",
        },
    ],
    [ // Lesson 20
        {
            p: "دَ سحر په بانګ کښې مې نوے ټپه واورېده.",
            f: "du sahár pu baang ke me níwey Tapa waawreda.",
            e: "I heard a new song in the morning call to prayer.",
            a: "20mm1",
        },
        {
            p: "نمر په ګوته نۀ پټېږى.",
            f: "nwar pu góota nu puTeGee.",
            e: "The sun cannot be hidden by a finger.",
            a: "20mm2",
        },
        {
            p: "د دروغو مزل لنډ وى.",
            // check pronounc
            f: "du darógho mazúl lunD wee.",
            e: "The distance of untruth is short.",
            a: "20mm3",
        },
        {
            p: [
                "عقل څۀ کړې بخت ترې وغواړه رحمانه",
                "عقلمند د بختورو غلامان وى.",
            ],
            // check pronounc ghUlaamaan
            f: [
                "áqul tsu kRe bakht tru óoghwaaRa rahmáana",
                "aqulmúnd du bakhawúro ghUlaamáan wee."
            ],
            e: [
                "What will you do with wisdom? Ask for luck Rahman.",
                "The wise are slaves of the lucky.",
            ],
            a: "20mm4",
        },
    ],
    [ // Lesson 21
        {
            p: [
                "نۀ په مينه مړېدل شته نۀ په مال",
                "نۀ په عُمر څوک مړېږى نۀ په سوال.",
            ],
            f: [
                "nu pu méena maRedul shta nu pu maal",
                "nu pu Úmar tsok maRéGee nu pu swaal.",
            ],
            e: [
                "There is no satisfaction in love, nor by things,",
                "Not by age are people filled, nor by begging.",
            ],
            a: "21mm1",
        },
        {
            p: "د باران نه تښتېدم د ناوې لاندې مې شپه شوه.",
            f: "du baaráan na tuxtedúm du naawé laande me shpa shwa",
            e: "I was getting out of the rain and ended up spending the night under a drain-pipe.",
            a: "21mm2",
        },
        {
            p: [
                "په دُنيا مۀ نازېږئ خلقه!",
                "د شاه جهان بادشاه نه پاتې شول تختُونه.",
            ],
            f: [
                "pu dUnyáa mu-naazeGeyy khalqa!",
                "du shaah jihaan baadsháa páat shwul takhtóona.",
            ],
            e: [
                "Don't love the world, Oh people!",
                "King Shah Jehan has left the throne.",
            ],
            a: "21mm3",
        },
    ],
    [ // Lesson 22
        {
            p: "چې يارانه په شلتالو شوه نو ما نه پاتې ده.",
            f: "che yaaraana pu shultaaló shwa no maa na paate da.",
            e: "If friendship is for a peach then I can't live with it.",
            a: "22mm1",
        },
        {
            p: "دوست له دومره قوت مۀ ورکوه چې برابرى درسره وکړى.",
            f: "dost la doomra qUwwát mu wărkawa che băraabăree dăr-sara óokRee.",
            e: "Don't give that much power to a friend that they can become equal to you.",
            a: "22mm2",
        },
        {
            p: "هر چا سره پنج، خو يار اشنا سره نۀ پنج.",
            f: "hăr chaa sara panj, kho yaar aashnáa sara nu panj.",
            e: "Compete with everyone but not with friends and companions.",
            a: "22mm3",
        },
        {
            p: "د کم عقل دوست نه هوښيار دُشمن ښۀ وى.",
            f: "du kum áqul dost na ooxyáar dUshmáan xu wee.",
            e: "The wise enemy is better than a stupid friend.",
            a: "22mm4",
        },
        {
            p: "هندُو د يار د پاره غټه غوښه خوړلې ده.",
            f: "hindóo du yaar dupaara, ghaTa ghwaxa khoRúle da.",
            e: "For a friend a Hindu has eaten beef.",
            a: "22mm5",
        },
    ],
    [ // Lesson 23
        {
            p: "ورورى خورى به کوُو خو حساب کتاب تر مېنځه.",
            f: "wróree khorée ba kawóo kho hisáab kitáab tur mendza.",
            e: "We will be like brothers and sisters but will keep accounts between us.",
            a: "23mm1",
        },
        {
            p: "دا ګز او دا میدان.",
            f: "daa gaz aw daa meydáan.",
            e: "This the yardstick and this the open ground.",
            a: "23mm2",
        },
        {
            p: "پښتُون سل کاله پس بدل واخستو او ویې وئيل چې زر مې واخستو.",
            f: "puxtoon sil kala pas badál wáaghisto aw we wayul [oo-ye-wayul] che zur me waaghisto.",
            e: `A Pukhtun took revenge after a hundred years and said, "I took it quickly."`,
            a: "23mm3",
        },
        {
            p: "په زور کلى نۀ کېږى.",
            f: "pu zor kúlee nu keGee.",
            e: "Community is not created by force.",
            a: "23mm4",
        },
        {
            p: "ښځې له يا کور دے يا ګور دے.",
            f: "xudze la yaa kor dey yaa gór dey.",
            e: "For the woman either the house or the grave.",
            a: "23mm5",
        },
    ],
    [ // Lesson 24
        {
            p: "چې بل ته کُوهے کنى، هغه پخپله پکښې پرېوځى.",
            f: "che bul ta ko`ey kunee, haghá pukhpúla pu ke preóodzee.",
            e: "The one who digs a well for another, will himself fall in it.",
            a: "24mm1",
        },
        {
            p: "د خپلو نه پردى ښۀ وى. د پردو نه اپريدى ښۀ وى.",
            f: "du khpulo na pradee xu wee. du pradó na aapreedee xu wee.",
            e: "Outsiders are better than relatives, and Afridis are better than outsiders.",
            a: "24mm2",
        },
        {
            p: "د تورې پرهر به روغ شى خو د ژبې پرهر نۀ روغيږى.",
            f: "du tóore parhar ba rogh shee kho du jzúbe parhar nu roghéGee.",
            e: "The wound of the sword can be healed, but the tongue's wound won't be healed.",
            a: "24mm3",
        },
        {
            p: "چې مرګ وينى، تبې ته رضا شى.",
            f: "che murg weenee, túbe ta razáa shee.",
            e: "If he sees death, he will be satisfied with a fever.",
            a: "24mm4",
        },
    ],
    [ // Lesson 25
        {
            p: [
                "ژوندے ځان په زمکه ښخ کړه لکه تخم",
                "کۀ لوئى غواړې د خاورو په مقام شه.",
            ],
            f: [
                "jzwundéy dzaan pu zmúka xukh kRa lúka tÚkhum",
                "ku looyee ghwaaRe, du khaawro pu maqáam sha.",
            ],
            e: [
                "Plant yourself alive, like a seed in the ground",
                "If you desire greatness, be in the place of dust.",
            ],
            a: "25mm1",
        },
        {
            p: "اتفاق کښې برکت وى.",
            f: "itifáaq ke barakát wee.",
            e: "There is blessing in unity.",
            a: "25mm2",
        },
        {
            p: "چرته ډب وى هلته ادب وى",
            f: "chărta Dub wee hálta adúb wee.",
            e: "Where there is beating there is obedience/manners.",
            a: "25mm3",
        },
        {
            p: "د اُوښ په شا سپو وخوړم.",
            f: "du oox pu shaa spo óokhoRum.",
            e: "I was bitten by dogs while sitting on the back of a camel.",
            a: "25mm4",
        },
    ],
    [ // Lesson 26
        {
            p: "دَ املوکو په تول کښې راغلے دے.",
            f: "du aamlóoko pu tol ke raaghúley dey.",
            e: "It came in the weighing of persimmons.",
            a: "26mm1",
        },
        {
            p: "پيشو خلق په اوله شپه وژنى.",
            f: "peesho khalaq pu awála shpa wujznee.",
            e: "People kill a cat on the first night.",
            a: "26mm2",
        },
        {
            p: "څۀ مو رانۀ کړل خو ما هم درته ټول مُونځونه بې اودسه کړى دى.",
            f: "tsu mU raa-nu-kRul kho maa hum dăr-ta Tol moondzóona be awdása kúRee dee.",
            e: "You didn't give me a thing, but I also have done all the prayers without ablution.",
            a: "26mm3",
        },
    ],
    [ // Lesson 27
        {
            p: "زمُونږ دومره قِسمت چرته دے؟",
            f: "zmoonG dóomra qismút chărta dey?",
            e: "Where would be so much luck for us?",
            a: "27mm1",
        },
        {
            p: "چې اختر تېر شى نکريزی په دیوال اوتپه.",
            f: "che akhtár ter shee nakréezee pu deewáal óotapa.",
            e: "When the festivities have ended, henna is pasted on the wall.",
            a: "27mm2",
        },
        {
            p: [
                "هر ز نده چې د مرده په قبر ورشى،",
                "دومره بس دے نصيحت په دې دُنيا.",
            ],
            f: [
                "hăr zindá che du mUrdá pu qúbur wăr-shee,",
                "dóomra bas dey naseehút pu de dUnyáa.",
            ],
            e: [
                "Any living person who sees a grave,",
                "For him this advice is enough.",
            ],
            a: "27mm3",
        },
        {
            p: [
                "کۀ هزار هزار پشتۍ ورله څوک ورکړى",
                "بې بُنياده دے بُنياد د دې دُنيا.",
            ],
            f: [
                "ku hazáar, hazáar pushtúy wăr-la tsok wăr-kRee",
                "be bUnyáada dey bUnyáad du de dUnyáa.",
            ],
            e: [
                "Even if someone gives thousands and thousands of supports,",
                "The foundation of this world is baseless.",
            ],
            a: "27mm4",
        },
    ],
];
