import React from "react";
import { HashLink } from 'react-router-hash-link';
// eslint-disable-next-line
import { BrowserRouter as Router, Link as RouterLink } from "react-router-dom";
import scrollWithOffset from "../../lib/scroll-with-offset.js";

function Link(props) {
    const { to } = props || "";
    if (to.includes("#")) {
        // If it's a hash link return the special hash link
        return <HashLink scroll={scrollWithOffset} to={to} style={props.style}>{props.children}</HashLink>;
    }
    // If it's a regular link return the regular router linker
    return <RouterLink to={to} style={props.style}>{props.children}</RouterLink>;
}; 

export default Link;