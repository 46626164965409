import React from 'react';

function ShareableTitle({ title, titleIcon, shareFunction }) {
    return (
        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "baseline" }}>
            <div style={{ display: "flex", alignItems: "center" }}>
                {titleIcon && <img src={titleIcon} alt="" style={{ marginRight: "1rem", flexShrink: 0, width: "2.5rem", height: "2.5rem" }}/>}
                <h1>{title}</h1>
            </div>
            {navigator.share && <div className="native-share-button" onClick={shareFunction}>
                <i className="material-icons">share</i>
            </div>}
        </div>
    );
}

export default ShareableTitle;