import React, { useState } from "react";

export default ({ store, maxWidth, width, rows }: { store: string, maxWidth?: string, width?: string, rows?: number }) => {
    const [value, setValue] = useState(localStorage.getItem(store) || "");

    function handleTyping(e: any) {
        localStorage.setItem(store, e.target.value);
        setValue(e.target.value);
    }

    // lines thanks to https://stackoverflow.com/a/34803946
    if (rows && rows > 1) {
        return (
            <textarea
                dir="auto"
                value={value}
                onChange={handleTyping}
                rows={rows}
                spellCheck={false}
                style={{
                    marginBottom: "1.5rem",
                    resize: "vertical",
                    width: "100%",
                    lineHeight: "4ch",
                    backgroundImage: "linear-gradient(transparent, transparent calc(4ch - 1px), #cccccc 0px)",
                    backgroundSize: "100% 4ch",
                }}
            />
        );
    }

    return (
        <input type="text" dir="auto" value={value} onChange={handleTyping} style={{
            border: 0,
            borderRadius: 0,
            borderBottom: "1.5px solid black",
            maxWidth: maxWidth || "inherit",
            width: width || "inherit",
        }} />
    );
}
