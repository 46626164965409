import React from 'react';

function License() {
    return (
        <div style={{ fontSize: "0.7rem", color: "grey", margin: "2rem 0", lineHeight: "1.4" }}>
            <div style={{ marginBottom: "1rem"}}>Copyright © 2020 Sampson, Robert</div>
            <div>All Rights Reserved. No part of this book may be used or reproduced in any manner whatsoever without written permission, except in the case of brief quotations.</div>
        </div>
    )
}

export default License;