
import React from 'react'
import { mdx } from '@mdx-js/react'
export const readingTime = {"text":"23 min read","minutes":22.39,"time":1343400,"words":4478}
/* @jsx mdx */
import Dialogue from "../components/Dialogue";
import Word from "../components/Word";
import Examples from "../components/Examples";
import Table from "../components/Table";
import Quiz from "../components/Quiz";
import memorizingMoments from "./memorizing-moments";
import readingWithDaughter from "../assets/images/reading-with-daughter.png";
import rahmanBook from "../assets/images/rahman-book.PNG";
import gateway from "../assets/images/gateway.png";

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope")
  return <div {...props}/>
};

const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <h2 {...{
      "id": "dialogue-ten-talking-about-religion"
    }}>{`Dialogue ten: Talking about religion`}</h2>
    <Dialogue audio="dialogue-10" mdxType="Dialogue">{[{
        s: "H",
        p: "تۀ مُسلمان شوے يې؟",
        f: "tu mUsalmáan shíwey ye?",
        e: "Have you become a Muslim?",
        a: "10d1"
      }, {
        s: "L",
        p: "زۀ يو خالِق مالِک رازِق خُدائ منم.",
        f: "zu yo khaalíq maalík raazíq khUdaay manúm.",
        e: "I believe in one creator, Lord, sustainer God.",
        a: "10d2"
      }, {
        s: "L",
        p: "يو ښۀ مُسلمان به تاسو څنګه پېژنئ؟",
        f: "yo xu mUsalmáan ba táaso tsínga péjzaneyy?",
        e: "How would you recognise a good Muslim?",
        a: "10d3"
      }, {
        s: "H",
        p: "هغه به پينځۀ وخته مُونځ کوى.",
        f: "haghá ba peendzú wakhta moondz kawee.",
        e: "He would do prayers five times.",
        a: "10d4"
      }, {
        s: "H",
        p: "او غلط کار به نۀ کوى.",
        f: "aw ghalát kaar ba nu kawée.",
        a: "10d5"
      }, {
        s: "L",
        p: "تا حج کړے دے؟",
        f: "taa haj kúRey dey?",
        e: "Have you done the pilgrimage?",
        a: "10d6"
      }, {
        s: "H",
        p: "نه، ما حج نۀ دے کړے.",
        f: "na, maa haj nu dey kuRey.",
        e: "No, I haven't done the pilgrimage.",
        a: "10d7"
      }, {
        s: "L",
        p: "ښه، دا راته ووايه چې څومره آسمانى کتابونه دى؟",
        f: "xa, daa raa-ta óo-waaya che tsóomra aasmaanee kitaaboona dee?",
        e: "Fine, tell me how many heavenly books there are?",
        a: "10d8"
      }, {
        s: "H",
        p: "څلور آسمانى کتابونه دى: قُرآن شريف، اِنجيل شريف، زبُور شريف او تورات شريف.",
        f: "tsalor aasmaanee kitaaboona dee: qUraan sharéef, injéel sharéef, zabóor shareef aw toráat shareef.",
        e: "There are four heavenly books: the Holy Quran, The Holy Gospel, the Holy Psalms and the Holy Torat.",
        a: "10d9"
      }, {
        s: "L",
        p: "تۀ دا کتابونه وائى؟",
        f: "tu daa kitaaboona waaye?",
        e: "Do you read these books?",
        a: "10d10"
      }, {
        s: "H",
        p: "نه، ټول نۀ وايم. قُرآن شريف مې وئيلے دے.",
        f: "na, Tol nu waayum. qUráan sharéef me wayuley dey.",
        e: "No, I don't read them all. I have read the Holy Quran.",
        a: "10d11"
      }]}</Dialogue>
    <h2 {...{
      "id": "pronunciation-drill"
    }}>{`Pronunciation drill`}</h2>
    <p>{`Focus on `}<Word w={{
        p: "ړ",
        f: "R",
        a: "rr-sound-2"
      }} mdxType="Word" /></p>
    <Examples memorizingMoment mdxType="Examples">{[{
        p: "ړُوند سړے لړم وچيچو او مړ شو.",
        f: "Roond saRey laRúm óocheecho aw muR sho.",
        e: "The blind man bit a scorpion and died.",
        a: "10p1"
      }]}</Examples>
    <p>{`👆 Practice this sentence with your helper.`}</p>
    <img src={readingWithDaughter} />
    <h2 {...{
      "id": "non-emphatic-possessive-pronouns"
    }}>{`Non-emphatic possessive pronouns`}</h2>
    <Table bordered unset wide titleRow={["Person", "Singular", "Plural"]} mdxType="Table">{[[{
        e: "1st"
      }, {
        p: "مې",
        f: "me",
        e: "my",
        a: "me-clitic"
      }, {
        p: "مو",
        f: "mU",
        e: "our",
        a: "muu-clitic"
      }], [{
        e: "2nd"
      }, {
        p: "دې",
        f: "de",
        e: "your",
        a: "de-clitic"
      }, {
        p: "مو",
        f: "mU",
        e: "your (plur. && polite sing.)",
        a: "muu-clitic"
      }], [{
        e: "3rd"
      }, {
        p: "یې",
        f: "ye",
        e: "his, her, its",
        a: "ye-clitic"
      }, {
        p: "یې",
        f: "ye",
        e: "theirs",
        a: "ye-clitic"
      }]]}</Table>
    <h4 {...{
      "id": "notes"
    }}>{`NOTES:`}</h4>
    <ol>
      <li parentName="ol">
        <p parentName="li">{`These possessive pronouns are interchangeable with the possesive pronouns (`}<Word w={{
            p: "زما",
            f: "‍zmaa",
            a: "zmaa"
          }} mdxType="Word" />, <Word w={{
            p: "ستا",
            f: "staa",
            a: "staa"
          }} mdxType="Word" />{`, etc.) already introduced (for example, Lesson 7 Possessive pronouns).`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Non-emphatic possessive pronouns always follow another word, generally the noun they modify. For example, the sentence “My son is coming home” could be said as:`}</p>
      </li>
    </ol>
    <Examples mdxType="Examples">{[{
        p: "زما زوئ کور ته راځى.",
        f: "zmaa zooy kor ta raadzee.",
        a: "10e1"
      }, {
        p: "زوئ مې کور ته راځى.",
        f: "zooy me kor ta raadzee.",
        a: "10e2"
      }]}</Examples>
    <ol {...{
      "start": 3
    }}>
      <li parentName="ol">{`These non-emphatic prouns can also be used as `}<em parentName="li">{`objects of present tense sentences`}</em>{` or `}<em parentName="li">{`subjects of transitive past tense sentences`}</em>{`. For example:`}</li>
    </ol>
    <Examples mdxType="Examples">{[{
        p: "شور مو ډېر زیات تنګوی.",
        f: "shor mU Der zyaat tangawee.",
        e: <>The noise disturbs <em>us</em> a lot</>,
        a: "10e3"
      }, {
        p: "دا مو څنګه پوخ کړے دے؟",
        f: "daa mU tsínga pokh kúRey dey?",
        e: <>How did <em>you</em> cook it?</>,
        a: "10e4"
      }]}</Examples>
    <ol {...{
      "start": 4
    }}>
      <li parentName="ol"><Word w={{
          p: "مو",
          f: "mU",
          a: "muu-cliticf"
        }} mdxType="Word" />, just like <Word w={{
          p: "تاسو",
          f: "táaso",
          a: "taaso"
        }} mdxType="Word" /> can be used to refer to a singular person of higher rank. (Plural of respect)</li>
    </ol>
    <h4 {...{
      "id": "non-emphatic-possessive-pronouns-in-sentences"
    }}>{`Non-emphatic possessive pronouns in sentences`}</h4>
    <Examples mdxType="Examples">{[{
        p: "زما ترۀ د ورسک نه نن راځى.",
        f: "zmaa tru du warsúk na nun raadzée.",
        e: "My uncle is coming from Warsak today.",
        a: "10e5"
      }, {
        p: "ترۀ مې د ورسک نه نن راځى.",
        f: "tru me du warsúk na nun raadzée.",
        e: "My uncle is coming from Warsak today.",
        a: "10e6"
      }, {
        p: "زمُونږ خور حلوا خورى.",
        f: "zmoonG khor halwáa khorée",
        e: "Our sister is eating pudding.",
        a: "10e7"
      }, {
        p: "خور مو حلوا خورى.",
        f: "khor mU halwaa khorée.",
        e: "Our sister is eating pudding.",
        a: "10e8"
      }, {
        p: "ستا پلار په کارخانه کښې کار کوى؟",
        f: "staa plaar pu kaarkhaaná ke kaar kawee?",
        e: "Does your (sing.) father work in a factory?",
        a: "10e9"
      }, {
        p: "پلار مو په کارخانه کښې کار کوى؟",
        f: "plaar mU pu kaarkhaana ke kaar kawee?",
        e: "Does your (plur.) father work in a factory?",
        a: "10e10"
      }, {
        p: "ستاسو ډوډۍ ډېره مزېداره ده.",
        f: "staaso DoDúy Déra mazeedáara da.",
        e: "Your (plur.) food is very delicious.",
        a: "10e11"
      }, {
        p: "ډوډۍ مو ډېره مزېداره ده.",
        f: "DoDúy mU Déra mazeedáara da.",
        e: "Your (plur.) food is very delicious.",
        a: "10e12"
      }, {
        p: "د دې مور بُوډۍ ده.",
        f: "du de mor booDúy da.",
        e: "Her mother is old.",
        a: "10e13"
      }, {
        p: "مور يې بُوډۍ ده.",
        f: "mor ye booDúy da.",
        e: "Her mother is old.",
        a: "10e14"
      }, {
        p: "د هغوى کور هلته دے.",
        f: "du haghwee kor hálta dey.",
        e: "Their house is there.",
        a: "10e15"
      }, {
        p: "کور يې هلته دے.",
        f: "kor ye hálta dey.",
        e: "Their house is there.",
        a: "10e16"
      }]}</Examples>
    <p>{`👆 Practice interchanging the possessive adjectives in similar sentences.`}</p>
    <h2 {...{
      "id": "possesive-pronouns-with-word-w-p-پکار-f-pukáar-a-pukaar---to-signal-need"
    }}>{`Possesive pronouns with `}<Word w={{
        p: "پکار",
        f: "pukáar",
        a: "pukaar"
      }} mdxType="Word" />{` to signal need`}</h2>
    <Examples mdxType="Examples">{[{
        p: "ستا هغه قلم پکار دے؟",
        f: "staa hágha qalúm pukáar dey?",
        e: "Do you need that pen?",
        a: "10e17"
      }, {
        p: "هغه قلم دې پکار دے؟",
        f: "hágha qalúm de pukáar dey?",
        e: "Do you need that pen?",
        a: "10e18"
      }, {
        p: "زما درې سرې کاپيانې پکار دى.",
        f: "zmaa dre sre kapiyáane pukáar dee.",
        e: "I need three red notebooks.",
        a: "10e19"
      }, {
        p: "درې سرې کاپيانې مې پکار دى.",
        f: "dre sre kapiyáane me pukáar dee.",
        e: "I need three red notebooks.",
        a: "10e20"
      }, {
        p: "د هغۀ څۀ شے پکار دے؟",
        f: "du haghú tsu shey pukáar dey?",
        e: "What does he need?",
        a: "10e21"
      }, {
        p: "څۀ شے يې پکار دے؟",
        f: "tsu shey ye pukáar dey?",
        e: "What does he need?",
        a: "10e22"
      }, {
        p: "ستاسو دا څپلۍ پکار نۀ دى؟",
        f: "staaso daa tsaplúy pukáar nú-dee?",
        e: "Don't you need these sandals?",
        a: "10e23"
      }, {
        p: "دا څپلۍ مو پکار نۀ دى.",
        f: "daa tsupulúy mU pukáar nu nu.",
        e: "We do not need these sandals.",
        a: "10e24"
      }, {
        p: "زمُونږ اوس هېڅ شے پکار نۀ دے.",
        f: "zmoonG oos hets shey pukáar nu dey.",
        e: "We don't need anything now.",
        a: "10e25"
      }, {
        p: "اوس مو هېڅ شے پکار نۀ دے.",
        f: "oos mU hets shey pukáar nu dey.",
        e: "We don't need anything now.",
        a: "10e26"
      }, {
        p: "د هغوى ډوډۍ پکار ده.",
        f: "du haghwée DoDúy pukáar da.",
        e: "They need food.",
        a: "10e27"
      }, {
        p: "ډوډۍ يې پکار ده.",
        f: "DoDúy ye pukáar da.",
        e: "They need food.",
        a: "10e28"
      }]}</Examples>
    <h2 {...{
      "id": "fifteen-common-transitive-verbs-in-the-present-tense"
    }}>{`Fifteen common transitive verbs in the present tense`}</h2>
    <ol>
      <li parentName="ol"><Word w={{
          p: "اغوستل",
          f: "aghostúl",
          e: "to wear, to put on",
          a: "aghostul"
        }} mdxType="Word" /> - present tense verb stem: <Word w={{
          p: "اغوند",
          f: "aghond",
          a: "aghond"
        }} mdxType="Word" /></li>
    </ol>
    <Examples mdxType="Examples">{[{
        p: "نن هوا ډېره يخه ده. څۀ اغوندې؟",
        f: "nun hawáa Déra yakha da. tsu aghonde?",
        e: "Today the air is very cold. What are you wearing?",
        a: "10e29"
      }, {
        p: "زۀ يو درُوند کوټ اغوندم.",
        f: "zu yo dróond koT aghondum.",
        e: "I am wearing a heavy coat.",
        a: "10e30"
      }, {
        p: "هغه نوې جامى اغوندى.",
        f: "haghá núwe jaame aghondee.",
        e: "He is wearing a new shalwar kamis.",
        a: "10e31"
      }]}</Examples>
    <ol {...{
      "start": 2
    }}>
      <li parentName="ol"><Word w={{
          p: "اورېدل",
          f: "aawredul",
          e: "to hear, to listen",
          a: "aawredul"
        }} mdxType="Word" /> - Present tense verb stem: <Word w={{
          p: "اور",
          f: "aawr",
          a: "aawr"
        }} mdxType="Word" /></li>
    </ol>
    <Examples mdxType="Examples">{[{
        p: "تاسو خبرُونه اورئ؟",
        f: "taaso khabróona aawreyy?",
        e: "Do you listen to the news?",
        a: "10e32"
      }, {
        p: "مُونږ هره شپه خبرُونه اورو.",
        f: "moonG hăra shpo khabróona aawroo.",
        e: "We listen to the news every night.",
        a: "10e33"
      }, {
        p: "هغه د پښتو سندرې اورى؟",
        f: "haghá du puxto sandúre áawree?",
        e: "Is he listening to Pukhto music?",
        a: "10e34"
      }, {
        p: "نه، هغه نۀ اورى.",
        f: "na, haghá nu áawree.",
        e: "No, he isn't listening.",
        a: "10e35"
      }]}</Examples>
    <h4 {...{
      "id": "note"
    }}>{`NOTE:`}</h4>
    <p>{`Verbs ending in `}<Word w={{
        p: "ـېدل",
        f: "-edul",
        a: "edul"
      }} mdxType="Word" /> are usually intransitive; <Word w={{
        p: "اورېدل",
        f: "aawredúl",
        e: "",
        a: "aawredul"
      }} mdxType="Word" />{` is an exception.`}</p>
    <ol {...{
      "start": 3
    }}>
      <li parentName="ol"><Word w={{
          p: "اخستل",
          f: "akhistúl",
          e: "to buy, to take",
          a: "akhistul"
        }} mdxType="Word" /> - Present tense verb stem: <Word w={{
          p: "اخل",
          f: "akhl",
          e: "",
          a: "akhl"
        }} mdxType="Word" /></li>
    </ol>
    <Examples mdxType="Examples">{[{
        p: "خانسامه مو هره ورځ د بازار نه سودا اخلى؟",
        f: "khaansaamá mU hărawrudz du baazáar na sodaa akhlee?",
        e: "Does your cook buy goods from the bazaar daily?",
        a: "10e36"
      }, {
        p: "نه، هره ورځ سودا نۀ اخلى.",
        f: "na hărawrudz sodaa nu akhlee.",
        e: "No, he does not buy goods every day.",
        a: "10e37"
      }, {
        p: "تۀ د هغۀ نه څۀ اخلې؟",
        f: "tu du haghú na tsu akhle?",
        e: "What are you taking from him?",
        a: "10e38"
      }, {
        p: "د هغۀ نه يو ښۀ کتاب اخلم.",
        f: "du haghú na yo xu kitáab ákhlum.",
        e: "I'm getting a good book from him.",
        a: "10e39"
      }]}</Examples>
    <ol {...{
      "start": 4
    }}>
      <li parentName="ol"><Word w={{
          p: "بوتلل",
          f: "botlúl",
          e: "to take (people/animals)",
          a: "botlul"
        }} mdxType="Word" /> - Present tense verb stem: <Word w={{
          p: "بوځ",
          f: "bodz",
          e: "",
          a: "bodz"
        }} mdxType="Word" /></li>
    </ol>
    <Examples mdxType="Examples">{[{
        p: "څۀ وخت هغه ډاکټر ته بوځې؟",
        f: "tsu wakht haghá DakTúr ta bodze?",
        e: "At what time are you taking him to the doctor?",
        a: "10e40"
      }, {
        p: "اوس یې بوځم",
        f: "oos ye bodzúm",
        e: "I'm taking him now.",
        a: "10e41"
      }, {
        p: "ډرائيور دا ښځه هم دفتر ته بوځى؟",
        f: "Draawúr daa xudza dăftar ta bodzee?",
        e: "Is the driver also taking this woman to the office?",
        a: "10e42"
      }, {
        p: "نه، نهٔ یې بوځی.",
        f: "na, nu ye bodzee.",
        e: "No, he's not taking her.",
        a: "10e43"
      }]}</Examples>
    <h4 {...{
      "id": "notes-1"
    }}>{`NOTES:`}</h4>
    <ol>
      <li parentName="ol">
        <Word w={{
          p: "بوتلل",
          f: "botlúl",
          e: "",
          a: "botlul"
        }} mdxType="Word" /> is only used for taking or carrying people and animals (i.e. animate objects).
      </li>
      <li parentName="ol">
        <p parentName="li">{`In contrast, the verb `}<Word w={{
            p: "وړل",
            f: "oRúl",
            e: "",
            a: "orrul"
          }} mdxType="Word" /> - Subjunctive verb stem: <Word w={{
            p: "یوس / وېس",
            f: "yos / wes",
            e: ""
          }} mdxType="Word" />{` is used with inanimate objects. For example:`}</p>
        <Examples mdxType="Examples">{[{
            p: "زۀ خط ډاکخانى ته يوسم؟",
            f: "zu khat Daak`khaane to yósum?",
            e: "Can I take a letter to the Post Office?",
            a: "10ee1"
          }]}</Examples>
      </li>
      <li parentName="ol">
        <Word w={{
          p: "ګنډل",
          f: "ganDúl",
          e: "to sow, to mend",
          a: "ganddul"
        }} mdxType="Word" />
      </li>
    </ol>
    <Examples mdxType="Examples">{[{
        p: "تۀ څۀ وخت زما قميص ګنډې؟",
        f: "tu tsu wakht zmaa kamées ganDé?",
        e: "When are you sewing my shirt?",
        a: "10e44"
      }, {
        p: "اوس يې ګنډم.",
        f: "oos ye ganDúm.",
        e: "I'm sewing it now.",
        a: "10e45"
      }, {
        p: "هغه اوس څۀ ګنډى؟",
        f: "haghá oos tsu ganDée?",
        e: "What is she sewing now?",
        a: "10e46"
      }, {
        p: "هغه نوې جامې ګنډى.",
        f: "haghá núwe jaamé ganDée.",
        e: "She is sewing new clothes.",
        a: "10e47"
      }]}</Examples>
    <ol {...{
      "start": 6
    }}>
      <li parentName="ol"><Word w={{
          p: "غوښتل",
          f: "ghwuxtúl",
          e: "to want, to ask (for)",
          a: "ghwuxtul"
        }} mdxType="Word" /> - Present tense verb stem: <Word w={{
          p: "غواړ",
          f: "ghwaaR",
          e: "",
          a: "ghwaarr"
        }} mdxType="Word" /></li>
    </ol>
    <Examples mdxType="Examples">{[{
        p: "هغه زما نه څۀ غواړى؟",
        f: "haghá zmaa na tsu ghwáaRee?",
        e: "What does she want from me?",
        a: "10e48"
      }, {
        p: "ستا نه لږې پیسې غواړى.",
        f: "staa na luGe peyse ghwaaRee.",
        e: "She wants a little money from you.",
        a: "10e49"
      }, {
        p: "تاسو څۀ غواړئ؟",
        f: "taaso tsu ghwaaReyy?",
        e: "What do you want?",
        a: "10e50"
      }, {
        p: "مِهربانى، هېڅ نۀ غواړم.",
        f: "miribaanée, hets ne ghwaaRum.",
        e: "Thanks, I don't want a thing.",
        a: "10e51"
      }]}</Examples>
    <ol {...{
      "start": 7
    }}>
      <li parentName="ol"><Word w={{
          p: "لیدل",
          f: "leedúl",
          e: "to see, to visit",
          a: "leedul"
        }} mdxType="Word" /> - Present tense verb stem: <Word w={{
          p: "وین",
          f: "ween",
          e: "",
          a: "ween"
        }} mdxType="Word" /></li>
    </ol>
    <Examples mdxType="Examples">{[{
        p: "تاسو هغه سړے وينئ؟",
        f: "taaso hágha saRéy weeneyy?",
        e: "Do you see that man?",
        a: "10e52"
      }, {
        p: "نه، زۀ يې د دې ځائ نه نۀ وينم.",
        f: "na, zu ye du de dzaay na nú weenum.",
        e: "No, I don't see him from here.",
        a: "10e53"
      }, {
        p: "هره ورځ خپل ملګرے وينې؟",
        f: "hărawrudz khpul malgúrey weene?",
        e: "Do you see your friend daily?",
        a: "10e54"
      }, {
        p: "نه، کله کله يې وينم.",
        f: "na, kala kala ye weenum.",
        e: "No, I see him now and again.",
        a: "10e55"
      }]}</Examples>
    <ol {...{
      "start": 8
    }}>
      <li parentName="ol"><Word w={{
          p: "لیکل",
          f: "leekúl",
          e: "to write",
          a: "leekul"
        }} mdxType="Word" /></li>
    </ol>
    <Examples mdxType="Examples">{[{
        p: "څوک ډېرې مزېدارې قيصې ليکى؟",
        f: "tsok Dére mazeedáare qeese leekée?",
        e: "Who writes very enjoyable stories?",
        a: "10e56"
      }, {
        p: "احمد ليکى.",
        f: "ahmad leekée.",
        e: "Ahmad writes.",
        a: "10e57"
      }, {
        p: "دا مضمُون د چا د پاره ليکې؟",
        f: "daa mazmóon du chaa dupáara leeké?",
        e: "Who are you writing this essay for?",
        a: "10e58"
      }, {
        p: "د کلاس د پاره يې ليکم.",
        f: "du klaas dupáara ye leekúm.",
        e: "I'm writing it for class.",
        a: "10e59"
      }]}</Examples>
    <ol {...{
      "start": 9
    }}>
      <li parentName="ol"><Word w={{
          p: "لوستل",
          f: "loostul, lwastul",
          e: "to read",
          a: "loostul"
        }} mdxType="Word" /> - Present tense verb stem: <Word w={{
          p: "لو",
          f: "lo",
          e: "",
          a: "lo"
        }} mdxType="Word" /></li>
    </ol>
    <Examples mdxType="Examples">{[{
        p: "تۀ اُردو لوستلے شې؟",
        f: "tu Urdóo loostúley she?",
        e: "Can you read Urdu?",
        a: "10e60"
      }, {
        p: "تۀ سحر څۀ لولې؟",
        f: "tu sahár tsu lóle?",
        e: "What do you read in the morning?",
        a: "10e61"
      }, {
        p: "زۀ سحر قُرآن پاک لولم.",
        f: "zu sahár qUráan-i-paak lolúm.",
        e: "I read the Holy Quran in the morning.",
        a: "10e62"
      }, {
        p: "تاسو ډېرې رسالې لولئ؟",
        f: "táaso Dere risaale lóleyy?",
        e: "Do you read a lot of magazines?",
        a: "10e63"
      }, {
        p: "نه، هره ورځ اخبار لولو.",
        f: "na, hărawrudz akhbáar lóloo.",
        e: "No, we read the newspapers daily.",
        a: "10e64"
      }]}</Examples>
    <h4 {...{
      "id": "note-1"
    }}>{`NOTE:`}</h4>
    <img src={rahmanBook} styple={{
      float: "right",
      maxWidth: "5rem"
    }} />
    <p>{`In common speech `}<Word w={{
        p: "وایل",
        f: "waayúl",
        e: "",
        a: "waayul"
      }} mdxType="Word" /> is often used in place of <Word w={{
        p: "لوستل",
        f: "loostul",
        e: "",
        a: "loostul"
      }} mdxType="Word" />. In special cases <Word w={{
        p: "لیدل",
        f: "leedúl",
        e: "",
        a: "leedul"
      }} mdxType="Word" />{` is also used to mean 'to read’.`}</p>
    <ol {...{
      "start": 10
    }}>
      <li parentName="ol"><Word w={{
          p: "وینځل",
          f: "weendzul",
          e: "to wash",
          a: "weendzul"
        }} mdxType="Word" /></li>
    </ol>
    <Examples mdxType="Examples">{[{
        p: "ښځه دې هره ورځ يواځې لوښى وينځى؟",
        f: "xudza de hărawrudz yawáaze lóoxee weendzee?",
        e: "Does your wife wash the dishes herself every day?",
        a: "10e65"
      }, {
        p: "او، ولې زۀ ورسره وينځم څۀ؟",
        f: "aw, wăle zu wărsara wéendzum ",
        e: "Yes, why, should I wash with her?",
        a: "10e66"
      }, {
        p: "تۀ هره هفته جامې وينځې؟",
        f: "tu hăra haftá jaamé wéendze?",
        e: "Do you wash clothes every week?",
        a: "10e67"
      }, {
        p: "او، هره هفته وينځم.",
        f: "aw, hăra haftá wéendzum.",
        e: "Yes, I wash them every week.",
        a: "10e68"
      }, {
        p: "ډوډۍ نه مخکښې زۀ لاس وينځم.",
        f: "DoDúy na mukhke zu laas wéendzum.",
        e: "I wash my hands before (eating) food.",
        a: "10e69"
      }]}</Examples>
    <h4 {...{
      "id": "notes-2"
    }}>{`NOTES:`}</h4>
    <ol>
      <li parentName="ol">
        <Word w={{
          p: "وینځل",
          f: "weendzúl",
          e: "",
          a: "weendzul"
        }} mdxType="Word" /> is only used to describe washing hands and feet, but not for the whole body (when <Word w={{
          p: "لمبل",
          f: "lambúl",
          e: "",
          a: "lambul"
        }} mdxType="Word" /> is used).
      </li>
      <li parentName="ol">
        <p parentName="li">{`Washing clothes and dishes is definitely `}<Word w={{
            p: "ښځو کار",
            f: "xudzo kaar",
            e: "women's work",
            a: "xudzo-kaar"
          }} mdxType="Word" />{` amongst traditional Pukhtun families, even when women are sick.`}</p>
      </li>
      <li parentName="ol">
        <Word w={{
          p: "پېژندل",
          f: "pejzandúl",
          e: "to know, to recognize",
          a: "pejzandul"
        }} mdxType="Word" /> - Present tense verb stem: <Word w={{
          p: "پېژن",
          f: "pejzan",
          e: "",
          a: "pejzan"
        }} mdxType="Word" />
      </li>
    </ol>
    <Examples mdxType="Examples">{[{
        p: "تۀ حسن پېژنې؟",
        f: "tu hasán péjzane?",
        e: "Do you know Hassan?",
        a: "10e70"
      }, {
        p: "او، پېژنم.",
        f: "aw, pejzanum.",
        e: "Yes, I know him.",
        a: "10e71"
      }, {
        p: "خفه نۀ شئ، زۀ تاسو نۀ پېژنم.",
        f: "khufa nu sheyy, zu táaso nu pejzanum.",
        e: "Don't be upset, I don't know you.",
        a: "10e72"
      }, {
        p: "تاسو ښۀ موټر پېژنئ؟",
        f: "taaso xu moTúr pejzaneyy?",
        e: "Can you recognise a good car?",
        a: "10e73"
      }, {
        p: "او، مُونږ پېژنُو.",
        f: "aw, moonG pejzanoo.",
        e: "Yes, we can recognise (one).",
        a: "10e74"
      }, {
        p: "هغوئ حقيقت پېژنى؟",
        f: "haghwée haqeeqát pejzanee?",
        e: "Do they know truth?",
        a: "10e75"
      }, {
        p: "زۀ دې پېژنم خو نُوم دې رانه هېر شو.",
        f: "zu de pejzanum kho noom de raa-na her sho.",
        e: "I know you, but I've forgotten your name.",
        a: "10e76"
      }]}</Examples>
    <h4 {...{
      "id": "note-2"
    }}>{`NOTE:`}</h4>
    <p>{`The verb `}<Word w={{
        p: "پوهېدل",
        f: "pohedúl",
        e: "",
        a: "pohedul"
      }} mdxType="Word" /> means 'to know or understand something', while <Word w={{
        p: "پېژندل",
        f: "pejzandúl",
        e: "",
        a: "pejzandul"
      }} mdxType="Word" />{` means 'to know someone’.`}</p>
    <ol {...{
      "start": 12
    }}>
      <li parentName="ol"><Word w={{
          p: "راوستل",
          f: "raawustúl",
          e: "to bring (animate objects)",
          a: "raawustul"
        }} mdxType="Word" /> - Present verb stem: <Word w={{
          p: "راول",
          f: "raawul",
          e: "",
          a: "raawul"
        }} mdxType="Word" /></li>
    </ol>
    <Examples mdxType="Examples">{[{
        p: "تاسو هره ورځ هغه د دفتر نه کور ته راولئ؟",
        f: "taaso hărawrudz haghá du dăftur na kor to raawuléyy?",
        e: "Do you bring her home from the office daily?",
        a: "10e77"
      }, {
        p: "او، هره ورځ يې راولم.",
        f: "aw, hărawrudz ye raawulúm.",
        e: "Yes, I bring her every day.",
        a: "10e78"
      }, {
        p: "رکشه به دې څۀ وخت دلته راولى؟",
        f: "rishka ba de tsu dălta raawulée?",
        e: "When will the rickshaw bring you here?",
        a: "10e79"
      }, {
        p: "هغه به مې بېګا له دلته راولى.",
        f: "hagha ba me begaa la dălta raawulée.",
        e: "He will bring me here tonight.",
        a: "10e80"
      }]}</Examples>
    <ol {...{
      "start": 13
    }}>
      <li parentName="ol"><Word w={{
          p: "راوړل",
          f: "raawRúl",
          e: "to bring (inanimate objects)",
          a: "raawrrul"
        }} mdxType="Word" /> - Present tense verb stem: <Word w={{
          p: "راوړ",
          f: "raawR",
          e: "",
          a: "raawrr"
        }} mdxType="Word" /></li>
    </ol>
    <Examples mdxType="Examples">{[{
        p: "نوکر دې څو بجې د بازار نه ډوډۍ راوړى؟",
        f: "nokúr de tso báje du baazáar na DoDúy raawRée?",
        e: "When does your servant bring bread from the bazaar?",
        a: "10e81"
      }, {
        p: "اتۀ، بجې راؤړى.",
        f: "atú báj raawRée.",
        e: "He brings it at eight o'clock.",
        a: "10e82"
      }, {
        p: "مُونږ له څوک چائې راؤړى؟",
        f: "moonG la tsok chaay raawRée?",
        e: "Who brings us tea?",
        a: "10e83"
      }, {
        p: "لُور مې راؤړى.",
        f: "loor me raawRée.",
        e: "My daughter brings it.",
        a: "10e84"
      }]}</Examples>
    <ol {...{
      "start": 14
    }}>
      <li parentName="ol"><Word w={{
          p: "وئیل / وائیل",
          f: "waayul / wayul",
          e: "to say, to tell, to read, to study",
          a: "waayul"
        }} mdxType="Word" /></li>
    </ol>
    <Examples mdxType="Examples">{[{
        p: "ته څه شے وائې؟",
        f: "tu tsu shey wáaye?",
        e: "What are you reading?",
        a: "10e85"
      }, {
        p: "يوه قيصه په اِنجيل شريف کښې وايم.",
        f: "yuwa qeesá pu injéel sharéef ke wáayum.",
        e: "I am reading a story in the Holy Injil.",
        a: "10e86"
      }, {
        p: "څۀ وائې؟",
        f: "tsu waaye?",
        e: "What are you saying?",
        a: "10e87"
      }, {
        p: "وايم ما سره چائې څکئ؟",
        f: "waayum maa sara chaay skeyy?",
        e: `I'm saying "Will you drink tea with me?"`,
        a: "10e88"
      }, {
        p: "زۀ سبق وايم.",
        f: "zu sabúq waayum.",
        e: "I am studying a lesson.",
        a: "10e89"
      }]}</Examples>
    <ol {...{
      "start": 15
    }}>
      <li parentName="ol"><Word w={{
          p: "وړل",
          f: "oRúl",
          e: "to take (inanimate objects)",
          a: "orrul"
        }} mdxType="Word" /></li>
    </ol>
    <Examples mdxType="Examples">{[{
        p: "تۀ نن زما کتاب وړې؟",
        f: "tu nun zmaa kitáab oRé?",
        e: "Are you taking my book today?",
        a: "10e90"
      }, {
        p: "او، نن ستا کتاب وړم.",
        f: "aw, nun staa kitáab oRúm.",
        e: "Yes, I'm taking your book today.",
        a: "10e91"
      }, {
        p: "هغه دا کاغذُونه دفتر ته وړى؟",
        f: "haghá daa kaaghazóona dăftur ta oRée?",
        e: "Is she taking these papers to the office?",
        a: "10e92"
      }, {
        p: "نه، نه یې وړی.",
        f: "na, nu ye oRée.",
        e: "No, she is not taking them.",
        a: "10e93"
      }]}</Examples>
    <h2 {...{
      "id": "memorising-moment"
    }}>{`Memorising moment`}</h2>
    <h4 {...{
      "id": "matalóona"
    }}>{`matalóona`}</h4>
    <Examples memorizingMoment mdxType="Examples">{memorizingMoments[10].slice(0, 2)}</Examples>
    <h4 {...{
      "id": "rahman-baba"
    }}>{`Rahman Baba`}</h4>
    <Examples memorizingMoment mdxType="Examples">{[memorizingMoments[10][2]]}</Examples>
    <h4 {...{
      "id": "note-3"
    }}>{`NOTE:`}</h4>
    <p>{`A `}<Word w={{
        p: "پیر",
        f: "peer",
        e: "pir"
      }} mdxType="Word" /> is a saint or religious guide. <Word w={{
        p: "خطا نه ځی",
        f: "khatáa nu dzee",
        e: ""
      }} mdxType="Word" />{`, translated 'to miss’, means literally, 'to not make a mistake’.`}</p>
    <h2 {...{
      "id": "tell-a-joke"
    }}>{`Tell a joke`}</h2>
    <p>{`👆 Have your language helper work with you so you can tell this joke. Then go out and use it.`}</p>
    <Dialogue audio="10joke" mdxType="Dialogue">{[{
        p: "زما يو دوست وو.",
        f: "zmaa yo dost wo.",
        e: "",
        a: "10j1"
      }, {
        p: "هغۀ وادۀ نۀ وو کړے",
        f: "haghá waadú nu wo kúRey",
        e: "",
        a: "10j2"
      }, {
        p: "او د خپل ورور په کور کښې اوسېدو.",
        f: "aw du khpul wror pu kor ke osedó.",
        e: "",
        a: "10j3"
      }, {
        p: "يوه ورځ پېښور ته راغے",
        f: "yúwawrudz pexawár ta ráaghey",
        e: "",
        a: "10j4"
      }, {
        p: "په هوټل کښې یې ډوډۍ خوړله",
        f: "pu hoTúl ke DoDúy khoRúla",
        e: "",
        a: "10j5"
      }, {
        p: "چې ډوډۍ یې وکتلې نو ټولې سوزېدلې وې.",
        f: "che DoDúy ye óokatule no Tóle swazedúle we.",
        e: "",
        a: "10j6"
      }, {
        p: `بېره ته يې آواز ورکړو "دېخوا راشه!"`,
        f: `bera ta ye aawáaz wărkRo, "de khwaa ráasha!"`,
        e: "",
        a: "10j7"
      }, {
        p: `"څۀ پکار دى؟"`,
        f: `"tsu pukáar dee?"`,
        e: "",
        a: "10j8"
      }, {
        p: `"زۀ باورچى خانې ته زم."`,
        f: `"zu baawarchee khaane ta dzum."`,
        e: "",
        a: "10j9"
      }, {
        p: `"څۀ د پاره؟ ما ته حکم کوئ چې څۀ پکار وى، زۀ به راوړم!"`,
        f: `"tsu dupáara? maa ta hÚkum kawéyy che tsu pukáar wee, zu ba ráawRum!"`,
        e: "",
        a: "10j10"
      }, {
        p: `"نه! زۀ په خپله کتل غواړم چې دا ډوډۍ زما ورندار خو نۀ دى پخې کړې."`,
        f: `"na! zu pu khpula katúl ghwáaRum che daa DoDúy zmaa wrandáar kho nu dee pakhe kúRe"`,
        e: "",
        a: "10j11"
      }]}</Dialogue>
    <p>{`The English translation is given at the end of the Lesson. Use it to check your translation / comprehension.`}</p>
    <img src={gateway} />
    <h2 {...{
      "id": "exercises-for-lesson-ten"
    }}>{`Exercises for Lesson Ten`}</h2>
    <ol>
      <li parentName="ol">{`Fill in the blanks in the following sentences with the correct form of the verb shown.`}</li>
    </ol>
    <Quiz mdxType="Quiz">{[{
        pref: {
          p: "وینځل",
          f: "weendzúl"
        },
        beg: {
          p: "هغه نن جامې",
          f: "hágha nun jáame"
        },
        end: {
          p: ".",
          f: "."
        },
        answer: [{
          p: "وینځی",
          f: "wéendzee"
        }]
      }, {
        pref: {
          p: "کتل",
          f: "katúl"
        },
        beg: {
          p: "مُونږ له دۀ سره خبرُونه",
          f: "moonG la du sará khabróona"
        },
        end: {
          p: ".",
          f: "."
        },
        answer: [{
          p: "ګورو",
          f: "góroo"
        }]
      }, {
        pref: {
          p: "اورېدل",
          f: "aawredúl"
        },
        beg: {
          p: "هغوى هره ورځ خبرُونه",
          f: "moonG hărawrudz khabróona"
        },
        end: {
          p: ".",
          f: "."
        },
        answer: [{
          p: "اورو",
          f: "áawroo"
        }, {
          p: "اورو",
          f: "áwroo"
        }]
      }, {
        pref: {
          p: "پېژندل",
          f: "pejzandúl"
        },
        beg: {
          p: "تۀ حسن او ګُل مکۍ",
          f: "tu hasán aw gUl makuy"
        },
        end: {
          p: ".",
          f: "."
        },
        answer: [{
          p: "پېژنې",
          f: "péjzane"
        }]
      }, {
        pref: {
          p: "لوستل",
          f: "loostúl"
        },
        beg: {
          p: "تاسو هره شپه يو نوے کتاب",
          f: "táaso hăra shpa yo núwey kitáab"
        },
        end: {
          p: ".",
          f: "."
        },
        answer: [{
          p: "لولئ",
          f: "lwuleyy"
        }, {
          p: "لولئ",
          f: "lwaleyy"
        }]
      }, {
        pref: {
          p: "بوتلل",
          f: "botlúl"
        },
        beg: {
          p: "ډرائيور مُونږ دفتر ته",
          f: "Draaywúr moonG dăftúr ta"
        },
        end: {
          p: ".",
          f: "."
        },
        answer: [{
          p: "بوځی",
          f: "bódzee"
        }, {
          p: "بوزی",
          f: "bózee"
        }]
      }, {
        pref: {
          p: "غوښتل",
          f: "ghwuxtúl"
        },
        beg: {
          p: "تاسو څۀ شے",
          f: "taaso tsu shey"
        },
        end: {
          p: ".",
          f: "."
        },
        answer: [{
          p: "غواړئ",
          f: "ghwaaReyy"
        }]
      }, {
        pref: {
          p: "لیدل",
          f: "leedúl"
        },
        beg: {
          p: "تۀ هره شپه زما ملګرے",
          f: "tu hăra shpa zmaa malgúrey"
        },
        end: {
          p: ".",
          f: "."
        },
        answer: [{
          p: "وینې",
          f: "weene"
        }]
      }, {
        pref: {
          p: "اخستل",
          f: "akhistúl"
        },
        beg: {
          p: "تاسو هغه کور",
          f: "táaso hágha kor"
        },
        end: {
          p: ".",
          f: "."
        },
        answer: [{
          p: "اخلئ",
          f: "ákhleyy"
        }]
      }, {
        pref: {
          p: "راوړل",
          f: "raawRúl"
        },
        beg: {
          p: "دوئ نن نوے مال زما دُوکان ته",
          f: "dwee nun núwey maal zmaa dookáan ta"
        },
        end: {
          p: ".",
          f: "."
        },
        answer: [{
          p: "راوړی",
          f: "ráawRee"
        }]
      }]}</Quiz>
    <ol {...{
      "start": 2
    }}>
      <li parentName="ol">{`Make meaningful questions and answers from the following pairs of words and then make similar sentences on your own.`}</li>
    </ol>
    <Examples mdxType="Examples">{[{
        p: "جامې وينځل",
        f: "jaame weendzul",
        a: "10q1"
      }, {
        p: "سندرې اورېدل",
        f: "sandúre aawredúl",
        e: "",
        a: "10q2"
      }, {
        p: "اخبر لوستل",
        f: "akhbar loostúl",
        e: "",
        a: "10q3"
      }, {
        p: "کپړه ګنډل",
        f: "kapRá ganDúl",
        e: "",
        a: "10q4"
      }, {
        p: "سودا اخستل",
        f: "sodaa akhistúl",
        e: "",
        a: "10q5"
      }, {
        p: "ماشُوم بوتلل",
        f: "maashóom botlúl",
        e: "",
        a: "10q6"
      }, {
        p: "قیصه لیکل",
        f: "qeesá leekúl",
        e: "",
        a: "10q7"
      }, {
        p: "لوښی وینځل",
        f: "lóoxee weendzúl",
        e: "",
        a: "10q8"
      }]}</Examples>
    <h4 {...{
      "id": "translation-of-the-joke"
    }}>{`Translation of the joke.`}</h4>
    <p>{`I had a friend. He was not married and lived with his brother. One day he came to Peshawar and ate bread in a restaurant. When he saw the bread, every piece was burned. He called to the waiter, “Come here!” "What do you want?" “I want to go into the kitchen.” "What for? Tell me what you want and I will bring it for you." “No! I want to see for myself if my sister-in-law made this bread.”`}</p>
    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;
export const tableOfContents = (components={}) => [
  {
    id: "dialogue-ten-talking-about-religion",
    level: 2,
    title: "Dialogue ten: Talking about religion",
    children: [
        
      ]
  },
{
    id: "pronunciation-drill",
    level: 2,
    title: "Pronunciation drill",
    children: [
        
      ]
  },
{
    id: "non-emphatic-possessive-pronouns",
    level: 2,
    title: "Non-emphatic possessive pronouns",
    children: [
        
      ]
  },
{
    id: "possesive-pronouns-with-word-w-p-پکار-f-pukáar-a-pukaar---to-signal-need",
    level: 2,
    title: <React.Fragment>{`Possesive pronouns with `}<Word w={{ p: "پکار", f: "pukáar", a: "pukaar" }} />{` to signal need`}</React.Fragment>,
    children: [
        
      ]
  },
{
    id: "fifteen-common-transitive-verbs-in-the-present-tense",
    level: 2,
    title: "Fifteen common transitive verbs in the present tense",
    children: [
        
      ]
  },
{
    id: "memorising-moment",
    level: 2,
    title: "Memorising moment",
    children: [
        
      ]
  },
{
    id: "tell-a-joke",
    level: 2,
    title: "Tell a joke",
    children: [
        
      ]
  },
{
    id: "exercises-for-lesson-ten",
    level: 2,
    title: "Exercises for Lesson Ten",
    children: [
        
      ]
  }
]

export const frontMatter = {
  "title": "Lesson Ten"
}

