export default [
    {
        p: "دا غټ او درُوند مېز دے.",
        f: "daa ghaT aaw droond mez dey.",
        e: "This is a large and heavy table.",
        a: "daa-ghatt-aaw-d",
    },
    {
        p: "هغه موټر تور او سپین دے.",
        f: "hágha moTúr tor aaw speen dey.",
        e: "That car is black and white.",
        a: "hagha-mottor-t",
    },
    {
        p: "تۀ ستړے يې؟",
        f: "tu stúRey ye?",
        e: "Are you tired?",
        a: "tu-sturrey-ye-q",
    },
    {
        p: "نه، ډېر ستړے نۀ يم.",
        f: "na, Der stúRey nu yim.",
        e: "No, I'm not very tired.",
        a: "na-dder-sturry-n",
        mb: true,
    },
    {
        p: "دا غټ کتاب دے؟",
        f: "daa ghaT kitáab dey?",
        e: "Is this a big book?",
        a: "daa-ghatt-kitaab-dey",
    },
    {
        p: "آو، دا غټ کتاب دے.",
        f: "aaw, daa ghaT kitáab dey.",
        e: "Yes, this is a big book.",
        a: "aaw-daa-ghatt-k",
        mb: true,
    },
    {
        p: "تۀ تږے يې؟",
        f: "tu túgey ye?",
        e: "Are you (m.) thirsty?",
        a: "tu-tugey-ye-q",
    },
    {
        p: "آو، زۀ ډېر تږے يم.",
        f: "aaw, zu Der túGey yim.",
        e: "Yes, I (m.) am very thirsty.",
        a: "aaw-zu-dder-tugey",
        mb: true,
    },
    {
        p: "هغه موټر کُوم رنګ دے؟",
        f: "hágha moTúr kum rang dey?",
        e: "What colour is that car?",
        a: "hagha-mottur-kum-r",
    },
    {
        p: "هغه سپين او سُور دے.",
        f: "hágha speen aaw soor dey.",
        e: "It's white and red.",
        a: "hagha-speen-aaw-s",
        mb: true,
    },
    {
        p: "دغه مېز درُوند دے؟",
        f: "dágha mez dróond dey?",
        e: "Is this table heavy?",
        a: "dagha-mez-droond-q",
    },
    {
        p: "نه، دغه مېز درُوند نۀ دے، سپک دے.",
        f: "na, dágha mez droond nu dey, spuk dey.",
        e: "No this table is not heavy, it's light.",
        a: "na-dagha-mez-droond-n",
    },
    {
        p: "دغه څۀ شے دے؟",
        f: "dágha tsu shey dey?",
        e: "What is that?",
        a: "dagha-tsu-shey-dey",
    },
    {
        p: "دغه قلم دے.",
        f: "dágha qalúm dey.",
        e: "It's a pen.",
        a: "dagha-qalum-dey",
    },
    {
        p: "دا قلم څنګه دے؟",
        f: "daa qalúm tsínga dey?",
        e: "How is this pen?",
        a: "daa-qalum-tsunga-dey",
    },
    {
        p: "دا ښۀ قلم دے.",
        f: "daa xu qalúm dey.",
        e: "It is a good pen.",
        a: "daa-xu-qalum-dey",
    },
    {
        p: "هغه څۀ شے دے؟",
        f: "hágha tsu shey dey?",
        e: "What thing is that?",
        a: "hagha-tsu-shey-dey",
    },
    {
        p: "هغه موټر دے.",
        f: "hágha moTúr dey.",
        e: "That is a car.",
        a: "hagha-mottur-dey",
    },
    {
        p: "هغه موټر څنګه دے؟",
        f: "hágha moTur tsunga dey?",
        e: "How is that car?",
        a: "hagha-mottur-tsunga",
    },
    {
        p: "هغه موټر ډېر خراب دے.",
        f: "hágha moTur Der kharaab dey.",
        e: "That car is very bad.",
        a: "hagha-mottur-der-k",
    },
];