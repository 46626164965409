export default [
    {
        p: "زهٔ پښتُون یم.",
        f: "zu puxtóon yim.",
        e: "I am a Pukhtun.",
        a: "zu-puxtoon-yim",
    },
    {
        p: "تهٔ طالِبعلم یې؟",
        f: "‍tu taalibílum ye?",
        e: "Are you a student?",
        a: "tu-taalibilum-ye",
    },
    {
        p: "دے افغان دے.",
        f: "dey afgháan dey.",
        e: "He is an Afghan.",
        a: "dey-afghaan-dey",
    },
    {
        p: "هغه شپُونکے دے.",
        f: "haghá shpoonkéy dey.",
        e: "He is a shepherd.",
        a: "hagha-shpoonkey-dey",
    },
    {
        p: "هغه سړے دے.",
        f: "haghá saRéy dey.",
        e: "He is a man.",
        a: "hagha-sarrey-dey",
    },
    {
        p: "زهٔ پلار یم.",
        f: "zu plaar yim.",
        e: "I am a father.",
        a: "zu-plaar-yim",
    },
    {
        p: "تهٔ هلک یې.",
        f: "tu halúk ye.",
        e: "You are a boy.",
        a: "tu-haluk-ye",
    },
    {
        p: "هغه ماشُوم دے.",
        f: "haghá maashóom dey.",
        e: "He is a child.",
        a: "hagha-maashoom-dey",
    },
    {
        p: "زهٔ قاضی یم.",
        f: "zu qaazee yim.",
        e: "I am a judge.",
        a: "zu-qaazee-yim",
    },
    {
        p: "هغه مالی دے.",
        f: "haghá maalée dey.",
        e: "He is a gardener.",
        a: "hagha-maalee-dey",
    },
    {
        pre: "Q:",
        p: "تهٔ طالبعلم یې؟",
        f: "tu taalibílum ye?",
        e: "Are you a student?",
        a: "tu-taalibilum-ye",
    },
    {
        pre: "A:",
        p: "آو، طالبعلم یم.",
        f: "aaw, taalibílum yim",
        e: "Yes, I'm a student",
        a: "aaw-taalibilum-yim",
    },
    {
        pre: "Q:",
        p: "تهٔ مستری یې؟",
        f: "tu mistrée ye?",
        e: "Are you a mechanic?",
        a: "tu-mistree-ye",
    },
    {
        pre: "A:",
        p: "نه مستری نهٔ یم.",
        f: "na, mistrée nu yim.",
        e: "No, I'm not a mechanic.",
        a: "na-mistree-nu-yim",
    },
];
