
import React from 'react'
import { mdx } from '@mdx-js/react'
export const readingTime = {"text":"43 min read","minutes":42.765,"time":2565900,"words":8553}
/* @jsx mdx */
import Dialogue from "../components/Dialogue";
import Word from "../components/Word";
import Examples from "../components/Examples";
import Table from "../components/Table";
import Quiz from "../components/Quiz";
import memorizingMoments from "./memorizing-moments";
import bus from "../assets/images/bus.png";
import coveredWoman from "../assets/images/covered-woman.PNG";
import guyCrouching from "../assets/images/guy-crouching.png";
import smelter from "../assets/images/smelter.png";
import chilum from "../assets/images/chilum.png";
import pukhtunkhwa from "../assets/images/pukhtunkhwa.png";
import park from "../assets/images/park.png";
import guyWithPatoo from "../assets/images/guy-with-patoo.png";

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope")
  return <div {...props}/>
};

const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <h2 {...{
      "id": "dialogue-fifteen-public-transportation"
    }}>{`Dialogue fifteen: Public transportation`}</h2>
    <Dialogue audio="dialogue-15" mdxType="Dialogue">{[{
        s: "L",
        p: "دا ګاډے ښار ته ځى؟",
        f: "daa gáaDey xaar ta dzee?",
        e: "Is this vehicle going to the city?",
        a: "15d1"
      }, {
        s: "H",
        p: "نه، نۀ ځى.",
        f: "na, nu dzee.",
        e: "No it's not going.",
        a: "15d2"
      }, {
        s: "L",
        p: "د ښار ګاډے د کُوم ځائ نه ځى؟",
        f: "du xaar gáaDey du kum dzaay na dzee?",
        e: "Where does the city bus go from?",
        a: "15d3"
      }, {
        s: "H",
        p: "وګوره! د ښار ګاډے هلته ودرېږى.",
        f: "oogora! du xaar gáaDey hálta oodréGee.",
        e: "Look! The city bus stops over there.",
        a: "15d4"
      }, {
        s: "L",
        p: "بخښنه غواړم، زۀ نا بلد يم، ټاون ته کُوم ګاډے ځى؟",
        f: "bakhxana ghwáaRum, zu naabalád yim, Taawn ta kum gáaDey dzee?",
        e: "Excuse me, I don't know my way around, which bus goes to Town?",
        a: "15d5"
      }, {
        s: "H",
        p: "دلته ودرېږه، يوڅو منټه پس به راشى.",
        f: "dălta óodreGa, yo tso minTa pas ba ráashee.",
        e: "Stand here, it will come in a few minutes.",
        a: "15d6"
      }, {
        s: "L",
        p: "ښه، مِهربانى.",
        f: "xa, mirabaanee.",
        e: "OK, thanks.",
        a: "15d7"
      }, {
        s: "L",
        p: "ښاره پورې څومره کرايه ده؟",
        f: "xáara póre tsóomra karaaya da?",
        e: "How many rupees is it to the city?",
        a: "15d8"
      }, {
        s: "H",
        p: "صرف شل روپۍ.",
        f: "sirf shil roopúy.",
        e: "Only twenty rupees.",
        a: "15d9"
      }, {
        s: "L",
        p: "کۀ ټکسی کښې کښېنم نو څو روپۍ به واخلى؟",
        f: "ku Taksée ke kénum no tso roopúy ba waakhlee?",
        e: "If I go in a taxi, how much will it cost?",
        a: "15d10"
      }, {
        s: "H",
        p: "اتيا روپۍ.",
        f: "atiyáa roopúy",
        e: "Eighty rupees.",
        a: "15d11"
      }]}</Dialogue>
    <img src={bus} style={{
      float: "right",
      maxWidth: "12rem"
    }} />
    <p>{`👆 `}<strong parentName="p">{`Men`}</strong>{`: Spend a day riding around on public transport in your area. Listen for the names of destinations called out by conductors. Work out the routes. Later, confirm your findings with your helper. `}</p>
    <p>{`👆 `}<strong parentName="p">{`Ladies`}</strong>{`: This week become an expert on rickshaws or whatever transport is appropriate for you where you are. Learn the names of the useful landmarks and roads that rickshaw drivers know for directions.`}</p>
    <h4 {...{
      "id": "note"
    }}>{`NOTE:`}</h4>
    <p>{`Like many English loan words, the Pukhto word for “minute” is not uniform. Different speakers use it in a variety of forms.`}</p>
    <h2 {...{
      "id": "pronunciation-drill"
    }}>{`Pronunciation drill`}</h2>
    <img src={coveredWoman} style={{
      float: "right",
      maxWidth: "8rem"
    }} />
    <p>{`👆 How are you doing at remembering and pronouncing the names of your new friends correctly? Here are some common names to practice with your helper. Knowing the meanings may help you remember them too.`}</p>
    <ol>
      <li parentName="ol">{`Ladies’ names:`}</li>
    </ol>
    <Examples twoColumns mdxType="Examples">{[{
        p: "ګلنار بېګم",
        f: "gUlnaar begum",
        e: "",
        a: "15n1"
      }, {
        p: "ګُلناز",
        f: "gUlnáaz",
        e: "",
        a: "15n2"
      }, {
        p: "ګُل څانګه",
        f: "gUl tsaanga",
        e: "",
        a: "15n3"
      }, {
        p: "چمن ګُل",
        f: "chaman gUl",
        e: "",
        a: "15n3b"
      }, {
        p: "شبنم",
        f: "shabnam",
        e: "",
        a: "15n4"
      }, {
        p: "شمع",
        f: "shama",
        e: "",
        a: "15n5"
      }, {
        p: "نُورجهان",
        f: "noor jahaan",
        e: "",
        a: "15n6"
      }, {
        p: "نورين ګُل",
        f: "noreen gUl",
        e: "",
        a: "15n7"
      }, {
        p: "نسيم بېګم",
        f: "naseem begum",
        e: "",
        a: "15n8"
      }, {
        p: "شميم اختر",
        f: "shameem akhtar",
        e: "",
        a: "15n9"
      }, {
        p: "سکينه",
        f: "sakeena",
        e: "",
        a: "15n10"
      }, {
        p: "سلمى",
        f: "salmaa",
        e: "",
        a: "15n11"
      }, {
        p: "صالحه",
        f: "saaliha",
        e: "",
        a: "15n12"
      }, {
        p: "طائره",
        f: "taa`ira",
        e: "",
        a: "15n13"
      }, {
        p: "سائره",
        f: "saa`ira",
        e: "",
        a: "15n14"
      }, {
        p: "ساجده",
        f: "saajida",
        e: "",
        a: "15n15"
      }, {
        p: "باغ آرام",
        f: "baagh aaraam",
        e: "",
        a: "15n16"
      }, {
        p: "رابعه",
        f: "raabiya",
        e: "",
        a: "15n17"
      }, {
        p: "پروين",
        f: "parween",
        e: "",
        a: "15n18"
      }, {
        p: "قمروجان",
        f: "qamro jaan",
        e: "",
        a: "15n19"
      }, {
        p: "کلثوم",
        f: "kalsóom",
        e: "",
        a: "15n20"
      }, {
        p: "سعيده",
        f: "sa'ida",
        e: "",
        a: "15n21"
      }, {
        p: "شېربانو",
        f: "sherbaano",
        e: "",
        a: "15n22"
      }, {
        p: "زينت",
        f: "zeenat",
        e: "",
        a: "15n23"
      }, {
        p: "زاهِده",
        f: "zaahida",
        e: "",
        a: "15n24"
      }, {
        p: "رُخسانه",
        f: "rUkhsaana",
        e: "",
        a: "15n25"
      }, {
        p: "فرخنده",
        f: "farkhanda",
        e: "",
        a: "15n26"
      }, {
        p: "بى بى فوزيه",
        f: "beebee fozia",
        e: "",
        a: "15n27"
      }, {
        p: "بى بى رقيه",
        f: "beebee rUqaia",
        e: "",
        a: "15n28"
      }, {
        p: "بختوره",
        f: "bakhtawura",
        e: "",
        a: "15n29"
      }, {
        p: "بى بى",
        f: "beebee",
        e: "",
        a: "15n30"
      }, {
        p: "بى بى طائره",
        f: "beebee taa`ira",
        e: "",
        a: "15n31"
      }, {
        p: "حليمه بى بى",
        f: "haleema beebee",
        e: "",
        a: "15n32"
      }, {
        p: "خديجه بى بى",
        f: "khUdija beebee",
        e: "",
        a: "15n33"
      }, {
        p: "بى بى فاطمه",
        f: "beebee faatima",
        e: "",
        a: "15n34"
      }, {
        p: "مريم ناز",
        f: "maryam naaz",
        e: "",
        a: "15n35"
      }, {
        p: "سُلطانه",
        f: "sUltaana",
        e: "",
        a: "15n36"
      }, {
        p: "هاجِره",
        f: "haajira",
        e: "",
        a: "15n37"
      }, {
        p: "دلشاد",
        f: "dilshaad",
        e: "",
        a: "15n38"
      }, {
        p: "تاج محل",
        f: "taaj mahal",
        e: "",
        a: "15n39"
      }, {
        p: "بخت تاجه",
        f: "bakht taaja",
        e: "",
        a: "15n40"
      }, {
        p: "تاج بېګم",
        f: "taaj begum",
        e: "",
        a: "15n41"
      }, {
        p: "خندانه",
        f: "khandaana",
        e: "",
        a: "15n42"
      }, {
        p: "جميله",
        f: "jameela",
        e: "",
        a: "15n43"
      }, {
        p: "جېهان آرا",
        f: "jahaan aaraa",
        e: "",
        a: "15n44"
      }, {
        p: "بخت زيبا",
        f: "bakht zaiba",
        e: "",
        a: "15n45"
      }, {
        p: "نُور محل",
        f: "noor mahal",
        e: "",
        a: "15n46"
      }, {
        p: "رېحانه",
        f: "rehaana",
        e: "",
        a: "15n47"
      }, {
        p: "صابره",
        f: "saabra",
        e: "",
        a: "15n48"
      }, {
        p: "سليمه",
        f: "saleema",
        e: "",
        a: "15n49"
      }, {
        p: "شبانه",
        f: "shabaana",
        e: "",
        a: "15n50"
      }, {
        p: "شګُفته",
        f: "shagUfta",
        e: "",
        a: "15n51"
      }, {
        p: "فرى زاده",
        f: "faree zaada",
        e: "",
        a: "15n52"
      }, {
        p: "بدرى جماله",
        f: "badree jamaala",
        e: "",
        a: "15n53"
      }, {
        p: "جامِ شيرين",
        f: "jaam sheereen",
        e: "",
        a: "15n54"
      }, {
        p: "ياسمين",
        f: "yaasmeen",
        e: "",
        a: "15n55"
      }]}</Examples>
    <img src={guyCrouching} style={{
      maxWidth: "10rem"
    }} />
    <ol {...{
      "start": 2
    }}>
      <li parentName="ol">{`Men’s names:`}</li>
    </ol>
    <Examples twoColumns mdxType="Examples">{[{
        p: "زړور  خان",
        f: "zuRawar khaan",
        e: "",
        a: "15n56"
      }, {
        p: "عبدالولى",
        f: "abdul walee",
        e: "",
        a: "15n57"
      }, {
        p: "اِمتياز احمد",
        f: "imtiyaaz ahmad",
        e: "",
        a: "15n58"
      }, {
        p: "فضل خالِق",
        f: "fazl-i-khaaliq",
        e: "",
        a: "15n59"
      }, {
        p: "زيارت ګُل",
        f: "ziyaarat gUl",
        e: "",
        a: "15n60"
      }, {
        p: "تازه ګُل",
        f: "taaza gUl",
        e: "",
        a: "15n61"
      }, {
        p: "جُمعه ګُل",
        f: "jUma gUl",
        e: "",
        a: "15n62"
      }, {
        p: "حضرت حسين",
        f: "hazrat hUseyn",
        e: "",
        a: "15n63"
      }, {
        p: "حبيب الرحمان",
        f: "habeeb-Ur-rahmaan",
        e: "",
        a: "15n64"
      }, {
        p: "جابِرخان",
        f: "jaabir khaan",
        e: "",
        a: "15n65"
      }, {
        p: "فقير خان",
        f: "faqeer khaan",
        e: "",
        a: "15n66"
      }, {
        p: "ګلاب ګُل",
        f: "gUlaab gUl",
        e: "",
        a: "15n67"
      }, {
        p: "خائسته رحمان",
        f: "khaaysta rahmaan",
        e: "",
        a: "15n68"
      }, {
        p: "خورشید",
        f: "khoorsheed",
        e: "",
        a: "15n69"
      }, {
        p: "محمد رفيق",
        f: "mUhamad rafeeq",
        e: "",
        a: "15n70"
      }, {
        p: "صادِق",
        f: "saadiq",
        e: "",
        a: "15n71"
      }, {
        p: "يعقوب",
        f: "yaaqoob",
        e: "",
        a: "15n72"
      }, {
        p: "يُونس",
        f: "yoonas",
        e: "",
        a: "15n73"
      }, {
        p: "ظهور",
        f: "zahoor",
        e: "",
        a: "15n74"
      }, {
        p: "داؤد",
        f: "daawUd",
        e: "",
        a: "15n75"
      }, {
        p: "طاهر",
        f: "taahir",
        e: "",
        a: "15n76"
      }, {
        p: "اعتبار ګُل",
        f: "itbaar gUl",
        e: "",
        a: "15n77"
      }, {
        p: "اينځر ګُل",
        f: "eendzar gUl",
        e: "",
        a: "15n78"
      }, {
        p: "خان زاده",
        f: "khaan zaada",
        e: "",
        a: "15n79"
      }, {
        p: "ګُل زاده",
        f: "gUl zaada",
        e: "",
        a: "15n80"
      }, {
        p: "ګُل فروش",
        f: "gUl farosh",
        e: "",
        a: "15n81"
      }, {
        p: "مياه سيد کریم",
        f: "miyaan said kareem",
        e: "",
        a: "15n82"
      }, {
        p: "خان نواب",
        f: "khaan nawaab",
        e: "",
        a: "15n83"
      }, {
        p: "خان بهادر",
        f: "khaan badur",
        e: "",
        a: "15n84"
      }, {
        p: "مياه سيد على",
        f: "miyaan said alee",
        e: "",
        a: "15n85"
      }, {
        p: "بهروز",
        f: "bahroz",
        e: "",
        a: "15n86"
      }, {
        p: "دِلدار شاه",
        f: "dildaar shaah",
        e: "",
        a: "15n87"
      }, {
        p: "امير جان",
        f: "ameer jaan",
        e: "",
        a: "15n88"
      }, {
        p: "جانباز",
        f: "jaanbaaz",
        e: "",
        a: "15n89"
      }, {
        p: "بهرام خان",
        f: "bihraam khaan",
        e: "",
        a: "15n90"
      }, {
        p: "دولت خان",
        f: "dolat khaan",
        e: "",
        a: "15n91"
      }, {
        p: "اکبر خان",
        f: "akbar khaan",
        e: "",
        a: "15n92"
      }, {
        p: "زاهِد",
        f: "zaahid",
        e: "",
        a: "15n93"
      }, {
        p: "فضل حسين",
        f: "fazal hUseyn",
        e: "",
        a: "15n94"
      }, {
        p: "فضل واحد",
        f: "fazl-i-waahid",
        e: "",
        a: "15n95"
      }, {
        p: "شاهدالله",
        f: "shaahidUllah",
        e: "",
        a: "15n96"
      }, {
        p: "کريم الله",
        f: "kareemUllah",
        e: "",
        a: "15n97"
      }, {
        p: "ظفر على",
        f: "zafan alee",
        e: "",
        a: "15n98"
      }, {
        p: "سيد الله",
        f: "saidUllah",
        e: "",
        a: "15n99"
      }, {
        p: "عبدُالغفور",
        f: "abdUlghafoor",
        e: "",
        a: "15n100"
      }, {
        p: "جماالدين",
        f: "jamaalUdeen",
        e: "",
        a: "15n101"
      }, {
        p: "سردار على",
        f: "sardaar alee",
        e: "",
        a: "15n102"
      }, {
        p: "عبدالقيوم",
        f: "abdUlqayyUm",
        e: "",
        a: "15n103"
      }, {
        p: "عبدالکبير",
        f: "abdUlqabeer",
        e: "",
        a: "15n104"
      }, {
        p: "عبدالباقى",
        f: "abdUlbaaqee",
        e: "",
        a: "15n105"
      }, {
        p: "عُمر حيات",
        f: "Umar hayaat",
        e: "",
        a: "15n106"
      }, {
        p: "احسان الٰهى",
        f: "ihsaan ilaahee",
        e: "",
        a: "15n107"
      }, {
        p: "فخرالدين",
        f: "fakhrUdeen",
        e: "",
        a: "15n108"
      }, {
        p: "غازى",
        f: "ghaazee",
        e: "",
        a: "15n109"
      }, {
        p: "قاضى",
        f: "qaazee",
        e: "",
        a: "15n110"
      }, {
        p: "قمر دين",
        f: "qamar deen",
        e: "",
        a: "15n111"
      }, {
        p: "عبدالرشيد",
        f: "abdUrrasheed",
        e: "",
        a: "15n112"
      }, {
        p: "کريم بخش",
        f: "kareem bakhash",
        e: "",
        a: "15n113"
      }, {
        p: "خُدا بخش",
        f: "khUdaa bakhash",
        e: "",
        a: "15n114"
      }, {
        p: "بخت بيدار",
        f: "bakht beydaar",
        e: "",
        a: "15n115"
      }]}</Examples>
    <h2 {...{
      "id": "the-future-tense"
    }}>{`The future tense`}</h2>
    <p>{`There are two forms of the future tense, each with a slightly different meaning. In everyday speech the difference is often ignored and the distinction is made by intonation of voice rather than verb forms.`}</p>
    <h3 {...{
      "id": "1-imperfective"
    }}>{`1. Imperfective`}</h3>
    <p>{`This implies a continuous action: 'I will be going, seeing, finishing’, etc.`}</p>
    <blockquote>
      <em><Word w={{
          p: "به",
          f: "ba",
          e: "",
          a: "ba-f-2"
        }} mdxType="Word" /> + Present Form = Imperfective Future</em>
    </blockquote>
    <p>{`The imperfective (strong) future uses the `}<em parentName="p">{`present form`}</em>{` of the verb and and adds the particle `}<Word w={{
        p: "به",
        f: "ba",
        e: "",
        a: "ba-f-2"
      }} mdxType="Word" />{`. For example,`}</p>
    <Examples mdxType="Examples">{[{
        p: "زۀ به هغه صبا وينم.",
        f: "zu ba haghá sabáa wéenum",
        e: "I will be seeing him tomorrow.",
        a: "15e1"
      }, {
        p: "تۀ به صبا چرته ځې؟",
        f: "tu ba sabaa chărta dze?",
        e: "Where will be you going tomorrow?",
        a: "15e2"
      }, {
        p: "زۀ به د خپل دوست کره ځم.",
        f: "zu ba du khpul dost kara dzum.",
        e: "I will be going to my friend's house.",
        a: "15e3"
      }, {
        p: "دے به نن شپه کار کوى؟",
        f: "dey ba nun shpa kaar kawee?",
        e: "Will he be working tonight?",
        a: "15e4"
      }, {
        p: "او، نن شپه به کار کوى.",
        f: "aw, nun shpa ba kaar kawee.",
        e: "Yes, he will be working tonight.",
        a: "15e5"
      }]}</Examples>
    <h3 {...{
      "id": "2-perfective"
    }}>{`2. Perfective`}</h3>
    <p>{`In the perfective future tense the action is delimited or complete, that is, it is of short duration. The perfective future also can give a verb a weaker or less definite feel.`}</p>
    <blockquote>
      <em><Word w={{
          p: "به",
          f: "ba",
          e: "",
          a: "ba-f-2"
        }} mdxType="Word" /> + Subjunctive Form = Perfective Futurue</em>
    </blockquote>
    <p>{`The perfective future is made by using the `}<em parentName="p">{`subjunctive form of the verb`}</em>{` and adding the particle `}<Word w={{
        p: "به",
        f: "ba",
        e: "",
        a: "ba-f-2"
      }} mdxType="Word" />{`. For example, notice the difference between the regular present and subjunctive forms of these verbs:`}</p>
    <Table bordered unset wide verticalContent titleRow={["Verb", "Present / Imperfective", "Subjunctive / Perfective"]} mdxType="Table">{[[{
        p: "لیدل",
        f: "leedúl",
        e: "to see",
        a: "leedul-m"
      }, {
        p: "وینم",
        f: "wéenum",
        e: "I see",
        a: ""
      }, {
        p: "ووينم",
        f: "óoweenum",
        e: "...I'll see (subjunct.)",
        a: ""
      }], [{
        p: "وهل",
        f: "wahúl",
        e: "to hit",
        a: "wahul-m"
      }, {
        p: "وهم",
        f: "wahúm",
        e: "I hit",
        a: ""
      }, {
        p: "ووهم",
        f: "óowahum",
        e: "...I'll hit (subjunct.)",
        a: ""
      }]]}</Table>
    <p>{`Now let’s make some sentences with the perfective future. (`}<Word w={{
        p: "به",
        f: "ba",
        e: "",
        a: "ba-f-2"
      }} mdxType="Word" />{` + subjunctive)`}</p>
    <Examples mdxType="Examples">{[{
        p: "زۀ به هغه ووينم.",
        f: "zu ba haghá óoweenum.",
        e: "I will see him.",
        a: "15e6"
      }, {
        p: "زۀ به هغه ووهم.",
        f: "zu ba haghá óowahum.",
        e: "I will hit him.",
        a: "15e7"
      }]}</Examples>
    <h4 {...{
      "id": "transitive-verbs-in-the-perfective-future"
    }}>{`Transitive verbs in the perfective future`}</h4>
    <p>{`As we saw above, most regular (non-compound / non-seperable) verbs add a `}<Word w={{
        p: "و",
        f: "oo / w",
        e: "",
        a: "oo-sound-2"
      }} mdxType="Word" />{` to the beginning in the subjunctive form and put the emphasis on the first syllable.`}</p>
    <Examples mdxType="Examples">{[{
        p: "تۀ به نن شپه خطُونه وليکې؟",
        f: "tu ba nun shpa khatoona ooleeke?",
        e: "Will you write letters tonight?",
        a: "15ee8"
      }, {
        p: "او، بېګاله به يې وليکم.",
        f: "aw, begaala ba ye ooleekum.",
        e: "Yes, I will write them tonight.",
        a: "15ee9"
      }, {
        p: "دے به سخکال غنم وکرى؟",
        f: "dey ba sakhkáal ghunúm óokaree?",
        e: "Will he sow wheat this year?",
        a: "15e10"
      }, {
        p: "په دې هفته کښې به هغه ووينې؟",
        f: "pu de hafta ke ba haghá óoweene?",
        e: "Will you see him this week?",
        a: "15e11"
      }, {
        p: "او، وبه يې وينم.",
        f: "aw, óo-ba-ye-weenum.",
        e: "Yes, I will see him.",
        a: "15e12"
      }, {
        p: "کله به ورته خط ولېږې؟",
        f: "kala ba wărta khat óoleGe?",
        e: "When will you send him the letter?",
        a: "15e13"
      }, {
        p: "صبا به ورته ولېږم.",
        f: "sabaa ba wărta ooleGum.",
        e: "I will send the letter tomorrow.",
        a: "15e14"
      }, {
        p: "نن به کپړې ووينځې؟",
        f: "nun ba kapRe óoweendze?",
        e: "Will you wash clothes today?",
        a: "15e15"
      }, {
        p: "نه، نن به ونۀ وينځم.",
        f: "na, nun ba oo-nú-weendzum.",
        e: "No, I will not wash them today.",
        a: "15e16"
      }, {
        p: "زۀ به يو سړے وتښتوم.",
        f: "zu ba yo saRey óotaxtawum.",
        e: "I will kidnap one man.",
        a: "15e17"
      }, {
        p: "زۀ به ستا ګرېوان وشلوم.",
        f: "zu ba staa grewaan ooshlawum.",
        e: "I will tear your shirt.",
        a: "15e18"
      }, {
        p: "زۀ به دا لوبه وګټم.",
        f: "zu ba daa lóba óogaTum.",
        e: "I will win this match.",
        a: "15e19"
      }, {
        p: "تاسو به دا لوبه ونۀ ګټئ.",
        f: "taaso ba daa lóba oo-nú-gaTeyy.",
        e: "You will not win the match.",
        a: "15e20"
      }, {
        p: "خور به مې اور ولګوى.",
        f: "khor ba me or oolugawee.",
        e: "My sister will light the fire.",
        a: "15e21"
      }]}</Examples>
    <h5 {...{
      "id": "note-1"
    }}>{`NOTE:`}</h5>
    <p>{`The `}<Word w={{
        p: "خ",
        f: "kh",
        e: "",
        a: "kh-sound"
      }} mdxType="Word" />{` is sometimes not pronounced, hence `}<em>{`sakaal`}</em>{`.`}</p>
    <img src={smelter} style={{
      maxWidth: "8rem"
    }} />
    <p>{`In everyday speech the `}<Word w={{
        p: "و",
        f: "oo",
        e: "",
        a: "oo-sound-2"
      }} mdxType="Word" />{` prefix is sometimes dropped from sentences, this is especially so in the negative. For example,`}</p>
    <Examples memorizingMoment mdxType="Examples">{[{
        p: ["زۀ به دا کتاب ونۀ لېږم. (یا)", "زۀ به دا کتاب نۀ لېږم."],
        f: ["zu ba daa kitáab oo-nú-leGum.", "zu ba daa kitáab nú-leGum."],
        e: "I will not send this book.",
        a: "15e23"
      }]}</Examples>
    <h5 {...{
      "id": "some-transitive-verbrs-that-take-the-word-w-p-ـو-f--oo---w-e--a-15e24---prefix"
    }}>{`Some transitive verbrs that take the `}<Word w={{
        p: "ـو",
        f: "-oo / -w",
        e: "",
        a: "15e24"
      }} mdxType="Word" />{` prefix:`}</h5>
    <Examples twoColumns mdxType="Examples">{[{
        p: "استول",
        f: "astawúl",
        e: "to send",
        a: "15v1"
      }, {
        p: "بلل",
        f: "balúl",
        e: "to invite",
        a: "15v2"
      }, {
        p: "بهېول",
        f: "bayawúl",
        e: "to make flow",
        a: "15v3"
      }, {
        p: "پړسول",
        f: "paRsawúl",
        e: "to inflate",
        a: "15v4"
      }, {
        p: "پړقول",
        f: "pRaqawúl",
        e: "to make shiny",
        a: "15v5"
      }, {
        p: "تښتول",
        f: "tuxtawúl",
        e: "to kidnap",
        a: "15v6"
      }, {
        p: "ځغلول",
        f: "dzghalawúl",
        e: "to chase",
        a: "15v7"
      }, {
        p: "ځوړندول",
        f: "dzwaRandawúl",
        e: "to hange",
        a: "15v8"
      }, {
        p: "چلول",
        f: "chulawúl",
        e: "to drive",
        a: "15v9"
      }, {
        p: "رابلل",
        f: "raabalúl",
        e: "to send for",
        a: "15v10"
      }, {
        p: "راستول",
        f: "raastawúl",
        e: "to send (from here)",
        a: "15v11"
      }, {
        p: "رپول",
        f: "rapawúl",
        e: "to (cause to) shake",
        a: "15v12"
      }, {
        p: "رسول",
        f: "raswúl",
        e: "to deliver",
        a: "15v13"
      }, {
        p: "رغړول",
        f: "rghaRawúl",
        e: "to roll up",
        a: "15v14"
      }, {
        p: "شرمول",
        f: "shărmawúl",
        e: "to humiliate",
        a: "15v15"
      }, {
        p: "شلول",
        f: "shilawúl",
        e: "to tear",
        a: "15v16"
      }, {
        p: "غوښتل",
        f: "ghoxtúl",
        e: "to want",
        a: "15v17"
      }, {
        p: "کتل",
        f: "katúl",
        e: "to look at (present root ګور-)",
        a: "15v18"
      }, {
        p: "کرل",
        f: "karúl",
        e: "to sow",
        a: "15v19"
      }, {
        p: "ګټل",
        f: "gaTúl",
        e: "to gain",
        a: "15v20"
      }, {
        p: "ګرځول",
        f: "gurdzawúl",
        e: "to take someone for a walk",
        a: "15v21"
      }, {
        p: "لګول",
        f: "lugawúl",
        e: "to turn on",
        a: "15v22"
      }, {
        p: "ليدل",
        f: "leedúl",
        e: "to see (present root وین-)",
        a: "15v23"
      }, {
        p: "لېږل",
        f: "leGúl",
        e: "to send",
        a: "15v24"
      }, {
        p: "ليکل",
        f: "leekúl",
        e: "to write",
        a: "15v25"
      }, {
        p: "لوستل",
        f: "lostúl",
        e: "to read",
        a: "15v26"
      }, {
        p: "وينځل",
        f: "weendzúl",
        e: "to wash",
        a: "15v27"
      }, {
        p: "يشول",
        f: "yashawúl",
        e: "to boil",
        a: "15v28"
      }]}</Examples>
    <h4 {...{
      "id": "note-2"
    }}>{`NOTE:`}</h4>
    <p>{`The verbs `}<Word w={{
        p: "رابلل",
        f: "raa-balúl",
        e: "",
        a: "15v10"
      }} mdxType="Word" /> and <Word w={{
        p: "راستول",
        f: "raastawúl",
        e: "",
        a: "15v11"
      }} mdxType="Word" /> are formed from <Word w={{
        p: "بلل",
        f: "balúl",
        e: "",
        a: "15v2"
      }} mdxType="Word" /> and <Word w={{
        p: "استول",
        f: "astawul",
        e: "",
        a: "15v1"
      }} mdxType="Word" /> with an added <Word w={{
        p: "را",
        f: "raa",
        e: "",
        a: "raa-f"
      }} mdxType="Word" /> on front. The <Word w={{
        p: "ـو",
        f: "-oo",
        e: "",
        a: "oo-sound-2"
      }} mdxType="Word" /> prefix gets attached <em>after</em> the <Word w={{
        p: "را",
        f: "raa",
        e: "",
        a: "raa-f"
      }} mdxType="Word" />. For example, <Word w={{
        p: "راوبلم",
        f: "raa-oo-balum",
        e: "",
        a: ""
      }} mdxType="Word" /> or <Word w={{
        p: "راواستوم",
        f: "raa-waastawum",
        e: "",
        a: ""
      }} mdxType="Word" />{`.`}</p>
    <h4 {...{
      "id": "intransitive-verbs-in-the-perfective-future-tense"
    }}>{`Intransitive verbs in the perfective future tense`}</h4>
    <h5 {...{
      "id": "here-are-some-intransitive-verbs-that-take-the-word-w-p-و-f-oo--w-e--a-oo-sound-f---prefix"
    }}>{`Here are some intransitive verbs that take the `}<Word w={{
        p: "و",
        f: "oo / w",
        e: "",
        a: "oo-sound-f"
      }} mdxType="Word" />{` prefix.`}</h5>
    <Examples twoColumns mdxType="Examples">{[{
        p: "پړسېدل",
        f: "paRsedúl",
        e: "to swell (up)",
        a: "15v29"
      }, {
        p: "پړقېدل",
        f: "pRaqedúl",
        e: "to shine",
        a: "15v30"
      }, {
        p: "تښتېدل",
        f: "tuxtedúl",
        e: "to run away",
        a: "15v31"
      }, {
        p: "څڅېدل",
        f: "tsatsedúl",
        e: "to drip",
        a: "15v32"
      }, {
        p: "ختل",
        f: "khatúl",
        e: "to climb (present root خېژ-)",
        a: "15v33"
      }, {
        p: "خوځېدل",
        f: "khwadzedúl",
        e: "to move",
        a: "15v34"
      }, {
        p: "خورېدل",
        f: "khwaredúl",
        e: "to be scattered",
        a: "15v35"
      }, {
        p: "رپېدل",
        f: "rapedúl",
        e: "to shake",
        a: "15v36"
      }, {
        p: "رسېدل",
        f: "rasedúl",
        e: "to arrive",
        a: "15v37"
      }, {
        p: "رغړېدل",
        f: "irghaRedúl",
        e: "to roll along",
        a: "15v38"
      }, {
        p: "زغلېدل",
        f: "zghaledúl",
        e: "to move",
        a: "15v39"
      }, {
        p: "شرمېدل",
        f: "shărmedúl",
        e: "to be shy",
        a: "15v40"
      }, {
        p: "غوړېدل",
        f: "ghwaRedúl",
        e: "to be spread",
        a: "15v41"
      }, {
        p: "کړېدل",
        f: "kaRedúl",
        e: "to be troubled",
        a: "15v42"
      }, {
        p: "ګرځېدل",
        f: "gurdzedúl",
        e: "to walk",
        a: "15v43"
      }, {
        p: "لړزېدل",
        f: "laRzedúl",
        e: "to tremble",
        a: "15v44"
      }, {
        p: "لګېدل",
        f: "lugedúl",
        e: "to touch",
        a: "15v45"
      }, {
        p: "نړېدل",
        f: "naRedúl",
        e: "to tumble down",
        a: "15v46"
      }, {
        p: "يشېدل",
        f: "yishedúl",
        e: "to be boiled",
        a: "15v47"
      }]}</Examples>
    <h4 {...{
      "id": "example-sentences"
    }}>{`Example sentences:`}</h4>
    <Examples mdxType="Examples">{[{
        p: "زما خېټه به وپړسېږى.",
        f: "zmaa kheTa ba oo-paRseGee.",
        e: "My stomach will swell up.",
        a: "15e24"
      }, {
        p: "زمُونږ چت کچه دے او په باران کښې به وڅاڅى.",
        f: "zmoonG chut kacha dey aw pu baaraan ke ba óo-tsaatsee.",
        e: "Our roof is poor quality and will leak in the rain.",
        a: "15e25"
      }, {
        p: "هغه څيز چې صفا شى، نو وبه پړقېږى.",
        f: "hágha tseez che safaa shee, no oo-ba pRaqéGee.",
        e: "The thing that is clean will shine.",
        a: "15e26"
      }]}</Examples>
    <p>{`👆 Make more sentences from the list of verbs above.`}</p>
    <h2 {...{
      "id": "the-verb-word-w-p-کېدل-f-kedúl-e-to-become-a-kedul-m---in-the-future-tense"
    }}>{`The verb `}<Word w={{
        p: "کېدل",
        f: "kedúl",
        e: "to become",
        a: "kedul-m"
      }} mdxType="Word" />{` in the future tense`}</h2>
    <p>{`The verb `}<Word w={{
        p: "کېدل",
        f: "kedúl",
        e: "to become",
        a: "kedul-m"
      }} mdxType="Word" /> has an irregular subjunctive / perfective root form, <Word w={{
        p: "شـ",
        f: "sh-",
        e: "",
        a: "sh-sound"
      }} mdxType="Word" /> which <em>never takes a <Word w={{
          p: "و",
          f: "oo",
          e: "",
          a: "oo-sound-2"
        }} mdxType="Word" />{` prefix`}</em>{`.`}</p>
    <h4 {...{
      "id": "the-subjunctive-form-of-word-w-p-کېدل-f-kedúl-e-to-become-a-kedul-m--"
    }}>{`The subjunctive form of `}<Word w={{
        p: "کېدل",
        f: "kedúl",
        e: "to become",
        a: "kedul-m"
      }} mdxType="Word" /></h4>
    <Table bordered unset titleRow={["", "singular", "plural"]} mdxType="Table">{[[{
        e: "1st"
      }, {
        p: "شم",
        f: "shum",
        e: "",
        a: "shum-m"
      }, {
        p: "شُو",
        f: "shoo",
        e: "",
        a: "shoo-m"
      }], [{
        e: "2nd"
      }, {
        p: "شې",
        f: "she",
        e: "",
        a: "she-m"
      }, {
        p: "شُئ",
        f: "sheyy",
        e: "",
        a: "sheyy-m"
      }], [{
        e: "3rd"
      }, {
        p: "شی",
        f: "shee",
        e: "",
        a: "shee-m"
      }, {
        p: "شی",
        f: "shee",
        e: "",
        a: "shee-m"
      }]]}</Table>
    <h4 {...{
      "id": "note-3"
    }}>{`NOTE:`}</h4>
    <p>{`This verb is not to be confused with `}<Word w={{
        p: "کېدل",
        f: "kedúl",
        e: "to happen",
        a: "kedul-m"
      }} mdxType="Word" /> with *does* take a <Word w={{
        p: "و",
        f: "oo",
        e: "",
        a: "oo-sound-2"
      }} mdxType="Word" />{` prefix.`}</p>
    <p>{`Just like with all other verbs, we use`}</p>
    <ul>
      <li parentName="ul">{`the present form + `}<Word w={{
          p: "به",
          f: "ba",
          e: "",
          a: "ba-f-2"
        }} mdxType="Word" />{` for an imperfective future, and`}</li>
      <li parentName="ul">{`the subjunctive form + `}<Word w={{
          p: "به",
          f: "ba",
          e: "",
          a: "ba-f-2"
        }} mdxType="Word" />{` for a perfective future.`}</li>
    </ul>
    <p>{`For example contrast:`}</p>
    <Table bordered unset wide verticalContent titleRow={["Imperfective (Strong)", "Perfective (Weak)"]} mdxType="Table">{[[{
        p: "به خفه کېږی.",
        f: "ba khúfa keGee.",
        e: "She will be upset",
        a: "15e27"
      }, {
        p: "به خفه شی.",
        f: "ba khúfa shee.",
        e: "She will be upset",
        a: "15e28"
      }]]}</Table>
    <h4 {...{
      "id": "perfective-future-example-sentences-with-word-w-p-کېدل-f-kedúl-e-to-become-a-----word-w-p-کېدل-f-kedúl-e-to-become-a----compounds"
    }}>{`Perfective future example sentences with `}<Word w={{
        p: "کېدل",
        f: "kedúl",
        e: "to become",
        a: ""
      }} mdxType="Word" /> / <Word w={{
        p: "کېدل",
        f: "kedúl",
        e: "to become",
        a: ""
      }} mdxType="Word" />{` compounds:`}</h4>
    <Examples mdxType="Examples">{[{
        p: "انشاءالله، ډاکټر به شې.",
        f: "inshaal`laa, DakTúr ba she.",
        e: "God willing, you will become a doctor.",
        a: "15e29"
      }, {
        p: "انشاءالله، شم به.",
        f: "inshaal`laa, shum ba.",
        e: "God willing, I will.",
        a: "15e30"
      }, {
        p: "هغوى به زمُونږ نه خفه شى.",
        f: "haghwée ba zmoonG na khúfa shee.",
        e: "Will they become upset with us.",
        a: "15e31"
      }, {
        p: "نه، خفه به نۀ شى.",
        f: "na, khúfa ba nu shee.",
        e: "No, they won't get upset.",
        a: "15e32"
      }, {
        p: "تاسو به خوشحاله شئ؟",
        f: "táaso ba khosháala sheyy?",
        e: "Will you be happy?",
        a: "15e33"
      }, {
        p: "او، خوشحاله به شو.",
        f: "aw, khoshaala ba shoo",
        e: "Yes, we will be happy.",
        a: "15e34"
      }, {
        p: "ستا پلار به جوړ شى؟",
        f: "staa plaar ba joR shee?",
        e: "Will your father be healed?",
        a: "15e35"
      }, {
        p: "آو، انشاءالله جوړ به شى.",
        f: "aw, inshaal`la joR ba shee.",
        e: "Yes, God willing he will be healed.",
        a: "15e36"
      }, {
        p: "ډوډۍ به اوس پخه شى؟",
        f: "DoDúy ba oos pakhá shee?",
        e: "Will the food be cooked now?",
        a: "15e37"
      }, {
        p: "آو، پخه به شى.",
        f: "aw, pakhá ba shee.",
        e: "Yes it will be cooked.",
        a: "15e38"
      }, {
        p: "چائې به اوس جوړې شى.",
        f: "chaay ba oos joRe shee?",
        e: "Tea will be made now.",
        a: "15e39"
      }, {
        p: "زما نوې ګړۍ به پېدا شى؟",
        f: "zmaa niwe gaRúy ba pedáa shee?",
        e: "Will my new watch be found?",
        a: "15e40"
      }, {
        p: "مۀ خفه کېږه، پېدا به شى.",
        f: "mu-khufa keGa, pedaa ba shee.",
        e: "Don't be upset, it will be found.",
        a: "15e41"
      }, {
        p: "نن به زما کپړې اِسترى شى؟",
        f: "nun ba zmaa kapRé istrée shee?",
        e: "Will my clothes be ironed today?",
        a: "15e42"
      }, {
        p: "بې غمه شه! اِسترى به شى.",
        f: "be ghúma sha! istrée ba shee.",
        e: "Don't worry! They will be ironed.",
        a: "15e43"
      }]}</Examples>
    <img src={chilum} />
    <h4 {...{
      "id": "intransitive-compound-verbs-with-word-w-p-کېدل-f-kedúl-e-to-become-a-kedul-m--"
    }}>{`Intransitive compound verbs with `}<Word w={{
        p: "کېدل",
        f: "kedúl",
        e: "to become",
        a: "kedul-m"
      }} mdxType="Word" /></h4>
    <p>{`As you can see in the exampels above, there are mamy compound verbs made using `}<Word w={{
        p: "کېدل",
        f: "kedúl",
        e: "to become",
        a: "kedul-m"
      }} mdxType="Word" />. Remember, there is no <Word w={{
        p: "و",
        f: "oo",
        e: "",
        a: "oo-sound-2"
      }} mdxType="Word" /> added to the subjunctive / perfective form of <Word w={{
        p: "کېدل",
        f: "kedúl",
        e: "to become",
        a: "kedul-m"
      }} mdxType="Word" />, so <em>these verbs will never take a <Word w={{
          p: "و",
          f: "oo",
          e: "",
          a: "oo-sound-2"
        }} mdxType="Word" />{` prefix`}</em>{`.`}</p>
    <p>{`Notice how in the present form the verbs get stuck in the infinitive and present forms if the first word ends in a consonant, but they always get broken up in the subjunctive form.`}</p>
    <Table bordered unset wide verticalContent titleRow={["Verb", "Present / Imperfective", "Subjunctive / Perfective"]} mdxType="Table">{[[{
        p: "اُودهٔ کېدل",
        f: "oodú kedúl",
        e: "to go to sleep",
        a: "15v48"
      }, {
        p: "اُوده کېږی",
        f: "oodú kéGee",
        e: "",
        a: ""
      }, {
        p: "اُوده شی",
        f: "oodú shee",
        e: "",
        a: ""
      }], [{
        p: "پټېدل",
        f: "puTedúl",
        e: "to hide oneself",
        a: "15v49"
      }, {
        p: "پټېږی",
        f: "puTéGee",
        e: "",
        a: ""
      }, {
        p: "پټ شی",
        f: "puT shee",
        e: "",
        a: ""
      }], [{
        p: "پیدا کېدل",
        f: "peydaa kedúl",
        e: "to be born",
        a: "15v50"
      }, {
        p: "پیدا کېږی",
        f: "peydaa keGee",
        e: "",
        a: ""
      }, {
        p: "پیدا شی",
        f: "peydaa shee",
        e: "",
        a: ""
      }], [{
        p: "تيارېدل",
        f: "tayaaredúl",
        e: "to be(come) ready",
        a: "15v51"
      }, {
        p: "تیارېږی",
        f: "tayaaréGee",
        e: "",
        a: ""
      }, {
        p: "تیار شی",
        f: "tayaar shee",
        e: "",
        a: ""
      }], [{
        p: "روانېدل",
        f: "rawaanedúl",
        e: "to start out",
        a: "15v52"
      }, {
        p: "روانېږی",
        f: "rawaanéGee",
        e: "",
        a: ""
      }, {
        p: "روان شی",
        f: "rawaan shee",
        e: "",
        a: ""
      }], [{
        p: "ښکارېدل",
        f: "xkaaredúl",
        e: "to apppear",
        a: "15v53"
      }, {
        p: "ښکارېږی / ښکاری",
        f: "xkaaréGee / xkaaree",
        e: "",
        a: ""
      }, {
        p: "ښکاره شی",
        f: "xkaara shee",
        e: "",
        a: ""
      }], [{
        p: "کُوزېدل",
        f: "koozedúl",
        e: "to get down",
        a: "15v54"
      }, {
        p: "کُوزېږی",
        f: "koozéGee",
        e: "",
        a: ""
      }, {
        p: "کُوز شی",
        f: "kooz shee",
        e: "",
        a: ""
      }], [{
        p: "ماتېدل",
        f: "maatedúl",
        e: "to be broken",
        a: "15v55"
      }, {
        p: "ماتېږی",
        f: "maatéGee",
        e: "",
        a: ""
      }, {
        p: "مات شی",
        f: "maat shee",
        e: "",
        a: ""
      }], [{
        p: "معلومېدل",
        f: "maaloomedúl",
        e: "to be known",
        a: "15v56"
      }, {
        p: "معلومېږی",
        f: "maalooméGee",
        e: "",
        a: ""
      }, {
        p: "معلوم شی",
        f: "maaloom shee",
        e: "",
        a: ""
      }], [{
        p: "پوهېدل",
        f: "pohedul",
        e: "to understand",
        a: "pohedul-m"
      }, {
        p: "پوهېږی",
        f: "pohéGee",
        e: "",
        a: ""
      }, {
        p: "پوهه شی",
        f: "poha shee",
        e: "",
        a: ""
      }]]}</Table>
    <p>{`👆 Generate some future sentences with your helper using these verbs.`}</p>
    <p>{`As with regular verbs, these intransitive `}<Word w={{
        p: "کېدل",
        f: "kedúl",
        e: "to become",
        a: "kedul-m"
      }} mdxType="Word" />{` compound verbs carry slightly different meanings in the perfective (weak) and imperfective (strong) forms.`}</p>
    <p>{`For example contrast the different uses of `}<Word w={{
        p: "پوهېدل",
        f: "pohedul",
        e: "",
        a: "pohedul-f"
      }} mdxType="Word" />{`:`}</p>
    <Table bordered unset wide verticalContent titleRow={["Imperfective (Strong)", "Perfective (Weak)"]} mdxType="Table">{[[{
        p: "زهٔ به بوهېږم.",
        f: "zu ba pohéGum.",
        e: "I will understand. (Whenever you say it)",
        a: "15e44"
      }, {
        p: "زهٔ به پوهه شم.",
        f: "zu ba póha shum.",
        e: "I may understand. (Some doubt)",
        a: "15e45"
      }]]}</Table>
    <h2 {...{
      "id": "the-verb-word-w-p-کول-f-kawul-e-to-make-a-kawul-m---in-the-future-tense"
    }}>{`The verb `}<Word w={{
        p: "کول",
        f: "kawul",
        e: "to make",
        a: "kawul-m"
      }} mdxType="Word" />{` in the future tense.`}</h2>
    <p>{`The verb `}<Word w={{
        p: "کول",
        f: "kawul",
        e: "to make",
        a: "kawul"
      }} mdxType="Word" /> also has an irregular subjunctive / perfective root form, <Word w={{
        p: "کړـ",
        f: "kR-",
        e: "",
        a: ""
      }} mdxType="Word" /> which also <em>never takes a <Word w={{
          p: "و",
          f: "oo",
          e: "",
          a: "oo-sound-2"
        }} mdxType="Word" /> prefix</em>. Often in spoken language the root <Word w={{
        p: "کړـ",
        f: "kR-",
        e: "",
        a: ""
      }} mdxType="Word" /> is shortened to <Word w={{
        p: "کـ",
        f: "k-",
        e: "",
        a: "k-sound"
      }} mdxType="Word" />{` in speech (not in writing).`}</p>
    <h4 {...{
      "id": "the-subjunctive-form-of-word-w-p-کول-f-kawúl-e-to-make-a-kawul-m--"
    }}>{`The subjunctive form of `}<Word w={{
        p: "کول",
        f: "kawúl",
        e: "to make",
        a: "kawul-m"
      }} mdxType="Word" /></h4>
    <Table bordered unset titleRow={["", "singular", "plural"]} mdxType="Table">{[[{
        e: "1st"
      }, {
        p: "کړم / کم",
        f: "kRum / kum",
        e: "",
        a: ""
      }, {
        p: "کړو / کو",
        f: "kRoo / koo",
        e: "",
        a: ""
      }], [{
        e: "2nd"
      }, {
        p: "کړې / کې",
        f: "kRe / ke",
        e: "",
        a: ""
      }, {
        p: "کړئ / کئ",
        f: "kReyy / keyy",
        e: "",
        a: ""
      }], [{
        e: "3rd"
      }, {
        p: "کړی / کی",
        f: "kRee / kee",
        e: "",
        a: ""
      }, {
        p: "کړی / کی",
        f: "kRee / kee",
        e: "",
        a: ""
      }]]}</Table>
    <h4 {...{
      "id": "compound-verbs-with-word-w-p-کول-f-kawul-e-to-make-a---"
    }}>{`Compound verbs with `}<Word w={{
        p: "کول",
        f: "kawul",
        e: "to make",
        a: ""
      }} mdxType="Word" /></h4>
    <p>{`There are many compounds made with `}<Word w={{
        p: "کول",
        f: "kawul",
        e: "to make",
        a: "kawul-m"
      }} mdxType="Word" />{` in Pukhto. These compounds take an object and involve doing something to that object that changes it.`}</p>
    <Table bordered unset wide verticalContent titleRow={["Verb", "Present / Imperfective", "Subjunctive / Perfective"]} mdxType="Table">{[[{
        p: "پوهول",
        f: "pohawul",
        e: "to make understand",
        a: ""
      }, {
        p: "پوهوی",
        f: "pohawée",
        e: "",
        a: ""
      }, {
        p: "پوهه کړی",
        f: "poha kRée",
        e: "",
        a: ""
      }], [{
        p: "اُلټۍ کول",
        f: "UlTúy kawul",
        e: "",
        a: "15v57"
      }, {
        p: "اُلټۍ کوی",
        f: "UlTúy kawee",
        e: "",
        a: ""
      }, {
        p: "اُلټۍ کړی",
        f: "UlTúy kRee",
        e: "",
        a: ""
      }], [{
        p: "اُودهٔ کول",
        f: "oodu kawul",
        e: "to put to sleep",
        a: "15v58"
      }, {
        p: "اُوده کوی",
        f: "oodu kawee",
        e: "",
        a: ""
      }, {
        p: "اُوده کړی",
        f: "oodu kRee",
        e: "",
        a: ""
      }], [{
        p: "پټول",
        f: "puTawul",
        e: "to hide / cause to hide",
        a: "15v59"
      }, {
        p: "پټوی",
        f: "puTawee",
        e: "",
        a: ""
      }, {
        p: "پټ کړی",
        f: "puT kRee",
        e: "",
        a: ""
      }], [{
        p: "جوړول",
        f: "joRawul",
        e: "to build, mend, cause to recover",
        a: "15v60"
      }, {
        p: "جوړوی",
        f: "joRawee",
        e: "",
        a: ""
      }, {
        p: "جوړ کړی",
        f: "joR kRee",
        e: "",
        a: ""
      }], [{
        p: "ماتول",
        f: "maatawul",
        e: "to break (something)",
        a: "15v61"
      }, {
        p: "ماتوی",
        f: "maatawee",
        e: "",
        a: ""
      }, {
        p: "مات کړی",
        f: "maat kRee",
        e: "",
        a: ""
      }]]}</Table>
    <Table bordered unset wide verticalContent titleRow={["Imperfective", "Perfective"]} mdxType="Table">{[[{
        p: "زۀ به خپل طالبعلم پوهوم.",
        f: "zu ba khpul taalibílum pohawúm.",
        e: "I will make my student understand (i.e. inform my student).",
        a: "15ee45"
      }, {
        p: "زۀ به خپل سټُوډنټ پوهه کړم (کم).",
        f: "zu ba khpul sTooDánT póha kRum (kum).",
        e: "I will make my student understand.",
        a: "15ee44"
      }]]}</Table>
    <h4 {...{
      "id": "examples-of-compound-verbs-with-word-w-p-کول-f-kawul-e-to-make-a----in-the-future"
    }}>{`Examples of compound verbs with `}<Word w={{
        p: "کول",
        f: "kawul",
        e: "to make",
        a: ""
      }} mdxType="Word" />{` in the future`}</h4>
    <Examples mdxType="Examples">{[{
        p: "تۀ به د ماښام ډوډۍ د پاره څۀ پاخۀ کې؟",
        f: "tu ba du maaxaam DoDúy du paara tsu paakhú ke?",
        e: "What will you cook for dinner?",
        a: "15e55"
      }, {
        p: "کېدے شى چې دال پاخۀ کړم.",
        f: "kedey she che daal paakhú kRum.",
        e: "I might cook lentils.",
        a: "15e56"
      }, {
        p: "تاسو به ماښام ورېژې پخې کړئ؟",
        f: "taaso ba maaxaam wrejze pakhé kReyy?",
        e: "Will you cook rice tonight?",
        a: "15e57"
      }, {
        p: "نه، ماښام به نۀ پخوُو.",
        f: "na, maaxaam ba nu pakhawoo.",
        e: "No, we won't cook it tonight.",
        a: "15e58"
      }, {
        p: "په دې موسم کښې به هغه څۀ خرڅ کى؟",
        f: "pu de mosum ke ba haghá tsu khats kee?",
        e: "What will he sell in this season?",
        a: "15e59"
      }, {
        p: "په دې موسم کښې به انګُور او انار خرڅ کړى.",
        f: "pu de mosum ke ba aangóor aw aanáan khats kee.",
        e: "He will sell grapes and pomegranates in this season.",
        a: "15e60"
      }, {
        p: "ترکاڼ به کله مېز جوړ کړى؟",
        f: "tarkáaN ba kala mez joR kee?",
        e: "When will the carpenter build the table?",
        a: "15e61"
      }, {
        p: "په دې هفته کښې به جوړ نۀ کى.",
        f: "pu de haftá ba joR-nu-kee.",
        e: "He won't make it this week.",
        a: "15e62"
      }, {
        p: "کُرسۍ به کله جوړه کې؟",
        f: "kUrsúy ba kala joRa ke?",
        e: "When will you fix the chair?",
        a: "15e63"
      }, {
        p: "اول به دا کُرسۍ جوړه کم، او بيا به ستا کُرسۍ جوړه کړم.",
        f: "aawál ba daa kUrsúy joRa kRum, aw byaa ba staa kUrsúy jóRa kum.",
        e: "First I will fix this chair, and then I will fix your chair.",
        a: "15e64"
      }, {
        p: "کله به هغه خپل دُوکان بند کړى؟",
        f: "kala ba haghá khpul dookáan band kRee?",
        e: "When will he close his shop?",
        a: "15e65"
      }, {
        p: "هغه به اتۀ بجې بند کړى.",
        f: "haghá ba aatú baje band kee.",
        e: "He will close it at eight.",
        a: "15e66"
      }, {
        p: "کله به اخبار ختم کړې؟",
        f: "kala ba akhbáar khatum kRe?",
        e: "When will you finish the newspaper?",
        a: "15e67"
      }, {
        p: "لس مېنټه پس به يې ختم کړم.",
        f: "lus minTa pas ba ye khátum kRum.",
        e: "I will finish it after ten minutes.",
        a: "15e68"
      }, // WATCH DIFFERENT NUMBERS HERE
      {
        p: "تۀ به کله کار شروع کې؟",
        f: "tu ba kala kaar shUroo ke?",
        e: "When will you be starting work?",
        a: "15e6"
      }, {
        p: "دوه ورځې پس به کار شروع کوم.",
        f: "dwawrudze pas ba kaar shUróo kawum.",
        e: "I will be starting work after two days.",
        a: "15e7"
      }, {
        p: "کله به دا کار خلاص کې؟",
        f: "kala ba daa kaar khilaas ke?",
        e: "When will you be finishing this work?",
        a: "15e8"
      }, {
        p: "نن به يې خلاص کوم.",
        f: "nun ba ye khilaas kum.",
        e: "I will be finishing this today.",
        a: "15e9"
      }]}</Examples>
    <h4 {...{
      "id": "notes"
    }}>{`NOTES:`}</h4>
    <ol>
      <li parentName="ol">
        <p parentName="li">{`Verbal adjectives are inflected in agreement with the object.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`In the perfective form, the negative verbs prefix `}<Word w={{
            p: "نهٔ",
            f: "nu-",
            e: "",
            a: "nu"
          }} mdxType="Word" /> precedes the verb stem <Word w={{
            p: "کړـ",
            f: "kR-",
            e: "",
            a: ""
          }} mdxType="Word" />{`:`}</p>
        <Examples mdxType="Examples">{[{
            p: "زۀ به ور کولاؤ کم.",
            f: "zu ba war kooláaw kum.",
            e: "I will open the door.",
            a: "15e69"
          }, {
            p: "زۀ به ور کولاؤ نۀ کم.",
            f: "zu ba war kooláaw nú-kum",
            e: "I will not open the door.",
            a: "15e70"
          }]}</Examples>
      </li>
    </ol>
    <h2 {...{
      "id": "the-verb-word-w-p-کول-f-kawul-e-to-do-a-kawul-m---in-the-future-tense"
    }}>{`The verb `}<Word w={{
        p: "کول",
        f: "kawul",
        e: "to do",
        a: "kawul-m"
      }} mdxType="Word" />{` in the future tense.`}</h2>
    <p>{`The verb `}<Word w={{
        p: "کول",
        f: "kawul",
        e: "to do",
        a: "kawul-m"
      }} mdxType="Word" />, (not to be confused with the <Word w={{
        p: "کول",
        f: "kawul",
        e: "to make",
        a: "kawul-m"
      }} mdxType="Word" /> above) also has an irregular subjunctive / perfective root form, <Word w={{
        p: "وکړـ",
        f: "óokR-",
        e: "",
        a: ""
      }} mdxType="Word" /> which <em>always includes a <Word w={{
          p: "و",
          f: "oo",
          e: "",
          a: "oo-sound-2"
        }} mdxType="Word" /> prefix</em>. Often in spoken language the root <Word w={{
        p: "وکړـ",
        f: "óokR-",
        e: "",
        a: ""
      }} mdxType="Word" /> is shortened to <Word w={{
        p: "وکـ",
        f: "óok-",
        e: "",
        a: ""
      }} mdxType="Word" />{` in speech (not in writing).`}</p>
    <h4 {...{
      "id": "the-subjunctive-form-of-word-w-p-کول-f-kawúl-e-to-do-a---"
    }}>{`The subjunctive form of `}<Word w={{
        p: "کول",
        f: "kawúl",
        e: "to do",
        a: ""
      }} mdxType="Word" /></h4>
    <Table bordered unset titleRow={["", "singular", "plural"]} mdxType="Table">{[[{
        e: "1st"
      }, {
        p: "وکړم / وکم",
        f: "óokRum / óokum",
        e: "",
        a: ""
      }, {
        p: "وکړو / وکو",
        f: "óokRoo / óokoo",
        e: "",
        a: ""
      }], [{
        e: "2nd"
      }, {
        p: "وکړې / وکې",
        f: "óokRe / óoke",
        e: "",
        a: ""
      }, {
        p: "وکړئ / وکئ",
        f: "óokReyy / óokeyy",
        e: "",
        a: ""
      }], [{
        e: "3rd"
      }, {
        p: "وکړی / وکی",
        f: "óokRee / óokee",
        e: "",
        a: ""
      }, {
        p: "وکړی / وکی",
        f: "óokRee / óokee",
        e: "",
        a: ""
      }]]}</Table>
    <h4 {...{
      "id": "compound-verbs-with-word-w-p-کول-f-kawul-e-to-do-a---"
    }}>{`Compound verbs with `}<Word w={{
        p: "کول",
        f: "kawul",
        e: "to do",
        a: ""
      }} mdxType="Word" /></h4>
    <p>{`The verb `}<Word w={{
        p: "کول",
        f: "kawul",
        e: "to do",
        a: "kawul-m"
      }} mdxType="Word" />{` is often used in compound verbs describing actions where the action being done `}<em parentName="p">{`is`}</em>{` the object of the sentence.`}</p>
    <p>{`This below chart may help you to understand the difference between the compounds with `}<Word w={{
        p: "کول",
        f: "kawul",
        e: "to make",
        a: "kawul"
      }} mdxType="Word" /> and the compounds with <Word w={{
        p: "کول",
        f: "kawul",
        e: "to do",
        a: "kawul"
      }} mdxType="Word" /></p>
    <Table unset bordered titleRow={["Compounds w/ کول (to make)", "Compounds w/ کول (to do)"]} mdxType="Table">{[[{
        e: "Takes an outside object and does something that changes the object. (Makes the object ____ )"
      }, {
        e: "The object is inside the compound itself. The object is an action that is performed."
      }], [{
        e: <>
                    <div><em>Never</em> Takes a <Word w={{
              p: "و",
              f: "oo",
              e: "",
              a: ""
            }} mdxType="Word" /> prefix in the perfective/subjunctive form.</div>
                    <div style={{
            textAlign: "center"
          }}><Word w={{
              p: "کړـ",
              f: "kR-",
              e: "",
              a: ""
            }} mdxType="Word" /></div>
                </>
      }, {
        e: <>
                    <div><em>Always</em> Takes a <Word w={{
              p: "و",
              f: "oo",
              e: "",
              a: ""
            }} mdxType="Word" /> prefix in the perfective/subjunctive form.</div>
                    <div style={{
            textAlign: "center"
          }}><Word w={{
              p: "وکړـ",
              f: "óokR-",
              e: "",
              a: ""
            }} mdxType="Word" /></div>
                </>
      }]]}</Table>
    <p>{`Here are some examples of compound verbs with `}<Word w={{
        p: "کول",
        f: "kawul",
        e: "to do",
        a: "kawul-m"
      }} mdxType="Word" /></p>
    <Table bordered unset wide verticalContent titleRow={["Verb", "Present / Imperfective", "Subjunctive / Perfective"]} mdxType="Table">{[[{
        p: "کار کول",
        f: "kaar kawul",
        e: "to work",
        a: ""
      }, {
        p: "کار کوی",
        f: "kaar kawee",
        e: "",
        a: ""
      }, {
        p: "کار وکړی (وکی)",
        f: "kaar óokRee (óokee)",
        e: "",
        a: ""
      }], [{
        p: "خبرې کول",
        f: "khabúre kawul",
        e: "to talk",
        a: ""
      }, {
        p: "خبرې کوی",
        f: "khabúre kawee",
        e: "",
        a: ""
      }, {
        p: "خبرې وکړی (وکی)",
        f: "khabúre óokRee (óokee)",
        e: "",
        a: ""
      }], [{
        p: "تپوس کول",
        f: "tapóos kawul",
        e: "to ask a question",
        a: ""
      }, {
        p: "تپوس کول",
        f: "tapóos kawee",
        e: "",
        a: ""
      }, {
        p: "تپوس وکړی (وکی)",
        f: "tapóos óokRee (óokee)",
        e: "",
        a: ""
      }]]}</Table>
    <p>{`The imperfective form can be used to show that an an action is limited and immediate, as in a one-off event. To show continous action, the (subjunctive based) perfective form.`}</p>
    <Table bordered unset wide verticalContent titleRow={["Imperfective", "Perfective"]} mdxType="Table">{[[{
        p: "هغه به نن شپه کار کوى؟",
        f: "haghá ba nun shpa kaar kawee?",
        e: "Will he be working tonight? (As usual)",
        a: "15e50"
      }, {
        p: "هغه به نن شپه کار وکى؟",
        f: "haghá ba nun shpa kaar óokee?",
        e: "Will he work tonight? (This night only)",
        a: "15e49"
      }]]}</Table>
    <h4 {...{
      "id": "examples-of-compound-verbs-with-word-w-p-کول-f-kawul-e-to-do-a-kawul-m--"
    }}>{`Examples of compound verbs with `}<Word w={{
        p: "کول",
        f: "kawul",
        e: "to do",
        a: "kawul-m"
      }} mdxType="Word" /></h4>
    <Examples mdxType="Examples">{[{
        p: "دا به سخکال په ښار کښې کار وکی؟",
        f: "daa ba sakaal pu xaar ke kaar óokee?",
        e: "Will she work in the city this year?",
        a: "15e51"
      }, {
        p: "آو، وبه کى.",
        f: "aaw, óo-ba-kee.",
        e: "Yes, she will.",
        a: "15e52"
      }, {
        p: "زۀ به ستا د پلار نه تپوس وک (وکم).",
        f: "zu ba staa plaar na tapóos óokum.",
        e: "I will ask your father. (Lit. I will do a question from your father)",
        a: "15e46"
      }, {
        p: "څۀ وخت به هلته خبرې وکې؟",
        f: "tsu wakht ba halta khabúre óoke?",
        e: "What time will you speak there?",
        a: "15e53"
      }, {
        p: "صبا نه بله روځ به هلته خبرې وکم.",
        f: "sabaa na, bulawrudz ba hálta khabúre óokum.",
        e: "I shall speak there the day after tomorrow.",
        a: "15e54"
      }]}</Examples>
    <h2 {...{
      "id": "the-future-progressive-word-w-p-وی-f-wee-e--a-wee-f--"
    }}>{`The future progressive `}<Word w={{
        p: "وی",
        f: "wee",
        e: "",
        a: "wee-f"
      }} mdxType="Word" /></h2>
    <p>{`The equative verb (to be) in the future looks similar to it’s present form, except that all third person endings (`}<Word w={{
        p: "دے",
        f: "dey",
        e: "",
        a: "dey"
      }} mdxType="Word" />, <Word w={{
        p: "ده",
        f: "da",
        e: "",
        a: "da"
      }} mdxType="Word" />, and <Word w={{
        p: "دی",
        f: "dee",
        e: "",
        a: "dee"
      }} mdxType="Word" />) are replaced with <Word w={{
        p: "وی",
        f: "wee",
        e: "",
        a: "wee-f"
      }} mdxType="Word" />{`. `}</p>
    <p>{`Also, `}<Word w={{
        p: "وی",
        f: "wee",
        e: "",
        a: "wee-f"
      }} mdxType="Word" /> is used in a sentence without <Word w={{
        p: "به",
        f: "ba",
        e: "",
        a: "ba-f-2"
      }} mdxType="Word" />{`, it it signals an existing continuous or habitual state of a person or thing, for example, the weather every year at springtime.`}</p>
    <Examples mdxType="Examples">{[{
        p: "هغوى څۀ وخت په کلى کښې وى؟",
        f: "haghwée tsu wakht pu kúlee ke wee?",
        e: "When are they usually in the village?",
        a: "15e71"
      }, {
        p: "هغوى هېچرې په کلى کښې نۀ وى.",
        f: "haghwée hechere pu kúlee ke nú-wee.",
        e: "They are never in the village.",
        a: "15e72"
      }, {
        p: "صېب روزانه په دفتر کښې وى؟",
        f: "seb rozaana pu duftúr ke wee?",
        e: "Is the boss in his office daily?",
        a: "15e73"
      }, {
        p: "آو، د کار په ورځو کښې روزانه په دفتر کښې وى.",
        f: "aaw, du kaar pu wrádzo ke rozaaná pu duftúr ke wee.",
        e: "Yes, he is in the office on every work day.",
        a: "15e74"
      }, {
        p: "په سپرلى کښې موسم څنګه وى؟",
        f: "pu sparlée ke mosúm tsunga wee?",
        e: "What is the weather like during the spring?",
        a: "15e75"
      }, {
        p: "په سپرلى کښې نۀ ګرمى وى نۀ يخنى.",
        f: "pu sparlee ke nu garmee wee, nu yukhnée",
        e: "In the spring, there is neither hot nor cold.",
        a: "15e76"
      }, {
        p: "ګُلُونه به سرۀ وى؟",
        f: "gUlóona ba sru wee?",
        e: "Will the flowers be red?",
        a: "15e77"
      }, {
        p: "هلکان به دا وخت په ګراونډ کښې وى؟",
        f: "halukáan ba daa wakht pu gronD ke wee?",
        e: "Will the boys be at the sports ground at this time?",
        a: "15e78"
      }]}</Examples>
    <h4 {...{
      "id": "notes-1"
    }}>{`NOTES:`}</h4>
    <ol>
      <li parentName="ol">
        <p parentName="li">{`The `}<Word w={{
            p: "و",
            f: "w",
            e: "",
            a: "w-sound-3"
          }} mdxType="Word" />{` sound here is somewhere beween a / v / and / w /.`}</p>
      </li>
      <li parentName="ol">
        <Word w={{
          p: "به",
          f: "ba",
          e: "",
          a: "ba-f"
        }} mdxType="Word" /> is added to <Word w={{
          p: "وی",
          f: "wee",
          e: "",
          a: "wee-f"
        }} mdxType="Word" /> to make the imperfective future tense.
      </li>
    </ol>
    <h2 {...{
      "id": "comprehension-drill-learning-your-way-around"
    }}>{`Comprehension drill: Learning your way around`}</h2>
    <p>{`👆 Find a big map of your area. Take an imaginary trip with your finger, or give instructions for your helper to travel. Use the following words in your description, making complete sentences:`}</p>
    <Examples substitutions mdxType="Examples">{[{
        p: "اترائى",
        f: "Utráa`ee",
        e: "downward slope",
        a: "15s1"
      }, {
        p: "بندونه",
        f: "bandóona",
        e: "dams",
        a: "15s2"
      }, {
        p: "پُل",
        f: "pUl",
        e: "bridge",
        a: "15s3"
      }, {
        p: "چړائى",
        f: "chaRaa`ee",
        e: "upward slope",
        a: "15s4"
      }, {
        p: "ځنګل",
        f: "dzungúl",
        e: "jungle / forest",
        a: "15s5"
      }, {
        p: "سړکُونه",
        f: "saRukóona",
        e: "roads",
        a: "15s6"
      }, {
        p: "سيندُونه",
        f: "seendóona",
        e: "rivers",
        a: "15s7"
      }, {
        p: "ښار",
        f: "xaar",
        e: "city",
        a: "15s8"
      }, // check pronunciation
      {
        p: "صحراګانې",
        f: "sahraagáane",
        e: "deserts",
        a: "15s9"
      }, {
        p: "غرُونه",
        f: "ghróona",
        e: "mountains",
        a: "15s10"
      }, {
        p: "قبيلې",
        f: "qabeelé",
        e: "tribes",
        a: "15s11"
      }, {
        p: "کلى",
        f: "kúlee",
        e: "villages",
        a: "15s12"
      }]}</Examples>
    <h2 {...{
      "id": "towns-in-khyber-pukhtunkhwa"
    }}>{`Towns in Khyber Pukhtunkhwa`}</h2>
    <p>{`Learn the names of the principal towns. Pay special attention to those “hard” sounds that occur in names like `}<Word w={{
        p: "ملاکنډ",
        f: "malaakánD",
        e: "",
        a: "malakandd"
      }} mdxType="Word" /></p>
    <img src={pukhtunkhwa} style={{
      maxWidth: "100%"
    }} />
    <Examples substitutions mdxType="Examples">{[{
        p: "جترال",
        f: "chitráal",
        e: "",
        a: "15s13"
      }, {
        p: "دیر",
        f: "deer",
        e: "",
        a: "15s14"
      }, {
        p: "بشام",
        f: "bisháam",
        e: "",
        a: "15s15"
      }, {
        p: "سیدوشریف",
        f: "said-U-shareef",
        e: "",
        a: "15s16"
      }, {
        p: "مانسهره",
        f: "maansahara",
        e: "",
        a: "15s17"
      }, {
        p: "ایبټ آباد",
        f: "ebT aabaad",
        e: "",
        a: "15s18"
      }, {
        p: "ملکنډ",
        f: "malakanD",
        e: "",
        a: "15s19"
      }, {
        p: "مردان",
        f: "mardáan",
        e: "",
        a: "15s20"
      }, {
        p: "خیبر",
        f: "kheybur",
        e: "",
        a: "15s21"
      }, {
        p: "پیښور",
        f: "pexawúr",
        e: "",
        a: "15s22"
      }, {
        p: "کوهاټ",
        f: "koháaT",
        e: "",
        a: "15s23"
      }, {
        p: "هنګو",
        f: "hangóo",
        e: "",
        a: "15s24"
      }, {
        p: "بنو",
        f: "bánoo",
        e: "",
        a: "15s25"
      }, {
        p: "ټانک",
        f: "Taank",
        e: "",
        a: "15s26"
      }, {
        p: "ډیره اسماعیل خان",
        f: "Deyra ismaa'eel khaan",
        e: "",
        a: "15s27"
      }]}</Examples>
    <h2 {...{
      "id": "memorising-moment"
    }}>{`Memorising moment`}</h2>
    <h4 {...{
      "id": "mataloona"
    }}>{`mataloona`}</h4>
    <Examples memorizingMoment mdxType="Examples">{memorizingMoments[15].slice(0, 4)}</Examples>
    <img src={guyWithPatoo} />
    <h4 {...{
      "id": "rahman-baba"
    }}>{`Rahman Baba`}</h4>
    <Examples memorizingMoment mdxType="Examples">{memorizingMoments[15].slice(4, 6)}</Examples>
    <img src={park} />
    <h2 {...{
      "id": "exercises-for-lesson-fifteen"
    }}>{`Exercises for Lesson Fifteen`}</h2>
    <ol>
      <li parentName="ol">{`Change the following sentences into perfective future tense. For example:`}</li>
    </ol>
    <Examples mdxType="Examples">{[{
        p: "زۀ دې ته بېګاله يو خط ليکم.",
        f: "zu de ta begáala yo khat leekúm.",
        e: "",
        a: "15q1"
      }]}</Examples>
    <p><p style={{
        textAlign: "center"
      }}>{`becomes`}</p></p>
    <Examples mdxType="Examples">{[{
        p: "زۀ به دې ته بېګاله يو خط وليکم.",
        f: "zu ba de ta begáala yo khat óoleekum.",
        e: "",
        a: "15q2"
      }]}</Examples>
    <Quiz mdxType="Quiz">{[{
        q: {
          p: "دا په کور کښې لوښى وينځى.",
          f: "daa pu kor ke lóoxee wéendzee."
        },
        answer: [{
          p: "دا به په کور کښې لوښی ووینځی.",
          f: "daa ba pu kor ke lóoxee óoweendzee"
        }]
      }, {
        q: {
          p: "دے اوس ما سره خبرې نۀ کوى.",
          f: "dey oos maa sara khabúre nu kawee."
        },
        answer: [{
          p: "دے به اوس ما سره خبرې ونهٔ کړی.",
          f: "dey ba oos maa sara khabure oo-nu-kRee."
        }, {
          p: "دے به اوس ما سره خبرې ونهٔ کی.",
          f: "dey ba oos maa sara khabure oo-nu-kee."
        }]
      }, {
        q: {
          p: "هغه اوس لرګى ماتوى.",
          f: "haghá oos lagée maatawée."
        },
        answer: [{
          p: "هغه به اوس لرګی مات کړی.",
          f: "haghá ba oos largée maat kRee."
        }, {
          p: "هغه به اوس لرګی مات کی.",
          f: "haghá ba oos largée maat kee."
        }]
      }, {
        q: {
          p: "دے صرف تازه کېلې خرڅوى.",
          f: "dey sirf taaza kele khartsawee."
        },
        answer: [{
          p: "دے به صرف تازه کېلې خرځ کړی.",
          f: "dey ba sirf taaza kele kharts kRee."
        }, {
          p: "دے به صرف تازه کېلې خرځ کی.",
          f: "dey ba sirf taaza kele kharts kee."
        }]
      }, {
        q: {
          p: "ترکانړ يو کُرسۍ جوړوى.",
          f: "tarkáaN yo kUrsúy joRawee."
        },
        answer: [{
          p: "ترکاڼ به یو کُرسۍ جوړه کړی.",
          f: "tarkáaN ba yo kUrsúy joRa kRee."
        }, {
          p: "ترکاڼ به یو کُرسۍ جوړه کی.",
          f: "tarkáaN ba yo kUrsúy joRa kee."
        }]
      }, {
        q: {
          p: "کور ته يې بوځې؟",
          f: "kor ta ye bodze?"
        },
        answer: [{
          p: "کور ته به یې بوځې؟",
          f: "kor ta ba ye bódze?"
        }]
      }, {
        q: {
          p: "دے هم ډوډۍ د پاره دلته راولې؟",
          f: "dey hum DoDuy du paara dălta raawule?"
        },
        answer: [{
          p: "دے به هم ډوډۍ د پاره دلته راولې؟",
          f: "dey ba hum DoDuy du paara dalta raawule?"
        }]
      }, {
        q: {
          p: "زمُونږ کلاس څۀ وخت شروع کېږى؟",
          f: "zmoonG klaas tsu wakht shUróo kéGee?"
        },
        answer: [{
          p: "زمونږ کلاس به څهٔ وخت شروع شی؟",
          f: "zmoonG klaas ba tsu wakht shUróo shee?"
        }]
      }, {
        q: {
          p: "زۀ په پښتو پوهېږم.",
          f: "zu pu puxto poheGum."
        },
        answer: [{
          p: "زه به په پښتو پوهه شم.",
          f: "zu ba pu puxto poha shum."
        }]
      }]}</Quiz>
    <ol {...{
      "start": 2
    }}>
      <li parentName="ol">{`Answer the following questions in full sentences with negative responses.`}</li>
    </ol>
    <Quiz mdxType="Quiz">{[{
        q: {
          p: "دوی به کور هم واخلى؟",
          f: "dwee ba kor hum wáakhlee?"
        },
        answer: [{
          p: "نه، دوی به کور هم وانهٔ خلی.",
          f: "na, dwee ba kor hum waa-nu-khlee."
        }]
      }, {
        q: {
          p: "تۀ به هغوى سره کلى ته لاړ شې؟",
          f: "tu ba haghwée sara kúlee ta laaR she?"
        },
        answer: [{
          p: "نه، زه به هغوی سره کلی تهٔ لاړ نه شم.",
          f: "na, zu ba haghwée sara kúlee ta laaR nu shum."
        }]
      }, {
        q: {
          p: "ماشُومان به دلته راولې؟",
          f: "maashoomáan ba dălta ráawule?"
        },
        answer: [{
          p: "نه، ماشُومان به دلته نهٔ راولم.",
          f: "na, maashoomáan ba dalta nu raawulum."
        }, {
          p: "نه، ماشُومان به دلته نه رانهٔ ولم.",
          f: "na, maashoomáan ba dalta raa-nu-wulum."
        }]
      }, {
        q: {
          p: "په بازار کښې به يې خرڅ کُو؟",
          f: "pu baazáar ke ba ye kharts koo?"
        },
        answer: [{
          p: "نه، په بازار کښې به یې خرڅ نهٔ کو.",
          f: "na, pu baazáar ke ba ye kharts nu koo."
        }]
      }, {
        q: {
          p: "کريم به خپل کور ته راشى؟",
          f: "karéem ba khpul kor ta ráashee?"
        },
        answer: [{
          p: "نه، کریم به خپل کور ته نهٔ راشی.",
          f: "na, karéem ba khpul kor ta nu raashee."
        }, {
          p: "نه، کریم به خپل کور ته نهٔ را نهٔ شی.",
          f: "na, karéem ba khpul kor ta raa nu shee."
        }]
      }]}</Quiz>
    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;
export const tableOfContents = (components={}) => [
  {
    id: "dialogue-fifteen-public-transportation",
    level: 2,
    title: "Dialogue fifteen: Public transportation",
    children: [
        
      ]
  },
{
    id: "pronunciation-drill",
    level: 2,
    title: "Pronunciation drill",
    children: [
        
      ]
  },
{
    id: "the-future-tense",
    level: 2,
    title: "The future tense",
    children: [
        {
              id: "1-imperfective",
              level: 3,
              title: "1. Imperfective",
              children: [
                      
                    ]
            },
      {
              id: "2-perfective",
              level: 3,
              title: "2. Perfective",
              children: [
                      
                    ]
            }
      ]
  },
{
    id: "the-verb-word-w-p-کېدل-f-kedúl-e-to-become-a-kedul-m---in-the-future-tense",
    level: 2,
    title: <React.Fragment>{`The verb `}<Word w={{ p: "کېدل", f: "kedúl", e: "to become", a: "kedul-m" }} />{` in the future tense`}</React.Fragment>,
    children: [
        
      ]
  },
{
    id: "the-verb-word-w-p-کول-f-kawul-e-to-make-a-kawul-m---in-the-future-tense",
    level: 2,
    title: <React.Fragment>{`The verb `}<Word w={{ p: "کول", f: "kawul", e: "to make", a: "kawul-m" }} />{` in the future tense.`}</React.Fragment>,
    children: [
        
      ]
  },
{
    id: "the-verb-word-w-p-کول-f-kawul-e-to-do-a-kawul-m---in-the-future-tense",
    level: 2,
    title: <React.Fragment>{`The verb `}<Word w={{ p: "کول", f: "kawul", e: "to do", a: "kawul-m" }} />{` in the future tense.`}</React.Fragment>,
    children: [
        
      ]
  },
{
    id: "the-future-progressive-word-w-p-وی-f-wee-e--a-wee-f--",
    level: 2,
    title: <React.Fragment>{`The future progressive `}<Word w={{ p: "وی", f: "wee", e: "", a: "wee-f" }} /></React.Fragment>,
    children: [
        
      ]
  },
{
    id: "comprehension-drill-learning-your-way-around",
    level: 2,
    title: "Comprehension drill: Learning your way around",
    children: [
        
      ]
  },
{
    id: "towns-in-khyber-pukhtunkhwa",
    level: 2,
    title: "Towns in Khyber Pukhtunkhwa",
    children: [
        
      ]
  },
{
    id: "memorising-moment",
    level: 2,
    title: "Memorising moment",
    children: [
        
      ]
  },
{
    id: "exercises-for-lesson-fifteen",
    level: 2,
    title: "Exercises for Lesson Fifteen",
    children: [
        
      ]
  }
]

export const frontMatter = {
  "title": "Lesson Fifteen"
}

