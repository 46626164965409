import React from 'react';

import { NavHashLink } from 'react-router-hash-link';
import scrollWithOffset from '../../lib/scroll-with-offset.js';
import './in-chapter-nav.css';

function InChapterNav({ tableOfContents }) {
    return (
        <div className="in-chapter-nav">
            {/* TODO: Is returning undefineds here best practice?? */}
            {tableOfContents.map((item, i)=> {
                if (item.level === 2) return (
                    <div key={item.title + i} className="unstyled-link level-2">
                        <NavHashLink scroll={scrollWithOffset} to={`#${item.id}`}>{item.title}</NavHashLink>
                        {item.children.map((child, j) => {
                            if (child.level === 3) return (
                                <div className="level-3" key={child.title + j}>
                                    <NavHashLink scroll={scrollWithOffset} to={`#${child.id}`}>{child.title}</NavHashLink>
                                </div>
                            );
                            else return undefined;
                        })}
                    </div>
                )
                else {
                    return undefined;
                }
            })}
        </div>
    );
}

export default InChapterNav;
