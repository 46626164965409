import React from 'react';
import chapters from '../../content/content.js';
import SEO from '../seo/seo.js';
import Link from "../../components/link/link.js";
import ShareableTitle from '../shareable-title/sharable-title.js';
import License from "../license/license.js";

function TableOfContentsPage() {
  function handleShare() {
    if (!navigator.share) {
      alert("Sorry, Sharing links are not supported on your device.");
      return;
    }
    navigator.share && navigator.share({
      title: "Speaking Pakistani Pukhto",
      url: 'https://www.speakingpashto.com/',
    });
  }
  return (
    <>
      <SEO title="Table of Contents" />
      <div className="main-content">
        <main>
          <ShareableTitle title="Table of Contents" shareFunction={handleShare} />
          {chapters.map((chapter, i) => (
            <div style={{ marginBottom: "1rem" }} key={chapter.path + "group"}>
              <h3 key={chapter.path} style={{ marginBottom: "0.5rem" }}>
                <Link to={chapter.path} style={{ textDecoration: "none" }}>{chapter.frontMatter.title}</Link>
              </h3>
              {chapter.tableOfContents.map((subHeading) => (
                <div key={subHeading.title} style={{ marginLeft: "1.5rem", marginBottom: "0.4rem" }}>
                  <Link to={`${chapter.path}#${subHeading.id}`} style={{ textDecoration: "none" }}>{subHeading.title}</Link>
                </div>
              ))}
            </div>
          ))}
          <License />
        </main>
      </div>
    </>
  )
}

export default TableOfContentsPage