
import React from 'react'
import { mdx } from '@mdx-js/react'
export const readingTime = {"text":"30 min read","minutes":29.365,"time":1761899.9999999998,"words":5873}
/* @jsx mdx */
import Dialogue from "../components/Dialogue";
import Word from "../components/Word";
import Examples from "../components/Examples";
import Table from "../components/Table";
import Quiz from "../components/Quiz";
import degShop from "../assets/images/deg-shop.png";
import camel from "../assets/images/camel.png";
import horse from "../assets/images/horse.png";
import duck from "../assets/images/duck.png";
import hen from "../assets/images/hen.png";
import donkey from "../assets/images/donkey.png";
import porcupine from "../assets/images/porcupine.png";
import boyBackSitting from "../assets/images/boy-back-sitting.png";
import memorizingMoments from "./memorizing-moments";
export const animalMaxH = "10rem";
export const imgFlexRowStyle = { display: "flex", justifyContent: "space-between", margin: "1rem 0" };
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope")
  return <div {...props}/>
};

const layoutProps = {
  animalMaxH
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <h2 {...{
      "id": "dialogue-eight-ladies-chit-chat--ګب-شپ"
    }}>{`Dialogue eight: Ladies “chit-chat” / ګب شپ`}</h2>
    <Dialogue audio="dialogue-8-ladies" mdxType="Dialogue">{[{
        s: "L",
        p: "خورې زۀ خفه يم.",
        f: "khore zu khufa yim.",
        e: "Sister, I am discouraged.",
        a: "8d1"
      }, {
        s: "H",
        p: "څنګه خورې؟",
        f: "tsunga khore?",
        e: "How come, sister?",
        a: "8d2"
      }, {
        s: "L",
        p: "په پښتو کښې ښه ترقى نۀ کوم.",
        f: "pu puxto ke xa taraqée nu kawum.",
        e: "I am not making good progress in Pukhto.",
        a: "8d3"
      }, {
        s: "H",
        p: "په ريشتيا؟",
        f: "pu rishtyaa?",
        e: "Really?",
        a: "8d4"
      }, {
        s: "L",
        p: "په کور کښې زما ډېر کار دے.",
        f: "pu kor ke zmaa Der kaar dey.",
        e: "I have a lot of work at home.",
        a: "8d5"
      }, {
        s: "H",
        p: "زما هم.",
        f: "zmaa hum",
        e: "Me too.",
        a: "8d6"
      }, {
        s: "L",
        p: "چې پښتو زده کوم، زۀ ستړې کېږم.",
        f: "che puxto zda kawum, zu stuRe keGum.",
        e: "When I learn Pukhto I become tired.",
        a: "8d7"
      }, {
        s: "H",
        p: ["زۀ پوهېږم. )يا(", "او ما ته پته ده."],
        f: ["zu poheGum (yaa)", "aw maa ta pata da."],
        e: "I understand.",
        a: "8d8"
      }, {
        s: "L",
        p: "دا وخت زما د پاره بې خونده دے.",
        f: "daa wakht zmaa du paara be khwanda dey.",
        e: "This is a joyless time for me.",
        a: "8d9"
      }, {
        s: "H",
        p: "مۀ خفه کېږه.",
        f: "mu khufa keGa.",
        e: "Don't be sad.",
        a: "8d10"
      }, {
        s: "L",
        p: "خُدائ پاک زما مدد کوى چې کوشش کوم.",
        f: "khUdaay paak zmaa madúd kawee che koshis kawum.",
        e: "God helps me when I try.",
        a: "8d11"
      }, {
        s: "H",
        p: "ډېره ښه ده. خُدائ رحيم دے.",
        f: "Dera xa da. khUdaay raheem dey.",
        e: "That's good. God is merciful.",
        a: "8d12"
      }, {
        s: "L",
        p: "اکثر زما خوا له راځه!",
        f: "aksăr zmaa khwaa la raadza!",
        e: "Come to visit me often!",
        a: "8d13"
      }]}</Dialogue>
    <img src={degShop} />
    <h2 {...{
      "id": "mens-gupshup-with-a-shopkeeper"
    }}>{`Men’s “gupshup” with a shopkeeper`}</h2>
    <Dialogue audio="8-dialogue-men" mdxType="Dialogue">{[{
        s: "L",
        p: "کار دې څنګه روان دے؟",
        f: "kaar de tsunga rawaan dey?",
        e: "How is your business going?",
        a: "8d14"
      }, {
        s: "H",
        p: "د الله فضل دے ګُزاره کېږى.",
        f: "du aallaa fázul dey gUzaara kéGee.",
        e: "By the grace of God it's OK.",
        a: "8d15"
      }, {
        s: "L",
        p: "دا ستاسو خپل کار دے؟",
        f: "daa staaso khpul kaar dey?",
        e: "Is it your own business?",
        a: "8d16"
      }, {
        s: "H",
        p: ["نه جى، زۀ نوکر يم.", "د ګُل محمد کار دے."],
        f: ["na jee, zu nokúr yim.", "du gUl mUhmád kaar dey."],
        e: ["No sir, I'm an employee.", "It's Gul Muhammad's business."],
        a: "8d17"
      }, {
        s: "L",
        p: "ستا دياړى څومره ده؟",
        f: "staa dyaaRée tsoomra da?",
        e: "How much is your daily wage?",
        a: "8d18"
      }, {
        s: "H",
        p: "ياره دوه سوه روپۍ راکوى.",
        f: "yara dwa sawa roopúy raakawée.",
        e: "Oh friend, he's paying me two hundred rupees.",
        a: "8d19"
      }, {
        s: "L",
        p: "زۀ پښتو زده کول غواړم.",
        f: "zu puxto zda kawul ghwaaRum.",
        e: "I want to learn Pukhto.",
        a: "8d20"
      }, {
        s: "L",
        p: "کۀ ستاسو اعتراض نۀ وى، نو زۀ به دلته ګپ شپ له راځم.",
        f: "ku stáaso itiráaz nu wee, no zu ba dălta gupshúp la raadzúm.",
        e: "If you don't object, then I'll come here to chat with you.",
        a: "8d21"
      }, {
        s: "H",
        p: "هېڅ خبره نۀ ده، ستاسو چې کله مرضى وى، راتلے شئ.",
        f: "hets khabura nu da, staaso che kala murzée wee, raatley sheyy.",
        e: "It's no problem, whenever you wish you can come.",
        a: "8d22"
      }, {
        s: "L",
        p: "ډېره مِهربانى، خو بيا هم تاسو ما ته ټېم راکړئ. زۀ ستاسو کار خرابول نۀ غواړم.",
        f: "Déra mirabaanée, kho byaa hum taasa maa ta Taaym ráakReyy. zu stáaso kaar kharabawul nu ghwaaRum.",
        e: "Many thanks, but still give me a time. I don't want to disturb your business.",
        a: "8d23"
      }, {
        s: "H",
        p: "ښه، نو د دوؤ بجو نه تر درېو بجو پورې زۀ اوزګار يم.",
        f: "xa, no du dwuo bajó na tur dreo bajó pore zu oozgáar yim.",
        e: "Fine, from two o'clock up to three o'clock I'm free.",
        a: "8d24"
      }]}</Dialogue>
    <h2 {...{
      "id": "pronunciation-drill"
    }}>{`Pronunciation Drill`}</h2>
    <h4 {...{
      "id": "contrasting-word-w-p-ک-f-k-a----with-word-w-p-خ-f-kh-a----and-word-w-p-ښ-f-x-a---"
    }}>{`Contrasting `}<Word w={{
        p: "ک",
        f: "k",
        a: ""
      }} mdxType="Word" /> with <Word w={{
        p: "خ",
        f: "kh",
        a: ""
      }} mdxType="Word" /> and <Word w={{
        p: "ښ",
        f: "x",
        a: ""
      }} mdxType="Word" /></h4>
    <Examples mdxType="Examples">{[{
        p: "دے کار ته روان دے.",
        f: "dey kaar ta rawaan dey.",
        e: "He is going to work.",
        a: "8p1"
      }, {
        p: "دے ښار ته روان دے.",
        f: "dey xaar ta rawaan dey.",
        e: "He is going to the city.",
        a: "8p2"
      }, {
        p: "زمُونږ کور اوس په کلى کښې دے.",
        f: "zmoonG kor oos pu kulee ke dey.",
        e: "Our house is now in the village.",
        a: "8p3"
      }, {
        p: "زمُونږ خور اوس په کلى کښې ده.",
        f: "zmoonG khor oos pu kulee ke da.",
        e: "Our sister is now in the village.",
        a: "8p4"
      }, {
        p: "تۀ کال وليکه!",
        f: "tu kaal óoleeka!",
        e: "Write 'kaal'.",
        a: "8p5"
      }, {
        p: "تۀ خال وليکه!",
        f: "tu khaal óoleeka!",
        e: "Write 'khaal'.",
        a: "8p6"
      }]}</Examples>
    <p>{`There are several words where these sounds come close to each other in the same word and need careful practice to say well. For example:`}</p>
    <Examples mdxType="Examples">{[{
        p: "ښکُلې",
        f: "xkÚle",
        e: "beautiful",
        a: "8p7"
      }, {
        p: "مخکښې",
        f: "mákhke",
        e: "in front of / before",
        a: "8p8"
      }]}</Examples>
    <h2 {...{
      "id": "the-preposition-word-w-p-د-f-du-e-of-a-du---with-masculine-nouns-and-adjectives"
    }}>{`The preposition `}<Word w={{
        p: "د",
        f: "du",
        e: "of",
        a: "du"
      }} mdxType="Word" />{` with masculine nouns and adjectives`}</h2>
    <p>{`The preposition `}<Word w={{
        p: "د",
        f: "du",
        a: "du"
      }} mdxType="Word" />{` denotes belonging to.`}</p>
    <Table bordered unset wide titleRow={["Possesive Noun", "Subject", "Adjective", "Verb"]} mdxType="Table">{[[{
        f: "du maalúk"
      }, {
        f: "kor"
      }, {
        f: "looy"
      }, {
        f: "dey."
      }, {
        p: "د ملک کور لوئ دے.",
        e: "The chief's house is big.",
        a: "8s1"
      }], [{
        f: "du saReee"
      }, {
        f: "aas"
      }, {
        f: "tez"
      }, {
        f: "dey."
      }, {
        p: "د سړى اس تېز دے.",
        e: "The man's horse is fast.",
        a: "8s2"
      }], [{
        f: "du DakTúr"
      }, {
        f: "zooy"
      }, {
        f: "ooxyáar"
      }, {
        f: "dey."
      }, {
        p: "د ډاکټر زوئ هوښيار دے.",
        e: "The doctor's son is wise.",
        a: "8s3"
      }], [{
        f: "du sTooDanT"
      }, {
        f: "kitáab"
      }, {
        f: "lunD"
      }, {
        f: "dey."
      }, {
        p: "د سټُوډنټ کتاب لنډ دے.",
        e: "The student's book is short.",
        a: "8s4"
      }], [{
        f: "du sTooDanTaano"
      }, {
        f: "sabúq"
      }, {
        f: "asaan"
      }, {
        f: "dey."
      }, {
        p: "د سټُوډنټانو سبق آسان دے.",
        e: "The students' lesson is easy.",
        a: "8s5"
      }], [{
        f: "du Ustáaz"
      }, {
        f: "kaar"
      }, {
        f: "graan"
      }, {
        f: "dey."
      }, {
        p: "د اُستاذ کار ګران دے.",
        e: "The teacher's work is hard.",
        a: "8s6"
      }], [{
        f: "du moTúr"
      }, {
        f: "maalúk"
      }, {
        f: "booDáa"
      }, {
        f: "dey."
      }, {
        p: "د موټر مالِک بُوډا دے.",
        e: "The owner of the car is old.",
        a: "8s7"
      }], [{
        f: "du koroono"
      }, {
        f: "maalúk"
      }, {
        f: "maaldáar"
      }, {
        f: "dey."
      }, {
        p: "د کورونو مالِک مالدار دے.",
        e: "The owner of the houses is rich.",
        a: "8s8"
      }], [{
        f: "du maashoomaano"
      }, {
        f: "plaar"
      }, {
        f: "beemáar"
      }, {
        f: "dey."
      }, {
        p: "د ماشُومانو پلار بيمار دے.",
        e: "The children's father is sick.",
        a: "8s9"
      }], [{
        f: "du klúrk"
      }, {
        f: "afsúr"
      }, {
        f: "ghaT"
      }, {
        f: "dey."
      }, {
        p: "د کلرک افسر غټ دے.",
        e: "The clerk's boss is fat.",
        a: "8s10"
      }], [{
        f: "du puxtanó"
      }, {
        f: "gheyrát"
      }, {
        f: "mushahóor"
      }, {
        f: "dey."
      }, {
        p: "د پښتنو غيرت مشهُور دے.",
        e: "The bravery of Pukhtuns is famous.",
        a: "8s11"
      }], [{
        f: "du shaahíd"
      }, {
        f: "maamáa"
      }, {
        f: "ghaT"
      }, {
        f: "dey."
      }, {
        p: "د شاهِد ماما غټ دے.",
        e: "Shahid's uncle is fat.",
        a: "8s12"
      }]]}</Table>
    <h4 {...{
      "id": "note"
    }}>{`NOTE:`}</h4>
    <p>{`The possessive preposition `}<Word w={{
        p: "د",
        f: "du",
        e: "of",
        a: "du"
      }} mdxType="Word" />{`  precedes the noun it modifies. It usually comes first in the sentence.`}</p>
    <h2 {...{
      "id": "the-oblique-case-inflection"
    }}>{`The oblique case (inflection)`}</h2>
    <ol>
      <li parentName="ol">{`Nouns are inflected into the oblique case when they are preceded by a preposition and/or followed by a postposition. When such a noun is the object of a pre- or postposition, the inflection that occurs is in addition to the inflection for number and gender, as in the following example and the chart below (cf. Lesson 3):`}</li>
    </ol>
    <Examples mdxType="Examples">{[{
        p: "مُونږ په دې کورونو کښې اوسو.",
        f: "moonG pu de koroono ke osoo.",
        e: "We are living in these houses.",
        a: "8e1"
      }]}</Examples>
    <ol {...{
      "start": 2
    }}>
      <li parentName="ol">{`The preopsition `}<Word w={{
          p: "د",
          f: "du",
          a: "du"
        }} mdxType="Word" />{` signaling possesion is very common and you will see words inflected (if possible) to be put in the oblique case following this preposition.`}</li>
    </ol>
    <p>{`The three most common ways masculine nouns are inflected are shown below:`}</p>
    <h4 {...{
      "id": "with-animate-nouns"
    }}>{`With animate nouns`}</h4>
    <Table bordered unset titleRow={["", "Regular", "Oblique"]} mdxType="Table">{[[{
        e: "m. s."
      }, {
        p: "هلک",
        f: "halúk",
        a: "haluk"
      }, {
        p: "هلک",
        f: "halúk",
        a: "haluk"
      }], [{
        e: "m. pl."
      }, {
        p: "هلکان",
        f: "halukaan",
        a: "halukaan"
      }, {
        p: "هلکانو",
        f: "halukaano",
        a: "halukaano"
      }]]}</Table>
    <h4 {...{
      "id": "with-inanimate-nouns"
    }}>{`With inanimate nouns`}</h4>
    <Table bordered unset titleRow={["", "Regular", "Oblique"]} mdxType="Table">{[[{
        e: "m. s."
      }, {
        p: "کور",
        f: "kor",
        a: "kor"
      }, {
        p: "کور",
        f: "kor",
        a: "kor"
      }], [{
        e: "m. pl."
      }, {
        p: "کورونه",
        f: "koroona",
        a: "koroona"
      }, {
        p: "کورونو",
        f: "koroono",
        a: "koroono"
      }]]}</Table>
    <h4 {...{
      "id": "with-nouns-ending-in-word-w-p-ے-f-ey-a-ey-sound--"
    }}>{`With nouns ending in `}<Word w={{
        p: "ے",
        f: "ey",
        a: "ey-sound"
      }} mdxType="Word" /></h4>
    <Table bordered unset titleRow={["", "Regular", "Oblique"]} mdxType="Table">{[[{
        e: "m. s."
      }, {
        p: "سړے",
        f: "saRey",
        a: "sarrey"
      }, {
        p: "سړی",
        f: "saRee",
        a: "sarrey"
      }], [{
        e: "m. pl."
      }, {
        p: "سړی",
        f: "saRee",
        a: "sarree"
      }, {
        p: "سړو",
        f: "saRo",
        a: "sarro"
      }]]}</Table>
    <ol {...{
      "start": 3
    }}>
      <li parentName="ol">{`Adjectives are also inflected for the the oblique case when they precede a noun in the oblique case. Notice how the adjective `}<Word w={{
          p: "زوړ",
          f: "zoR",
          a: "zorr",
          e: "old"
        }} mdxType="Word" />{` is inflected in the second sentence.`}</li>
    </ol>
    <Examples mdxType="Examples">{[{
        p: "د موټرُونو مالِک بُوډا دے.",
        f: "du moTuroono maalúk booDáa dey.",
        e: "The owner of the cars is old.",
        a: "8e2"
      }, {
        p: "د زړو موټرُونو مالِک بُوډا دے.",
        f: "du zaRo moTuroono maalúk booDáa dey.",
        e: "The owner of the old cars is old.",
        a: "8e3"
      }]}</Examples>
    <p>{`Observe how the adjectives below are declined in the oblique case:`}</p>
    <Table bordered wide unset titleRow={["", "Regular", "Oblique", "Regular", "Oblique"]} mdxType="Table">{[[{
        e: "m. s."
      }, {
        p: "سپین",
        f: "speen",
        a: "speen"
      }, {
        p: "سپین",
        f: "speen",
        a: "speen"
      }, {
        p: "ستړے",
        f: "stúRey",
        a: "sturrey"
      }, {
        p: "ستړی",
        f: "stúRee",
        a: "sturree"
      }], [{
        e: "m. pl."
      }, {
        p: "سپین",
        f: "speen",
        a: "speen"
      }, {
        p: "سپینو",
        f: "speeno",
        a: "speeno"
      }, {
        p: "ستړي",
        f: "stúRey",
        a: "sturrey"
      }, {
        p: "ستړو",
        f: "stúRo",
        a: "sturro"
      }], [{
        e: "m. s."
      }, {
        p: "زوړ",
        f: "zoR",
        a: "zorr"
      }, {
        p: "زاړه",
        f: "zaaRú",
        a: "zaarru"
      }, {
        p: "سور",
        f: "soor",
        a: "soor"
      }, {
        p: "سرهٔ",
        f: "sru",
        a: "sru"
      }], [{
        e: "m. pl."
      }, {
        p: "زاړه",
        f: "zaaRú",
        a: "zaarru"
      }, {
        p: "زړو",
        f: "zaRo",
        a: "zarro"
      }, {
        p: "سرهٔ",
        f: "sru",
        a: "sru"
      }, {
        p: "سرو",
        f: "sro",
        a: "sro"
      }]]}</Table>
    <ol {...{
      "start": 4
    }}>
      <li parentName="ol">{`Pronouns and demonstrative pronouns/adjectives are also inflected in the oblique case. However, the inflection for demonstrative pronouns/adjectives works differently than regular pronouns. Here are some examples of inflectiv with demonstrative adjectives.`}</li>
    </ol>
    <Examples mdxType="Examples">{[{
        p: "په دې جېب کښې",
        f: "pu de jep ke",
        e: "in this pocket",
        a: "8e4"
      }, {
        p: "په هغو مېزونو باندې",
        f: "pu hagho mezoono baande",
        e: "on those tables",
        a: "8e5"
      }, {
        p: "د دې سړى",
        f: "du de saRee",
        e: "of this man",
        a: "8e6"
      }, {
        p: "د دغو سړو",
        f: "du dágho saRo",
        e: "of these men",
        a: "8e7"
      }]}</Examples>
    <h4 {...{
      "id": "demonstrative-pronouns-in-the-oblique-case"
    }}>{`Demonstrative pronouns in the oblique case`}</h4>
    <h5 {...{
      "id": "singular"
    }}>{`Singular`}</h5>
    <Table bordered wide unset titleRow={["", "Regular", "Oblique"]} mdxType="Table">{[[{
        e: "this"
      }, {
        p: "دا",
        f: "daa",
        a: "daa-dem"
      }, {
        p: "دې",
        f: "de",
        a: "de-dem"
      }], [{
        e: "that (masc.) (near)"
      }, {
        p: "دغه",
        f: "dágha",
        a: "dagha-dem"
      }, {
        p: "دغه",
        f: "dágha",
        a: "dagha-dem"
      }], [{
        e: "that (fem.) (near)"
      }, {
        p: "دغه",
        f: "dágha",
        a: "dagha-dem"
      }, {
        p: "دغې",
        f: "dághe",
        a: "daghe-dem"
      }], [{
        e: "that (masc.) (far)"
      }, {
        p: "هغه",
        f: "hágha",
        a: "hagha-dem"
      }, {
        p: "هغه",
        f: "hágha",
        a: "hagha-dem"
      }], [{
        e: "that (fem.) (far)"
      }, {
        p: "هغه",
        f: "hágha",
        a: "hagha-dem"
      }, {
        p: "هغې",
        f: "hághe",
        a: "haghe-dem"
      }]]}</Table>
    <h5 {...{
      "id": "plural"
    }}>{`Plural`}</h5>
    <Table bordered wide unset titleRow={["", "Regular", "Oblique"]} mdxType="Table">{[[{
        e: "these"
      }, {
        p: "دا",
        f: "daa",
        a: "daa-dem"
      }, {
        p: "دې",
        f: "de",
        a: "de-dem"
      }], [{
        e: "those (near)"
      }, {
        p: "دغه",
        f: "dágha",
        a: "dagha-dem"
      }, {
        p: "دغو",
        f: "dágho",
        a: "dagho-dem"
      }], [{
        e: "those (far)"
      }, {
        p: "هغه",
        f: "hágha",
        a: "hagha-dem"
      }, {
        p: "هغو",
        f: "hágho",
        a: "hagho-dem"
      }]]}</Table>
    <h4 {...{
      "id": "note-1"
    }}>{`NOTE:`}</h4>
    <p>{`Remember that the `}<em parentName="p">{`regular pronoun`}</em>{` `}<Word w={{
        p: "هغه",
        f: "haghá",
        e: "him/her/it",
        a: "hagha-2"
      }} mdxType="Word" /> is different than the <em>demonstrative pronoun/adjective</em> <Word w={{
        p: "هغه",
        f: "hágha",
        e: "this",
        a: "hagha-demons"
      }} mdxType="Word" />{`, (note the stress) and so it behaves different when inflecting.`}</p>
    <h4 {...{
      "id": "masculine-nouns-inflected-in-the-oblique-case"
    }}>{`Masculine nouns inflected in the oblique case`}</h4>
    <ol>
      <li parentName="ol">{`Masculine nouns ending in a consonant are not inflected in the oblique case:`}</li>
    </ol>
    <Examples mdxType="Examples">{[{
        p: "د هغه هلک مڼه شته؟",
        f: "du hágha halúk maNá shta?",
        e: "Does that boy have an apple?",
        a: "8e8"
      }, {
        p: "د هغه هلک مڼه په دې تالى کښې ده.",
        f: "du hágha haluk maNá pu taalée ke da.",
        e: "That boy's apple is in the dish.",
        a: "8e9"
      }, {
        p: "هغه مزدُور دلته کار کوى.",
        f: "hágha mazdóor dălta kaar kawee.",
        e: "That worker works here.",
        a: "8e10"
      }, {
        p: "د هغه مزدُور نېک په دې دفتر کښې کار کوى.",
        f: "du hágha mazdóor nek pu de dăftur ke kaar kawee.",
        e: "That worker's boss works in this office.",
        a: "8e11"
      }, {
        p: "غټ بکس ما سره دے.",
        f: "ghaT bakus maa sara dey.",
        e: "The big case is with me.",
        a: "8e12"
      }, {
        p: "د غټ بکس چابى په دې مېز باندې ده.",
        f: "du ghaT bakus chaabee pu de mez baande da.",
        e: "The big case's key is on this table.",
        a: "8e13"
      }]}</Examples>
    <ol {...{
      "start": 2
    }}>
      <li parentName="ol">{`Masculine singular nouns which end in a consonant add the plural suffixes `}<Word w={{
          p: "-ان",
          f: "-aan",
          a: "aan"
        }} mdxType="Word" /> plus the vowel <Word w={{
          p: "و",
          f: "o",
          a: "o-sound"
        }} mdxType="Word" />{` in the oblique case:`}</li>
    </ol>
    <Examples mdxType="Examples">{[{
        p: "د هلکانو سره بال شته؟",
        f: "du halukaano sara baal shta?",
        e: "Do the boys have a ball?",
        a: "8e14"
      }, {
        p: "د هلکانو بال خراب دے.",
        f: "du halukaano baal kharaab dey.",
        e: "The boys' ball is bad.",
        a: "8e15"
      }, {
        p: "د مريضانو ډاکټر شته؟",
        f: "du mareezáano DakTúr shta?",
        e: "Do these patients have a doctor?",
        a: "8e16"
      }, {
        p: "د مريضانو ډاکټر قابِل دے.",
        f: "du mareezáano DakTúr qaabíl dey.",
        e: "The patients' doctor is skilled.",
        a: "8e17"
      }]}</Examples>
    <ol {...{
      "start": 3
    }}>
      <li parentName="ol">{`The final `}<Word w={{
          p: "ـه",
          f: "-a",
          a: "a-sound"
        }} mdxType="Word" /> in the masculine plural suffix <Word w={{
          p: "ـونه",
          f: "-oona",
          a: "oona"
        }} mdxType="Word" /> is declined in the oblique case to <Word w={{
          p: "ـونو",
          f: "-oono",
          a: "oono"
        }} mdxType="Word" />{`:`}</li>
    </ol>
    <Examples mdxType="Examples">{[{
        p: "زمُونږ کورونه هلته دى.",
        f: "zmoonG koroona hálta dee.",
        e: "Our houses are there.",
        a: "8e18"
      }, {
        p: "مُونږ په دې کورونو کښې اوسو.",
        f: "moonG pu de koroono ke ósoo.",
        e: "We are living in these houses.",
        a: "8e19"
      }]}</Examples>
    <ol {...{
      "start": 4
    }}>
      <li parentName="ol">{`Masculine singular nouns and adjectives ending in `}<Word w={{
          p: "ے",
          f: "ey",
          a: "ey-sound"
        }} mdxType="Word" /> are declined to <Word w={{
          p: "ـی",
          f: "-ee",
          a: "ee-sound"
        }} mdxType="Word" />{` in the oblique case:`}</li>
    </ol>
    <Examples mdxType="Examples">{[{
        p: "دغه سړے غيرتمند دے.",
        f: "dágha saRéy gheyratmúnd dey.",
        e: <>This man has <em>ghairat</em>.</>,
        a: "8e20"
      }, {
        p: "د دغې سړى غيرت زيات دے.",
        // check pronunciation on recording
        f: "du dághe saRee gheyrát zyaat dey.",
        e: <>This man's <em>ghairat</em> is great.</>,
        a: "8e21"
      }]}</Examples>
    <p>{`Masculine plural nouns and adjectives ending in `}<Word w={{
        p: "ـی",
        f: "-ee",
        a: "ee-sound"
      }} mdxType="Word" /> are declined to <Word w={{
        p: "ـو",
        f: "-o",
        a: "o-sound"
      }} mdxType="Word" />{` in the oblique case:`}</p>
    <Examples mdxType="Examples">{[{
        p: "هغه سړى د ټوپۍ دى.",
        f: "hágha saRée du Topúy dee.",
        e: "These men are from Topi.",
        a: "8e22"
      }, {
        p: "د سړو بېګونه په موټر کښې دى.",
        f: "du saRo begoona pu moTúr ke dee.",
        e: "The men's bags are in the car.",
        a: "8e23"
      }, {
        p: "هغوى زما د ملګرو مېلمانۀ دى.",
        f: "haghwee zmaa du malguro melmaanu dee.",
        e: "They are my friends' guests.",
        a: "8e24"
      }, {
        p: "زمُونږ په ملګرو کښې يو امريکنے هم شته.",
        f: "zmoonG pu malguro ke yo amreekanéy hum shta.",
        e: "Among our friends there is also an American.",
        a: "8e25"
      }]}</Examples>
    <h4 {...{
      "id": "note-2"
    }}>{`NOTE:`}</h4>
    <p><p><Word w={{
          p: "غیرت",
          f: "gheyrát",
          a: "gheyrat"
        }} mdxType="Word" />{` is a central idea in Pukhtun culture. It has no direct English equivalent, but is close to the idea of jealous pride, zeal and honour.`}</p></p>
    <h2 {...{
      "id": "fun-with-animals"
    }}>{`Fun with animals`}</h2>
    <img src={camel} style={{
      float: "right"
    }} />
    <p>{`👆 Apart from sitting with your helper and this book, it is good to be out and about using new words and phrases. Photocopies of pictures are a great conversation starter, and will also allow you to control the talk in the area you are studying. Photocopy the following pages and find out about these animals from your friends.`}</p>
    <p>{`Here are some useful phrases to memorise:`}</p>
    <Examples mdxType="Examples">{[{
        p: "دې ځناور ته څۀ وائې؟",
        f: "de dzanaawár ta tsu waaye?",
        e: "What is this animal called?",
        a: "8e26"
      }, {
        p: "ډېرو ته څۀ وائې؟",
        f: "Dero ta tsu waaye?",
        e: "What are many called?",
        a: "8e27"
      }, {
        p: "ښځې ته څۀ وائې؟",
        f: "xudze ta tsu waaye?",
        e: "What is a female called?",
        a: "8e28"
      }, {
        p: "نر ته څۀ وائې؟",
        f: "năr ta tsu waaye?",
        e: "What is a male called?",
        a: "8e29"
      }, {
        p: "وړوکې ته څۀ وائې؟",
        f: "waRooke ta tsu waaye?",
        e: "What are the young called?",
        a: "8e30"
      }, {
        p: "وړوکې ښځې (نر) ته څۀ وائې؟",
        f: "waRóoke xudze (năr) to tsu waaye?",
        e: "What are young females (males) called?",
        a: "8e31"
      }, {
        p: "دا ځناور څنګه آواز کوی؟",
        f: "da dzanaawár tsínga aawáaz kawee?",
        e: "What noise does this animal make?",
        a: "8e32"
      }]}</Examples>

    <div style={imgFlexRowStyle}>
    <img src={donkey} style={{
        maxHeight: animalMaxH
      }} />
    <img src={duck} style={{
        maxHeight: animalMaxH
      }} />
    </div>
    <div style={imgFlexRowStyle}>
    <img src={horse} style={{
        maxheight: animalMaxH
      }} />
    <img src={hen} style={{
        maxHeight: animalMaxH
      }} />
    </div>
    <h2 {...{
      "id": "intransitive-verbs"
    }}>{`Intransitive Verbs`}</h2>
    <p>{`As mentioned in Lesson Seven, there are two kinds of verbs in Pukhto: transitive and intransitive. (Transitive verbs are “doing” verbs, for example 'to eat’.)`}</p>
    <p><strong parentName="p">{`Intransitive verbs`}</strong>{` are verbs that do `}<em parentName="p">{`not`}</em>{` act on anyone or anything, ie. they do not take an object. For example, to understand, to sleep, to be born, to start out, to wait, to smile, to arrive, to leave, to leak, etc.`}</p>
    <p>{`The distinction between transitive and intransitive verbs is very important in Pukhto.`}</p>
    <h3 {...{
      "id": "the-intransitive-verb-word-w-p-تلل-f-tlul-e-to-go-a-tlul---and-the-postposition-word-w-p-ته-f-ta-e-to-a-ta--"
    }}>{`The intransitive verb `}<Word w={{
        p: "تلل",
        f: "tlul",
        e: "to go",
        a: "tlul"
      }} mdxType="Word" /> and the postposition <Word w={{
        p: "ته",
        f: "ta",
        e: "to",
        a: "ta"
      }} mdxType="Word" /></h3>
    <Table bordered wide unset titleRow={["Subject", "Adverb", "Noun", "Post-position", "Verb"]} mdxType="Table">{[[{
        f: "tu"
      }, {
        f: "oos"
      }, {
        f: "kor"
      }, {
        f: "ta"
      }, {
        f: "dze?"
      }, {
        span: 3,
        p: "تۀ اوس کور ته ځې؟",
        e: "Are you going home now?",
        a: "8s13"
      }], [{
        f: "na,"
      }, {
        f: "oos"
      }, {
        f: "xaar"
      }, {
        f: "ta"
      }, {
        f: "dzum."
      }, {
        span: 3,
        p: "نه، اوس ښار ته ځم.",
        e: "No, I'm going to the city.",
        a: "8s14"
      }], [{
        f: "staa wror"
      }, {
        f: "tsu wakht"
      }, {
        f: "chaarsadé"
      }, {
        f: "ta"
      }, {
        f: "dzee?"
      }, {
        span: 3,
        p: "ستا ورور څۀ وخت چارسدې ته ځى؟",
        e: "When is your brother going to Charsadda?",
        a: "8s15"
      }], [{
        f: "zmaa wror"
      }, {
        f: "nun"
      }, {
        f: "chaarsadé"
      }, {
        f: "ta"
      }, {
        f: "dzee."
      }, {
        span: 3,
        p: "زما ورور نن چارسدې ته ځى.",
        e: "My brother is going to Charsadda today.",
        a: "8s16"
      }], [{
        f: "tu"
      }, {
        f: "nun shpa"
      }, {
        f: "sineemaa"
      }, {
        f: "ta"
      }, {
        f: "dze?"
      }, {
        span: 3,
        p: "تۀ نن شپه سنيما ته ځې؟",
        e: "Are you going to the cinema tonight?",
        a: "8s17"
      }], [{
        f: "na,"
      }, {
        f: "nun shpa"
      }, {
        f: "baazáar"
      }, {
        f: "ta"
      }, {
        f: "dzum."
      }, {
        span: 3,
        p: "نه، نن شپه بازار ته ځم.",
        e: "No, I'm going to the market tonight.",
        a: "8s18"
      }], [{
        f: "táaso"
      }, {
        f: "sabáa"
      }, {
        f: "chărta"
      }, {
        f: ""
      }, {
        f: "dzeyy?"
      }, {
        span: 3,
        p: "تاسو صبا چرته ځئ؟",
        e: "Where are you going tomorrow?",
        a: "8s19"
      }], [{
        f: ""
      }, {
        f: "sabáa"
      }, {
        f: "skool"
      }, {
        f: "ta"
      }, {
        f: "dzoo."
      }, {
        span: 3,
        p: "صبا سکُول ته ځُو.",
        e: "We are going to school tomorrow.",
        a: "8s20"
      }], [{
        f: "halukáan"
      }, {
        f: "tsu wakht"
      }, {
        f: "kúlee"
      }, {
        f: "ta"
      }, {
        f: "dzee?"
      }, {
        span: 3,
        p: "هلکان څۀ وخت کلى ته ځى؟",
        e: "When are the boys going to the village?",
        a: "8s21"
      }], [{
        f: ""
      }, {
        f: "sabáa du shpa ba"
      }, {
        f: "hálta"
      }, {
        f: ""
      }, {
        f: "dzee."
      }, {
        span: 3,
        p: "صبا د شپې به هلته ځى.",
        e: "They are going there tomorrow night.",
        a: "8s22"
      }]]}</Table>
    <h3 {...{
      "id": "the-intransitive-verb-word-w-p-راتلل-f-raatlúl-e-to-come-a-raatlul--"
    }}>{`The intransitive verb `}<Word w={{
        p: "راتلل",
        f: "raatlúl",
        e: "to come",
        a: "raatlul"
      }} mdxType="Word" /></h3>
    <Examples mdxType="Examples">{[{
        p: "دا هره ورځ دې بازار ته راځى؟",
        f: "daa hăra wradz de baazaar ta raadzee?",
        e: "Does she come to this market every day?",
        a: "8e33"
      }, {
        p: "نه، هره ورځ دلته نۀ راځى.",
        f: "na, hăra wradz dălta nu raadzee.",
        e: "No, she doesn't come here every day.",
        a: "8e34"
      }, {
        p: "تاسو هره شپه دې جُماعت ته راځئ؟",
        f: "taaso hăra shpa de jÚmaat ta raadzeyy?",
        e: "Do you come to this mosque every night?",
        a: "8e35"
      }, {
        p: "او، هره شپه دلته راځو.",
        f: "aw, hăra shpa dălta raadzoo.",
        e: "Yes, we come here every night.",
        a: "8e36"
      }, {
        p: "ستا لُور څۀ وخت کور ته راځى؟",
        f: "staa loor tsu wakht kor ta raadzee?",
        e: "When does your daughter come home?",
        a: "8e37"
      }, {
        p: "هغه هر سحر کور ته راځى.",
        f: "haghá hăr sahár kor ta raadzee.",
        e: "She comes home every morning.",
        a: "8e38"
      }, {
        p: "هغه هر کال دې کلى ته راځى؟",
        f: "haghá hăr kaal de kulee ta raadzee?",
        e: "Does she come to this village every year?",
        a: "8e39"
      }, {
        p: "نه، هره مياشت دلته راځى.",
        f: "na, hăra myaasht dălta raadzee.",
        e: "No, she comes here every month.",
        a: "8e40"
      }, {
        p: "دا جينۍ هر ماښام دې هسپتال ته راځى؟",
        f: "daa jeenuy hăr maaxaam de haspatáal ta raadzee?",
        e: "Does this girl come to this hospital every evening?",
        a: "8e41"
      }]}</Examples>
    <ol>
      <li parentName="ol">
        <p parentName="li">{`The prefix `}<Word w={{
            p: "را",
            f: "raa-",
            a: "raa-dir"
          }} mdxType="Word" /> indicates movement towards the speaker and transforms the verb <Word w={{
            p: "تلل",
            f: "tlul",
            e: "to go",
            a: "tlul"
          }} mdxType="Word" /> to <Word w={{
            p: "راتلل",
            f: "raatlúl",
            e: "to come",
            a: "raatlul"
          }} mdxType="Word" />{`.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`It may also be modified by the prefix `}<Word w={{
            p: "در",
            f: "dăr",
            a: "dar-dir"
          }} mdxType="Word" /> which indicates a movement towards you; and the prefix <Word w={{
            p: "ور",
            f: "wăr",
            a: "war-dir"
          }} mdxType="Word" />{` which indicates movement towards a third person or place. For example,`}</p>
        <ul parentName="li">
          <li parentName="ul"><Word w={{
              p: "راتلل",
              f: "raatlum",
              e: "I was coming",
              a: "raatlum"
            }} mdxType="Word" /></li>
          <li parentName="ul"><Word w={{
              p: "درتلل",
              f: "dărtlum",
              e: "I was coming to you",
              a: "dartlum"
            }} mdxType="Word" /></li>
          <li parentName="ul"><Word w={{
              p: "ورتلل",
              f: "wărtlul",
              e: "I was going to him",
              a: "wartlum"
            }} mdxType="Word" /></li>
        </ul>
      </li>
      <li parentName="ol">
        <p parentName="li">{`The adjective “every” may be masculine `}<Word w={{
            p: "هر",
            f: "hăr",
            a: "har"
          }} mdxType="Word" /> or feminine <Word w={{
            p: "هره",
            f: "hăra",
            a: "hara"
          }} mdxType="Word" />{` depending on the gender of the time word it is modifying.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Both `}<Word w={{
            p: "ورځ",
            f: "wradz",
            e: "day",
            a: "wradz"
          }} mdxType="Word" /> and <Word w={{
            p: "میاشت",
            f: "myaasht",
            e: "month",
            a: "myaasht"
          }} mdxType="Word" />{` are feminine, and are exceptions to the gender rules for nouns ending in consonants.`}</p>
      </li>
      <li parentName="ol">
        <Word w={{
          p: "دا",
          f: "daa",
          a: "daa-dem"
        }} mdxType="Word" /> is inflected to <Word w={{
          p: "دې",
          f: "de",
          a: "de-dem"
        }} mdxType="Word" /> if it precedes the postposition <Word w={{
          p: "ته",
          f: "ta",
          a: "ta"
        }} mdxType="Word" /> is in <Word w={{
          p: "دې بازار ته",
          f: "de baazaar ta",
          e: "to this baazaar",
          a: "de-baz-ta"
        }} mdxType="Word" />.
      </li>
    </ol>
    <p>{`Cover up the script and phonetics and see if you can say these sentences.`}</p>
    <Examples mdxType="Examples">{[{
        p: "هلته لاړ شه!",
        f: "hálta laaR sha!",
        e: "Go over there!",
        a: "8e42"
      }, {
        p: "درځم.",
        f: "dărdzúm",
        e: "I am coming to you.",
        a: "8e43"
      }, {
        p: "دې ښار ته ورځى.",
        f: "de xaar ta wărdzee.",
        e: "He is going to the city.",
        a: "8e44"
      }, {
        p: "راشه!",
        f: "raasha!",
        e: "Come here!",
        a: "8e45"
      }, {
        p: "درځى.",
        f: "dărdzee.",
        e: "He is coming to you.",
        a: "8e46"
      }]}</Examples>
    <h2 {...{
      "id": "the-preposition-postposition-word-w-p-د--د-پاره-f-du--du-para-e-for-a-du-du-paara--"
    }}>{`The preposition-postposition `}<Word w={{
        p: "د ... د پاره",
        f: "du ... du para",
        e: "for",
        a: "du-du-paara"
      }} mdxType="Word" /></h2>
    <Examples mdxType="Examples">{[{
        p: "دے د چا د پاره کار کوى؟",
        f: "dey du chaa du páara kaar kawée?",
        e: "Who does he work for?",
        a: "8e47"
      }, {
        p: "دے ما د پاره کار کوى.",
        f: "dey maa du páara kaar kawee.",
        e: "He works for me.",
        a: "8e48"
      }, {
        p: "دا د څۀ د پاره زيارت ته ځى؟",
        f: "daa du tsu du páara zyaarăt ta dzee?",
        e: "What is she going to the shrine for?",
        a: "8e49"
      }, {
        p: "دا د دعا د پاره هلته ځى.",
        f: "daa du dÚaa du páara hálta dzee.",
        e: "She is going there for prayer.",
        a: "8e50"
      }, {
        p: "جمال خان د څۀ د پاره دُوکان ته ځى؟",
        f: "jamaal khaan du tsu du páara dookáan ta dzee?",
        e: "What is Jamal Khan going to the shop for?",
        a: "8e51"
      }, {
        p: "هغه د دوائى د پاره هلته ځى.",
        f: "hagha du duwaaee du páara hálta dzee.",
        e: "He is going there for medicine.",
        a: "8e52"
      }, {
        p: "تۀ د څۀ د پاره راځې؟",
        f: "tu du tsu du páara raadze?",
        e: "What are you coming here for?",
        a: "8e53"
      }, {
        p: "زۀ د سبق د پاره دلته راځم.",
        f: "zu du sabúq du paara dălta raadzúm.",
        e: "I'm coming here for a lesson.",
        a: "8e54"
      }]}</Examples>
    <h4 {...{
      "id": "notes"
    }}>{`NOTES:`}</h4>
    <ol>
      <li parentName="ol">
        <Word w={{
          p: "د",
          f: "du",
          a: "du"
        }} mdxType="Word" /> is often dropped in daily conversation or altered to <Word w={{
          p: "له",
          f: "la",
          a: "la"
        }} mdxType="Word" />.
      </li>
      <li parentName="ol">
        <p parentName="li">{`In everyday speech you can also use `}<Word w={{
            p: "... ته",
            f: "... ta",
            e: "to",
            a: "ta"
          }} mdxType="Word" /> or <Word w={{
            p: "... له",
            f: "... la",
            e: "to",
            a: "la"
          }} mdxType="Word" /> with a similar meaning of purpose. And so the last sentence says <Word w={{
            p: "سبق له راځم",
            f: "sabúq la raadzum",
            a: "sblrz"
          }} mdxType="Word" />{`.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Nouns preceding `}<Word w={{
            p: "د پاره",
            f: "du paara",
            a: "du-paara"
          }} mdxType="Word" /> are in the oblique case. For example in the second sentence above: <Word w={{
            p: "دے ما د پاره کار کوی",
            f: "dey maa du paara kaar kawee",
            a: "dmdpk"
          }} mdxType="Word" />{`.`}</p>
      </li>
    </ol>
    <h2 {...{
      "id": "comprehension-drill-learning-to-obey-commands"
    }}>{`Comprehension drill: Learning to obey commands`}</h2>
    <Examples mdxType="Examples">{[{
        p: "ودرېږه!",
        f: "óodreGa!",
        e: "Stand up!",
        a: "8c1"
      }, {
        p: "واخله!",
        f: "wáakhla!",
        e: "Take it!",
        a: "8c2"
      }, {
        p: "کښېنه!",
        f: "kéna!",
        e: "Sit down",
        a: "8c3"
      }, {
        p: "کېږده!",
        f: "ké(G)da!",
        e: "Put down!",
        a: "8c4"
      }, {
        p: "راشه!",
        f: "ráasha!",
        e: "Come here!",
        a: "8c5"
      }, {
        p: "وڅکه!",
        f: "óoska!",
        e: "Drink!",
        a: "8c6"
      }, {
        p: "لاړ شه!",
        f: "láaR sha!",
        e: "Go!",
        a: "8c7"
      }, {
        p: "وخوره!",
        f: "óokhora!",
        e: "Eat!",
        a: "8c8"
      }, {
        p: "ښى طرف ته لاړ شه!",
        f: "xee taráf ta laaR sha!",
        e: "Go to the right!",
        a: "8c9"
      }, {
        p: "ګڅ طرف ته لاړ شه!",
        f: "guts taráf ta laaR sha!",
        e: "Go to the left!",
        a: "8c10"
      }, {
        p: "شا ته راؤګرځه!",
        f: "shaa ta raa-óo-gurdza!",
        e: "Turn around!",
        a: "8c11"
      }, {
        p: "شا ته ځه!",
        f: "shaa ta dza!",
        e: "Go back!",
        a: "8c12"
      }, {
        p: "مخکښې راشه!",
        f: "makhke raasha!",
        e: "Come forward!",
        a: "8c13"
      }]}</Examples>
    <h5 {...{
      "id": "note-3"
    }}>{`NOTE:`}</h5>
    <p>{`Studies have shown that new words introduced during a drill are more likely to be remembered if you really follow the commands with enthusiasm. Resist the temptation to simply repeat the commands. Do the actions as fast as possible. Have fun and make a game of it.`}</p>
    <h2 {...{
      "id": "memorising-moment"
    }}>{`Memorising moment`}</h2>
    <h4 {...{
      "id": "mataloona"
    }}>{`mataloona`}</h4>
    <Examples memorizingMoment mdxType="Examples">{memorizingMoments[8].slice(0, 3)}</Examples>
    <h4 {...{
      "id": "rahman-baba"
    }}>{`Rahman Baba`}</h4>
    <Examples memorizingMoment mdxType="Examples">{[memorizingMoments[8][3]]}</Examples>
    <img src={boyBackSitting} />
    <h2 {...{
      "id": "exercises-for-lesson-eight"
    }}>{`Exercises for Lesson Eight`}</h2>
    <ol>
      <li parentName="ol">{`Make sentences in the possessive case from the following pairs of words. For example:`}</li>
    </ol>
    <Examples mdxType="Examples">{[{
        p: "دا د طالبعلم المارئ ده.",
        f: "This is the student's bookcase.",
        e: "daa du taalibílum almaarúy da.",
        a: "8q1"
      }]}</Examples>
    <Quiz mdxType="Quiz">{[{
        q: {
          p: "مريضان کمره",
          f: "mareezaan kamrá"
        },
        answer: [{
          p: "دا د مریضانو کمره ده.",
          f: "daa du mareezaano kamra da."
        }]
      }, {
        q: {
          p: "ترۀ دُوکان",
          f: "tru dookáan"
        },
        answer: [{
          p: "دا د تره دوکان دے.",
          f: "daa du tru dookáan dey."
        }]
      }, {
        q: {
          p: "ماشُومان بال",
          f: "maashoomáan baal"
        },
        answer: [{
          p: "دا د ماشومانو بال دے.",
          f: "daa du maashoomaano baal dey."
        }]
      }, {
        q: {
          p: "پلاران ماشُومان",
          f: "plaaraan maashoom"
        },
        answer: [{
          p: "دا د پلارانو ماشومان دے.",
          f: "daa du plaaraano maashoom dey"
        }]
      }, {
        q: {
          p: "وروڼه خوېندې",
          f: "wrooNa khwende"
        },
        answer: [{
          p: "دا د وروڼو خوېندې دی.",
          f: "daa du wrooNo khwende dee."
        }]
      }, {
        q: {
          p: "ډاکټران دفتر",
          f: "DakTuraan dăftúr"
        },
        answer: [{
          p: "دا د ډاکټرانو دفتر دے.",
          f: "daa du DakTuraano dăftúr dey."
        }]
      }]}</Quiz>
    <ol {...{
      "start": 2
    }}>
      <li parentName="ol">{`Answer the following question by using a statement plus the time words listed below:`}</li>
    </ol>
    <Examples mdxType="Examples">{[{
        p: "ستا ورور څۀ وخت مينګورې ته ځى؟",
        f: "staa wror tsu wakht meengoré ta dzee?",
        e: "When is your brother going to Mingora?",
        a: "8q2"
      }]}</Examples>
    <Quiz mdxType="Quiz">{[{
        q: {
          p: "نن",
          f: "nun"
        },
        answer: [{
          p: "زما ورور نن مینګورې ته ځي.",
          f: "zmaa wror nun meengore tu dzee."
        }, {
          p: "هغه نن مینګورې ته ځي.",
          f: "haghá nun meengore tu dzee."
        }, {
          p: "نن مینګورې ته ځي.",
          f: "nun meengore tu dzee."
        }]
      }, {
        q: {
          p: "سبا",
          f: "sabáa"
        },
        answer: [{
          p: "زما ورور سبا مینګورې ته ځي.",
          f: "zmaa wror sabaa meengore tu dzee."
        }, {
          p: "هغه سبا مینګورې ته ځي.",
          f: "haghá sabaa meengore tu dzee."
        }, {
          p: "سبا مینګورې ته ځيسبا",
          f: "sabaa meengore tu dzee."
        }]
      }, {
        q: {
          p: "ماښام",
          f: "maaxáam"
        },
        answer: [{
          p: "زما ورور ماښام مینګورې ته ځي.",
          f: "zmaa wror maaxáam meengore tu dzee."
        }, {
          p: "هغه ماښام مینګورې ته ځي.",
          f: "haghá maaxáam meengore tu dzee."
        }, {
          p: "ماښام مینګورې ته ځيسبا",
          f: "maaxáam meengore tu dzee."
        }]
      }, {
        q: {
          p: "سحر",
          f: "sahár"
        },
        answer: [{
          p: "زما ورور سحر مینګورې ته ځي.",
          f: "zmaa wror sahár meengore tu dzee."
        }, {
          p: "هغه سحر مینګورې ته ځي.",
          f: "haghá sahár meengore tu dzee."
        }, {
          p: "سحر مینګورې ته ځيسبا",
          f: "sahár meengore tu dzee."
        }]
      }, {
        q: {
          p: "سبا شپه",
          f: "sabáa shpa"
        },
        answer: [{
          p: "زما ورور سبا شپه مینګورې ته ځي.",
          f: "zmaa wror sabáa shpa meengore tu dzee."
        }, {
          p: "هغه سبا شپه مینګورې ته ځي.",
          f: "haghá sabáa shpa meengore tu dzee."
        }, {
          p: "سبا شپه مینګورې ته ځيسبا",
          f: "sabáa shpa meengore tu dzee."
        }]
      }, {
        q: {
          p: "سبانن سحر",
          f: "nun sahár"
        },
        answer: [{
          p: "زما ورور نن سحر مینګورې ته ځي.",
          f: "zmaa wror nun sahár meengore tu dzee."
        }, {
          p: "هغه نن سحر مینګورې ته ځي.",
          f: "haghá nun sahár meengore tu dzee."
        }, {
          p: "نن سحر مینګورې ته ځيسبا",
          f: "nun sahár meengore tu dzee."
        }]
      }, {
        q: {
          p: "سبا سحر",
          f: "sabáa sahár"
        },
        answer: [{
          p: "زما ورور سبا سحر مینګورې ته ځي.",
          f: "zmaa wror sabáa sahár meengore tu dzee."
        }, {
          p: "هغه سبا سحر مینګورې ته ځي.",
          f: "haghá sabáa sahár meengore tu dzee."
        }, {
          p: "سبا سحر مینګورې ته ځيسبا",
          f: "sabáa sahár meengore tu dzee."
        }]
      }, {
        q: {
          p: "ماسپښین",
          f: "maaspaxéen"
        },
        answer: [{
          p: "زما ورور ماسپښین مینګورې ته ځي.",
          f: "zmaa wror maaspaxéen meengore tu dzee."
        }, {
          p: "هغه ماسپښین مینګورې ته ځي.",
          f: "haghá maaspaxéen meengore tu dzee."
        }, {
          p: "ماسپښین مینګورې ته ځيسبا",
          f: "maaspaxéen meengore tu dzee."
        }]
      }]}</Quiz>
    <img src={porcupine} />
    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;
export const tableOfContents = (components={}) => [
  {
    id: "dialogue-eight-ladies-chit-chat--ګب-شپ",
    level: 2,
    title: "Dialogue eight: Ladies “chit-chat” / ګب شپ",
    children: [
        
      ]
  },
{
    id: "mens-gupshup-with-a-shopkeeper",
    level: 2,
    title: "Men’s “gupshup” with a shopkeeper",
    children: [
        
      ]
  },
{
    id: "pronunciation-drill",
    level: 2,
    title: "Pronunciation Drill",
    children: [
        
      ]
  },
{
    id: "the-preposition-word-w-p-د-f-du-e-of-a-du---with-masculine-nouns-and-adjectives",
    level: 2,
    title: <React.Fragment>{`The preposition `}<Word w={{ p: "د", f: "du", e: "of", a: "du" }} />{` with masculine nouns and adjectives`}</React.Fragment>,
    children: [
        
      ]
  },
{
    id: "the-oblique-case-inflection",
    level: 2,
    title: "The oblique case (inflection)",
    children: [
        
      ]
  },
{
    id: "fun-with-animals",
    level: 2,
    title: "Fun with animals",
    children: [
        
      ]
  },
{
    id: "intransitive-verbs",
    level: 2,
    title: "Intransitive Verbs",
    children: [
        {
              id: "the-intransitive-verb-word-w-p-تلل-f-tlul-e-to-go-a-tlul---and-the-postposition-word-w-p-ته-f-ta-e-to-a-ta--",
              level: 3,
              title: <React.Fragment>{`The intransitive verb `}<Word w={{ p: "تلل", f: "tlul", e: "to go", a: "tlul" }} /> and the postposition <Word w={{ p: "ته", f: "ta", e: "to", a: "ta" }} /></React.Fragment>,
              children: [
                      
                    ]
            },
      {
              id: "the-intransitive-verb-word-w-p-راتلل-f-raatlúl-e-to-come-a-raatlul--",
              level: 3,
              title: <React.Fragment>{`The intransitive verb `}<Word w={{ p: "راتلل", f: "raatlúl", e: "to come", a: "raatlul" }} /></React.Fragment>,
              children: [
                      
                    ]
            }
      ]
  },
{
    id: "the-preposition-postposition-word-w-p-د--د-پاره-f-du--du-para-e-for-a-du-du-paara--",
    level: 2,
    title: <React.Fragment>{`The preposition-postposition `}<Word w={{ p: "د ... د پاره", f: "du ... du para", e: "for", a: "du-du-paara" }} /></React.Fragment>,
    children: [
        
      ]
  },
{
    id: "comprehension-drill-learning-to-obey-commands",
    level: 2,
    title: "Comprehension drill: Learning to obey commands",
    children: [
        
      ]
  },
{
    id: "memorising-moment",
    level: 2,
    title: "Memorising moment",
    children: [
        
      ]
  },
{
    id: "exercises-for-lesson-eight",
    level: 2,
    title: "Exercises for Lesson Eight",
    children: [
        
      ]
  }
]

export const frontMatter = {
  "title": "Lesson Eight"
}

