
import React from 'react'
import { mdx } from '@mdx-js/react'
export const readingTime = {"text":"27 min read","minutes":26.77,"time":1606200,"words":5354}
/* @jsx mdx */
import Dialogue from "../components/Dialogue";
import Word from "../components/Word";
import Examples from "../components/Examples";
import memorizingMoments from "./memorizing-moments.jsx";
import Table from "../components/Table";
import Quiz from "../components/Quiz";
import PosNegAnswers from "../components/PosNegAnswers";
import doctorConversationNoFrame from "../assets/images/doctor-conversation-no-frame.png";
import flyingCoach from "../assets/images/flying-coach.png";
import scorpion from "../assets/images/scorpion.png";
import imposedFace from "../assets/images/imposed-face.png";
import bodyPartsMan from "../assets/images/body-parts-man.png";
import womanSadir from "../assets/images/woman-sadir.png";
import sideLooker from "../assets/images/side-looker.png";

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope")
  return <div {...props}/>
};

const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <h2 {...{
      "id": "dialogue-twenty-four-sickness"
    }}>{`Dialogue twenty-four: Sickness`}</h2>
    <Dialogue audio="dialogue-24" mdxType="Dialogue">{[{
        s: "H",
        p: "زۀ ناجوړه يم، زما زُکام دے، تبه مې هم ده، او سر مې هم خوږېږى.",
        f: "zu naajóRa yim, zmaa zUkáam dey, túba me hum da, aw săr me hum khooGéGee.",
        e: "I'm sick, I have a cold, I also have a fever and my head hurts.",
        a: "24d1"
      }, {
        s: "L",
        p: "ډاکټر له لاړ شه!",
        f: "DakTúr la laaR sha!",
        e: "Go to the doctor!",
        a: "24d2"
      }, {
        s: "H",
        p: "نه، دوائی خورم.",
        f: "na, dawaayee khorum.",
        e: "No, I'm taking medicine.",
        a: "24d3"
      }, {
        s: "L",
        p: "د زُکام سره څۀ پرهېز کول پکار دی؟",
        f: "du zUkáam sara tsu parhez kawul pukaar dee?",
        e: "What restrictions are needed for a cold?",
        a: "24d4"
      }, {
        s: "H",
        p: "د غوړو، د يخو او تراوو څيزُونو نه به ځان ساتې.",
        f: "du ghwaRo, du yúkho aw traawó tseezóono na ba dzaan saate.",
        e: "You should keep away from oil, 'cold' and sour things.",
        a: "24d5"
      }, {
        s: "L",
        p: "تۀ هميشه ناجوړه کېږې؟",
        f: "tu hameyshá naajóRa keGe?",
        e: "Are you always getting sick?",
        a: "24d6"
      }, {
        s: "H",
        p: "نه، کله نا کله.",
        f: "na, kala naa kala",
        e: "No, now and then.",
        a: "24d7"
      }, {
        s: "L",
        p: "په تا باندې چرې څۀ درنه بيمارى تېره شوې ده؟",
        f: "pu taa baande chăre tsu drana beemaaree tera shiwe da?",
        e: "Have you ever had any serious disease?",
        a: "24d8"
      }, {
        s: "H",
        p: "آو، يو ځل مې ميعادى تبه تېره کړې ده.",
        f: "aw, yo dzul me mee`aadee túba tera kúRe da.",
        e: "Yes, one time I have had typhoid.",
        a: "24d9"
      }, {
        s: "L",
        p: "تۀ ځان د بيمارو نه څنګه بچ کوې؟",
        f: "tu dzaan du beemaaro na tsínga buch kawe?",
        e: "How can you keep yourself from sickness?",
        a: "24d10"
      }]}</Dialogue>
    <img src={doctorConversationNoFrame} style={{
      float: "right",
      maxWidth: "12rem"
    }} />
    <h4 {...{
      "id": "note"
    }}>{`NOTE:`}</h4>
    <p>{`👆 Explore the concept of “hot” and “cold” foods with your friends.`}</p>
    <h2 {...{
      "id": "pronunciation-drill"
    }}>{`Pronunciation drill`}</h2>
    <Examples memorizingMoment mdxType="Examples">{[{
        p: "زما د دوست مور په يو موړ کښې مړه شوه.",
        f: "zmaa du dost mor pu yo moR ke mRa shwa.",
        e: "",
        a: "24tt1"
      }]}</Examples>
    <h2 {...{
      "id": "to-have-done-the-present-perfect-tense"
    }}>{`'to have done’: The present perfect tense`}</h2>
    <p>{`The present perfect tense is used for talking about events that happened in the past, and have consequences in the present. It is formed from the past participle and the presnt equative “to be” verb.`}</p>
    <p>{`The past participle consists of the infinitive form of the verb plus an inflectable `}<Word w={{
        p: "ـے",
        f: "-ey",
        e: "",
        a: ""
      }} mdxType="Word" /> suffix. This suffix is inflected in the same way as it is with adjectives ending in an unstressed <Word w={{
        p: "ـے",
        f: "-ey",
        e: "",
        a: ""
      }} mdxType="Word" />{`.`}</p>
    <ul>
      <li parentName="ul">{`Masc. Sing. `}<Word w={{
          p: "ـے",
          f: "-ey",
          e: "",
          a: "ey-sound"
        }} mdxType="Word" /></li>
      <li parentName="ul">{`Fem. Sing. `}<Word w={{
          p: "ـې",
          f: "-e",
          e: "",
          a: "e-sound"
        }} mdxType="Word" /></li>
      <li parentName="ul">{`Masc. Plur. `}<Word w={{
          p: "ـی",
          f: "-ee",
          e: "",
          a: "ee-sound"
        }} mdxType="Word" /></li>
      <li parentName="ul">{`Fem. Plur. `}<Word w={{
          p: "ـې",
          f: "-e",
          e: "",
          a: "e-sound"
        }} mdxType="Word" /></li>
    </ul>
    <h4 {...{
      "id": "the-present-perfect-of-the-verb-word-w-p-رسېدل-f-rasedúl-e-to-arrive-a---"
    }}>{`The present perfect of the verb `}<Word w={{
        p: "رسېدل",
        f: "rasedúl",
        e: "to arrive",
        a: ""
      }} mdxType="Word" /></h4>
    <Table bordered unset wide verticalContent mdxType="Table">{[[{
        e: "past participle",
        span: 3
      }, {
        e: "+",
        rowSpan: 2
      }, {
        e: "equative",
        rowSpan: 2
      }, {
        e: "=",
        rowSpan: 2
      }, {
        e: "present perfect",
        rowSpan: 2
      }], [{
        e: "infinitive"
      }, {
        e: "+"
      }, {
        e: "suffix"
      }], [{
        p: "رسېدل",
        f: "rasedúl",
        e: "",
        a: "rasedul"
      }, {
        e: "+"
      }, {
        p: "ـے",
        f: "-ey",
        a: "ey-sound"
      }, {
        e: "+"
      }, {
        p: "یم",
        f: "yim",
        e: "",
        a: "yim"
      }, {
        e: "="
      }, {
        p: "رسېدلے یم",
        f: "rasedúley yim",
        e: "I (male) have arrived",
        a: "raseduley-yim"
      }]]}</Table>
    <h4 {...{
      "id": "notes"
    }}>{`NOTES:`}</h4>
    <ol>
      <li parentName="ol">{`The suffixes are declined according to the gender and number of the subject.  `}</li>
      <li parentName="ol">{`The verb `}<Word w={{
          p: "رسېدل",
          f: "rasedúl",
          e: "",
          a: "rasedul"
        }} mdxType="Word" />{` provides an example of a regular intransitive verb being conjugated in the present perfect tense. `}</li>
    </ol>
    <Table bordered unset verticalContent titleRow={["Person", "Singular", "Plural"]} mdxType="Table">{[[{
        e: "1st m."
      }, {
        p: "رسېدلے یم",
        f: "rasedúley yim",
        e: "I have arrived",
        a: "24v1"
      }, {
        p: "رسېدلی یو",
        f: "rasedúlee yoo",
        e: "We have arrived",
        a: "24v2"
      }], [{
        e: "1st f."
      }, {
        p: "رسېدلې یم",
        f: "rasedúle yim",
        e: "I have arrived",
        a: "24v3"
      }, {
        p: "رسېدلې یو",
        f: "rasedúle yoo",
        e: "We have arrived",
        a: "24v4"
      }], [{
        e: "2nd m."
      }, {
        p: "رسېدلے یې",
        f: "rasedúley ye",
        e: "You have arrived",
        a: "24v5"
      }, {
        p: "رسېدلی یئ",
        f: "rasedúlee yeyy",
        e: "You have arrived",
        a: "24v6"
      }], [{
        e: "2nd f."
      }, {
        p: "رسېدلې یې",
        f: "rasedúle ye",
        e: "You have arrived",
        a: "24v7"
      }, {
        p: "رسېدلې یئ",
        f: "rasedúle yeyy",
        e: "You have arrived",
        a: "24v8"
      }], [{
        e: "3rd m."
      }, {
        p: "رسېدلے دے",
        f: "rasedúley dey",
        e: "He has arrived",
        a: "24v9"
      }, {
        p: "رسېدلی دی",
        f: "rasedúlee dee",
        e: "They have arrived",
        a: "24v10"
      }], [{
        e: "3rd f."
      }, {
        p: "رسېدلې ده",
        f: "rasedúle da",
        e: "She has arrived",
        a: "24v11"
      }, {
        p: "رسېدلې دی",
        f: "rasedúle dee",
        e: "They have arrived",
        a: "24v12"
      }]]}</Table>
    <h2 {...{
      "id": "intransitive-verbs-in-the-present-tense"
    }}>{`Intransitive verbs in the present tense`}</h2>
    <Examples mdxType="Examples">{[{
        p: "نن چرته ګرځېدلے يې؟",
        f: "nun chărta gurdzedúley ye?",
        e: "Where have you walked today? (m.)",
        a: "24e1"
      }, {
        p: "زۀ ټوله ورځ په ښار کښې ګرځېدلے يم.",
        f: "ze Tola wradz pu xaar ke gurdzedúley yim.",
        e: "I have walked all day in the city.",
        a: "24e2"
      }, {
        p: "هغه کلى ته رسېدلې ده؟",
        f: "haghá kúlee ta rasedúle da?",
        e: "Has she reached the village?",
        a: "24e3"
      }, {
        p: "نه، تر اوسه نۀ ده رسېدلې.",
        f: "na, tur oosa nu da rasedúle.",
        e: "No, she hasn't reached it yet.",
        a: "24e4"
      }, {
        p: "تۀ مخکښې کوهاټ ته تلے يې؟",
        f: "tu makhke koháaT ta tuley ye?",
        e: "Have you (m.) gone to Kohat before?",
        a: "24e5"
      }, {
        p: "آو، تلے يم.",
        f: "aw, túley yim.",
        e: "Yes, I have gone",
        a: "24e6"
      }, {
        p: "حميده چا سره راغلې ده؟",
        f: "hameeda chaa sara raaghúle da?",
        e: "Who has Hamida come with?",
        a: "24e7"
      }, {
        p: "هغه خور سره راغلې ده.",
        f: "hagɦa khor sara raaghúle da.",
        e: "She has come with her sister.",
        a: "24e8"
      }, {
        p: "بشير د پوليس نه تښتېدلے دے؟",
        f: "bashéer du polóos na tuxtedúley dey?",
        e: "Has Bashir run away from the police?",
        a: "24e9"
      }, {
        p: "آو، تښتېدلے دے.",
        f: "aw, tuxtedúley dey.",
        e: "Yes, he has run away.",
        a: "24e10"
      }, {
        p: "تۀ چرته د مرګ نه يرېدلے يې؟",
        f: "tu chărta du murg na yăredúley ye?",
        e: "Have you (m.) ever been afraid of death?",
        a: "24e11"
      }, {
        p: "آو، کله نا کله فلائينګ کوچ کښې يرېدلے يم.",
        f: "aw, kála naa kála flaaying koch ke yăredúley yim.",
        e: "Yes, sometimes I have been afraid in a 'flying coach.'",
        a: "24e12"
      }]}</Examples>
    <img src={flyingCoach} />
    <h2 {...{
      "id": "negatives-in-the-present-perfect-tense"
    }}>{`Negatives in the present perfect tense`}</h2>
    <p>{`Short answers that are negative often involve splitting up the verb.`}</p>
    <p>{`👆 Have your helper read the question and answer him in the negative.`}</p>
    <Examples mdxType="Examples">{[{
        p: "مخکښې کله په سوات کښې اوسېدلے يې؟",
        f: "makhke kála pu swaat ke osedúley ye?",
        e: "Have you (male) ever lived in Swat before?",
        a: "24e13"
      }, {
        p: "نه، هلته نۀ يُو اوسېدلى.",
        f: "na, halta nu yoo osedúlee.",
        e: "No, we have not lived there.",
        a: "24e14"
      }, {
        p: "بس تر اوسه دلته ودرېدلے دے؟",
        f: "bus tur oosa dălta oodredúley dey?",
        e: "Has the bus stopped here yet?",
        a: "24e15"
      }, {
        p: "نه، تر اوسه پورې نۀ دے ودرېدلے.",
        f: "na, tur oosa pore nu dey oodredúley.",
        e: "No, it has not stopped here yet.",
        a: "24e16"
      }]}</Examples>
    <h4 {...{
      "id": "practice"
    }}>{`Practice:`}</h4>
    <p>{`👆 Now take the list of intransitive verbs from earlier chapters (for example, the twelve intransitive verbs in Lesson Twelve) and make sentences from them. Pay special attention to the three endings that go onto the infinitive. Try the negative forms too. Try to use as many verbs as you can and speak as fast as you can!`}</p>
    <h2 {...{
      "id": "intransitive-stative-compounds-in-the-present-perfect"
    }}>{`Intransitive stative compounds in the present perfect`}</h2>
    <h4 {...{
      "id": "word-w-p-ستړے-کېدل-f-stúrey-kedúl-e-to-become-tired-a---"
    }}><Word w={{
        p: "ستړے کېدل",
        f: "stúRey kedúl",
        e: "to become tired",
        a: ""
      }} mdxType="Word" /></h4>
    <Table bordered unset verticalContent titleRow={["Person", "Singular", "Plural"]} mdxType="Table">{[[{
        e: "1st m."
      }, {
        p: "ستړے شوے یم",
        f: "stúRey shíwey yim",
        e: "I have become tired",
        a: "24v13"
      }, {
        p: "ستړی شوی یو",
        f: "stúRee shíwee yoo",
        e: "We have become tired",
        a: "24v14"
      }], [{
        e: "1st f."
      }, {
        p: "ستړې شوې یم",
        f: "stúRe shíwe yim",
        e: "I have become tired",
        a: "24v15"
      }, {
        p: "ستړې شوې یو",
        f: "stúRe shíwe yoo",
        e: "We have become tired",
        a: "24v16"
      }], [{
        e: "2nd m."
      }, {
        p: "ستړے شوے یې",
        f: "stúRey shíwey ye",
        e: "You have become tired",
        a: "24v17"
      }, {
        p: "ستړی شوی یئ",
        f: "stúRee shíwee yeyy",
        e: "You have become tired",
        a: "24v18"
      }], [{
        e: "2nd f."
      }, {
        p: "ستړې شوې یې",
        f: "stúRe shíwe ye",
        e: "You have become tired",
        a: "24v19"
      }, {
        p: "ستړې شوې یئ",
        f: "stúRe shíwe yeyy",
        e: "You have become tired",
        a: "24v20"
      }], [{
        e: "3rd m."
      }, {
        p: "ستړے شوے دے",
        f: "stúRey shíwey dey",
        e: "He has become tired",
        a: "24v21"
      }, {
        p: "ستړی شوی دی",
        f: "stúRee shíwee dee",
        e: "They have become tired",
        a: "24v22"
      }], [{
        e: "3rd f."
      }, {
        p: "ستړې شوې ده",
        f: "stúRe shíwe da",
        e: "She has become tired",
        a: "24v23"
      }, {
        p: "ستړې شوې دی",
        f: "stúRe shíwe dee",
        e: "They have become tired",
        a: "24v24"
      }]]}</Table>
    <h4 {...{
      "id": "note-1"
    }}>{`NOTE:`}</h4>
    <p>{`Both the verbs `}<Word w={{
        p: "کېدل",
        f: "kedúl",
        e: "to become",
        a: "kedul"
      }} mdxType="Word" /> and <Word w={{
        p: "کېدل",
        f: "kedúl",
        e: "to happen",
        a: "kedul"
      }} mdxType="Word" /> have an irregular past participle form: <Word w={{
        p: "شوے",
        f: "shíwey",
        e: "",
        a: "shiwey-f"
      }} mdxType="Word" />{`.`}</p>
    <h4 {...{
      "id": "example-sentences-using-compound-intransitive-verbs-in-the-present-perfect-tense"
    }}>{`Example sentences using compound intransitive verbs in the present perfect tense:`}</h4>
    <Examples mdxType="Examples">{[{
        p: "هغه اُودۀ شوے دے؟",
        f: "haghá oodú shíwey dey?",
        e: "Has he gone to sleep?",
        a: "24e17"
      }, {
        p: "نه، هغه نۀ دے اُودۀ شوے.",
        f: "na, haghá nu dey oodú shíwey.",
        e: "No, he has not gone to sleep.",
        a: "24e18"
      }, {
        p: "مريم تر اوسه وادۀ شوې نۀ ده؟",
        f: "maryam tur oosa waadu shíwe nu da?",
        e: "Hasn't Miriam become married yet?",
        a: "24e19"
      }, {
        p: "نه، نۀ ده وادۀ شوې.",
        f: "na, nu da waadu shíwe.",
        e: "No, she hasn't become married.",
        a: "24e20"
      }, {
        p: "د دۀ ښځه هم مړه شوې ده؟",
        f: "du du xúdza hum mRa shíwe da?",
        e: "Has his wife also died?",
        a: "24e21"
      }, {
        p: "نه، نۀ ده مړه شوې.",
        f: "na, nu da mRa shíwe.",
        e: "No, she hasn't died.",
        a: "24e22"
      }, {
        p: "ګُلالۍ خوږ شوې ده؟",
        f: "gUlaalúy khoG shíwe da?",
        e: "Has Gulalay been hurt?",
        a: "24e23"
      }, {
        p: "ستا وروڼه خپل پلار سره رضا شوى دى؟",
        f: "staa wrooNa khpul plaar sara razáa shíwee dee?",
        e: "Have your brothers become reconciled with their father?",
        a: "24e24"
      }, {
        p: "نه، نۀ دى رضا شوى.",
        f: "na, nu dee rázáa shíwee.",
        e: "No, they haven't become reconciled.",
        a: "24e25"
      }, {
        p: "ماشُومان ويښ شوى دى؟",
        f: "maashoomáan weex shíwee dee?",
        e: "Have the children woken up?",
        a: "24e26"
      }, {
        p: "نه، نۀ دى ويښ شوى.",
        f: "na, nu dee weex shíwee.",
        e: "No, they haven't woken up.",
        a: "24e27"
      }, {
        p: "نوکره د مخکښې نه ستړې شوې ده؟",
        f: "nokúra du makhke na stúRe shíwe da?",
        e: "Has the servant become tired already?",
        a: "24e28"
      }, {
        p: "کلاس ختم شوے دے.",
        f: "klas khátum shíwey dey.",
        e: "The lesson (m.s.) has become finished.",
        a: "24e29"
      }, {
        p: "کپړې مې زړې شوې دى.",
        f: "kapRé me zaRe shíwe dee.",
        e: "My clothes (f.pl.) have become old.",
        a: "24e30"
      }, {
        p: "لُور مې ښه شوې ده.",
        f: "loor me xa shíwe da.",
        e: "My daughter (f.s.) has become well.",
        a: "24e31"
      }, {
        p: "ورېژې پخې شوې دى؟",
        f: "wréjze pakhé shíwe dee?",
        e: "Has the rice (f.pl.) become cooked?",
        a: "24e32"
      }, {
        p: "ستا کار ختم شوے دے؟",
        f: "staa kaar khátum shíwey dey?",
        e: "Has your work (m.s.) been completed?",
        a: "24e33"
      }, {
        p: "نه، نۀ دے ختم شوے.",
        f: "na, nu dey khátum shíwey.",
        e: "No, it hasn't been completed.",
        a: "24e34"
      }, {
        p: "جينۍ زخمى شوې ده.",
        f: "jeenúy zakhmee shíwe da.",
        e: "The girl (f.s.) has been hurt.",
        a: "24e35"
      }]}</Examples>
    <h2 {...{
      "id": "passive-sentences-in-the-present-perfect"
    }}>{`Passive sentences in the present perfect`}</h2>
    <p>{`The passive version of transitive verbs is similar to intransitive verbs because:`}</p>
    <ul>
      <li parentName="ul">{`It also uses `}<Word w={{
          p: "کېدل",
          f: "kedúl",
          e: "to become",
          a: "kedul"
        }} mdxType="Word" /></li>
      <li parentName="ul">{`Since there’s no object, the verb agrees with the subject in the past.`}</li>
    </ul>
    <Examples mdxType="Examples">{[{
        p: "جامې وينځلې شوې دى؟",
        f: "jaamé weendzúle shíwe dee?",
        e: "Have the clothes been washed?",
        a: "24e36"
      }, {
        p: "نه، تر اوسه پورې نۀ دى وينځلې شوې.",
        f: "na, tur oosa pore nu dee weendzúle shíwe.",
        e: "No, they haven't been washed yet.",
        a: "24e37"
      }, {
        p: "تۀ چيچلے شوے يې؟",
        f: "tu cheechúley shíwey ye?",
        e: "Have you (m.) been stung?",
        a: "24e38"
      }, {
        p: "نه، نۀ يم چيچلے شوے.",
        f: "na, nu yim cheechúley shíwey.",
        e: "No, I (m.) haven't been stung.",
        a: "24e39"
      }]}</Examples>
    <img src={scorpion} />
    <h2 {...{
      "id": "transitive-verbs-in-the-present-perfect"
    }}>{`Transitive verbs in the present perfect`}</h2>
    <p>{`Like all transitive verbs in the past tense, agreement must be shown between the object and the verb. Both the past participle and the present endings are inflected.`}</p>
    <Examples mdxType="Examples">{[{
        p: "ما کتاب اخستے دے.",
        f: "maa kitáab akhístey dey.",
        e: "I have bought a book. (m.s.)",
        a: "24e40"
      }, {
        p: "ما کتابونه اخستى دى.",
        f: "maa kitaabóona akhístee dee.",
        e: "I have bought books. (m.pl.)",
        a: "24e41"
      }, {
        p: "ما کاپى اخستې ده.",
        f: "maa kaapée akhíste de.",
        e: "I have bought a notebook. (f.s.)",
        a: "24e42"
      }, {
        p: "ما کاپيانې اخستې دى.",
        f: "maa kapyáane akhíste dee.",
        e: "I have bought notebooks. (f.pl.)",
        a: "24e43"
      }, {
        p: "تا دے معاف کړے دے؟",
        f: "taa de maaf kúrey dey?",
        e: "Have you forgiven him? (m.s.)",
        a: "24e44"
      }, {
        p: "تا هغوى معاف کړى دى؟",
        f: "taa haghwée maaf kúRee dee?",
        e: "Have you forgiven them? (m.pl.)",
        a: "24e45"
      }, {
        p: "تا هغه معاف کړې ده؟",
        f: "taa haghá maaf kúRe da?",
        e: "Have you forgiven her? (f.s.)",
        a: "24e46"
      }, {
        p: "تا هغوى معاف کړې دى؟",
        f: "taa haghwée maaf kúRe dee?",
        e: "Have you forgiven them? (f.pl.)",
        a: "24e47"
      }, {
        p: "هغې خط وئيلے دے.",
        f: "haghé khat wayuley dey?",
        e: "She has read the letter. (m.s.)",
        a: "24e48"
      }, {
        p: "هغې خطُونه وئيلى دى.",
        f: "haghé khatóona wayúlee dee.",
        e: "She has read the letters. (m.pl.)",
        a: "24e49"
      }, {
        p: "هغۀ قيصه وئيلې ده.",
        f: "haghú qeesá wayule da.",
        e: "He has read the story. (f.s.)",
        a: "24e50"
      }, {
        p: "هغۀ قيصې وئيلې دى.",
        f: "haghú qeesé wayúle dee.",
        e: "He has read the stories. (f.pl.)",
        a: "24e51"
      }, {
        p: "مُونږ سپے نۀ دے ويشتلے.",
        f: "moonG spey nu dey weeshtúley.",
        e: "We haven't hit the dog. (m.s.)",
        a: "24e52"
      }, {
        p: "ما سپى نۀ دى ويشتلى.",
        f: "maa spee nu dee weeshtúlee.",
        e: "I haven't hit the dogs. (m.pl.)",
        a: "24e53"
      }, {
        p: "هغۀ جينۍ نۀ ده وهلې.",
        f: "haghú jeenúy nu da wahúle.",
        e: "He hasn't hit the girl. (f.s.)",
        a: "24e54"
      }, {
        p: "هغۀ جينکۍ نۀ دى وهلې.",
        f: "haghú jeenukúy nu dee wahúle.",
        e: "He hasn't hit the girls. (f.pl.)",
        a: "24e55"
      }, {
        p: "تا زۀ ليدلے يم.",
        f: "taa zu leedúley yim.",
        e: "You have seen me (male).",
        a: "24e56"
      }, {
        p: "تا مُونږ ليدلى يو.",
        f: "taa moonG leedúlee yoo.",
        e: "You have seen us. (m.pl.)",
        a: "24e57"
      }, {
        p: "ما تۀ ليدلے يې.",
        f: "maa tu leedúley ye.",
        e: "I have seen you (masc.).",
        a: "24e58"
      }, {
        p: "ما تاسو ليدلى يئ.",
        f: "maa taaso leedúlee yeyy.",
        e: "I have seen you. (m.pl.)",
        a: "24e59"
      }, {
        p: "ستا ګوته ورکه شوې ده؟",
        f: "staa góota wrúka shíwe da?",
        e: "Has your ring become lost? (f.s.)",
        a: "24e60"
      }, {
        p: "تا ګوته مونتې ده؟",
        f: "taa góota mónte da?",
        e: "Have you found the ring? (f.s.)",
        a: "24e61"
      }, {
        p: "څۀ دې ليکلى دى.",
        f: "tsu de leekúlee dee?",
        e: "What have you written? (m.pl.)",
        a: "24e62"
      }, {
        p: "يو مضمُون مې سټُوډنټانو د پاره ليکلے دے.",
        f: "yo mazmóon me sTooDanTáano dupaara leekúley dey.",
        e: "I have written one article for the students. (m.s.)",
        a: "24e63"
      }, {
        p: "هغوى ډوډۍ خوړلې ده؟",
        f: "haghwée DoDúy khoRule da?",
        e: "Have they eaten the food? (f.s.)",
        a: "24e64"
      }, {
        p: "آو، هغوى خوړلې ده.",
        f: "aw, haghwée khoRúle da.",
        e: "Yes, they have eaten it.",
        a: "24e65"
      }, {
        p: "تا هغه مخکښې د ځان سره پېښور ته بوتلې ده؟",
        f: "taa haghá makhke du dzaan sara pexawúr ta botúle da?",
        e: "Have you taken her with you to Peshawar before? (f.s.)",
        a: "24e66"
      }, {
        p: "نه، هغه مې ځان سره نۀ ده بوتلې.",
        f: "na, haghá me dzaan sara nu da botúley.",
        e: "No, I haven't taken her with me.",
        a: "24e67"
      }, {
        p: "تاسو اوس اوس خپله ملګرې ليدلې ده؟",
        f: "taaso oos oos khpúla malgúre leedúle da?",
        e: "Have you seen your friend recently? (f.s.)",
        a: "24e68"
      }, {
        p: "نه، ما نۀ ده ليدلې.",
        f: "na, maa nu da leedúle.",
        e: "No, I haven't seen her.",
        a: "24e69"
      }, {
        p: "تا موټر راوستے دے؟",
        f: "taa moTúr raawústey dey?",
        e: "Have you brought the car? (m.s.)",
        a: "24e70"
      }, {
        p: "آو، ما راوستے دے.",
        f: "aw, maa raawústey dey.",
        e: "Yes, I have brought it.",
        a: "24e71"
      }, {
        p: "هغۀ کاغذونه راوړى دى؟",
        f: "haghú kaaghazóona raawúRee dee?",
        e: "Has he brought the papers? (m.pl.)",
        a: "24e72"
      }, {
        p: "نه، نۀ يې دى راوړى.",
        f: "na, nu ye dee raawúRee.",
        e: "No, he hasn't brought them.",
        a: "24e73"
      }, {
        p: "جهاز مخکښې نه تلے دے؟",
        f: "jiháaz makhke na túley dey?",
        e: "Has the plane left already? (m.s.)",
        a: "24e74"
      }, {
        p: "آو، نن د مخکښې نه تلے دے.",
        f: "aw, nun du makhke na túley dey.",
        e: "Yes, it has already gone today.",
        a: "24e75"
      }, {
        p: "تا خبرُونه اورېدلى دى؟",
        f: "taa khabróona aawredúlee dee?",
        e: "Have you listened to the news? (m.pl.)",
        a: "24e76"
      }, {
        p: "آو، ما د رُوس خبرُونه اورېدلى دى.",
        f: "aw, maa du roon khabróona aawredúlee dee.",
        e: "Yes, I have listened to the Russian news.",
        a: "24e77"
      }, {
        p: "ما نن ډېر خاندلى دى.",
        f: "maa nun Der khaandúlee dee.",
        e: "I have laughed a lot today. (m.pl.)",
        a: "24e78"
      }, {
        p: "تا ژړلى دى؟",
        f: "taa jzaRúlee dee?",
        e: "Have you been crying?",
        a: "24e79"
      }, {
        p: "تا زۀ ولې وټکولم؟",
        f: "taa ze wăle Takawúley yim.",
        e: "Why have you hit me (masc.) ?",
        a: "24e80"
      }]}</Examples>
    <h4 {...{
      "id": "note-2"
    }}>{`NOTE:`}</h4>
    <p>{`Remember that `}<Word w={{
        p: "خندل",
        f: "khandúl",
        e: "to laugh",
        a: ""
      }} mdxType="Word" /> and <Word w={{
        p: "ژړل",
        f: "jzaRul",
        e: "to cry",
        a: ""
      }} mdxType="Word" />{` are grammatically transitive, and always act as if there is a a masculine plural object.`}</p>
    <h2 {...{
      "id": "compound-transitive-verbs-in-the-present-perfect"
    }}>{`Compound transitive verbs in the present perfect`}</h2>
    <p>{`Both `}<Word w={{
        p: "کول",
        f: "kawúl",
        e: "to do",
        a: "kawul"
      }} mdxType="Word" /> and <Word w={{
        p: "کول",
        f: "kawúl",
        e: "to make",
        a: "kawul"
      }} mdxType="Word" /> have an irregular past participle form: <Word w={{
        p: "کړے",
        f: "kúRey",
        e: "",
        a: "kurrey-m"
      }} mdxType="Word" /></p>
    <Examples mdxType="Examples">{[{
        p: "هغۀ دلته ډېر کار کړے دے.",
        f: "haghú dălta Der kaar kúRey dey.",
        e: "He has done a lot of work here. (m.s.)",
        a: "24e81"
      }, {
        p: "تا زما د پاره يوڅو کارُونه کړى دى.",
        f: "taa zmaa du paara yo tso kaaroona kúRee dee.",
        e: "You have done a few jobs for me. (m.pl.)",
        a: "24e82"
      }, {
        p: "ما مخکښې د هغوى مدد نۀ دے کړے.",
        f: "maa makhke du haghwée madúd nu dey kuRey.",
        e: "I haven't given them help before. (m.s.)",
        a: "24e83"
      }, {
        p: "هغۀ زما ډېر مدد کړے دے.",
        f: "haghú zmaa Der madúd kúRey dey.",
        e: "He has given me a lot of help. (m.s.)",
        a: "24e84"
      }, {
        p: "ترکاڼ ما د پاره يو مېز جوړ کړے دے.",
        f: "tarkáaN maa dupaara yo mez joR kuRey dey.",
        e: "The carpenter has made a table for me. (m.s.)",
        a: "24e85"
      }, {
        p: "تا زما ګاډے تر اوسه پورې نۀ دے سم کړے؟",
        f: "taa zmaa gáaDey tu ooso pore nu dey sum kuRey?",
        e: "Haven't you fixed my car yet? (m.s.)",
        a: "24e86"
      }, {
        p: "هغۀ کړکۍ سمه کړې ده؟",
        f: "haghú kiRkúy súma kuRe da?",
        e: "Has he repaired the window? (f.s.)",
        a: "24e87"
      }, {
        p: "هغوى ډېرې ښې کُرسۍ جوړې کړې دى.",
        f: "haghwée Dere xe kUrsúy joRe kuRe dee.",
        e: "They have made very good chairs. (f.pl.)",
        a: "24e88"
      }, {
        p: "ما نن يو کباب پوخ کړے دے.",
        f: "maa nun yo kabáab pokh kuRey dey.",
        e: "I cooked a kabab today. (m.s.)",
        a: "24e89"
      }, {
        p: "تا غوښه پخه کړې ده؟",
        f: "taa ghwaxa pakha kúre da?",
        e: "Have you cooked the meat? (f.s.)",
        a: "24e90"
      }, {
        p: "هغې ډېرې خرابې ورېژې پخې کړى دى.",
        f: "haghé Dére kharáabe wréjze pakhé kúRe dee.",
        e: "She has cooked very bad rice. (f.pl.)",
        a: "24e91"
      }]}</Examples>
    <h2 {...{
      "id": "answering-questions-in-the-present-perfect"
    }}>{`Answering questions in the present perfect`}</h2>
    <p>{`When you are asked a question make a habit of answering in the same tense. Listen carefully to the question, the speaker will have solved any difficulties of agreement for you. Use that knowledge to answer flawlessly.`}</p>
    <p>{`👆 Answer the following questions postively and negatively, staying in the same tense. Use this pattern:`}</p>
    <Table bordered unset verticalContent mdxType="Table">{[[{
        e: "Question:"
      }, {
        p: "هغهٔ خپل قلم هېر کړے دے؟",
        f: "haghú khpul qalúm her kúRey dey?",
        e: "Has he forgotten his pen?",
        a: "24e92"
      }], [{
        e: "Positive reply:"
      }, {
        p: "آو، هېر کړے يې دے.",
        f: "aw, her kúRey ye dey.",
        e: "Yes, he has forgotten it.",
        a: "24e93"
      }], [{
        e: "Negative reply:"
      }, {
        p: "نه، هغۀ نۀ دے هېر کړے.",
        f: "na, haghú nu dey her kúRey",
        e: "No, he hasn't forgotten it.",
        a: "24e94"
      }]]}</Table>
    <PosNegAnswers storeSet="a" mdxType="PosNegAnswers">{[{
        p: "ستا خپل څاروى ياد شوى دى؟",
        f: "staa khpul tsaarwée yaad shíwee dee?",
        e: "Have you remembered your livestock? (m.pl.)",
        a: "24e95"
      }, {
        p: "تا خپل کتاب ورک کړے دے؟",
        f: "taa khpul kitáab wruk kúRey dey?",
        e: "Have you lost your book? (m.s.)",
        a: "24e96"
      }, {
        p: "تا هغوى سره خبرې کړې دى؟",
        f: "taa haghwée sara khabúrey kúRe dee?",
        e: "Have you spoken with them? (f.pl.)",
        a: "24e97"
      }, {
        p: "تا بجلى لګولې ده؟",
        f: "taa bijlúy lugawúle da?",
        e: "Have you turned on the electricity? (f.s.)",
        a: "24e98"
      }, {
        p: "تۀ زمُونږ د نوى سکرټرى سره مِلاؤ شوے يې؟",
        f: "taa zmoonG du núwee sikreTrée sara milaaw shíwey ye?",
        e: "Have you met our new secretary?",
        a: "24e99"
      }, {
        p: "تا څۀ فېصله کړې ده؟",
        f: "taa tsu fesalá kúRe da?",
        e: "What decision have you made? (f.s.)",
        a: "24e100"
      }, {
        p: "تا نن رباب ولې نۀ دے وهلے؟",
        f: "taa nun rabáab wăle nu dey wahúley?",
        e: "Why haven't you played rabab today? (m.s.)",
        a: "24e101"
      }]}</PosNegAnswers>
    <h2 {...{
      "id": "oneself-word-w-p-پخپله-f-pukhpula-e--a---"
    }}>{`Oneself: `}<Word w={{
        p: "پخپله",
        f: "pukhpula",
        e: "",
        a: ""
      }} mdxType="Word" /></h2>
    <Examples mdxType="Examples">{[{
        p: "ماشُومان پخپله بازار ته تلے شى؟",
        f: "maashoomáan pukhpúla baazáar ta tley shee?",
        e: "Can the children go to the bazaar by themselves?",
        a: "24e102"
      }, {
        p: "آو، تلے شى ځکه چې ځوانان دى.",
        f: "aw, tley shee dzuka che dzwaanáan dee.",
        e: "Yes, they can go by themselves, they are teenagers.",
        a: "24e103"
      }, {
        p: "تۀ پخپله چرګ پخولے شې؟",
        f: "tu pukhpúla churg pakhawúley she?",
        e: "Can you cook chicken by yourself?",
        a: "24e104"
      }, {
        p: "آو، زۀ پخپله چرګ پخولے شم.",
        f: "aw, zu pukhpúla churg pakhawúley shum.",
        e: "Yes, I can cook it by myself.",
        a: "24e105"
      }, {
        p: "تۀ به هغوى سره خبرې کوې؟",
        f: "tu ba haghwée sara khabúre kawe?",
        e: "Will you speak with them?",
        a: "24e106"
      }, {
        p: "زۀ به پخپله د هغوى سره خبرې کوم.",
        f: "zu ba pukhpúla du haghwée sara khabúre kawúm.",
        e: "I will speak with them myself.",
        a: "24e107"
      }, {
        p: "ټول پخپله مۀ خوره!",
        f: "Tol pukhpúla mu khora!",
        e: "Don't eat it all yourself!",
        a: "24e108"
      }, {
        p: "ګړۍ پخپله ماته شوه.",
        f: "gaRúy pukhpúla maata shwa.",
        e: "The clock broke by itself.",
        a: "24e109"
      }, {
        p: "تۀ غريبانو ته پیسې پخپله ورکوې؟",
        f: "tu ghareebáano ta peyse pukhpúla wărkawe?",
        e: "Do you give money to the poor yourself?",
        a: "24e110"
      }, {
        p: "آو، زۀ پخپله پيسې ورکوم.",
        f: "aw, zu pukhpúla peyse wărkawum.",
        e: "Yes, I give them money myself.",
        a: "24e111"
      }, {
        p: "زۀ پخپله خبرې کوم.",
        f: "zu pukhpúla khabúre kawum.",
        e: "I'll talk myself.",
        a: "24e112"
      }]}</Examples>
    <img src={imposedFace} />
    <h2 {...{
      "id": "using-word-w-p-خپل-f-khpul-e-own-a-khpul---and-word-w-p-پخپله-f-pukhpúla-e-oneself-a----in-the-same-sentence"
    }}>{`Using `}<Word w={{
        p: "خپل",
        f: "khpul",
        e: "own",
        a: "khpul"
      }} mdxType="Word" /> and <Word w={{
        p: "پخپله",
        f: "pukhpúla",
        e: "oneself",
        a: ""
      }} mdxType="Word" />{` in the same sentence.`}</h2>
    <Examples mdxType="Examples">{[{
        p: "هغۀ خپله کمره پخپله صفا کړه؟",
        f: "haghú khpula kamrá pukhpúla safáa kRa?",
        e: "Did he clean his own room by himself?",
        a: "24e113"
      }, {
        p: "نه، د هغۀ مور صفا کړه.",
        f: "na, du haghú mor safáa kRa.",
        e: "No, his mother cleaned it.",
        a: "24e114"
      }, {
        p: "خپل کار پخپله نۀ شې کولے؟",
        f: "khpul kar pukhpúla nu she kawúley?",
        e: "Can't you do your own work by yourself?",
        a: "24e115"
      }, {
        p: "آو، زۀ خپل کار پخپله کولے شم.",
        f: "aw, zu khpul kaar pukhpula kawúley shum.",
        e: "Yes, I can do my own work by myself.",
        a: "24e116"
      }]}</Examples>
    <h2 {...{
      "id": "medical-talk"
    }}>{`Medical talk`}</h2>
    <h4 {...{
      "id": "questions"
    }}>{`Questions:`}</h4>
    <Examples mdxType="Examples">{[{
        p: "څۀ تکليف دے؟",
        f: "tsu takléef dey?",
        e: "What is the problem?",
        a: "24e117"
      }, {
        p: "تۀ ټوخېږې؟",
        f: "tu TookhéGe?",
        e: "Are you coughing?",
        a: "24e118"
      }, {
        p: "تاسو اُلټۍ کوئ؟",
        f: "taaso UlTúy kawéyy?",
        e: "Are you vomiting?",
        a: "24e119"
      }, {
        p: "چينجى شته؟",
        f: "cheenjée shta?",
        e: "Do you have worms?",
        a: "24e120"
      }, {
        p: "متيازې سوزى؟",
        f: "mityáaze swázee?",
        e: "Is your urine burning?",
        a: "24e121"
      }, {
        p: "غوږ بهېږی؟",
        f: "ghwuG bahéGee?",
        e: "Is the ear draining?",
        a: "24e122"
      }, {
        p: "په خېټه ماشوم شته؟",
        f: "pu khéTa maashóom shta?",
        e: "Are you pregnant?",
        a: "24e123"
      }, {
        p: "ستا خېټه خرابه ده، دستُونه شته؟",
        f: "staa khéTa kharáaba da, dastóona shta?",
        e: "Is your stomach upset, do you have diarrhoea?",
        a: "24e124"
      }]}</Examples>
    <h4 {...{
      "id": "answers"
    }}>{`Answers:`}</h4>
    <Examples mdxType="Examples">{[{
        p: "سپينې ګولۍ ما خوړلى دى، خو ښۀ شوے نۀ يم.",
        f: "speene goolúy maa khoRúlee dee, kho xu ",
        e: "I took some white tablets, but I have not got better",
        a: "24e125"
      }, {
        p: "درې څلور ورځو نه زۀ ناجوړه يم.",
        f: "dre tsalor wradzo to zu naajóRa yim.",
        e: "I've been ill for three or four days.",
        a: "24e126"
      }, {
        p: "ډوډۍ مې زړۀ ته نۀ کېږى.",
        f: "DoDúy me zRu to nu kéGee.",
        e: "I don't feel like eating food.",
        a: "24e127"
      }]}</Examples>
    <h2 {...{
      "id": "memorising-moment"
    }}>{`Memorising moment`}</h2>
    <h4 {...{
      "id": "parts-of-the-body"
    }}>{`Parts of the body`}</h4>
    <p>{`Use the drawings to drill the names of these parts of the body.`}</p>
    <img src={bodyPartsMan} />
    <Examples substitutions mdxType="Examples">{[{
        p: "اوږه",
        f: "oogá",
        e: "shoulder",
        a: "ooga-m"
      }, {
        p: "تلے",
        f: "taléy",
        e: "palm",
        a: "taley-m"
      }, {
        p: "څنګل",
        f: "tsangúl",
        e: "elbow",
        a: "tsangul-m"
      }, {
        p: "پښه",
        f: "xpa",
        e: "foot",
        a: "xpa-m"
      }, {
        p: "خېټه",
        f: "kheTá",
        e: "stomach",
        a: "khetta-m"
      }, {
        p: "زنګُون",
        f: "zangóon",
        e: "knee",
        a: "zangoon-m"
      }, {
        p: "سر",
        f: "săr",
        e: "head",
        a: "sar-m"
      }, {
        p: "سينه",
        f: "seená",
        e: "chest",
        a: "seena-m"
      }, {
        p: "شا",
        f: "shaa",
        e: "upper back",
        a: "shaa-m"
      }, {
        p: "ګوته",
        f: "góota",
        e: "finger",
        a: "goota-m"
      }, {
        p: "ګيټۍ",
        f: "geeTúy",
        e: "ankle",
        a: "geettuy-m"
      }, {
        p: "لاس",
        f: "laas",
        e: "hand",
        a: "laas-m"
      }, {
        p: "لېچه",
        f: "lécha",
        e: "arm",
        a: "lecha-m"
      }, {
        p: "مټه",
        f: "muTa",
        e: "upper arm",
        a: "mutta-m"
      }, {
        p: "مړوند",
        f: "maRwánd",
        e: "wrist",
        a: "marrwand-m"
      }, {
        p: "ملا",
        f: "mlaa",
        e: "back",
        a: "mlaa-m"
      }, {
        p: "مېده",
        f: "medá",
        e: "stomach",
        a: "meda-m"
      }, {
        p: "نرۍ ملا",
        f: "narúy mlaa",
        e: "lower back",
        a: "narruy-mlaa-m"
      }, {
        p: "نوک",
        f: "nook",
        e: "fingernail",
        a: "nook-m"
      }]}</Examples>
    <h4 {...{
      "id": "face"
    }}>{`Face`}</h4>
    <img src={womanSadir} />
    <Examples substitutions mdxType="Examples">{[{
        p: "ورُوځه",
        f: "wróodza",
        e: "eyebrow",
        a: "wroodza-m"
      }, {
        p: "غوږ",
        f: "ghwuG",
        e: "ear",
        a: "ghwugg-m"
      }, {
        p: "ژبه",
        f: "jzíba",
        e: "tongue",
        a: "jziba-m"
      }, {
        p: "زنه",
        f: "zína",
        e: "chin",
        a: "zina-m"
      }, {
        p: "څټ",
        f: "tsuT",
        e: "back of neck",
        a: "tsutt-m"
      }, {
        p: "وېښتۀ",
        f: "wextú",
        e: "hair",
        a: "wextu-m"
      }, {
        p: "پوزۀ",
        f: "póza",
        e: "nose",
        a: "poza-m"
      }, {
        p: "شُونډې",
        f: "shóonDe",
        e: "lips",
        a: "shoondde-m"
      }, {
        p: "غاښُونه",
        f: "ghaaxóona",
        e: "teeth",
        a: "ghaaxoona-m"
      }, {
        p: "مرۍ",
        f: "marúy",
        e: "throat (inside)",
        a: "maruy-m"
      }, {
        p: "غاړه",
        f: "gháaRa",
        e: "throat (outside, front of neck)",
        a: "ghaarra-m"
      }]}</Examples>
    <h4 {...{
      "id": "matalóona"
    }}>{`matalóona`}</h4>
    <Examples memorizingMoment mdxType="Examples">{memorizingMoments[24]}</Examples>
    <h2 {...{
      "id": "exercises-for-lesson-twenty-four"
    }}>{`Exercises for Lesson Twenty-Four`}</h2>
    <ol>
      <li parentName="ol">{`Substitution drill:`}</li>
    </ol>
    <p>{`👆 Have your helper point to various parts of the body, and substitute the names into the sentence below:`}</p>
 <Examples mdxType="Examples">{[{
        p: "سر مې خوږېږی.",
        f: "săr me khooGéGee.",
        e: "My head hurts.",
        a: "24e128"
      }]}</Examples>
    <p>{`👆 Then have your helper make up similar drills.`}</p>
    <ol {...{
      "start": 2
    }}>
      <li parentName="ol">{`Answer the following questions giving both positive and negative short answers.`}</li>
    </ol>
    <PosNegAnswers storeSet="b" mdxType="PosNegAnswers">{[{
        p: "تاسو چرته افغانستان ته تلى يئ؟",
        f: "taaso chărta afghaanistáan ta túlee yeyy?",
        e: "",
        a: "24e129"
      }, {
        p: "وادۀ دې کړے دے؟",
        f: "waadú de kúRey dey?",
        e: "",
        a: "24e130"
      }, {
        p: "نن مو کُوم مضمُون وئيلے دے؟",
        f: "nun mU kum mazmóon wayúley dey?",
        e: "",
        a: "24e131"
      }, {
        p: "تاسو نن خپلې کور والا سره په پارک کښې ګرځېدلى يئ؟",
        f: "nun taaso khpule kor waalaa sara pu paark ke gurdzedúlee yeyy?",
        e: "",
        a: "24e132"
      }, {
        p: "تا تر اوسه پورې خپل ملګرے ليدلے نۀ دے؟",
        f: "taa tur oosa pore khpul malgúrey leedúley nu dey?",
        e: "",
        a: "24e133"
      }]}</PosNegAnswers>
    <p>{`👆 Have your helper make up lots more.`}</p>
    <img src={sideLooker} style={{
      maxWidth: "10rem"
    }} />
    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;
export const tableOfContents = (components={}) => [
  {
    id: "dialogue-twenty-four-sickness",
    level: 2,
    title: "Dialogue twenty-four: Sickness",
    children: [
        
      ]
  },
{
    id: "pronunciation-drill",
    level: 2,
    title: "Pronunciation drill",
    children: [
        
      ]
  },
{
    id: "to-have-done-the-present-perfect-tense",
    level: 2,
    title: "'to have done’: The present perfect tense",
    children: [
        
      ]
  },
{
    id: "intransitive-verbs-in-the-present-tense",
    level: 2,
    title: "Intransitive verbs in the present tense",
    children: [
        
      ]
  },
{
    id: "negatives-in-the-present-perfect-tense",
    level: 2,
    title: "Negatives in the present perfect tense",
    children: [
        
      ]
  },
{
    id: "intransitive-stative-compounds-in-the-present-perfect",
    level: 2,
    title: "Intransitive stative compounds in the present perfect",
    children: [
        
      ]
  },
{
    id: "passive-sentences-in-the-present-perfect",
    level: 2,
    title: "Passive sentences in the present perfect",
    children: [
        
      ]
  },
{
    id: "transitive-verbs-in-the-present-perfect",
    level: 2,
    title: "Transitive verbs in the present perfect",
    children: [
        
      ]
  },
{
    id: "compound-transitive-verbs-in-the-present-perfect",
    level: 2,
    title: "Compound transitive verbs in the present perfect",
    children: [
        
      ]
  },
{
    id: "answering-questions-in-the-present-perfect",
    level: 2,
    title: "Answering questions in the present perfect",
    children: [
        
      ]
  },
{
    id: "oneself-word-w-p-پخپله-f-pukhpula-e--a---",
    level: 2,
    title: <React.Fragment>{`Oneself: `}<Word w={{ p: "پخپله", f: "pukhpula", e: "", a: "" }} /></React.Fragment>,
    children: [
        
      ]
  },
{
    id: "using-word-w-p-خپل-f-khpul-e-own-a-khpul---and-word-w-p-پخپله-f-pukhpúla-e-oneself-a----in-the-same-sentence",
    level: 2,
    title: <React.Fragment>{`Using `}<Word w={{ p: "خپل", f: "khpul", e: "own", a: "khpul" }} /> and <Word w={{ p: "پخپله", f: "pukhpúla", e: "oneself", a: "" }} />{` in the same sentence.`}</React.Fragment>,
    children: [
        
      ]
  },
{
    id: "medical-talk",
    level: 2,
    title: "Medical talk",
    children: [
        
      ]
  },
{
    id: "memorising-moment",
    level: 2,
    title: "Memorising moment",
    children: [
        
      ]
  },
{
    id: "exercises-for-lesson-twenty-four",
    level: 2,
    title: "Exercises for Lesson Twenty-Four",
    children: [
        
      ]
  }
]

export const frontMatter = {
  "title": "Lesson Twenty-Four"
}

