export default [
    {
        p: "دا لوی کور دے.",
        f: "daa looy kor dey.",
        e: "This is a big house.",
        a: "daa-looy-kor",
    },
    {
        p: "دا لوی کورونه دی.",
        f: "daa looy koróona dee.",
        e: "These are big housese.",
        a: "daa-looy-koroona",
    },
    {
        p: "ستا سپے وړُوکے دے.",
        f: "staa spey waRóokey dey.",
        e: "Your dog is small.",
        a: "ps4-pl1-1",
    },
    {
        p: "ستا سپی واړهٔ دی.",
        f: "staa spee waaRu dee.",
        e: "Your dogs are small.",
        a: "ps4-pl1-2",
    },
    {
        p: "هغه مار لنډ دے.",
        f: "hágha maar lunD dey.",
        e: "That snake is short.",
        a: "ps4-p1",
    },
    {
        p: "هغه ماران لنډی دی.",
        f: "hágha maaraan lunDee dee.",
        e: "Those snakes are short.",
        a: "ps4-p2",
    },
    {
        p: "دغه خطرناک لړم دے.",
        f: "dágha khatarnáak laRúm dey.",
        e: "That is a dangerous scorpion.",
        a: "ps4-p3",
    },
    {
        p: "دغه خطرناک لړمانان دی.",
        f: "dágha khatarnáak laRumaanaan dee.",
        e: "Those are dangerous scorpions.",
        a: "ps4-p4",
    },
    {
        p: "دا آلُو مزېدار دے.",
        f: "daa aaloo muzedaar dey.",
        e: "This potatoe is delicious.",
        a: "ps4-p5",
    },
    {
        p: "دا آلوګان مزېدار دی.",
        f: "daa aaloogáan muzedaar dee.",
        e: "These potatoes are tasty.",
        a: "ps4-p6",
    },
    {
        p: "هغه کدُو خراب دے.",
        f: "hágha kadóo kharáab dey.",
        e: "That squash is rotten.",
        a: "ps4-p7",
    },
    {
        p: "هغه کدوان خراب دی.",
        f: "hágha kadwáan kharáab dee.",
        e: "Those squash (squashes) are rotten.",
        a: "ps4-p8",
    },
    {
        p: "زما نوم ______ دے.",
        f: "zmaa noom _______ dey.",
        e: "My name is _______.",
        a: "ps4-p9",
    },
    {
        p: "هغه څوک دے؟",
        f: "haghá tsok dey?",
        e: "Who is he?",
        a: "ps4-p10",
    },
    {
        p: "یو مشهور اُستاذ دے.",
        f: "yo mushahóor Ustáaz dey.",
        e: "A famous teacher.",
        a: "ps4-p11",
    },
    {
        p: "څو کسان دی؟",
        f: "tso kasáan dee?",
        e: "How many people are there?",
        a: "ps4-p12",
    },
    {
        p: "څومره آبادی ده؟",
        f: "tsóomra aabaadée da?",
        e: "What is the population?",
        a: "ps4-p13",
    },
    {
        p: "تاسو څومره ورُوڼه یئ؟",
        f: "taaso tsoomra wrooNa yeyy?",
        e: "How many brothers are you?",
        a: "ps4-p14",
    },
    {
        p: "ښهٔ قلم چا سره دے.",
        f: "xu qalúm chaa sara dey?",
        e: "Who has a good pen?",
        a: "ps4-p15",
    },
    {
        p: "ښهٔ قلم ما سره دے.",
        f: "xu qalúm maa sara dey.",
        e: "I have a good pen.",
        a: "ps4-p16",
    },
    {
        p: "ډېرې پیسې چا سره دی؟",
        f: "Dere peyse chaa sara dee?",
        e: "Who has a lot of money?",
        a: "ps4-p17",
    },
    {
        p: "هیچا سره نشته.",
        f: "heechaa sara nishta.",
        e: "No one has any.",
        a: "ps4-p18",
    },
    {
        p: "شاهد چرته دے؟",
        f: "shaahid chărta dey?",
        e: "Where is Shahid?",
        a: "ps4-pp15",
    },
    {
        p: "ستا ورور چرته دے؟",
        f: "staa wroor chărta dey?",
        e: "Where is your brother?",
        a: "ps4-pp16",
    },
    {
        p: "هغه په ښار کښې دے.",
        f: "hagha pu xaar ke dey.",
        e: "He is in the city.",
        a: "ps4-pp17",
    },
    {
        p: "هغه په کور کښې دے.",
        f: "hagha pu kor ke dey.",
        e: "He is in the house.",
        a: "ps4-pp18",
    },
    {
        p: "څوک په کلاس کښې دے؟ (یا) په کلاس کښې څوک دي؟",
        f: "tsok pu klaas ke dey? (or) pu klaas ke tsok dee?",
        e: "Who is in the classroom?",
        a: "ps4-p19",
    },
    {
        p: "اُستاذ په کلاس کښې دے.",
        f: "Ustáaz pu klaas ke dey.",
        e: "The teacher is in the classroom.",
        a: "ps4-p20",
    },
    {
        p: "قلم په بکس کښې دے؟",
        f: "qalúm pu baks ke dey?",
        e: "Is the pen in the suitcase?",
        a: "ps4-p21",
    },
    {
        p: "نه، په بکس باندې دے.",
        f: "na, pu bakus baande dey.",
        e: "No, it is on the suitcase.",
        a: "ps4-p22",
    },
    {
        p: "نثار په چت باندې دے؟",
        f: "nisáar pu chut baandey dey?",
        e: "Is Nisar on the roof?",
        a: "ps4-p23",
    },
    {
        p: "نه، په کمره کښې دے.",
        f: "na, pu kamrá kxe dey.",
        e: "No, he is in the room.",
        a: "ps4-p24",
    },
    {
        p: "پِنسل چرته دے؟",
        f: "pinsúl chărta dey?",
        e: "Where is the pencil?",
        a: "ps4-p25",
    },
    {
        p: "پِنسل د مېز لاندې دے.",
        f: "pinsul du mez laande dey.",
        e: "The pencil is under the table.",
        a: "ps4-p26",
    },
];
