
import React from 'react'
import { mdx } from '@mdx-js/react'
export const readingTime = {"text":"16 min read","minutes":15.795,"time":947700,"words":3159}
/* @jsx mdx */
import Dialogue from "../components/Dialogue";
import Word from "../components/Word";
import Examples from "../components/Examples";
import Table from "../components/Table";
import Quiz from "../components/Quiz";
import coveredWoman from "../assets/images/covered-woman.PNG";
import mangoSeller from "../assets/images/mango-seller.PNG";
import hand from "../assets/images/hand.PNG";
import memorizingMoments from "./memorizing-moments";
import examples from "./lesson-three-examples.js";

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope")
  return <div {...props}/>
};

const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <h2 {...{
      "id": "dialogue-three-shopping"
    }}>{`Dialogue three: Shopping`}</h2>
    <Dialogue audio="dialogue-3-shopping" mdxType="Dialogue">{[{
        s: "H",
        p: "څنګه حال دے؟",
        f: "tsínga haal dey?",
        e: "How are you?",
        a: "tsunga-haal-dey"
      }, {
        s: "L",
        p: "مِهربانى، ښۀ يم. تاسو څنګه يئ؟",
        f: "mirabaanee, xu yim. táaso tsínga yeyy?",
        e: "Thanks, I'm fine. How are you?",
        a: "mirabaanee-xu-yum-taaso"
      }, {
        s: "L",
        p: "دې ته پښتو کښې څۀ وائى؟",
        f: "de tu puxto ke tsu wáayee?",
        e: "What do you call this in Pukhto?",
        a: "de-ta-puxto-ke-tsu"
      }, {
        s: "H",
        p: "دا پياز دى.",
        f: "daa pyaaz dee.",
        e: "These are onions.",
        a: "daa-pyaaz-dee"
      }, {
        s: "L",
        p: "پياز کلو په څو دى؟",
        f: "pyaaz kiló pu tso dee?",
        e: "How much is a kilo of onions?",
        a: "pyaaz-kilo-pu-tso"
      }, {
        s: "H",
        p: "اويا روپۍ کلو.",
        f: "awiya roopuy kilo.",
        e: "Seventy rupees a kilo.",
        a: "awiya-rupuy-k"
      }, {
        s: "L",
        p: "يو پاؤ راکه.",
        f: "yo paaw ráaka",
        e: "Give me one pao (quarter of a kilo).",
        a: "yo-paaw-raaka"
      }, {
        s: "L",
        p: "درزن کېلې په څو دى؟",
        f: "darzún kelé pu tso dee?",
        e: "How much are a dozen bananas?",
        a: "darzun-kele-pu-tso"
      }, {
        s: "H",
        p: "په څلوېښت روپۍ دى.",
        f: "pu tsalwéxt roopúy dee.",
        e: "They are forty rupees.",
        a: "pu-tsalwext-rup"
      }, {
        s: "L",
        p: "ډېرې ګرانې دى، لږې ارزانې راکه!",
        f: "Dere graane dee, luGe arzaane raaka!",
        e: "They are very expensive. Give me a little cheaper!",
        a: "ddere-graane-dee-lugge-arzaane"
      }, {
        s: "H",
        p: "خیر دے، په پينځۀ دېرش روپۍ راکه.",
        f: "kheyr dey, pu peendzú dersh roopúy raaka.",
        e: "It's OK, give me thirty-five rupees.",
        a: "khey-dey-pu-peendzu"
      }, {
        s: "L",
        p: "ټيک ده.",
        f: "Teek da.",
        e: "That's fine.",
        a: "tteek-da"
      }, {
        s: "H",
        p: "نور څۀ شے پکار دے؟",
        f: "noor tsu shey pukáar dey?",
        e: "Do you want anything else?",
        a: "noor-tsu-shey-pukaar-dey"
      }, {
        s: "L",
        p: "نه بس دے، يار.",
        f: "na bas dey, yaar.",
        e: "No that is enough, friend.",
        a: "na-bas-dey-yaar"
      }, {
        s: "L",
        p: "ټولې څومره پیسې شوې؟",
        f: "Tole tsoomra peyse shwe?",
        e: "How much does it all come to?",
        a: "ttole-tsoomra-peyse-shwe"
      }]}</Dialogue>
    <h4 {...{
      "id": "note"
    }}>{`NOTE:`}</h4>
    <div>
      <p><Word w={{
          p: "یار",
          f: "yaar",
          a: "yaar"
        }} mdxType="Word" /> means 'friend'. It is not appropriate for women to use yaar when speaking to men. Ladies can use the phrase <Word w={{
          p: "نه بس دے، مِهربانى.",
          f: "na bas dey, mirabaanee",
          a: "na-bas-dey-miribaanee"
        }} mdxType="Word" /> instead.
      </p></div>
    <h2 {...{
      "id": "ladies-dialogue-making-friends"
    }}>{`Ladies’ dialogue: Making friends`}</h2>
    <Dialogue audio="dialogue-3-making-friends" mdxType="Dialogue">{[{
        s: "L",
        p: "السلام عليکم!",
        f: "áasalaam aléykUm!",
        e: "Peace be upon you!",
        a: "asalam-3"
      }, {
        s: "H",
        p: "وعليکم السلام!",
        f: "wa'aleykUm asaláam!",
        e: "And upon you be peace",
        a: "waleyk-3"
      }, {
        s: "L",
        p: "زۀ هلته اوسېږم او زۀ پښتو زده کوم.",
        f: "zu hálta oséGum aaw zu puxtó zdá kawúm.",
        e: "I live over there and I am learning Pukhto.",
        a: "zu-halta-oseggum-aw-p-z"
      }, {
        s: "H",
        p: "ښه! دننه راشه!",
        f: "xa! dunúna ráasha!",
        e: "Good! Come in!",
        a: "xa-danuna-raasha"
      }, {
        s: "L",
        p: "پښتو ګرانه ده او زما لږ مدد پکار دے.",
        f: "puxtó gráana da aaw zmaa luG madúd pukáar dey.",
        e: "Pukhto is difficult and I need a little help.",
        a: "puxto-graana-da-aw-zma-l"
      }, {
        s: "H",
        p: "خو زۀ په ليکلو باندې نۀ پوهېږم.",
        f: "kho zu pu leekúlo baande nú poheGum.",
        e: "But I don't know writing.",
        a: "kho-zu-pu-leekulo-b-n-p"
      }, {
        s: "L",
        p: "خیر دے. هسې زما سوال دے چې ته لږ زما تلفُظ ټیک کولے شې؟",
        f: "kheyr dey. hase zmaa swaal dey che tu luG zmaa tulaafÚz Teek kawuley she?",
        e: "That's OK. My request is, can you simply correct my pronunciation?",
        a: "kheyr-de-hase-zmaa-swaal"
      }, {
        s: "H",
        p: "بالکل! بې غمه شه!",
        f: "bílkUl! be ghuma sha!",
        e: "Sure! Don't be worried!",
        a: "bilkuul-be-ghama-sha"
      }, {
        s: "L",
        p: "دا څۀ دے؟",
        f: "daa tsu dey?",
        e: "What is this?",
        a: "daa-tsu-dey"
      }]}</Dialogue>
    <h4 {...{
      "id": "note-1"
    }}>{`NOTE:`}</h4>
    <img src={coveredWoman} style={{
      float: "right",
      maxWidth: "10rem"
    }} />
    <p>{`While Pukhtun women are very hospitable, they are also very busy. It is hard for language learners to find ladies to visit simply for brief conversations. It takes time to have tea, and be polite. So, relax and enjoy new friendships.`}</p>
    <h2 {...{
      "id": "numbers-one-to-ten"
    }}>{`Numbers one to ten`}</h2>
    <Examples twoColumns mdxType="Examples">{[{
        p: "۱ یو",
        f: "yo",
        e: "1",
        a: "yo"
      }, {
        p: "۲ دوه",
        f: "dwa",
        e: "2",
        a: "dwa"
      }, {
        p: "۳ درې",
        f: "dre",
        e: "3",
        a: "dre"
      }, {
        p: "۴ څلور",
        f: "tsalór",
        e: "4",
        a: "tsalor"
      }, {
        p: "۵ پینځهٔ",
        f: "peendzú",
        e: "5",
        a: "peendzu"
      }, {
        p: "۶ شپږ",
        f: "shpuG",
        e: "6",
        a: "shpugg"
      }, {
        p: "۷ اووهٔ",
        f: "oowú",
        e: "7",
        a: "oowu"
      }, {
        p: "۸ اتهٔ",
        f: "aatú",
        e: "8",
        a: "aatu"
      }, {
        p: "۹ نههٔ",
        f: "núhu",
        e: "9",
        a: "nuhu"
      }, {
        p: "۱۰ لس",
        f: "lus",
        e: "10",
        a: "lus"
      }]}</Examples>
    <h4 {...{
      "id": "note-2"
    }}>{`NOTE:`}</h4>
    <p>{`Urdu numbers are also commonly used, especially in the bigger cities.`}</p>
    <h2 {...{
      "id": "a-shopping-list"
    }}>{`A shopping list`}</h2>
    <p>{`👆 This list is a starting point only. On a sheet of paper, add other things you often need to buy. You will find that the words you use are the words you will remember. Role play shopping with your language helper.`}</p>
    <h4 {...{
      "id": "dried-food"
    }}>{`Dried Food`}</h4>
    <Examples mdxType="Examples">{[{
        p: "وریژې",
        f: "wréejze",
        e: "rice",
        a: "wreejze"
      }, {
        p: "اوړهٔ",
        f: "ooRú",
        e: "flour",
        a: "oorru"
      }, {
        p: "چینی",
        f: "cheenée",
        e: "sugar",
        a: "cheenee"
      }, {
        p: "ګرمه مساله",
        f: "gárma masaala",
        e: "curry powder",
        a: "garma-masaala"
      }]}</Examples>
    <h4 {...{
      "id": "vegetables"
    }}>{`Vegetables`}</h4>
    <Examples mdxType="Examples">{[{
        p: "ګل ګوپی",
        f: "gUl gopée",
        e: "cauliflower",
        a: "guul-gopee"
      }, {
        p: "ټېپر",
        f: "Tepúr",
        e: "turnip",
        a: "ttepur"
      }, {
        p: "مُولۍ",
        f: "moolúy",
        e: "radish",
        a: "mooluy"
      }, {
        p: "مټر",
        f: "maTúr",
        e: "peas",
        a: "mattur"
      }]}</Examples>
    <h4 {...{
      "id": "fruit"
    }}>{`Fruit`}</h4>
    <Examples mdxType="Examples">{[{
        p: "انګُور",
        f: "angóor",
        e: "grape",
        a: "angoor"
      }, {
        p: "آم",
        f: "aam",
        e: "mango",
        a: "aam"
      }, {
        p: "کېله",
        f: "kelá",
        e: "banana",
        a: "kela"
      }, {
        p: "سېو، مڼه",
        f: "sew, maNá",
        e: "apple",
        a: "sew-manna"
      }, {
        p: "مالټه",
        f: "maalTá",
        e: "orange",
        a: "maaltta"
      }, {
        p: "امرُود",
        f: "amróod",
        e: "guava",
        a: "amrood"
      }, {
        p: "ناشپاتۍ",
        f: "naashpaatúy",
        e: "pear",
        a: "naashpaatuy"
      }]}</Examples>
    <h4 {...{
      "id": "other-useful-items"
    }}>{`Other Useful Items`}</h4>
    <Examples mdxType="Examples">{[{
        p: "شات",
        f: "shaat",
        e: "honey",
        a: "shaat"
      }, {
        p: "مهی",
        f: "mahée",
        e: "fish",
        a: "mahee"
      }, {
        p: "غټه غوښه",
        f: "ghaTa ghwáxa",
        e: "beef",
        a: "ghatta-ghwaxa"
      }, {
        p: "نرۍ غوښه",
        f: "narúy ghwaxa",
        e: "mutton",
        a: "naruy-ghwaxa"
      }, {
        p: "ماسته",
        f: "maastú",
        e: "yogurt",
        a: "maastu"
      }, {
        p: "مالګه",
        f: "máalga",
        e: "salt",
        a: "maalga"
      }]}</Examples>
    <h4 {...{
      "id": "note-3"
    }}>{`NOTE:`}</h4>
    <img src={mangoSeller} style={{
      float: "right",
      marginLeft: "1rem"
    }} />
    <p>{`How is your pronunciation going? Pukhtuns are so pleased you are learning their language that they will seldom correct you. You will have to find other ways to check yourself. One good test is to go to a shop and ask for an item without pointing to it. You know you are doing well when you get what you asked for. If you are having particular trouble with one sound, ask for something with that sound in it.`}</p>
    <p>{`When you can get peas `}<Word w={{
        p: "ټ",
        f: "T",
        a: "tt-sound"
      }} mdxType="Word" />, beef <Word w={{
        p: "غ",
        f: "gh",
        a: "gh-sound"
      }} mdxType="Word" /> and flour <Word w={{
        p: "ړ",
        f: "R",
        a: "rr-sound"
      }} mdxType="Word" />{` without any trouble, you are doing well. The ultimate test is to go into a shop that does `}<em parentName="p">{`not`}</em>{` stock the item you find hard to pronounce. Because the word is out of context you will only be understood if your pronunciation is accurate.`}</p>
    <h2 {...{
      "id": "pronunciation-drill"
    }}>{`Pronunciation Drill`}</h2>
    <h3 {...{
      "id": "contrasting-r---ر-and-r---ړ"
    }}>{`Contrasting r - ر and R - ړ`}</h3>
    <Examples mdxType="Examples">{[{
        p: "دا سُور دے.",
        f: "daa soor dey",
        e: "This is red.",
        a: "da-soor-dey"
      }, {
        p: "دا سوړ دے.",
        f: "daa soR dey.",
        e: "This is cold",
        a: "da-sorr-dey"
      }, {
        p: "هغه غوړ دے.",
        f: "hágha ghwaR dey.",
        e: "That is oily.",
        a: "hagha-ghwarr-dey"
      }, {
        p: "هغه ور دے.",
        f: "hágha war dey.",
        e: "That is a door.",
        a: "hagha-war-dey"
      }, {
        p: "زهٔ لاړم.",
        f: "zu láaRum.",
        e: "I went.",
        a: "zu-laarrum"
      }, {
        p: "زهٔ لرم.",
        f: "zu larúm.",
        e: "I have.",
        a: "zu-larum"
      }, {
        p: "دا زوړ سړے ‌ړُوند دے.",
        f: "daa zoR saRéy Róond dey.",
        e: "This old man is blind.",
        a: "da-zorr-sarrey-rroond-dey"
      }]}</Examples>
    <h2 {...{
      "id": "plural-subject-pronouns"
    }}>{`Plural subject pronouns`}</h2>
    <Table titleRow={["Person", "Pronoun", "Verb"]} bordered mdxType="Table">{[[{
        e: "we"
      }, {
        f: "moonG",
        p: "مُونږ",
        a: "moong"
      }, {
        f: "yoo",
        p: "یو",
        a: "yoo"
      }], [{
        e: "you (plural)"
      }, {
        f: "táaso",
        p: "تاسو",
        a: "taaso"
      }, {
        f: "yeyy",
        p: "یئ",
        a: "yeyy"
      }], [{
        e: "they"
      }, {
        f: "haghwée",
        p: "هغوی",
        a: "haghwee"
      }, {
        p: "دی",
        f: "dee",
        a: "dee"
      }]]}</Table>
    <h2 {...{
      "id": "masculine-plural-nouns"
    }}>{`Masculine plural nouns`}</h2>
    <p>{`Nouns in Pukhto agree by changing their endings. The two mose common masculine plural endings are `}<Word w={{
        p: "ی",
        f: "ee",
        a: "ee-sound"
      }} mdxType="Word" /> and <Word w={{
        p: "ان",
        f: "aan",
        a: "aan"
      }} mdxType="Word" />{`.`}</p>
    <Table titleRow={["singular", "", "plural"]} mdxType="Table">{[[{
        col: true,
        p: "سړے",
        f: "saRéy",
        e: "a man",
        a: "sarrey"
      }, {
        col: true,
        e: "-->",
        p: " ",
        f: " "
      }, {
        col: true,
        p: "سړی",
        f: "saRée",
        e: "men",
        a: "sarree"
      }], [{
        col: true,
        p: "ډاکټر",
        f: "DaakTúr",
        e: "a doctor",
        a: "ddaakttur"
      }, {
        col: true,
        e: "-->",
        p: " ",
        f: " "
      }, {
        col: true,
        p: "ډاکټران",
        f: "DaakTuráan",
        e: "doctors",
        a: "ddaaktturaan"
      }]]}</Table>
    <p>{`Here are some examples:`}</p>
    <Examples mdxType="Examples">{examples.slice(0, 4)}</Examples>
    <h4 {...{
      "id": "note-4"
    }}>{`NOTE:`}</h4>
    <p>{`Nouns can be either singular or plural (number), masculine or feminine (gender), and be one of two basic cases; direct or oblique. Depending on whether they are in the direct or oblique case, nouns undergo a number of inflections which will be introduced later.`}</p>
    <h2 {...{
      "id": "changing-masculine-nouns-to-their-plural-forms"
    }}>{`Changing masculine nouns to their plural forms`}</h2>
    <p>{`👆 Have your helper say the sentence in the singular form. You make it plural.`}</p>
    <h3 {...{
      "id": "endings-in-word-w-p-ے-f-ey-a-ey-sound--change-to-word-w-p-ی-f-ee-a-ee-sound--"
    }}>{`Endings in `}<Word w={{
        p: "ے",
        f: "ey",
        a: "ey-sound"
      }} mdxType="Word" /> change to <Word w={{
        p: "ی",
        f: "ee",
        a: "ee-sound"
      }} mdxType="Word" /></h3>
    <Examples mdxType="Examples">{examples.slice(4, 6)}</Examples>
    <h3 {...{
      "id": "consonant-endings-become-word-w-p-ان-f--aan--"
    }}>{`Consonant endings become `}<Word w={{
        p: "ان",
        f: "aan"
      }} mdxType="Word" /></h3>
    <Examples mdxType="Examples">{examples.slice(6, 12)}</Examples>
    <h3 {...{
      "id": "endings-in-word-w-p-ا-f-aa-a-aa-sound---and-word-w-p-ی-f-ee-a-ee-sound---change-to-word-w-p-ان-f-aan-a-aan--"
    }}>{`Endings in `}<Word w={{
        p: "ا",
        f: "aa",
        a: "aa-sound"
      }} mdxType="Word" /> and <Word w={{
        p: "ی",
        f: "ee",
        a: "ee-sound"
      }} mdxType="Word" /> change to <Word w={{
        p: "ان",
        f: "aan",
        a: "aan"
      }} mdxType="Word" /></h3>
    <Examples mdxType="Examples">{examples.slice(12, 16)}</Examples>
    <h3 {...{
      "id": "certain-nouns-change-to-word-w-p-انهٔ-f-aanu-a-aanu-sound--"
    }}>{`Certain nouns change to `}<Word w={{
        p: "انهٔ",
        f: "aanu",
        a: "aanu-sound"
      }} mdxType="Word" /></h3>
    <Examples mdxType="Examples">{examples.slice(16, 22)}</Examples>
    <h4 {...{
      "id": "note-5"
    }}>{`NOTE:`}</h4>
    <div>
      <p><Word w={{
          p: "درُوند",
          f: "droond",
          a: "droond"
        }} mdxType="Word" /> literally means 'heavy', but also refers to an important or honourable person. In contrast, a worthless person is considered <Word w={{
          p: "سپک",
          f: "spuk",
          a: "spuk"
        }} mdxType="Word" />.</p>
    </div>
    <h2 {...{
      "id": "personal-pronouns"
    }}>{`Personal pronouns`}</h2>
    <Table bordered unset wide titleRow={["Person", "Singular", "Plural"]} mdxType="Table">{[[{
        e: "1st"
      }, {
        p: "زما",
        f: "zmáa",
        e: "my, mine",
        a: "zmaa"
      }, {
        p: "زمُونږ",
        f: "zmoonG",
        e: "our, ours",
        a: "zmoongg"
      }], [{
        e: "2nd"
      }, {
        p: "ستا",
        f: "staa",
        e: "your, yours",
        a: "staa"
      }, {
        p: "ستاسو",
        f: "staaso",
        e: "your, yours",
        a: "staaso"
      }], [{
        e: "3rd masc."
      }, {
        p: "د دهٔ",
        f: "du du",
        e: "his",
        a: "du-du"
      }, {
        p: "د دئ، د دوی",
        f: "du deyy, du dwee",
        e: "their, theirs (near)",
        a: "du-deyy-du-dwee"
      }], [{
        e: "3rd fem."
      }, {
        p: "د دې",
        f: "du de",
        e: "hers",
        a: "du-de"
      }, {
        p: "د دئ، د دوی",
        f: "du deyy, du dwee",
        e: "their, theirs (near)",
        a: "du-deyy-du-dwee"
      }], [{
        e: "3rd masc."
      }, {
        p: "د هغهٔ",
        f: "du haghú",
        e: "his (far)",
        a: "du-haghu"
      }, {
        p: "د هغئ، د هغوی",
        f: "du haghéyy, du haghwée",
        e: "their, theirs (far)",
        a: "du-hagheyy-du-haghwee"
      }], [{
        e: "3rd fem."
      }, {
        p: "د هغې",
        f: "du haghé",
        e: "hers (far)",
        a: "du-haghe"
      }, {
        p: "د هغئ، د هغوی",
        f: "du haghéyy, du haghwée",
        e: "their, theirs (far)",
        a: "du-hagheyy-du-haghwee"
      }]]}</Table>
    <h4 {...{
      "id": "note-6"
    }}>{`NOTE:`}</h4>
    <p>{`The pronouns, `}<Word w={{
        p: "د هغئ",
        f: "du hagheyy",
        a: "du-hagheyy"
      }} mdxType="Word" /> and <Word w={{
        p: "د دئ",
        f: "du deyy",
        a: "du-deyy"
      }} mdxType="Word" /> may be heard colloquially, but the most standard forms are <Word w={{
        p: "د هغوی",
        f: "du haghwee",
        a: "du-haghwee"
      }} mdxType="Word" /> and <Word w={{
        p: "د دوی",
        f: "du dwee",
        a: "du-dwee"
      }} mdxType="Word" />{`. Refer also to the introduction of possessive pronouns in Lesson Seven.`}</p>
    <h2 {...{
      "id": "irregular-family-names"
    }}>{`Irregular family names`}</h2>
    <p>{`Here are four irregular plural forms of kinship terms:`}</p>
    <Examples mdxType="Examples">{examples.slice(22, 31)}</Examples>
    <h2 {...{
      "id": "comprehension-drill-counting"
    }}>{`Comprehension drill: Counting`}</h2>
    <p>{`👆 This is the first drill where you will act like a soldier on parade. Simply obey the instructions without comment. The more vigorously you do so the more easily new words will stick. Teach your helper to slowly add new words according to your success, and keep going back and mixing them up.`}</p>
    <Examples hideEnglish mdxType="Examples">{[{
        p: "یوه کوته ښکاره که!",
        f: "yuwa goota xkaara ka!",
        e: "Show one finger!",
        a: "yuwa-goota-xkaara"
      }, {
        p: "دوه کوتې ښکاره که!",
        f: "dwa goota xkaara ka!",
        e: "Show two fingers!",
        a: "dwa-goote-xkaara"
      }, {
        p: "درې کوتې ښکاره که!",
        f: "dre goota xkaara ka!",
        e: "Show three fingers!",
        a: "dre-goote-xkaara"
      }, {
        p: "لس کوتې ښکاره که!",
        f: "lus goota xkaara ka!",
        e: "Show ten fingers!",
        a: "las-goote-xkaara"
      }]}</Examples>
    <h4 {...{
      "id": "note-7"
    }}>{`NOTE:`}</h4>
    <img src={hand} style={{
      float: "right"
    }} />
    <p>{`Pukhto is a rhyming language. Here the `}<Word w={{
        p: "ه",
        f: "a",
        a: "a-sound"
      }} mdxType="Word" /> ending on <Word w={{
        p: "ګوته",
        f: "góota",
        e: "a finger",
        a: "goota"
      }} mdxType="Word" /> modifies tho <Word w={{
        p: "یو",
        f: "yo",
        e: "one",
        a: "yo"
      }} mdxType="Word" /> to <Word w={{
        p: "یوه",
        f: "yuwa",
        a: "yuwa"
      }} mdxType="Word" />{`. More on this later.`}</p>
    <h2 {...{
      "id": "memorising-moment"
    }}>{`Memorising moment`}</h2>
    <h3 {...{
      "id": "word-w-p-متلونه-f-matulóona-a-matuloona--"
    }}><Word w={{
        p: "متلونه",
        f: "matulóona",
        a: "matuloona"
      }} mdxType="Word" /></h3>
    <Examples memorizingMoment mdxType="Examples">{memorizingMoments[3]}</Examples>
    <p><strong parentName="p">{`Reminder:`}</strong>{` First learn the proverbs and then explore their meanings with your new friends.`}</p>
    <h2 {...{
      "id": "exercises-for-lesson-three"
    }}>{`Exercises for lesson three`}</h2>
    <ol>
      <li parentName="ol">
        <p parentName="li">{`Substitute the words below for the underlined word in the sentence:`}</p>
        <p parentName="li">{`👆 Have your helper say the first sentence and then call out the words to be substituted in. This pattern of practice is best limited to known vocabulary words.`}</p>
        <Examples mdxType="Examples">{[{
            p: "پیاز په ځو دی؟",
            // TODO: Could have replacing f with JSX children ie. https://github.com/iansinnott/react-string-replace/blob/master/index.js
            f: "pyáaz pu tso dee?",
            a: "pyaaz-pu-tso-dee"
          }]}</Examples>
      </li>
    </ol>
    <Examples substitutions mdxType="Examples">{[{
        p: "مالټه",
        f: "maalTá",
        a: "maaltta"
      }, {
        p: "آلُو",
        f: "aalóo",
        a: "aaloo"
      }, {
        p: "مرچ / مرچکے",
        f: "mruch / marchakéy",
        a: "mruch-marchakey"
      }, {
        p: "ټماټر",
        f: "TamaaTúr",
        a: "ttamaattur"
      }]}</Examples>
    <ol {...{
      "start": 2
    }}>
      <li parentName="ol">{`Make the following sentences plural: `}</li>
    </ol>
    <Quiz oneBigBlank mdxType="Quiz">{[{
        q: {
          p: "هغه وړُوکے ماشُوم دے.",
          f: "haghá waRóokey maashóom dey."
        },
        answer: [{
          p: "هغوی وړُوکی ماشُومان دی.",
          f: "haghwee waRóokee maashoomáan dee."
        }, {
          p: "هغی وړُوکی ماشُومان دی.",
          f: "haghee waRóokee maashoomáan dee."
        }, {
          p: "هغوی واړهٔ ماشُومان دی.",
          f: "haghwee waaRú maashoomáan dee."
        }, {
          p: "هغی واړهٔ ماشُومان دی.",
          f: "haghee waaRú maashoomáan dee."
        }]
      }, {
        q: {
          p: "زۀ کلک عيسائى يم.",
          f: "zu kluk eesaaee yim."
        },
        answer: [{
          p: "مونږ کلک عیسایان یُو.",
          f: "moonG kluk eesaayaan yoo."
        }]
      }, {
        q: {
          p: "دے ښۀ مېلمه دے.",
          f: "dey xu melmá dey."
        },
        answer: [{
          p: "دوی ښه مېلمانهٔ دی.",
          f: "dwee xu melmaanú dee."
        }]
      }, {
        q: {
          p: "تۀ ډېر ستړے يې.",
          f: "tu Der stúRey ye."
        },
        answer: [{
          p: "تاسو ډېر ستړی یئ.",
          f: "taaso Der stuRee yeyy."
        }, {
          p: "تاسو ډېر ستړی یئ.",
          f: "taaso Der stuRee yuy."
        }]
      }, {
        q: {
          p: "زۀ پښتُون يم.",
          f: "zu puxtóon yum."
        },
        answer: [{
          p: "مُونږ پښتانهٔ یو.",
          f: "moonG puxtaanú yoo."
        }]
      }, {
        q: {
          p: "زهٔ وږے یم.",
          f: "zu wúGey yim"
        },
        answer: [{
          p: "مُونږ وږی یو.",
          f: "moonG wúGee yoo."
        }]
      }, {
        q: {
          p: "هغه ښکُلے دے    .",
          f: "haghá xkÚley dey."
        },
        answer: [{
          p: "هغوی ښکُلی دی.",
          f: "haghwée xkÚlee dee."
        }, {
          p: "هغی ښکُلی دی.",
          f: "haghée xkÚlee dee."
        }]
      }]}</Quiz>
    <ol {...{
      "start": 3
    }}>
      <li parentName="ol">{`Use the correct form of the present tense of the verb “to be” to complete each sentence:`}</li>
    </ol>
    <Quiz mdxType="Quiz">{[{
        beg: {
          p: "زۀ پښتُون",
          f: "zu puxtóon"
        },
        end: {
          p: ".",
          f: "."
        },
        answer: [{
          p: "یم",
          f: "yim"
        }, {
          p: "یم",
          f: "yam"
        }, {
          p: "یم",
          f: "yum"
        }]
      }, {
        beg: {
          p: "مُونږ مېلمانهٔ",
          f: "moonG melmaanú"
        },
        end: {
          p: ".",
          f: "."
        },
        answer: [{
          p: "یو",
          f: "yoo"
        }]
      }, {
        beg: {
          p: "تاسو ستړی",
          f: "taaso stúRee"
        },
        end: {
          p: ".",
          f: "."
        },
        answer: [{
          p: "یئ",
          f: "yeyy"
        }, {
          p: "یئ",
          f: "yuy"
        }]
      }, {
        beg: {
          p: "هغه هلک",
          f: "haghá halúk"
        },
        end: {
          p: ".",
          f: "."
        },
        answer: [{
          p: "دے",
          f: "dey"
        }]
      }, {
        beg: {
          p: "مُونږ تږی",
          f: "moonG túGee"
        },
        end: {
          p: ".",
          f: "."
        },
        answer: [{
          p: "یو",
          f: "yoo"
        }]
      }, {
        beg: {
          p: "هغوی قاضیان",
          f: "haghwee qaazeeáan"
        },
        end: {
          p: "؟",
          f: "?"
        },
        answer: [{
          p: "دی",
          f: "dee"
        }]
      }, {
        beg: {
          p: "مُونږ ستړی",
          f: "moonG stúRee"
        },
        end: {
          p: ".",
          f: "."
        },
        answer: [{
          p: "یو",
          f: "yoo"
        }]
      }, {
        beg: {
          p: "هغوی هلکان",
          f: "haghwée halukáan"
        },
        end: {
          p: ".",
          f: "."
        },
        answer: [{
          p: "دی",
          f: "dee"
        }]
      }]}</Quiz>
    <ol {...{
      "start": 4
    }}>
      <li parentName="ol">{`Substitution: Use the words below to change the basic sentence:`}</li>
    </ol>
    <Examples mdxType="Examples">{[{
        p: "زهٔ ډاکټر یم.",
        f: "zu DaakTúr yim.",
        a: "zu-ddakttur-yim"
      }]}</Examples>
    <Examples substitutions mdxType="Examples">{[{
        p: "دے",
        f: "dey",
        a: "dey"
      }, {
        p: "طالبعلم",
        f: "taalibílum",
        a: "taalibilum"
      }, {
        p: "مشهُور",
        f: "mushahóor",
        a: "mushahoor"
      }, {
        p: "ستړی",
        f: "stuRee",
        a: "sturree"
      }, {
        p: "مُونږ",
        f: "moonG",
        a: "moongg"
      }, {
        p: "هغوی",
        f: "haghwée",
        a: "haghwee"
      }, {
        p: "پښتانهٔ",
        f: "puxtaanú",
        a: "puxtaanu"
      }, {
        p: "مسلمانان",
        f: "mUsalmaanáan",
        a: "muusalmaanaan"
      }, {
        p: "ډاکټران",
        f: "DaakTuráan",
        a: "ddaaktturaan"
      }, {
        p: "تاسو",
        f: "taaso",
        a: "taaso"
      }]}</Examples>
    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;
export const tableOfContents = (components={}) => [
  {
    id: "dialogue-three-shopping",
    level: 2,
    title: "Dialogue three: Shopping",
    children: [
        
      ]
  },
{
    id: "ladies-dialogue-making-friends",
    level: 2,
    title: "Ladies’ dialogue: Making friends",
    children: [
        
      ]
  },
{
    id: "numbers-one-to-ten",
    level: 2,
    title: "Numbers one to ten",
    children: [
        
      ]
  },
{
    id: "a-shopping-list",
    level: 2,
    title: "A shopping list",
    children: [
        
      ]
  },
{
    id: "pronunciation-drill",
    level: 2,
    title: "Pronunciation Drill",
    children: [
        {
              id: "contrasting-r---ر-and-r---ړ",
              level: 3,
              title: "Contrasting r - ر and R - ړ",
              children: [
                      
                    ]
            }
      ]
  },
{
    id: "plural-subject-pronouns",
    level: 2,
    title: "Plural subject pronouns",
    children: [
        
      ]
  },
{
    id: "masculine-plural-nouns",
    level: 2,
    title: "Masculine plural nouns",
    children: [
        
      ]
  },
{
    id: "changing-masculine-nouns-to-their-plural-forms",
    level: 2,
    title: "Changing masculine nouns to their plural forms",
    children: [
        {
              id: "endings-in-word-w-p-ے-f-ey-a-ey-sound--change-to-word-w-p-ی-f-ee-a-ee-sound--",
              level: 3,
              title: <React.Fragment>{`Endings in `}<Word w={{ p: "ے", f: "ey", a: "ey-sound" }}/> change to <Word w={{ p: "ی", f: "ee", a: "ee-sound" }} /></React.Fragment>,
              children: [
                      
                    ]
            },
      {
              id: "consonant-endings-become-word-w-p-ان-f--aan--",
              level: 3,
              title: <React.Fragment>{`Consonant endings become `}<Word w={{ p: "ان", f : "aan" }} /></React.Fragment>,
              children: [
                      
                    ]
            },
      {
              id: "endings-in-word-w-p-ا-f-aa-a-aa-sound---and-word-w-p-ی-f-ee-a-ee-sound---change-to-word-w-p-ان-f-aan-a-aan--",
              level: 3,
              title: <React.Fragment>{`Endings in `}<Word w={{ p: "ا", f: "aa", a: "aa-sound" }} /> and <Word w={{ p: "ی", f: "ee", a: "ee-sound" }} /> change to <Word w={{ p: "ان", f: "aan", a: "aan" }} /></React.Fragment>,
              children: [
                      
                    ]
            },
      {
              id: "certain-nouns-change-to-word-w-p-انهٔ-f-aanu-a-aanu-sound--",
              level: 3,
              title: <React.Fragment>{`Certain nouns change to `}<Word w={{ p: "انهٔ", f: "aanu", a: "aanu-sound" }} /></React.Fragment>,
              children: [
                      
                    ]
            }
      ]
  },
{
    id: "personal-pronouns",
    level: 2,
    title: "Personal pronouns",
    children: [
        
      ]
  },
{
    id: "irregular-family-names",
    level: 2,
    title: "Irregular family names",
    children: [
        
      ]
  },
{
    id: "comprehension-drill-counting",
    level: 2,
    title: "Comprehension drill: Counting",
    children: [
        
      ]
  },
{
    id: "memorising-moment",
    level: 2,
    title: "Memorising moment",
    children: [
        {
              id: "word-w-p-متلونه-f-matulóona-a-matuloona--",
              level: 3,
              title: <React.Fragment><Word w={{ p: "متلونه", f: "matulóona", a: "matuloona" }} /></React.Fragment>,
              children: [
                      
                    ]
            }
      ]
  },
{
    id: "exercises-for-lesson-three",
    level: 2,
    title: "Exercises for lesson three",
    children: [
        
      ]
  }
]

export const frontMatter = {
  "title": "Lesson Three"
}

