import React, { useState } from 'react';
import ScrollToTop from './components/scroll-to-top/scroll-to-top.js';
import chapters from './content/content.js';
// eslint-disable-next-line
import { BrowserRouter as Router, Route, withRouter, Switch } from "react-router-dom";
import Backdrop from "./components/backdrop/backdrop.js";
import { CSSTransition } from 'react-transition-group';
import Header from "./components/header/header.js"
import Drawer from "./components/side-drawer/side-drawer.js";
// import Search from "./components/search/search.js";
import Media from 'react-media';
import Chapter from './components/chapter/chapter.js';
import TableOfContentsPage from './components/table-of-contents-page/table-of-contents-page.js';
import Page404 from './components/page-404/page-404.js';
import menuBreakpoint from './etc/menuBreakpoint.js';
// Uncomment this line to make and download a prebuilt search index for lunr
// Then take that file "search-index.js" and put it in /src/assets/search
// import { index, store } from './content/make-and-download-index.js';
// import { index, store } from './content/internal-search.js';
import "./assets/fonts/material-icons/material-icons.css";
import "./App.css"

// TODO: Work on SEO section

function App(props) {
  const [drawerIsOpen, setDrawerIsOpen] = useState(false);
  // const [searchResults, setSearchResults] = useState({ results: [] });
  function handleToggle() {
    setDrawerIsOpen(!drawerIsOpen);
  }
  function handleClose() {
    setDrawerIsOpen(false);
  }
  // function search(text) {
  //   const results = index.search(text);
  //   const processedResults = [];
  //   results.forEach((result, i) => {
  //     processedResults.push({ ...store[result.ref], key: i });
  //   });
  //   setSearchResults({ text, results: processedResults });
  // }
  const pathname = props.location.pathname;
  return (
    <ScrollToTop drawerOpen={drawerIsOpen} closeDrawer={handleClose}>
      <Header siteTitle="Speaking Pakistani Pukhto" onToggle={handleToggle} drawerOpen={drawerIsOpen} />
        <div style={{ paddingTop: "2.85rem" }}>
          <Media query={`(max-width: ${menuBreakpoint})`}>
            {matches =>
              matches ? (
                <>
                  <CSSTransition
                    in={drawerIsOpen}
                    timeout={200}
                    classNames="slider-drawer"
                    unmountOnExit
                    onEnter={() => setDrawerIsOpen(true)}
                    onExited={() => setDrawerIsOpen(false)}
                  >
                    <Drawer sections={chapters} withShadow currentPath={props.location.pathname} toggleDrawer={handleToggle} />
                  </CSSTransition>
                  <CSSTransition
                    in={drawerIsOpen}
                    timeout={200}
                    classNames="backdrop-fade"
                    unmountOnExit
                    onEnter={() => setDrawerIsOpen(true)}
                    onExited={() => setDrawerIsOpen(false)}
                  >
                    <Backdrop onClick={handleToggle} currentPath={pathname} />
                  </CSSTransition>
                </>                  
              ) : (
                <Drawer sections={chapters} currentPath={pathname} />
              )
            }
          </Media>
          <Switch>
            <Route path={'/'} exact component={TableOfContentsPage} />
            {/* <Route path={'/search'} exact render={() => <Search search={search} results={searchResults} />} /> */}
              {chapters.map(chapter => (
                <Route key={chapter.slug} path={`/${chapter.slug}/`} render={() => <Chapter chapter={chapter} toggleSidebar={handleToggle} />} />
              ))}
            <Route component={Page404} />
          </Switch>
        </div>
      </ScrollToTop>
  );
}

export default withRouter(App);
