// eslint-disable-next-line
import { BrowserRouter as Router, Link } from "react-router-dom";
import React from "react"
import Media from 'react-media';
import "./header.css";
import menuBreakpoint from '../../etc/menuBreakpoint.js';

const Header = ({ siteTitle, onToggle, drawerOpen }) => {
  function handleTitleClick() {
    if (drawerOpen) onToggle();
  }
  return (
    <header>
      <div className="toolbar" style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
        <div>
          <div style={{ display: "flex", alignItems: "center" }}>
            <Media query={`(max-width: ${menuBreakpoint})`}>
              {matches =>
                matches ? (
                  <div style={{ marginRight: "0.25rem" }}>
                    <button className="toggle-button" style={{ height: "100%", width: "100%" }} onClick={onToggle}>
                      <i className="material-icons navigation darker">menu</i>
                    </button>
                  </div>
                ) : (
                  <div></div>
                )
              } 
            </Media>
            <div className="unstyled-link">
              <Link to="/" onClick={handleTitleClick}>
                <div style={{ padding: "0.5rem 1rem 0.5rem 0rem" }}>
                    <h4 style={{ margin: 0, marginLeft: "0.2rem" }}>
                      {siteTitle}
                    </h4>
                </div>
              </Link>
            </div>
          </div>
        </div>
        {/* <div className="unstyled-link" style={{ paddingRight: "1rem" }}>
          <Link to="/search">
            <button className="toggle-button" onClick={handleTitleClick}>
              <i className="material-icons navigation darker md-18">search</i>
            </button>
          </Link>
        </div> */}
      </div>
    </header>
  )
}

Header.defaultProps = {
  siteTitle: ``,
  drawerOpen: false,
};

export default Header;
