import React from "react";
import standardizeF from "../lib/standardize-f";
import PersistentBlank from "./persistent-blank";
import playAudio from "../lib/play-audio";
const wideWidth = "600px";
const mediumWidth = "450px";

const oneTextItemInCell = (cell) => (
    Object.keys(cell).filter((item) => ["p", "f", "e"].includes(item)).length === 1
);

const Table = ({ titleRow, children, bordered, unset, wide, medium, verticalContent, colGroup }) => {
    // probably don't need this scroll availability notification ??
    // let scrollAmount = 0;
    // const [ scrolled, setScrolled ] = useState(false);
    // function handleScroll() {
    //     scrollAmount++
    //     if (scrolled && (scrollAmount > 5)) {
    //         setScrolled(true);
    //     }
    // }

    // const audios = {};
    // const goThroughRowsToMakeAudios = (item) => {
    //     if (Array.isArray(item)) {
    //         item.forEach(it => {
    //             goThroughRowsToMakeAudios(it);
    //         });
    //     } else if (item.a) {
    //         audios[item.a] = createRef();;
    //     }
    // };
    // goThroughRowsToMakeAudios(children);

    const drawCell = (cell, key, inset, insetLast) => {
        if (Array.isArray(cell)) {
            return (
                <td 
                    key={`${key}-a`}
                    className={`${bordered ? "bordered" : ""} inside`}
                >
                    <table className="inside">
                        <tbody>
                            {cell.map((c, i) => (
                                <tr key={i}>
                                    {drawCell(c, `${key}${i}`, true, i === cell.length - 1)}
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </td>
            );
        }
        const oneItem = oneTextItemInCell(cell);
        return (
            <td
                colSpan={cell.span ? cell.span : 1}
                rowSpan={cell.rowSpan ? cell.rowSpan : 1}
                key={key + "c"}
                className={`${bordered ? "bordered" : ""} ${inset ? "inset" : ""} ${insetLast ? "inset-last" : ""}`}
                onClick={() => playAudio(cell.a)}
                style={{ textAlign: cell.blank ? "center" : "inherit"}}
            >   
                {!cell.blank ?
                    <div style={(cell.f && cell.p) ? { display: "flex", justifyContent: oneItem ? "center" : "space-between", textAlign: (cell.col || oneItem || verticalContent || cell.verticalContent) ? "center" : "inherit", flexDirection: (cell.col || verticalContent || cell.verticalContent) ? "column" : "row", alignItems: cell.col ? "center" : "stretch" } : {}}>
                        {cell.f && <div style={{ margin: "0 0.25rem", textAlign: oneItem ? "center" : "inherit" }}>{standardizeF(cell.f)}</div>}
                        {cell.e && <div style={{ margin: "0 0.25rem" }}>{cell.e}</div>}
                        {cell.p && <div style={{ margin: "0 0.25rem", textAlign: oneItem ? "center" : "inherit" }} dir="rtl">{cell.p}</div>}
                    </div>
                :
                    <PersistentBlank store={cell.store} maxWidth="7rem" />
                }
            </td>
        );
    };
    return (
        <div style={{ overflowX: "auto", marginBottom: "1em" }}>
            <table className={bordered ? "bordered" : ""} style={{ tableLayout: unset ? "unset" : "set", minWidth: wide ? wideWidth : medium ? mediumWidth : "inherit" }}>
                {titleRow && <thead>
                    <tr className={bordered ? "bordered" : ""}>
                        {titleRow.map((title, i) => {
                            if (Array.isArray(title)) {
                                return <th key={i} className="sticky-row">{title.map((l) => <div>{l}</div>)}</th>
                            }
                            else if (typeof title === "string") {
                                return <th key={i} className="sticky-row">{title}</th>;
                            }
                            return <th key={i} colSpan={title.span} className="sticky-row">{title.title}</th>;
                        })}
                    </tr>
                </thead>}
                {colGroup && <colgroup>
                    {colGroup.map((colm, i) => (
                        <col key={i} style={colm.style} />
                    ))}
                </colgroup>}
                <tbody>
                    {children.map((row, i) => (
                        <tr key={i}>
                            {row.map((cell, j) => drawCell(cell, `${i}-${j}`))}
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default Table;
