
import React from 'react'
import { mdx } from '@mdx-js/react'
export const readingTime = {"text":"26 min read","minutes":25.065,"time":1503900,"words":5013}
/* @jsx mdx */
import Dialogue from "../components/Dialogue";
import Word from "../components/Word";
import Examples from "../components/Examples";
import Table from "../components/Table";
import Quiz from "../components/Quiz";
import memorizingMoments from "./memorizing-moments";
import jamrud from "../assets/images/jamrud.png";
import palangFamily from "../assets/images/palang-family.png";
import maternalKinship from "../assets/images/maternal-kinship.svg";
import paternalKinship from "../assets/images/paternal-kinship.svg";
import inlawsMalePerspective from "../assets/images/inlaws-male-perspective.svg";
import inlawsFemalePerspective from "../assets/images/inlaws-female-perspective.svg";
import grave from "../assets/images/grave.png";
import brush from "../assets/images/brush.png";

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope")
  return <div {...props}/>
};

const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <h2 {...{
      "id": "dialogue-eighteen-questions-about-relatives"
    }}>{`Dialogue eighteen: Questions about relatives`}</h2>
    <Dialogue audio="dialogue-18" mdxType="Dialogue">{[{
        s: "L",
        p: "هغه دې څهٔ دے؟",
        f: "haghá de tsu dey?",
        e: "What relation is he to you?",
        a: "18d1"
      }, {
        s: "H",
        p: "ترۀ مې دے.",
        f: "tru me dey.",
        e: "He is my uncle.",
        a: "18d2"
      }, {
        s: "L",
        p: "ترۀ څۀ ته وائى؟",
        f: "tru tsu ta waayee?",
        e: "What does uncle mean?",
        a: "18d3"
      }, {
        s: "H",
        p: "ترۀ د پلار ورور ته وائى.",
        f: "tru du plaar wroor ta waayee.",
        e: "Uncle means father's brother.",
        a: "18d4"
      }, {
        s: "L",
        p: "تۀ د هغې څۀ کېږې؟",
        f: "tu du haghé tsu keGe?",
        e: "What are you to her?",
        a: "18d5"
      }, {
        s: "H",
        p: "زۀ د هغې خور يم.",
        f: "zu du haghé khor yum.",
        e: "I am her sister.",
        a: "18d6"
      }, {
        s: "L",
        p: "هغۀ ته څۀ نُوم اخلې؟",
        f: "haghú ta tsu noom aakhle?",
        e: "What do you call him?",
        a: "18d7"
      }, {
        s: "H",
        p: "زۀ هغۀ ته خان جى وايم.",
        f: "zu haghú ta khaan jee waayum.",
        e: "I call him hanji.",
        a: "18d8"
      }, {
        s: "L",
        p: "زۀ ورته څۀ ووايم؟",
        f: "zu wăr-ta tsu oowaayum?",
        e: "What should I call him?",
        a: "18d9"
      }, {
        s: "H",
        p: "تۀ ورته کاکا جى ووايه.",
        f: "tu wăr-ta kaakaa jee oowaaya.",
        e: "Yu should all him 'Uncle Sir'.",
        a: "18d10"
      }, {
        s: "H",
        p: "تربُور مې دے.",
        f: "tarbóor me dey.",
        e: "H's my couin.",
        a: "18d11"
      }, {
        s: "L",
        p: "دا ستا سکه د ترۀ زوئ دے؟",
        f: "daa staa saká du tru zooy dey?",
        e: "Is he your real first cousin?",
        a: "18d12"
      }, {
        s: "H",
        p: "آو، سکه د ترۀ زوئ مې دے.",
        f: "aaw, saká du tru zooy me dey.",
        e: "Yes, he is my real first cousin.",
        a: "18d13"
      }, {
        s: "L",
        p: "تا نه مشر دے کۀ کشر؟",
        f: "taa na mushur dey ku kushur?",
        e: "Is he older or younger than you?",
        a: "18d14"
      }, {
        s: "H",
        p: "زما همزولے دے.",
        f: "zmaa humzóley dey.",
        e: "He is the same age as me.",
        a: "18d14"
      }]}</Dialogue>
    <h4 {...{
      "id": "note"
    }}>{`NOTE:`}</h4>
    <p>{`Coming to grips with how families and relatives fit together is an important part of understanding Pukhtun culture. A useful introduction to family life is `}<a parentName="p" {...{
        "href": "https://open.bu.edu/handle/2144/3822"
      }}>{`the anthropological study of Swat Pukhtuns by Charles Lindholm`}</a>{`.`}</p>
    <h2 {...{
      "id": "kinship-terms"
    }}>{`Kinship terms`}</h2>
    <h4 {...{
      "id": "paternal-kinship-chart"
    }}>{`Paternal kinship chart`}</h4>
    <img src={paternalKinship} className="full-width-img" />
    <Examples substitutions mdxType="Examples">{[{
        p: "نیکهٔ",
        f: "neekú",
        e: "grandfather",
        a: "neeku"
      }, {
        p: "نیا",
        f: "nyaa",
        e: "grandmother",
        a: "nyaa"
      }, {
        p: "ترهٔ",
        f: "tru",
        e: "uncle",
        a: "tru"
      }, {
        p: "ترور",
        f: "tror",
        e: "paternal aunt",
        a: "tror"
      }, {
        p: "پلار",
        f: "plaar",
        e: "father",
        a: "plaar"
      }, {
        p: "نرهٔ زوئ",
        f: "tru zooy",
        e: "uncle's son",
        a: "tru-zooy"
      }, {
        p: "ترور زوئ",
        f: "tror zooy",
        e: "aunt's son",
        a: "tror-zooy"
      }, {
        p: "ترهٔ لُور",
        f: "tru loor",
        e: "uncle's daughter",
        a: "tru-loor"
      }, {
        p: "ترور لُور",
        f: "tror loor",
        e: "aunt's daughter",
        a: "tror-loor"
      }, {
        p: "خوریے",
        f: "khora`éy",
        e: "sister's son",
        a: "khorayey"
      }, {
        p: "ورېره",
        f: "wrerá",
        e: "brother's daughter",
        a: "wrera"
      }, {
        p: "ورارهٔ",
        f: "wraarú",
        e: "brother's son",
        a: "wraaru"
      }, {
        p: "ځورځه",
        f: "khordzá",
        e: "sister's daughter",
        a: "khordza"
      }]}</Examples>
    <h4 {...{
      "id": "note-1"
    }}>{`NOTE:`}</h4>
    <p><p><Word w={{
          p: "ترهٔ زوئ",
          f: "tru zooy",
          e: "",
          a: "tru-zooy-f"
        }} mdxType="Word" /> is also called <Word w={{
          p: "تربُور",
          f: "tarbóor",
          e: "",
          a: "tarboor-f"
        }} mdxType="Word" />; and <Word w={{
          p: "ترهٔ لُور",
          f: "tru loor",
          e: "",
          a: "tru-loor-f"
        }} mdxType="Word" /> may be <Word w={{
          p: "ترله",
          f: "turlá",
          e: "",
          a: "turla-f"
        }} mdxType="Word" />{`.`}</p></p>
    <p>{`In learning kinship terms it is a good idea to first ask what relationship someone is to your friend. After the reply see if you can correctly say what the reverse relationship is. The conversation might go like this:`}</p>
    <Examples mdxType="Examples">{[{
        p: "هغه دې څۀ دے؟",
        f: "haghá de tsu dey?",
        e: "What's he to you?",
        a: "18e1"
      }, {
        p: "ترۀ مې دے.",
        f: "tru me dey.",
        e: "He's my uncle.",
        a: "18e2"
      }, {
        p: "نو تۀ د هغۀ ورارۀ ېې؟",
        f: "no tu du haghú wraarú ye?",
        e: "So you're his nephew?",
        a: "18e3"
      }]}</Examples>
    <h4 {...{
      "id": "maternal-kinship-chart"
    }}>{`Maternal Kinship chart`}</h4>
    <img src={maternalKinship} className="full-width-img" />
    <Examples substitutions mdxType="Examples">{[{
        p: "مور",
        f: "mor",
        e: "mother",
        a: "mor-2"
      }, {
        p: "ماما",
        f: "maamáa",
        e: "maternal uncle",
        a: "maamaa"
      }, {
        p: "ماسی",
        f: "maasée",
        e: "maternal aunt",
        a: "maasee"
      }]}</Examples>
    <img src={palangFamily} />
    <h4 {...{
      "id": "kinship-between-inlaws"
    }}>{`Kinship between inlaws`}</h4>
    <h5 {...{
      "id": "you-represents-a-male-in-this-chart"
    }}>{`YOU represents a male in this chart`}</h5>
    <img src={inlawsMalePerspective} className="full-width-img" />
    <Examples substitutions mdxType="Examples">{[{
        p: "سخر",
        f: "skhur",
        e: "father-in-law",
        a: "skhur"
      }, {
        p: "خواښې",
        f: "khwáaxe",
        e: "mother-in-law",
        a: "khwaaxe"
      }, {
        p: "اوښے",
        f: "áawxey / óoxey",
        e: "brother-in-law",
        a: "aawkhey"
      }, {
        p: "ښینې",
        f: "xéene",
        e: "sister-in-law",
        a: "xeene"
      }, {
        p: "ښځه",
        f: "xúdza",
        e: "wife",
        a: "xudza"
      }, // check pronunciation
      {
        p: "نګور",
        f: "ingóor",
        e: "daughter-in-law",
        a: "ingoor"
      }, {
        p: "زُوم",
        f: "zoom",
        e: "son-in-law",
        a: "zoom"
      }, {
        p: "نوسے",
        f: "nwaséy",
        e: "grandson",
        a: "nwasey"
      }, {
        p: "نوسۍ",
        f: "nwasúy",
        e: "granddaughter",
        a: "nwasuy"
      }, {
        p: "سانډُو",
        f: "saanDóo",
        e: "sister-in-law's husband",
        a: "saanddoo"
      }]}</Examples>
    <h5 {...{
      "id": "you-representes-a-femaile-in-this-chart"
    }}>{`YOU representes a femaile in this chart`}</h5>
    <img src={inlawsFemalePerspective} className="full-width-img" />
    <Examples substitutions mdxType="Examples">{[{
        p: "لېور",
        f: "lewúr",
        e: "brother-in-law",
        a: "lewur"
      }, {
        p: "ندرور",
        f: "indrór",
        e: "sister-in-low",
        a: "indror"
      }, {
        p: "خاوند / څښتن / مېړهٔ",
        f: "khaawúnd / tsaxtán / meRú",
        e: "husband",
        a: "khaawund-ya"
      }, {
        p: "یور",
        f: "yor",
        e: "brother-in-law's wife",
        a: "yor"
      }]}</Examples>
    <h4 {...{
      "id": "practicing-kinship-names"
    }}>{`Practicing kinship names`}</h4>
    <p>{`You might be wondering how to learn all these new kinship terms. One idea is to put together a collection of your own family pictures and develop a dialogue about each family member. Be aware that it is not appropriate for men to show pictures of females to Pukhtun men, so some creativity may be needed to avoid offense.`}</p>
    <h2 {...{
      "id": "pronunciation-drill"
    }}>{`Pronunciation drill`}</h2>
    <p>{`Focus on `}<Word w={{
        p: "ق",
        f: "q",
        e: "",
        a: "q-sound"
      }} mdxType="Word" />{` by practicing tongue twisters:`}</p>
    <Examples memorizingMoment mdxType="Examples">{[{
        p: "په قائره کښې عقلمند قات ل د قانُون قابِل قاضى نه قلم وتښتولو.",
        f: "pu qaayrá ke aqulmánd qaatíl du qaanóon qaabíl qaazée na qalúm óotuxtawulo.",
        e: "In Cairo the clever assassin snatched the pen from the clever judge of law.",
        a: "18tt1"
      }, {
        p: "قاضى په قبر کښې هم د قدم، قلم، قسم قيصه کوله.",
        f: "qaazée pu qúbur ke hum de qádum, qalúm, qasúm, qeesá kawúla.",
        e: "Even in the grave the judge was telling the tale of step, pen and oaths.",
        a: "18tt2"
      }]}</Examples>
    <img src={jamrud} />
    <h2 {...{
      "id": "the-past-tense"
    }}>{`The past tense`}</h2>
    <p>{`Before starting the past tense it is helpful to be clear on the distinction between transitive and intransitive verbs. They operate in very different ways. Transitive verbs involve action on an object (he ate an apple, she hit me, etc.). Intransitive verbs have no object (I was walking, he was asleep, etc.). (See Lessons Seven and Eight.)`}</p>
    <h4 {...{
      "id": "the-simple-past-tense-of-the-equative-to-be-verb"
    }}>{`The simple past tense of the equative (’to be’) verb`}</h4>
    <Table bordered unset verticalContent titleRow={["Person", "Singular", "Plural"]} mdxType="Table">{[[{
        e: "1st"
      }, {
        p: "وم",
        f: "wum",
        e: "I was",
        a: "wum"
      }, {
        p: "وُو",
        f: "woo",
        e: "We were",
        a: "woo"
      }], [{
        e: "2nd"
      }, {
        p: "وې",
        f: "we",
        e: "you were",
        a: "we"
      }, {
        p: "وئ",
        f: "weyy",
        e: "you were",
        a: "weyy"
      }], [{
        e: "3rd m."
      }, {
        p: "وو",
        f: "wo",
        e: "he was",
        a: "wo"
      }, {
        p: "وُو",
        f: "woo",
        e: "they were",
        a: "woo-2"
      }], [{
        e: "3rd f."
      }, {
        p: "وه",
        f: "wa",
        e: "she was",
        a: "wa"
      }, {
        p: "وې",
        f: "we",
        e: "they were",
        a: "we-2"
      }]]}</Table>
    <h4 {...{
      "id": "note-2"
    }}>{`NOTE:`}</h4>
    <p>{`The 2nd person singular and 3rd person feminine plural are slightly different in pronunciation. (IS THIS TRUE? RECHECK)`}</p>
    <p>{`👆 Ask your helper to teach you this difference.`}</p>
    <h4 {...{
      "id": "examples-of-the-past-tense-to-be-verb-in-sentences"
    }}>{`Examples of the past tense “to be” verb in sentences`}</h4>
    <Examples mdxType="Examples">{[{
        p: "زۀ ډاکټر وم.",
        f: "zu DakTúr wum.",
        e: "I was a doctor.",
        a: "18e4"
      }, {
        p: "مُونږ ډېر خواږۀ دوستان وُو.",
        f: "moonG Der khaawGu dostáan woo.",
        e: "We were very sweet friends.",
        a: "18e4a"
      }, {
        p: "تۀ ښۀ هلک وې؟",
        f: "tu xu halúk we?",
        e: "Were you a good boy?",
        a: "18e5"
      }, {
        p: "تاسو خفه نۀ وئ؟",
        f: "táaso khufa nu weyy?",
        e: "Weren't you upset?",
        a: "18e6"
      }, {
        p: "هغه ډېر نرے وو.",
        f: "haghá Der naréy wo.",
        e: "He was very skinny.",
        a: "18e7"
      }, {
        p: "دا ناجوړه وه.",
        f: "daa naajóRa wa.",
        e: "She was sick.",
        a: "18e8"
      }, {
        p: "هغه ډېر خوشحاله وو.",
        f: "haghá Der khoshaala wo.",
        e: "He was very happy.",
        a: "18e9"
      }, {
        p: "هغوى ډېرې ښکُلې وې.",
        f: "haghwee Dere xkUle we.",
        e: "They were very pretty. (f.pl.)",
        a: "18e10"
      }, {
        p: "هغه ښۀ فلم نۀ وو.",
        f: "hágha xu filum nu wo.",
        e: "That was not a good film. (m.s.)",
        a: "18e11"
      }, {
        p: "هغه ښۀ کتابونه وُو.",
        f: "hágha xu kitaabóona woo.",
        e: "Those were good books. (m.pl.)",
        a: "18e12"
      }, {
        p: "هغه ښه قيصه وه.",
        f: "hágha xa qeesá wa.",
        e: "That was a good story. (f.s.)",
        a: "18e13"
      }, {
        p: "هغه ښې قيصې وې.",
        f: "hágha xe qeesé we.",
        e: "Those were good stories. (f.pl.)",
        a: "18e14"
      }, {
        p: "رحمان بابا ډېر لوئ شاعر وو.",
        f: "rahmaan baabaa Der looy shaa'ir wo.",
        e: "Rahman Baba was a great poet. (m.s.)",
        a: "18e15"
      }]}</Examples>
    <h4 {...{
      "id": "note-3"
    }}>{`NOTE:`}</h4>
    <p>{`The bookish form of the 3rd person masculine plural is `}<Word w={{
        p: "ول",
        f: "wul",
        e: "",
        a: "wul-f"
      }} mdxType="Word" />{`, but it is not used in normal speech.`}</p>
    <h4 {...{
      "id": "adverbs-of-time-used-in-the-past-tense"
    }}>{`Adverbs of time used in the past tense`}</h4>
    <Examples mdxType="Examples">{[{
        p: "هغه پخوا څنګه کاريګر وو؟",
        f: "haghá pukhwaa tsunga kaareegúr wo?",
        e: "What kind of worker was he before?",
        a: "18e16"
      }, {
        p: "هغه ډېر مِحنتى وو.",
        f: "haghá Der mihnatée wo.",
        e: "He was very hard working.",
        a: "18e17"
      }, {
        p: "بېګا ستا خور څنګه وه؟",
        f: "begáa staa khor tsunga wa?",
        e: "How was your sister last night?",
        a: "18e18"
      }, {
        p: "تېره مياشت کښې تۀ چرته وې؟",
        f: "téra myaasht ke tu chărta we?",
        e: "Where were you last month?",
        a: "18e19"
      }, {
        p: "زۀ په سوات کښې وم.",
        f: "ze pu swaat ke wum.",
        e: "I was in Swat.",
        a: "18e20"
      }, {
        p: "په تېره هفته کښې ستا زامن چرته وُو؟",
        f: "pu téra haftá ke staa zaamun chărta woo?",
        e: "Where were your sons last week?",
        a: "18e21"
      }, {
        p: "په تېره هفته کښې په هنګُو کښې وُو.",
        f: "pu téra haftá ke pu hangóo ke woo.",
        e: "They were in Hangu last week.",
        a: "18e22"
      }, {
        p: "ښځې پرُون په کور کښې وې؟",
        f: "xúdze paróon pu kor ke we?",
        e: "Were the women at home yesterday?",
        a: "18e23"
      }, {
        p: "نه، هغوى نۀ وې، د ګاونډيانو په کور کښې وې.",
        f: "na, haghwée nu we, du gaawunDiyaano pu kor ke we.",
        e: "No, they were not, they were at the neighbours' house.",
        a: "18e24"
      }, {
        p: "پروسکال ته چرته وې؟",
        f: "parosakaal tu chărta we?",
        e: "Where were you last year?",
        a: "18e25"
      }]}</Examples>
    <h4 {...{
      "id": "note-4"
    }}>{`NOTE:`}</h4>
    <p>{`The adjective `}<Word w={{
        p: "تېر",
        f: "ter",
        e: "",
        a: "ter-f"
      }} mdxType="Word" />{` is used to modify the time words that point to the past and like other adjectives it is inflected for number and gender.`}</p>
    <h2 {...{
      "id": "the-verb-word-w-p-کېدل-f-kedúl-e-to-become-a-kedul---in-the-simple-past-tense"
    }}>{`The verb `}<Word w={{
        p: "کېدل",
        f: "kedúl",
        e: "to become",
        a: "kedul"
      }} mdxType="Word" />{` in the simple past tense`}</h2>
    <p>{`As we saw in earlier lessons, the auxillary verb `}<Word w={{
        p: "کېدل",
        f: "kedul",
        e: "",
        a: "kedul"
      }} mdxType="Word" /> is irregular and it's perfective aspect forms always start with <Word w={{
        p: "شـ",
        f: "sh--",
        e: "",
        a: "sh-sound"
      }} mdxType="Word" />. (They are based on the <em>perfective root</em> of <Word w={{
        p: "کېدل",
        f: "kedúl",
        e: "to become",
        a: "kedul"
      }} mdxType="Word" />, which is <Word w={{
        p: "شول",
        f: "shwul",
        e: "",
        a: "shwul-f"
      }} mdxType="Word" />{`.)`}</p>
    <h4 {...{
      "id": "conjugation-of-the-simple-past-tense-of-word-w-p-کېدل-f-kedul-e-to-become-a-kedul--"
    }}>{`Conjugation of the simple past tense of `}<Word w={{
        p: "کېدل",
        f: "kedul",
        e: "to become",
        a: "kedul"
      }} mdxType="Word" /></h4>
    <Table bordered unset titleRow={["", "singular", "plural"]} mdxType="Table">{[[{
        e: "1st"
      }, {
        p: "شوم",
        f: "shwum",
        e: "",
        a: ""
      }, {
        p: "شُو",
        f: "shoo",
        e: "",
        a: "shoo-m"
      }], [{
        e: "2nd"
      }, {
        p: "شوې",
        f: "shwe",
        e: "",
        a: ""
      }, {
        p: "شُوئ",
        f: "shweyy",
        e: "",
        a: ""
      }], [{
        e: "3rd m."
      }, {
        p: "شو",
        f: "sho",
        e: "",
        a: ""
      }, {
        p: "شُو",
        f: "shoo",
        e: "",
        a: ""
      }], [{
        e: "3rd f."
      }, {
        p: "شوه",
        f: "shwa",
        e: "",
        a: ""
      }, {
        p: "شوې",
        f: "shwe",
        e: "",
        a: ""
      }]]}</Table>
    <h5 {...{
      "id": "note-5"
    }}>{`NOTE:`}</h5>
    <p>{`There is also a bookish form of the 3rd person simple past: `}<Word w={{
        p: "شول",
        f: "shwul",
        e: "",
        a: "shwul-f2"
      }} mdxType="Word" />{`.`}</p>
    <h4 {...{
      "id": "examples-of-the-verb-word-w-p-کېدل-f-kedul-e-to-become-a-kedul---used-in-the-simple-past"
    }}>{`Examples of the verb `}<Word w={{
        p: "کېدل",
        f: "kedul",
        e: "to become",
        a: "kedul"
      }} mdxType="Word" />{` used in the simple past.`}</h4>
    <Examples mdxType="Examples">{[{
        p: "هغوى مُونږ ته غُصه شُو؟",
        f: "haghwée moonG to ghUsa shoo?",
        e: "Did they become angry with us?",
        a: "18e26"
      }, {
        p: "نه، غُصه نۀ شُو.",
        f: "na, ghUsa nu shoo.",
        e: "No they didn't become angry.",
        a: "18e27"
      }, {
        p: "تۀ کله خوږ شوې؟",
        f: "tu kala khoG shwe?",
        e: "When did you get injured?",
        a: "18e28"
      }, {
        p: "زۀ يوه هفته مخکښې خوږ شوم.",
        f: "zu yuwa haftá makhke khoG shwum.",
        e: "I got injured a week ago.",
        a: "18e29"
      }, {
        p: "څۀ وشُو؟ ولې جينکۍ دومره خوشحاله شوې؟",
        f: "tsu óoshoo? wăle jeenakúy doomra khosháala shwe?",
        e: "What happened? Why have the girls become so happy?",
        a: "18e30"
      }, {
        p: "ځکه چې نتيجه ووته او دوئ پاس شوې.",
        f: "dzuka che nateejá óowata aw dwee paas shwe.",
        e: "Because their results came out and they passed.",
        a: "18e31"
      }, {
        p: "ته بېګا څۀ وخت اُودۀ شوې؟",
        f: "tu begáa tsu wakht oodú shwe?",
        e: "When did you go to sleep last night?",
        a: "18e32"
      }, {
        p: "زۀ لس نيمې بجې اُودۀ شوم.",
        f: "zu lus néme bajé oodú shwum.",
        e: "I went to sleep at ten-thirty.",
        a: "18e33"
      }, {
        p: "د خُدائ په فضل سره کار ختم شو.",
        f: "du khUdaay pu fázul sara kaar khatum sho.",
        e: "By the grace of God the job is finished.",
        a: "18e34"
      }]}</Examples>
    <h4 {...{
      "id": "note-6"
    }}>{`NOTE:`}</h4>
    <p>{`If you are British, take care with words like `}<Word w={{
        p: "بېګا",
        f: "begáa",
        e: "",
        a: "begaa-f"
      }} mdxType="Word" /> that end in a long <Word w={{
        p: "ا",
        f: "aa",
        e: "",
        a: "aa-sound"
      }} mdxType="Word" /> sound. It is a common mistake to add an <Word w={{
        p: "ر",
        f: "r",
        e: "",
        a: "r-sound"
      }} mdxType="Word" />{` sound to the end of the word.`}</p>
    <h4 {...{
      "id": "important-note"
    }}>{`IMPORTANT NOTE:`}</h4>
    <p>{`This verb `}<Word w={{
        p: "کېدل",
        f: "kedul",
        e: "to become ",
        a: "kedul"
      }} mdxType="Word" /> is not to be confused with it's twin, <Word w={{
        p: "کېدل",
        f: "kedul",
        e: "to happen",
        a: "kedul"
      }} mdxType="Word" />! These verbs look the same, but the behave differently and mean different things. The perfective forms (like the subjunctive and simple past) of <Word w={{
        p: "کېدل",
        f: "kedul",
        e: "to become",
        a: "kedul"
      }} mdxType="Word" /> always start with <Word w={{
        p: "شـ",
        f: "sh--",
        e: "",
        a: "sh-sound"
      }} mdxType="Word" /> while the perfective forms of <Word w={{
        p: "کېدل",
        f: "kedul",
        e: "to happen",
        a: "kedul"
      }} mdxType="Word" /> always start with <Word w={{
        p: "وشـ",
        f: "oosh--",
        e: "",
        a: ""
      }} mdxType="Word" />{`.`}</p>
    <p>{`Look again at an example from above. The first sentence uses `}<Word w={{
        p: "کېدل",
        f: "kedúl",
        e: "to happen",
        a: "kedul"
      }} mdxType="Word" /> and so the simple past tense starts with <Word w={{
        p: "وشـ",
        f: "oosh--",
        e: "",
        a: ""
      }} mdxType="Word" />. The second sentence uses <Word w={{
        p: "کېدل",
        f: "kedúl",
        e: "to become",
        a: "kedul"
      }} mdxType="Word" /> and the simple past tense starts with <Word w={{
        p: "شـ",
        f: "sh--",
        e: "",
        a: "sh-sound"
      }} mdxType="Word" /></p>
    <Examples mdxType="Examples">{[{
        p: "څۀ وشُو؟ ولې جينکۍ دومره خوشحاله شوې؟",
        f: "tsu óoshoo? wăle jeenakúy doomra khosháala shwe?",
        e: "What happened? Why have the girls become so happy?",
        a: "18e30"
      }]}</Examples>
    <h3 {...{
      "id": "the-passive-voice-in-the-simple-past-tense"
    }}>{`The passive voice in the simple past tense`}</h3>
    <p>{`Earlier we learned that we can put transitive verbs in the passive voice by using `}<Word w={{
        p: "کېدل",
        f: "kedul",
        e: "to become",
        a: "kedul"
      }} mdxType="Word" /> with the infinitive. For example, with the transitive verb <Word w={{
        p: "وینځل",
        f: "weendzul",
        e: "to wash",
        a: "weendzul-m"
      }} mdxType="Word" /></p>
    <Examples mdxType="Examples">{[{
        p: "زه خپل قمیض وینځم.",
        f: "zu khpul qamées weendzum.",
        e: "I am washing my shirt.",
        a: ""
      }, {
        p: "زما قمیص وینځلے کېږی.",
        f: "zmaa qamées weendzuley keGee.",
        e: "My shirt is being washed. (Present passive)",
        a: ""
      }]}</Examples>
    <p>{`To say these kinds of passive sentences in their simple past form, we have to change the `}<Word w={{
        p: "کېدل",
        f: "kedúl",
        e: "",
        a: "kedul"
      }} mdxType="Word" /> to its simple past form, and use the perfective root instead of the infinitive (for regular verbs, add a <Word w={{
        p: "و",
        f: "oo / w",
        e: "",
        a: "oo-sound"
      }} mdxType="Word" />{` to the front of the infinitive).`}</p>
    <Examples mdxType="Examples">{[{
        p: "زما قمیص ووینځلے شو.",
        f: "zmaa qamées óoweendzuley sho.",
        e: "My shirt was washed. (Simple past passive)",
        a: ""
      }]}</Examples>
    <h4 {...{
      "id": "examples-of-the-passive-voice-in-simple-past"
    }}>{`Examples of the passive voice in simple past`}</h4>
    <Examples mdxType="Examples">{[{
        p: "زما قميص نن ووينځلے شو؟",
        f: "zmaa qamáas nun óoweendzuley sho?",
        e: "Was my shirt washed today?",
        a: ""
      }, {
        p: "نه، ونهٔ وینځلے شو.",
        f: "na, oo-nu-weendzuley sho.",
        e: "No, it was not washed.",
        a: "18e32"
      }, {
        p: "خطُونه مو ونۀ لېږلی شُو؟",
        f: "khatóona mU oo-nu-leGulee shoo?",
        e: "Were your letters not sent?",
        a: "18e33"
      }, {
        p: "آو، ولېږلی شُو.",
        f: "aw, óoleGulee shoo.",
        e: "Yes, they were sent.",
        a: "18e34"
      }, {
        p: "ډوډۍ څۀ وخت وخوړلې شوه؟",
        f: "DoDuy tsu wakht óokhoRule shwa?",
        e: "When was the food eaten?",
        a: ""
      }, {
        p: "سحر وختى وخوړلې شوه.",
        f: "sahár wakhtee óokhoRule shwa.",
        e: "It was eaten early this morning.",
        a: "18e36"
      }, {
        p: "زهٔ پرُون ووهلے شوم.",
        f: "zu paróon óowahuley shwum.",
        e: "I (male) was hit yesterday.",
        a: ""
      }, {
        p: "تۀ بېګا وليدے شوې؟",
        f: "tu begáa óoleedey shwe?",
        e: "Were you (male) seen last night?",
        a: "18e38"
      }, {
        p: "نه، ما ډېر اِحتياط کوو.",
        f: "na, maa Der ihtiyáat kawó",
        e: "No, I was very careful. (not passive)",
        a: "18e39"
      }, {
        p: "فلمُونه وکتلی شول؟",
        f: "filmóona ookatulee shwul?",
        e: "Were the films watched?",
        a: "18e48" // passive issue وکتلے شول

      }, {
        p: "نه، بجلى نۀ وه.",
        f: "na, bijlee nu wa.",
        e: "No, there was no electricity.",
        a: "18e49"
      }]}</Examples>
    <h4 {...{
      "id": "note-7"
    }}>{`NOTE:`}</h4>
    <p>{`For all the passive sentences above, `}<em parentName="p">{`the verb agrees with the subject`}</em>{`, because there is no object. Later we will see that when there are active transitive verbs with an object, the verb will agree with the object in the past tense. This is part of what’s called “ergativity.”`}</p>
    <h3 {...{
      "id": "intransitive-compounds-in-the-simple-past-tense"
    }}>{`Intransitive compounds in the simple past tense`}</h3>
    <h4 {...{
      "id": "compounds-with-word-w-p-کېدل-f-kedul-e-to-became-a-kedul---in-the-simple-past-tense"
    }}>{`Compounds with `}<Word w={{
        p: "کېدل",
        f: "kedul",
        e: "to became",
        a: "kedul"
      }} mdxType="Word" />{` in the simple past tense`}</h4>
    <h5 {...{
      "id": "intransitive-compounds-made-with-nouns"
    }}>{`Intransitive compounds made with nouns`}</h5>
    <Examples mdxType="Examples">{[{
        p: "پرُون باران څۀ وخت شروع شو؟",
        f: "paróon baaraan tsu wakht shUróo sho?",
        e: "When did the rain start yesterday?",
        a: "18e40"
      }, {
        p: "تقريباً اتۀ بجې شروع شو.",
        f: "taqreebán aatu baje shUróo sho.",
        e: "It started about eight o'clock.",
        a: "18e41"
      }, {
        p: "قميصُونه مې اِسترى شُو؟",
        f: "qameesóona me istree shoo?",
        e: "Were my shirts ironed?",
        a: "18e42"
      }, {
        p: "نه، اِسترى نۀ شُو.",
        f: "na, istree nu shoo.",
        e: "No, they were not ironed.",
        a: "18e43"
      }, {
        p: "دا پېغله کله وادۀ شوه؟",
        f: "daa peghla kala waadu shwa?",
        e: "When did this girl get married?",
        a: "18e44"
      }, {
        p: "يوه هفته مخکښې وادۀ شوه.",
        f: "yuwa hafta makhke waadú shwa.",
        e: "She got married a week ago.",
        a: "18e45"
      }, {
        p: "کمرې جارُو شوې؟",
        f: "kamre jaaróo shwe?",
        e: "Were the rooms swept?",
        a: "18e46"
      }, {
        p: "آو، سحر جارُو شوې.",
        f: "aw, sahar jaaróo shwe.",
        e: "Yes, they were swept this morning.",
        a: "18e47"
      }]}</Examples>
    <h5 {...{
      "id": "intransitive-compounds-made-with-adjectives"
    }}>{`Intransitive compounds made with adjectives`}</h5>
    <Examples mdxType="Examples">{[{
        p: "ستا موټر کله خرڅ شو؟",
        f: "staa moTúr kala kharts sho?",
        e: "When was your car sold?",
        a: "18ee48"
      }, {
        p: "يوه مياشت مخکښې خرڅ شو.",
        f: "yuwa myaasht makhke kharts sho.",
        e: "It was sold a month earlier.",
        a: "18ee49"
      }, {
        p: "غنم څۀ وخت لامدۀ شُو؟",
        f: "ghunúm tsu wakht laamdú shoo?",
        e: "When did the wheat become wet? (m.pl.)",
        a: "18e50"
      }, {
        p: "بېګا لامدۀ شُو.",
        f: "begáa laamdú shoo.",
        e: "It became wet last night.",
        a: "18e51"
      }, {
        p: "په وړُوکى هلک څۀ وشُو؟",
        f: "pu waRóokee halúk tsu óosho?",
        e: "What happened to the small boy?",
        a: "18e52"
      }]}</Examples>
    <p><p><small>{`Note: The above sentence uses a different word `}<Word w={{
            p: "کېدل",
            f: "kedúl",
            e: "to happen",
            a: ""
          }} mdxType="Word" /> so the simple past tense form looks different and begins with <Word w={{
            p: "وشـ",
            f: "oosh--",
            e: "",
            a: ""
          }} mdxType="Word" />{`.`}</small></p></p>
    <Examples mdxType="Examples">{[{
        p: "د عاجز پښه ما ته شوه.",
        f: "du aajúz xpa maata shwa.",
        e: "The poor boy's foot was broken. (f.s.)",
        a: "18e53"
      }, {
        p: "کُرسۍ دې کله جوړې شوې؟",
        f: "kUrsúy de kala jóRe shwe?",
        e: "When were your chairs made? (f.pl.)",
        a: "18e54"
      }, {
        p: "درې ورځې مخکښې جوړې شوې.",
        f: "drewrudze makhke jóRe shwe.",
        e: "They were made three days ago.",
        a: "18e55"
      }]}</Examples>
    <h4 {...{
      "id": "note-8"
    }}>{`NOTE:`}</h4>
    <p>{`The verbal adjectives are also inflected according to the number and gender of the subject.
See the following examples:`}</p>
    <Table bordered unset verticalContent titleRow={["", "Got Wet", "Got Dry"]} mdxType="Table">{[[{
        e: "m. s."
      }, {
        p: "لوند شو",
        f: "loond sho",
        e: "",
        a: "18c1"
      }, {
        p: "اوچ شو",
        f: "ooch sho",
        e: "",
        a: "18c2"
      }], [{
        e: "m. pl."
      }, {
        p: "لامدهٔ شُو",
        f: "laamdú shoo",
        e: "",
        a: "18c3"
      }, {
        p: "اوچ شُو",
        f: "ooch shoo",
        e: "",
        a: "18c4"
      }], [{
        e: "f. s."
      }, {
        p: "لمده شوه / لنده شوه",
        f: "lamdá shwa, landá shwa",
        e: "",
        a: "18c5"
      }, {
        p: "اوچه شوه",
        f: "oocha shwa",
        e: "",
        a: "18c6"
      }], [{
        e: "f. pl."
      }, {
        p: "لمدې شوې",
        f: "lamdé shwe",
        e: "",
        a: "18c7"
      }, {
        p: "اوچې شوې",
        f: "ooche shwe",
        e: "",
        a: "18c8"
      }]]}</Table>
    <Table bordered unset verticalContent titleRow={["", "Got Cooked/Ripe", "Died (became dead)"]} mdxType="Table">{[[{
        e: "m. s."
      }, {
        p: "بوخ شو",
        f: "pokh sho",
        e: "",
        a: "18c9"
      }, {
        p: "مړ شو",
        f: "muR sho",
        e: "",
        a: "18c10"
      }], [{
        e: "m. pl."
      }, {
        p: "پاخهٔ شُو",
        f: "paakhú shoo",
        e: "",
        a: "18c11"
      }, {
        p: "مړهٔ شُو",
        f: "mRu shoo",
        e: "",
        a: "18c12"
      }], [{
        e: "f. s."
      }, {
        p: "پخه شوه",
        f: "pakhá shwa",
        e: "",
        a: "18c13"
      }, {
        p: "مړه شوه",
        f: "mRa shwa",
        e: "",
        a: "18c14"
      }], [{
        e: "f. pl."
      }, {
        p: "پخې شوې",
        f: "pakhé shwe",
        e: "",
        a: "18c15"
      }, {
        p: "مړې شوې",
        f: "mRe shwe",
        e: "",
        a: "18c16"
      }]]}</Table>
    <Examples mdxType="Examples">{[{
        p: "چرګ څۀ وخت پوخ شو؟",
        f: "churg tsu wakht pokh sho?",
        e: "When was the chicken cooked? (m.s.)",
        a: "18e56"
      }, {
        p: "چرګان څۀ وخت پاخۀ شُو؟",
        f: "churgáan tsu wakht paakhú shoo?",
        e: "When were the chickens cooked? (m.pl.)",
        a: "18e57"
      }, {
        p: "ډوډۍ څۀ وخت پخه شوه؟",
        f: "DoDúy tsu wakht pakhá shwa?",
        e: "When was the bread cooked? (f.s.)",
        a: "18e58"
      }, {
        p: "ورېژې څۀ وخت پخې شوې؟",
        f: "wréjze tsu wakht pakhé shwe?",
        e: "When was the rice cooked? (f.pl.)",
        a: "18e59"
      }, {
        p: "يو سړے نن مړ شو.",
        f: "yo saRey nun muR sho.",
        e: "A man died today. (m.s.)",
        a: "18e60"
      }, {
        p: "درې سړى نن مړۀ شُو.",
        f: "dre saRee nun mRu shoo.",
        e: "Three men died today. (m.pl.)",
        a: "18e61"
      }, {
        p: "يوه ښځه نن مړۀ شوه.",
        f: "yuwa xudza nun mRa shwa.",
        e: "One woman died today. (f.s.)",
        a: "18e62"
      }, {
        p: "درې ښځې نن مړې شوې.",
        f: "dre xudze nun mRe shwe.",
        e: "Three women died today. (f.pl.)",
        a: "18e63"
      }]}</Examples>
    <img src={grave} />
    <h2 {...{
      "id": "comprehension-drill-inquiring-about-the-time"
    }}>{`Comprehension drill: Inquiring about the time`}</h2>
    <Examples mdxType="Examples">{[{
        p: "څو بجې دى؟",
        f: "tso baje dee?",
        e: "What time is it?",
        a: "18e64"
      }, {
        p: "څلور بجې دى.",
        f: "tsalor baje dee.",
        e: "It's four o'clock.",
        a: "18e65"
      }, {
        p: "ستا په ګړۍ کښې څو بجې دى؟",
        f: "staa pu gaRúy ke tso baje dee?",
        e: "What's the time on your watch?",
        a: "18e66"
      }, {
        p: "دولس بجې دى.",
        f: "dólus baje dee.",
        e: "It's twelve o'clock.",
        a: "18e67"
      }, {
        p: "څۀ وخت دے؟",
        f: "tsu wakht dey?",
        e: "What time is it?",
        a: "18e68"
      }, {
        p: "شپږ نيمې بجې دى.",
        f: "shpuG neeme baje deee.",
        e: "It's six thirty.",
        a: "18e69"
      }, {
        p: "څۀ وخت دے؟",
        f: "tsu wakht dey?",
        e: "What time is it?",
        a: "18e70"
      }, {
        p: "پاؤ کم پينځۀ بجې دى.",
        f: "paaw kam peendzu baje dee.",
        e: "It is quarter to five.",
        a: "18e71"
      }, {
        p: "څۀ وخت دے؟",
        f: "tsu wakht dey?",
        e: "What time is it?",
        a: "18e72"
      }, {
        p: "پاؤ باندې دولس بجې دى.",
        f: "paaw baande dolus baje dee.",
        e: "It is a quarter past twelve.",
        a: "18e73"
      }, {
        p: "لس منټه کم څلور بجې دى.",
        f: "lus minTa kam tsalór dee.",
        e: "It is ten minutes to four.",
        a: "18e74"
      }, {
        p: ["شل د پاسه پينځۀ بجې دى. (یا)", "شل منټه د پاسه پينځۀ بجې دى."],
        f: ["shil du paasa peendzu baje dee. (yaa)", "shil minTa du paasa peendzú baje dee."],
        e: "It's twenty past five.",
        a: "18e75"
      }, {
        p: "څۀ وخت دے؟",
        f: "tsu wakht dey?",
        e: "What time is it?",
        a: "18e76"
      }, {
        p: "يوه بجه ده.",
        f: "yuwá baja da.",
        e: "It's one o'clock.",
        a: "18e77"
      }]}</Examples>
    <h4 {...{
      "id": "note-9"
    }}>{`NOTE:`}</h4>
    <p>{`Remember to pay attention to the gender of “one” `}<Word w={{
        p: "یو",
        f: "yo",
        e: "",
        a: "yo-4"
      }} mdxType="Word" /> / <Word w={{
        p: "یوه",
        f: "yuwá",
        e: "",
        a: "yuwa-4"
      }} mdxType="Word" /></p>
    <p>{`👆 Use a clock or watch to practice asking or telling the time with your helper.`}</p>
    <h2 {...{
      "id": "memorising-moment"
    }}>{`Memorising moment`}</h2>
    <h4 {...{
      "id": "mataloona"
    }}>{`mataloona`}</h4>
    <Examples memorizingMoment mdxType="Examples">{memorizingMoments[18]}</Examples>
    <h2 {...{
      "id": "exercises-for-lesson-eighteen"
    }}>{`Exercises for Lesson Eighteen`}</h2>
    <p>{`Change the following present tense questions into their simple past tense forms and answer the questions.`}</p>
    <Quiz mdxType="Quiz">{[{
        q: {
          p: "تۀ چرته پاتې کېږې؟",
          f: "tu chărta paate keGe?"
        },
        answer: [{
          p: "تهٔ چرته پاتې شوې؟",
          f: "tu chărta paate shwe?"
        }]
      }, {
        q: {
          p: "اوس باران شروع کېږى؟",
          f: "oos baaráan shUroo keGee?"
        },
        answer: [{
          p: "اوس باران شروع شو?",
          f: "oos baaráan shUróo sho?"
        }]
      }, {
        q: {
          p: "نن تاسو په دفتر کښې يئ؟",
          f: "nun taaso pu daftur ke yeyy?"
        },
        answer: [{
          p: "نن تاسو په دفتر کښې وئ؟",
          f: "nun taaso pu daftur ke weyy?"
        }]
      }, {
        q: {
          p: "څپلۍ دې خرابې دی؟",
          f: "tsaplúy de kharáabe dee?"
        },
        answer: [{
          p: "څپلۍ دې خرابې وې?",
          f: "tsaplúy de kharaabe we?"
        }]
      }, {
        q: {
          p: "هغه ډېر ښۀ سړے دے؟",
          f: "haghá Der xu saRéy dey?"
        },
        answer: [{
          p: "هغه ډېر ښهٔ سړے وو؟",
          f: "haghá Der xu saRey wo?"
        }, {
          p: "هغه ډېر ښهٔ سړے و؟",
          f: "haghá Der xu saRey wo?"
        }]
      }, {
        q: {
          p: "پلار مې ناجوړه دے؟",
          f: "plaar me naajoRa dey?"
        },
        answer: [{
          p: "پلار مې ناجوړه وو؟",
          f: "plaar me naajoRa wo?"
        }]
      }, {
        q: {
          p: "دغه بالخت شین دے؟",
          f: "dágha balúxt sheen dey?"
        },
        answer: [{
          p: "دغه بالخت شین وو؟",
          f: "dágha balúxt sheen wo?"
        }]
      }, {
        q: {
          p: "هغه سړے وړُوکے دے؟",
          f: "hágha saRey waRookey dey?"
        },
        answer: [{
          p: "هغه سړے وړُوکے وو؟",
          f: "hágha saRey waRookey wo?"
        }]
      }, {
        q: {
          p: "هغه موټر څنګه دے؟",
          f: "hágha moTur tsunga dey?"
        },
        answer: [{
          p: "هغه موټر څنګه وو؟",
          f: "hágha moTur tsunga wo?"
        }]
      }, {
        q: {
          p: "دے يو مالدار مالِک دے؟",
          f: "dey yo maaldaar maalík dey?"
        },
        answer: [{
          p: "دے يو مالدار مالِک وو؟",
          f: "dey yo maaldaar maalík wo?"
        }]
      }, {
        q: {
          p: "زما مور مِهربانه ده؟",
          f: "zmaa mor mirabaana da?"
        },
        answer: [{
          p: "زما مور مِهربانه وه؟",
          f: "zmaa mor mirabaana wa?"
        }]
      }, {
        q: {
          p: "زما خور مشره ده؟",
          f: "zmaa khor mushra da?"
        },
        answer: [{
          p: "زما خور مشره وه؟",
          f: "zmaa khor mushra wa?"
        }]
      }, {
        q: {
          p: "ستا لُور ډېره ښائسته ده؟",
          f: "staa loor Dera xaaysta da?"
        },
        answer: [{
          p: "ستا لُور ډېره ښائسته وه؟",
          f: "staa loor Dera xaaysta wa?"
        }]
      }]}</Quiz>
    <img src={brush} />
    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;
export const tableOfContents = (components={}) => [
  {
    id: "dialogue-eighteen-questions-about-relatives",
    level: 2,
    title: "Dialogue eighteen: Questions about relatives",
    children: [
        
      ]
  },
{
    id: "kinship-terms",
    level: 2,
    title: "Kinship terms",
    children: [
        
      ]
  },
{
    id: "pronunciation-drill",
    level: 2,
    title: "Pronunciation drill",
    children: [
        
      ]
  },
{
    id: "the-past-tense",
    level: 2,
    title: "The past tense",
    children: [
        
      ]
  },
{
    id: "the-verb-word-w-p-کېدل-f-kedúl-e-to-become-a-kedul---in-the-simple-past-tense",
    level: 2,
    title: <React.Fragment>{`The verb `}<Word w={{ p: "کېدل", f: "kedúl", e: "to become", a: "kedul" }} />{` in the simple past tense`}</React.Fragment>,
    children: [
        {
              id: "the-passive-voice-in-the-simple-past-tense",
              level: 3,
              title: "The passive voice in the simple past tense",
              children: [
                      
                    ]
            },
      {
              id: "intransitive-compounds-in-the-simple-past-tense",
              level: 3,
              title: "Intransitive compounds in the simple past tense",
              children: [
                      
                    ]
            }
      ]
  },
{
    id: "comprehension-drill-inquiring-about-the-time",
    level: 2,
    title: "Comprehension drill: Inquiring about the time",
    children: [
        
      ]
  },
{
    id: "memorising-moment",
    level: 2,
    title: "Memorising moment",
    children: [
        
      ]
  },
{
    id: "exercises-for-lesson-eighteen",
    level: 2,
    title: "Exercises for Lesson Eighteen",
    children: [
        
      ]
  }
]

export const frontMatter = {
  "title": "Lesson Eighteen"
}

