import React from 'react';
import Media from 'react-media';
import Link from "../../components/link/link.js";
import menuBreakpoint from '../../etc/menuBreakpoint.js';
import License from "../license/license";

function ChapterFooter({ previous, next, toggleSidebar }) {
    return (
      <>
        <License />
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          {previous ? 
            <div>
              <Link to={previous.path}>
                <i className="material-icons navigation">navigate_before</i>
              </Link>
            </div>
          :
            <div><i className="material-icons navigation" style={{ color: "white" }}>navigate_before</i></div>
          }
          <Media query={`(max-width: ${menuBreakpoint})`}>
            {matches =>
              matches ? (
                <div onClick={toggleSidebar}>
                  <i className="material-icons navigation">menu</i>
                </div>
              ) : (
                <div></div>
              )
            }
          </Media>
          {next ? 
            <div>
              <Link to={next.path}><i className="material-icons navigation">navigate_next</i></Link>
            </div>
          :
            <div><i className="material-icons navigation" style={{ color: "white" }}>navigate_next</i></div>
          }
        </div>
      </>
    )
}

export default ChapterFooter;