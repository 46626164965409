
import React from 'react'
import { mdx } from '@mdx-js/react'
export const readingTime = {"text":"27 min read","minutes":26.505,"time":1590300,"words":5301}
/* @jsx mdx */
import Dialogue from "../components/Dialogue";
import Word from "../components/Word";
import Examples from "../components/Examples";
import memorizingMoments from "./memorizing-moments.jsx";
import Table from "../components/Table";
import Quiz from "../components/Quiz";
import AudioPiece from "../components/AudioPiece";
import PersistentBlank from "../components/persistent-blank";
import childrenSquatting from "../assets/images/children-squatting.PNG";
import duck from "../assets/images/duck.png";
import girlWaving from "../assets/images/girl-waving.png";
import boySitting from "../assets/images/boy-sitting.PNG";

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope")
  return <div {...props}/>
};

const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <img src={childrenSquatting} />
    <h2 {...{
      "id": "dialogue-twenty-two-friendship"
    }}>{`Dialogue twenty-two: Friendship`}</h2>
    <Dialogue audio="dialogue-22" mdxType="Dialogue">{[{
        s: "L",
        p: "نن زۀ د دوستانې مُتعلق خبرې زده کوم.",
        f: "nun zu du dostaané mUtaaliq khabure zda kawum.",
        e: "Today I am learning to talk about friendship.",
        a: "22d1"
      }, {
        s: "H",
        p: "ډېره ښه خبره ده.",
        f: "Dera xa khabura da.",
        e: "That's very good news.",
        a: "22d2"
      }, {
        s: "L",
        p: "ستا دوستان څوک دى؟",
        f: "staa dostáan tsok dee?",
        e: "Who are your friends?",
        a: "22d3"
      }, {
        s: "H",
        p: "زما دوستان زما همزولى دى.",
        f: "zmaa dostáan zmaa humzólee dee.",
        e: "My friends are my peers.",
        a: "22d4"
      }, {
        s: "L",
        p: "ستا دوستان څومره ښۀ دى؟",
        f: "staa dostaan tsomra xu dee?",
        e: "How good are your friends?",
        a: "22d5"
      }, {
        s: "H",
        p: "زما خو ډېر ښۀ دوستان دى.",
        f: "zmaa kho Der xu dostáan dee.",
        e: "My friends are very good.",
        a: "22d6"
      }, {
        s: "L",
        p: "د دوستانې مطلب څۀ دے؟",
        f: "du dostaané matlab tsu dey?",
        e: "What is the meaning of friendship?",
        a: "22d7"
      }, {
        s: "H",
        p: "يو بل سره خلُوص، وفا او ښېګړه ده.",
        f: "yo bul sara khUlóos, wafáa aw xegúRa da.",
        e: "Devotion to one another, faithfulness and help.",
        a: "22d8"
      }, {
        s: "L",
        p: "د دوستانې څۀ اصُول دى؟",
        f: "du dostaane tsu Usóol dee?",
        e: "What are the principles of friendship?",
        a: "22d9"
      }, {
        s: "H",
        p: "چې د دوست د احساساتو خيال ساتى.",
        f: "che du dost du ihsaasáato khyaal saatee.",
        e: "To care for a friend's feelings.",
        a: "22d10"
      }, {
        s: "L",
        p: "کۀ ستا دوست ستا نه خفه شى تۀ به څۀ کوې؟",
        f: "ku staa dost staa na khufa shee, tu ba tsu kawe?",
        e: "If your friend is angry with you, what will you do?",
        a: "22d11"
      }, {
        s: "H",
        p: "زۀ به يې رضا کوم.",
        f: "zu ba ye razáa kawum.",
        e: "I would reconcile with him (lit. 'reconcile him').",
        a: "22d12"
      }]}</Dialogue>
    <h2 {...{
      "id": "rhythm-and-stress"
    }}>{`Rhythm and stress`}</h2>
    <p>{`👆 Mimic your helper reading this poem until you sound similar. Pukhto is a musical language with a strong emphasis on rhyme and rhythm. Try to get a feel of these new patterns.`}</p>
    <h4 {...{
      "id": "word-w-p-بطحه-f-báta-e-duck-a---"
    }}><Word w={{
        p: "بطحه",
        f: "báta",
        e: "duck",
        a: ""
      }} mdxType="Word" /></h4>
    <Examples memorizingMoment mdxType="Examples">{[{
        p: ["زۀ کۀ جوړه شوې په کاغذ باندې غلطه يم", "بيا هم په مښ وکه پېژندے شم چې زۀ بطحه يم", "غواړم شپه او ورځ دې په اوبو کښې لامبوزنه وے", "ښکار مې د چينجو کولے دا شانې تورزنه وے", "سيند وى کۀ تالاب وى کۀ وله وى زۀ وردانګمه", "ښۀ تېزه هوا کښې د اوبو په چپو زانګمه.", "ډېر ځلې سيلاب د اوبو راشى ما په مخه کړى", "د خپلو پښو په زور مې قِسمت بيا د چا په برخه کړى", "زۀ طوفان نوح کښې بې خطرې ورګډيدے شمه", "بل سيلاب کۀ راشى زۀ ترې کله تښتېدے شمه", "څوک هم د موجونو د څپيړو تاب راوړے نۀ شى", "زۀ يم چې په دې کښې څوک زمانه برے اوړے نۀ شى", "شکل په صُورت باندې زۀ ډېره مزېداره يم", "دا راکښې غضب دے چې زۀ ډېره وزن داره يم"]
      }]}</Examples>
    <AudioPiece a="22bata" mdxType="AudioPiece" />
    <img src={duck} />
    <h4 {...{
      "id": "note"
    }}>{`NOTE:`}</h4>
    <p>{`There are strong regional differences in the way in which `}<Word w={{
        p: "بطحه",
        f: "bata",
        e: "duck",
        a: "bata-f"
      }} mdxType="Word" /> is spelt. <Word w={{
        p: "بته",
        a: "bata-f"
      }} mdxType="Word" /> and <Word w={{
        p: "بطخه",
        f: "",
        e: "",
        a: "batakha"
      }} mdxType="Word" />{`.`}</p>
    <h2 {...{
      "id": "was-doing-the-past-progressive-tense"
    }}>{`'was doing’: The past progressive tense`}</h2>
    <p>{`The past progressive tense is formed from the past endings added to the infinitive. `}</p>
    <p>{`Study these examples:`}</p>
    <Table unset bordered verticalContent titleRow={["", "Infinitive", "Past progressive"]} mdxType="Table">{[[{
        e: "I was going."
      }, {
        p: "راتلل",
        f: "raatlúl",
        a: "22v1"
      }, {
        p: "زۀ راتلم.",
        f: "zu raatlúm",
        a: "22v2"
      }], [{
        e: "He was dying."
      }, {
        p: "مړ کېدل",
        f: "muR kedúl",
        a: "22v3"
      }, {
        p: "هغه مړ کېدو.",
        f: "haghá muR kedó",
        a: "22v4"
      }], [{
        e: "They were sleeping."
      }, {
        p: "اُودۀ کېدل",
        f: "oodu kedúl",
        a: "22v5"
      }, {
        p: "هغوى اُودۀ کېدل",
        f: "haghwee oodú kedúl",
        a: "22v6"
      }], [{
        e: "Were you walking?"
      }, {
        p: "ګرځېدل",
        f: "gurdzedúl",
        a: "22v7"
      }, {
        p: "تاسو ګرځېدئ؟",
        f: "taaso gurdzedéyy?",
        a: "22v8"
      }], [{
        e: "He was sleeping."
      }, {
        p: "اُودۀ کېدل",
        f: "oodu kedul",
        a: "22v9"
      }, {
        p: "هغه اُودۀ کېدهٔ.",
        f: "haghá oodú kedú",
        a: "22v10"
      }]]}</Table>
    <h4 {...{
      "id": "note-1"
    }}>{`NOTE:`}</h4>
    <p>{`As with the simple past, you can either drop or keep the `}<Word w={{
        p: "ل",
        f: "ul",
        e: "",
        a: ""
      }} mdxType="Word" /> on the end. Often the <Word w={{
        p: "ل",
        f: "ul",
        e: "",
        a: ""
      }} mdxType="Word" />{` is dropped in everyday speech.`}</p>
    <img src={boySitting} />
    <h3 {...{
      "id": "intransitive-verbs-in-the-past-progressive"
    }}>{`Intransitive verbs in the past progressive`}</h3>
    <Examples mdxType="Examples">{[{
        p: "زمُونږ لُور د خوب نه پاڅېده کله چې اور ولګېدو.",
        f: "zmoonG loor du khop na paatsedá kala che or óolugedo.",
        e: "Our daughter was waking up when the fire started.",
        a: "22e1"
      }, {
        p: "زما ښځه اُودۀ کېده کله چې ټېلېفون راغے.",
        f: "zmaa xudza oodú kedá kala che Telefon ráaghey.",
        e: "My wife was going to sleep when the telephone rang.",
        a: "22e2"
      }, {
        p: "هغۀ مړ کېدو کله چې ډاکټر راغے.",
        f: "haghá muR kedó kala che DakTur raaghey.",
        e: "He was dying when the doctor came.",
        a: "22e3"
      }, {
        p: "باران شروع کېدو کله چې دفتر ته ورسېدم.",
        f: "baaráan shUróo kedó kala che dăftur ta óorasedum.",
        e: "It was starting to rain when I reached the office.",
        a: "22e4"
      }, {
        p: "پرُون زما پښې خوږېدې.",
        f: "paróon zmaa xpe khoGede.",
        e: "Yesterday my feet were hurting. (f.pl.)",
        a: "22e5"
      }, {
        p: "زۀ هره ورځ بېمارېدم.",
        f: "zu hára wradz bemaaredúm.",
        e: "I would get sick every day.",
        // need recording because I changed sentence to be past in line with examples
        a: ""
      }]}</Examples>
    <h4 {...{
      "id": "note-2"
    }}>{`NOTE:`}</h4>
    <p>{`Using the sentences below as practice, contrast the simple past tense with the past progressive:`}</p>
    <Examples mdxType="Examples">{[{
        p: "هغه سړے مړ شو.",
        f: "hágha saRey muR sho.",
        e: "That man died.",
        a: "22e7"
      }, {
        p: "هغه سړے مړ کېدو.",
        f: "hágha saRey muR kedó.",
        e: "That man was dying.",
        a: "22e8"
      }, {
        p: "ماشُومان اُودۀ شول؟",
        f: "maashoomáan oodú shwul?",
        e: "Did the children go to sleep?",
        a: "22e9"
      }, {
        p: "ماشُومان اُودۀ کېدل؟",
        f: "maashoomaan oodu kedúl?",
        e: "Were the children going to sleep?",
        a: "22e10"
      }]}</Examples>
    <h3 {...{
      "id": "intransitive-compounds-with-word-w-p-کېدل-f-kedul-e--a----in-the-past-progressive"
    }}>{`Intransitive compounds with `}<Word w={{
        p: "کېدل",
        f: "kedul",
        e: "",
        a: ""
      }} mdxType="Word" />{` in the past progressive`}</h3>
    <Examples mdxType="Examples">{[{
        p: "ستا موټر د خالى په ورځ سمېدهٔ [سمېدو].",
        f: "staa moTúr du khaalee pu wradz sumedu [sumedo].",
        e: "Your car was being repaired on Saturday.",
        a: "22e11"
      }, {
        p: "پرُون زما کمره رنګ کېده.",
        f: "paróon zmaa kamrá rang keda.",
        e: "Yesterday my room was getting painted.",
        a: "22e12"
      }, {
        p: "ډوډۍ پخېدې کله چې ټلۍ ووهلې شوه.",
        f: "DoDúy pakhedé kala che Talúy óowahule shwa.",
        e: "The food was being cooked when the doorbell rang.",
        a: "22e13"
      }]}</Examples>
    <h4 {...{
      "id": "more-intransitive-past-progressive-verbs"
    }}>{`More intransitive past progressive verbs`}</h4>
    <Examples mdxType="Examples">{[{
        p: "تا سره څوک تلو؟",
        f: "taa sara tsok tlo?",
        e: "Who was going with you?",
        a: "22e14"
      }, {
        p: "شاهد ما سره تلو.",
        f: "shaahíd maas sara tlo.",
        e: "Shahid was going with me.",
        a: "22e15"
      }, {
        p: "تاسو کله ګرځېدئ؟",
        f: "taaso kala gurdzedéyy?",
        e: "When were you walking?",
        a: "22e16"
      }, {
        p: "زۀ سحر اتۀ بجې ګرځېدم.",
        f: "zu sahár aatú baje gurdzedúm.",
        e: "I was walking at eight o'clock in the morning.",
        a: "22e17"
      }, {
        p: "تۀ چرته تلې؟",
        f: "tu chărta tle?",
        e: "Where were you going?",
        a: "22e18"
      }, {
        p: "زۀ بازار ته تلم.",
        f: "zu baazáar ta tlum.",
        e: "I was going to the market.",
        a: "22e19"
      }, {
        p: "هغه د دفتر نه راتله.",
        f: "haghá du dăftur na raatlá.",
        e: "She was coming from the office?",
        a: "22e20"
      }, {
        p: "نه، هغه د بازار نه راتله.",
        f: "na, haghá du baazáar na raatlá.",
        e: "No, she was coming from the market.",
        a: "22e21"
      }, {
        p: "څۀ وخت چې ښځې د هوټل نه راوتلې، نو څۀ چل وشو؟",
        f: "tsu wakht che xudze du hoTúl no raawatúle, no tsu chal oosho?",
        e: "When the ladies were coming out of the hotel, what happened?",
        a: "22e22"
      }, {
        p: "څۀ وخت چې هغوى راوتې نو واؤره ورېدل شروع شو.",
        f: "tsu wakht che haghwée raawaté no waawra waredúl shUroo sho.",
        e: "When they were coming out it started to snow.",
        a: "22e23"
      }, {
        p: "زۀ نن سحر په سائيکل سکُول ته راتلم کله چې ...",
        f: "zu nun sahár pu saaykúl skool ta raatlúm, kala che ...",
        e: "This morning I was going to school by cycle, when ...",
        a: "22e24"
      }, {
        p: "څۀ وخت چې هغه د اس نه غورزېدو نو تا وليدو؟",
        f: "tsu wakht che haghá da aas na ghorzedó no taa óoleedo?",
        e: "When he was falling from the horse did you see him?",
        a: "22e25"
      }, {
        p: "آو، ما وليدو.",
        f: "aw, maa óoleedo.",
        e: "Yes, I saw him.",
        a: "22e26"
      }, {
        p: "کله چې زۀ په پېښور کښې اوسېدم، تا زۀ پېژندم؟",
        f: "kala che zu pu pexawúr ke osedúm, taa zu pejzandúm?",
        e: "When I was living in Peshawar did you know me?",
        a: "22e27"
      }, {
        p: "آو، ما تۀ پېژندې.",
        f: "aw, maa tu pejzandé.",
        e: "Yes, I knew (lit. 'was knowing') you.",
        a: "22e28"
      }, {
        p: "چې زۀ راغلم نو تۀ پروت وې؟",
        f: "che zu ráaghlum no tu prot we?",
        e: "When I was coming were you lying down?",
        a: "22e29"
      }, {
        p: "نه، زۀ د خوب نه پاڅېدم.",
        f: "na, zu du khob na patsedúm.",
        e: "No, I was waking up from sleep.",
        a: "22e30"
      }]}</Examples>
    <h3 {...{
      "id": "word-w-p-راتلل-f-raatlúl-e--a----in-the-past-progressive-tense---was-coming"
    }}><Word w={{
        p: "راتلل",
        f: "raatlúl",
        e: "",
        a: ""
      }} mdxType="Word" />{` in the past progressive tense - “was coming”`}</h3>
    <Table unset bordered verticalContent titleRow={["Person", "Singular", "Plural"]} mdxType="Table">{[[{
        e: "1st"
      }, {
        p: "راتلم",
        f: "raatlúm",
        e: "I was coming",
        a: "22v11"
      }, {
        p: "راتلو",
        f: "raatlóo",
        e: "we were coming",
        a: "22v12"
      }], [{
        e: "2nd"
      }, {
        p: "راتلې",
        f: "raatlé",
        e: "you were coming",
        a: "22v13"
      }, {
        p: "راتلئ",
        f: "raatléyy",
        e: "you were coming",
        a: "22v14"
      }], [{
        e: "3rd m."
      }, {
        p: "راتلو",
        f: "raatló",
        e: "he was coming",
        a: "22v15"
      }, {
        p: "راتلل",
        f: "raatlúl",
        e: "they were coming",
        a: "22v16"
      }], [{
        e: "3rd f."
      }, {
        p: "راتله",
        f: "raatlá",
        e: "she was coming",
        a: "22v17"
      }, {
        p: "راتلې",
        f: "raatlé",
        e: "they were coming",
        a: "22v18"
      }]]}</Table>
    <h3 {...{
      "id": "transitive-verbs-in-the-past-progressive"
    }}>{`Transitive verbs in the past progressive`}</h3>
    <p>{`As in the simple past, transitive verbs agree with the object. But whereas we used the `}<em parentName="p">{`perfective stem`}</em>{` for the simple past, we use the `}<em parentName="p">{`infinitive`}</em>{` for the past progressive.`}</p>
    <p>{`The `}<em parentName="p">{`perfective stem`}</em>{` is often made by adding a `}<Word w={{
        p: "و",
        f: "oo",
        e: "",
        a: "oo-sound"
      }} mdxType="Word" />{` prefix. That prefix is not addded to the infinitive, and so we won’t use in with the past progressive. For example:`}</p>
    <Examples mdxType="Examples">{[{
        p: "تاسو څهٔ کول؟",
        f: "táaso tsu kawúl?",
        e: "What things were you doing? (m.pl.)",
        a: "22e31"
      }]}</Examples>
    <p>{`A great many sentences have past progressive followed by simple past:`}</p>
    <Examples mdxType="Examples">{[{
        p: "کله چې هغۀ زما پټے رېبلو ما په ټوپک وويشتو.",
        f: "kala che haghú zmaa paTéy rebúlo maa pu Topák óoweeshto.",
        e: "When he was reaping my field I shot him with the gun.",
        a: "22e32"
      }, {
        p: "ما موټر چلوو کله چې زۀ يې ووهلم.",
        f: "maa moTúr chalawó kala che zu ye óowahulum.",
        e: "I was driving the car when he hit me.",
        a: "22e33"
      }, {
        p: "ما په جُماعت کښې مُونځ کولو کله چې غل زما څپلۍ پټې کړې.",
        f: "maa pu jUmáat ke moondz kawulo che ghul zmaa tsaplúy púTe kRe.",
        e: "I was praying in the mosque when a thief stole my sandals.",
        a: "22e34"
      }]}</Examples>
    <h4 {...{
      "id": "finish-the-story"
    }}>{`Finish the story`}</h4>
    <p>{`👆 Have your teacher say these sentences then use them to start a story in the simple past in any way you like.`}</p>
    <Examples mdxType="Examples">{[{
        p: "کله چې ما پښې وينځلې ...",
        f: "kala che maa xpe weendzúle ...",
        e: "While I was washing my feet ...",
        a: "22e35"
      }, {
        p: "کله چې ما کپړې غوړولې ...",
        f: "kala che maa kapRé ghwaRawúle ...",
        e: "While I was spreading out the clothes ...",
        a: "22e36"
      }, {
        p: "کله چې هغې چائې څښلې ...",
        f: "kala che haghé chaay skúle ...",
        e: "While she was drinking tea ...",
        a: "22e37"
      }]}</Examples>
    <h4 {...{
      "id": "what-were-you-doing-when"
    }}>{`What were you doing when?`}</h4>
    <p>{`It is a useful skill to get people to talk and expand on what they are saying as they tell a story. A good question can trigger a whole conversation. Try some of these phrases when your friends are relating a past event:`}</p>
    <Examples mdxType="Examples">{[{
        p: "تاسو څۀ کول؟",
        f: "táaso tsu kawul?",
        e: "What were you doing?",
        a: "22e38"
      }, {
        p: "هغه کله زخمى شو؟",
        f: "haghá kala zakhmee sho?",
        e: "When was he wounded?",
        a: "22e39"
      }, {
        p: "هغه څۀ وخت خبره کړے شوه؟",
        f: "haghá tsu wakht khabúra kRey shwa?",
        e: "When was she informed?",
        a: "22e40"
      }, {
        p: "پښو له دې څۀ چل وشو؟",
        f: "xpo la de tsu chal óosho?",
        e: "What happened to your feet?",
        a: "22e41"
      }, {
        p: "تا رېډيو اورېدو؟",
        f: "taa reDyó aawredó?",
        e: "Were you listening to the radio?",
        a: "22e42"
      }, {
        p: "تا بېګا سبق وئیې؟",
        f: "taa begáa sabúq waye?",
        e: "Were you studying last night?",
        a: "22e43"
      }, {
        p: "تا پرُون لامبو وهله؟",
        f: "taa paróon laambó wahúla?",
        e: "Were you swimming yesterday?",
        a: "22e44"
      }, {
        p: "تا د شپې چغې وهلې؟",
        f: "taa du shpe chúghe wahule?",
        e: "Were you screaming in the night?",
        a: "22e45"
      }]}</Examples>
    <p>{`👆 Now get your helper to ask you questions about what you did yesterday.`}</p>
    <h3 {...{
      "id": "compound-verbs-in-the-past-progressive"
    }}>{`Compound verbs in the past progressive`}</h3>
    <Examples mdxType="Examples">{[{
        p: "ستا خور څۀ کول؟",
        f: "staa khor tsu kawul?",
        e: "What was your sister doing?",
        a: "22e46"
      }, {
        p: "زما خور ډوډۍ پخوله.",
        f: "zmaa khor DoDúy pakhawúla.",
        e: "My sister was baking bread. (f.s.)",
        a: "22e47"
      }, {
        p: "هغه سړو څۀ رنګ کول؟",
        f: "hágho saRo tsu rang kawul?",
        e: "What were those men dyeing?",
        a: "22e48"
      }, {
        p: "هغوى زما جامې رنګ کولې.",
        f: "haghwée zmaa jaame rang kawule.",
        e: "They were dyeing my clothes. (f.pl.)",
        a: "22e49"
      }, {
        p: "هغه سړى تا ته کنځل ولې کول؟",
        f: "hágha saRee taa ta kundzul wăle kawul?",
        e: "Why was that man cursing you?",
        a: "22e50"
      }, {
        p: "ځکه چې ما د هغۀ خره ناګا کړې وه.",
        f: "dzuka che maa du haghú khra naagáa kuRe wa.",
        e: "Because I was holding his donkey.",
        a: "22e51"
      }, {
        p: "چې زۀ راغلم نو تۀ اُودۀ وې؟",
        f: "che zu ráaghlum no tu oodú we?",
        e: "When I came were you sleeping?",
        a: "22e52"
      }, {
        p: "څۀ چل وشو چې تا کتاب وې؟",
        f: "tsu chal óosho che taa kitáab we?",
        e: "What happened as you were reading the book?",
        a: "22e53"
      }, {
        p: "کله چې ما کتاب وې نو ورور مې راغے.",
        f: "kala che maa kitáab we no wror me ráaghey.",
        e: "As I was studying, my brother came.",
        a: "22e54"
      }, {
        p: "څۀ وخت چې مې کبډى کوله نو پښه مې ماته شوه.",
        f: "tsu wakht che me kabÚDee kawúla nu xpa me maata shwa.",
        e: "When I was playing Kubudi then my leg [lit. foot] broke.",
        a: "22e55"
      }, {
        p: "څۀ وخت چې غل غلا کوله څوکيدار چرته وو؟",
        f: "tsu wakht che ghul ghlaa kawúla tsokeedáar chărta wo?",
        e: "When the thief was robbing, where was the watchman?",
        a: "22e56"
      }, {
        p: "هغۀ په بازار کښې خوراک کوو.",
        f: "haghú pu baazáar ke khoráak kawó",
        e: "He was in the market eating food.",
        a: "22e57"
      }, {
        p: "تا د دې نه مخکښې پښتو وئيله؟",
        f: "taa du de na makhke puxto wayúla?",
        e: "Were you speaking in Pukhto before this?",
        a: "22e58"
      }, {
        p: "نه، مُونږ پښتو نۀ وئيله.",
        f: "na, moonG puxto nu wayúla.",
        e: "No, we were not speaking Pukhto.",
        a: "22e59"
      }]}</Examples>
    <h4 {...{
      "id": "note-3"
    }}>{`NOTE:`}</h4>
    <p>{`In the past tense the verb `}<Word w={{
        p: "وئیل / وائیل",
        f: "wayul / waayul",
        e: "to say, to read",
        a: ""
      }} mdxType="Word" /> is often shortened in colloquial speech. Although the full form of "I was reading a book" would be <Word w={{
        p: "زهٔ کتاب وئیلو",
        f: "zu kitáab wayúlo",
        e: "",
        a: ""
      }} mdxType="Word" />, people will often just use a quick <Word w={{
        p: "زهٔ کتاب وې",
        f: "zu kitáab we",
        e: "",
        a: ""
      }} mdxType="Word" />{` instead.`}</p>
    <h3 {...{
      "id": "contrasting-simple-and-progressive-past-tenses"
    }}>{`Contrasting simple and progressive past tenses`}</h3>
    <p>{`The difference between the simple past and the progressive past is simple`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`progressive past`}</strong>{`: uses the infinitive`}</li>
      <li parentName="ul"><strong parentName="li">{`simple past`}</strong>{`: uses the perfective root (which often includes a `}<Word w={{
          p: "و",
          f: "oo",
          e: "",
          a: ""
        }} mdxType="Word" />{` prefix)`}</li>
    </ul>
    <Examples mdxType="Examples">{[{
        p: "ما په دفتر کښې کار وکړو.",
        f: "maa pu dăftúr ke kaar ookRo.",
        e: "I worked in the office.",
        a: "22e60"
      }, {
        p: "ما په دفتر کښې کار کولو.",
        f: "maa pu dăftúr ke kaar kawúlo.",
        e: "I was working in an office.",
        a: "22e61"
      }, {
        p: "ماشُومانو يوه لوبه وکړه.",
        f: "maashoomaano yuwa loba óokRa.",
        e: "The children played a game.",
        a: "22e62"
      }, {
        p: "ماشُومانو يوه لوبه کوله.",
        f: "maashoomaano yuwa loba kawúla.",
        e: "The children were playing a game.",
        a: "22e63"
      }, {
        p: "تا په پښتو کښې خبرې وکړې؟",
        f: "taa pu puxtó ke khabure óokRe?",
        e: "Did you speak in Pukhto?",
        a: "22e64"
      }, {
        p: "تا په پښتو کښې خبرې کولې؟",
        f: "taa pu puxto ke khabure kawúle?",
        e: "Were you speaking in Pukhto?",
        a: "22e65"
      }]}</Examples>
    <p>{`When the verb `}<Word w={{
        p: "کول",
        f: "kawul",
        e: "to make ...",
        a: "kawul"
      }} mdxType="Word" /> is used in stative compounds with complements ending in a consonant the initial <Word w={{
        p: "کـ",
        f: "k-",
        e: "",
        a: "k-sound"
      }} mdxType="Word" />{` is often dropped and the verb is fused together with the complement in the infinitive.`}</p>
    <Table bordered unset verticalContent titleRow={["Complement", "Aux. Verb", "Infinitve"]} mdxType="Table">{[[{
        p: "جوړ",
        f: "joR",
        e: "made, well",
        a: ""
      }, {
        p: "کول",
        f: "kawúl",
        e: "to make",
        a: ""
      }, {
        p: "جوړول",
        f: "joRawul",
        e: "to  make",
        a: ""
      }], [{
        p: "پوخ",
        f: "pokh",
        e: "cooked",
        a: ""
      }, {
        p: "کول",
        f: "kawúl",
        e: "to make",
        a: ""
      }, {
        p: "پخول",
        f: "pakhawúl",
        e: "to cook",
        a: ""
      }], [{
        p: "بیان",
        f: "bayaan",
        e: "explanation",
        a: ""
      }, {
        p: "کول",
        f: "kawul",
        e: "to make",
        a: ""
      }, {
        p: "بیانول",
        f: "bayaanawul",
        e: "to explain",
        a: ""
      }]]}</Table>
    <Examples mdxType="Examples">{[{
        p: "ما يو مېز جوړ کړو.",
        f: "maa yo mez joR kRo.",
        e: "I built a desk. (m.s.)",
        a: "22e66"
      }, {
        p: "ما يو مېز جوړولو.",
        f: "maa yo mez joRawúlo.",
        e: "I was building a desk.",
        a: "22e67"
      }, {
        p: "تاسو لرګى ټوټې کړل؟",
        f: "taas largée ToTé kRul?",
        e: "Did you chop the wood? (m.pl.)",
        a: "22e68"
      }, {
        p: "تاسو لرګى ټوټې کول؟",
        f: "taaso largée ToTé kawul?",
        e: "Were you chopping the wood?",
        a: "22e69"
      }, {
        p: "هغې کمره صفا کړه.",
        f: "haghé kamrá safáa kRa.",
        e: "She cleaned the room. (f.s.)",
        a: "22e70"
      }, {
        p: "هغې کمره صفا کوله.",
        f: "haghé kamrá safáa kawula.",
        e: "She was cleaning the room.",
        a: "22e71"
      }, {
        p: "هغۀ کړکۍ بندې کړې.",
        f: "haghú kiRkúy bande kRe.",
        e: "He closed the windows. (f.pl.)",
        a: "22e72"
      }, {
        p: "هغۀ کړکۍ بندولې.",
        f: "haghú kiRkúy bandawúle.",
        e: "He was closing the windows.",
        a: "22e73"
      }]}</Examples>
    <h3 {...{
      "id": "how-to-practice-this-tense"
    }}>{`How to practice this tense?`}</h3>
    <p>{`👆 Memorise a story of something dramatic that happened to you and tell it to your friends.`}</p>
    <p>{`Tell it to your helper, and ask him or her to tell it back to you in the two past tenses we have met so far. Practice these versions with your helper – paying particular attention to the verb endings.`}</p>
    <h2 {...{
      "id": "prepositions-showing-location"
    }}>{`Prepositions showing location`}</h2>
    <ol>
      <li parentName="ol"><Word w={{
          p: "مخامخ",
          f: "mukhaamúkh",
          e: "facing, opposite",
          a: "mukhamukh-f"
        }} mdxType="Word" /></li>
    </ol>
    <Examples mdxType="Examples">{[{
        p: "زما کور هسپتال ته مخامخ دے.",
        f: "zmaa kor haspatáal ta mukhaamúkh dey.",
        e: "My house is opposite the hospital.",
        a: "22e74"
      }, {
        p: "سنيما د بېنک کُوڅې ته مخامخ ده.",
        f: "sineemáa du benk kootsé ta mukhaamúkh da.",
        e: "The cinema is opposite to the street of the bank.",
        a: "22e75"
      }]}</Examples>
    <ol {...{
      "start": 2
    }}>
      <li parentName="ol"><Word w={{
          p: "په خوا کښې",
          f: "pu khwaa ke",
          e: "next to, beside",
          a: "pu-khwaa-ke"
        }} mdxType="Word" /></li>
    </ol>
    <Examples mdxType="Examples">{[{
        p: "زۀ د اور په خوا کښې کښېنم ځکه چې يخنى مې کېږى.",
        f: "zu du or pu khwaa ke kenum dzuka che yukhnee me kéGee.",
        e: "I am sitting beside the fire because I am getting cold.",
        a: "22e76"
      }, {
        p: "د پاسپورټ دفتر د افغان هوټل په خوا کښې دے.",
        f: "du paspórT dăftur du afgháan hoTúl pu khwaa ke dey.",
        e: "The Passport Office is beside the Afghan restaurant.",
        a: "22e77"
      }]}</Examples>
    <h4 {...{
      "id": "note-4"
    }}>{`NOTE:`}</h4>
    <p>{`Confusingly, the English loan word `}<Word w={{
        p: "هوټل",
        f: "hoTúl",
        e: "",
        a: "hottul-f"
      }} mdxType="Word" />{` is mainly used in Pukhto to mean a place to eat, rather than to sleep and is equivalent to 'restaurant’.`}</p>
    <ol {...{
      "start": 3
    }}>
      <li parentName="ol"><Word w={{
          p: "مخې ته",
          f: "mukhe ta",
          e: "in front of",
          a: "mukhe-ta-f"
        }} mdxType="Word" /> and <Word w={{
          p: "مخکښې",
          f: "mukhke",
          e: "in front of",
          a: "mukhke-f"
        }} mdxType="Word" /></li>
    </ol>
    <Examples mdxType="Examples">{[{
        p: "ما پرُون هغه د سبزۍ د دُوکان مخکښې وليدو.",
        f: "maa paróon haghá du sabzúy du dookáan makhke óoleedo.",
        e: "Yesterday I saw him in front of the grocery store.",
        a: "22e78"
      }, {
        p: "زمُونږ د کور مخې ته يو وړُوکے خوړ دے.",
        f: "zmoonG du kor mukhe ta yo waRóokey khwaR dey.",
        e: "In front of our house there is a small stream.",
        a: "22e79"
      }]}</Examples>
    <ol {...{
      "start": 4
    }}>
      <li parentName="ol"><Word w={{
          p: "ساته",
          f: "shaata",
          e: "behind, at the back of, in back of",
          a: "shaata-f"
        }} mdxType="Word" /></li>
    </ol>
    <Examples mdxType="Examples">{[{
        p: "د جمرود سړک زمُونږ د کور شاته تېرېږى.",
        f: "du jamróod saRúk zmoonG du kor sháata teréGee.",
        e: "Jamrud road passes behind our house.",
        a: "22e80"
      }, {
        p: "نن هغه زما شاته ناست وو.",
        f: "nun haghá zmaa shaata naast wo.",
        e: "Today he was sitting behind me.",
        a: "22e81"
      }]}</Examples>
    <ol {...{
      "start": 5
    }}>
      <li parentName="ol"><Word w={{
          p: "جوښت",
          f: "joxt",
          e: "right next to, alongside",
          a: "joxt-f2"
        }} mdxType="Word" /></li>
    </ol>
    <Examples mdxType="Examples">{[{
        p: "زما کور د بلور کور سره جوښت دے.",
        f: "zmaa kor do bilór kor sara joxt dey.",
        e: "My house is next to Bilour's house.",
        a: "22e82"
      }, {
        p: "په مُونځ کښې دومره جوښت اودرېږئ چې اوږې يو بل سره لږى.",
        f: "pu moondz ke doomra joxt oodrégeyy che oogGé yo bul sara lúGee.",
        e: "In prayer stand so close that your shoulders touch.",
        a: "22e83"
      }]}</Examples>
    <ol {...{
      "start": 6
    }}>
      <li parentName="ol"><Word w={{
          p: "پې، پرې",
          f: "pe, pre",
          e: "thereon",
          a: "pre-pe-f"
        }} mdxType="Word" /></li>
    </ol>
    <Examples mdxType="Examples">{[{
        p: "پښه پې مهٔ ږده، ځکه چې ګُناه ده.",
        f: "pxa pe mu Gda, dzuka che gUnáah da.",
        e: "Don't put your foot on it, as that's a sin.",
        a: "22e84"
      }, {
        p: "هغه په کور کښې ناست وو او چت پې را پرېوتو.",
        f: "haghá pu kor ke naast wo aw chut pe raa-prewato.",
        e: "He was sitting in the house and the roof fell on him.",
        a: "22e85"
      }]}</Examples>
    <h4 {...{
      "id": "note-5"
    }}>{`NOTE:`}</h4>
    <p><p><Word w={{
          p: "پې",
          f: "pe",
          e: "",
          a: "pe-f2"
        }} mdxType="Word" /> is an abbreviated/colloquial form of <Word w={{
          p: "پرې",
          f: "pre",
          e: "",
          a: "pre-f2"
        }} mdxType="Word" /></p></p>
    <h2 {...{
      "id": "comprehension-drill-laundry"
    }}>{`Comprehension drill: Laundry`}</h2>
    <Examples mdxType="Examples">{[{
        p: "سپينې او رنګدارې کپړې بېلې کئ!",
        f: "speene aw rangdaare kapRe bele keyy!",
        e: "Separate the white and coloured clothes!",
        a: "22c1"
      }, {
        p: "سپينې کپړې په ګرمو اوبو کښې او رنګدارې په يخو اوبو کښې واچوه.",
        f: "speene kapRé pu garmo oobo ke aw rangdaare pu yukho oobo ke wáachawa.",
        e: "Put the white clothes in hot and the coloured in cold water.",
        a: "22c2"
      }, {
        p: "خو دا نرۍ کپړې په لاسونو ووينځئ.",
        f: "kho daa naRúy kapRe pu laasóono óoweendzeyy.",
        e: "But wash these thin clothes by hand.",
        a: "22c3"
      }, {
        p: "بيا يې په تار وغوړوئ.",
        f: "byaa ye pu taar óoghwaRaweyy.",
        e: "Later spread them on the line.",
        a: "22c4"
      }, {
        p: "چې وچې شى، بيا يې راغونډې کړئ او اِسترى يې کړئ!",
        f: "che Úche shee, byaa ye raaghónDe kReyy aw istrée ye kReyy!",
        e: "When they become dry, collect them and iron them!",
        a: "22c5"
      }]}</Examples>
    <p>{`👆 Add at least five more commands that you have to use often.`}</p>
    <PersistentBlank store="22-more-commands" rows={6} mdxType="PersistentBlank" />
    <img src={girlWaving} style={{
      maxWidth: "10rem"
    }} />
    <h2 {...{
      "id": "memorising-moment"
    }}>{`Memorising moment`}</h2>
    <h4 {...{
      "id": "mataloona"
    }}>{`mataloona`}</h4>
    <Examples memorizingMoment mdxType="Examples">{memorizingMoments[22]}</Examples>
    <h4 {...{
      "id": "note-6"
    }}>{`NOTE:`}</h4>
    <p><p><Word w={{
          p: "پنج",
          f: "panj",
          e: "",
          a: ""
        }} mdxType="Word" /> means ‘five’, but here it is being used idiomatically to mean ‘claws’ <Word w={{
          p: "پنجه",
          f: "panjá",
          e: "",
          a: ""
        }} mdxType="Word" />{`. To give ‘five’ means to come against someone competitively.`}</p></p>
    <h2 {...{
      "id": "exercises-for-lesson-twenty-two"
    }}>{`Exercises for Lesson Twenty-Two`}</h2>
    <ol>
      <li parentName="ol">{`Use the propositions: `}<Word w={{
          p: "مخامخ",
          f: "mukhaamúkh",
          e: "facing, opposite",
          a: ""
        }} mdxType="Word" />; <Word w={{
          p: "مخکښې",
          f: "mákhke",
          e: "in front",
          a: ""
        }} mdxType="Word" />; <Word w={{
          p: "په خوا کښې",
          f: "pu khwaa ke",
          e: "beside",
          a: ""
        }} mdxType="Word" />; and <Word w={{
          p: "شاته",
          f: "sháata",
          e: "behind",
          a: ""
        }} mdxType="Word" />{` in the following sentences.`}</li>
    </ol>
    <Quiz mdxType="Quiz">{[{
        beg: {
          p: "خپل سائيکل زما د کور",
          f: "khpul saaykúl zmaa du kor"
        },
        end: {
          p: "ته مۀ پريږده!",
          f: "mu preGda!"
        },
        answer: [{
          p: "مخکښې",
          f: "makhke"
        }, {
          p: "مخکښې",
          f: "mukhke"
        }]
      }, {
        beg: {
          p: "یوه ښکُلې جینۍ زما",
          f: "yúwa xkÚle jeenúy zmaa"
        },
        end: {
          p: "ناسته ده.",
          f: "náasta da."
        },
        answer: [{
          p: "په خوا کښې",
          f: "pu khwaa ke"
        }, {
          p: "شاته",
          f: "shaata"
        }]
      }, {
        beg: {
          p: "د هغهٔ دُوکان زما د کور",
          f: "du haghú dookáan zmaa du kor"
        },
        end: {
          p: "پروت دے.",
          f: "prot dey."
        },
        answer: [{
          p: "په خوا کښې",
          f: "pu khwaa ke"
        }, {
          p: "شاته",
          f: "shaata"
        }]
      }, {
        beg: {
          p: "زمُونږ د کور",
          f: "zmoonG du kor"
        },
        end: {
          p: "يو وړُوکے لختے دے.",
          f: "yo waRóokey lakhtey dey."
        },
        answer: [{
          p: "په خوا کښې",
          f: "pu khwaa ke"
        }]
      }]}</Quiz>
    <ol {...{
      "start": 2
    }}>
      <li parentName="ol">
        <p parentName="li">{`Say the following sentences in the past progressive tense, and then finish the story.`}</p>
        <p parentName="li">{`a. As he was lying on the ground …`}<br parentName="p"></br>{`
`}{`b. As they approached the mountain pass …`}<br parentName="p"></br>{`
`}{`c. One day Ariq was hunting …`}<br parentName="p"></br>{`
`}{`d. One day he was ploughing his field …`}<br parentName="p"></br>{`
`}{`e. He was going to the village barber for a hair cut …`}<br parentName="p"></br>{`
`}{`f. As she was returning to her village …`}<br parentName="p"></br>{`
`}{`g. One day he was sitting under a shady tree …`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`In the following sentences change the simple past tense verbs to the progressive past.`}</p>
      </li>
    </ol>
    <Quiz mdxType="Quiz">{[{
        q: {
          p: "ملګرې مو تاسو ته څۀ درکړل؟",
          f: "malgúre mU taaso ta tsu dăr-kRul?"
        },
        answer: [{
          p: "ملګرې مو تاسو ته څۀ درکول؟",
          f: "malgúre mU taaso ta tsu dăr-kawul?"
        }]
      }, {
        q: {
          p: "ما هغۀ ته يو خط ولېږو.",
          f: "maa haghú ta yo khat óoleGo."
        },
        answer: [{
          p: "ما هغۀ ته يو خط لېږلو.",
          f: "maa haghú ta yo khat leGúlo."
        }, {
          p: "ما هغۀ ته يو خط لېږو.",
          f: "maa haghú ta yo khat leGó."
        }]
      }, {
        q: {
          p: "مور مې راته خوندناکه ډوډۍ پخه کړه.",
          f: "mor me raa-ta khwandanáaka DoDúy pakhá kRa."
        },
        answer: [{
          p: "مور مې راته خوندناکه ډوډۍ پخوله.",
          f: "mor me raa-ta khwandanáaka DoDúy pakhawúla."
        }, {
          p: "مور مې راته خوندناکه ډوډۍ پخوه.",
          f: "mor me raa-ta khwandanáaka DoDúy pakhawá."
        }]
      }, {
        q: {
          p: "پلار مې ورسره هم لاړو.",
          f: "plaar me wăr-sara hum laaRo."
        },
        answer: [{
          p: "پلار مې ورسره هم تلو.",
          f: "plaar me wăr-sara hum tlo."
        }, {
          p: "پلار مې ورسره هم تللو.",
          f: "plaar me wăr-sara hum tlulo."
        }, {
          p: "پلار مې ورسره هم تلهٔ.",
          f: "plaar me wăr-sara hum tlu."
        }, {
          p: "پلار مې ورسره هم تهٔ.",
          f: "plaar me wăr-sara hum tu."
        }]
      }, {
        q: {
          p: "ورور مې په باغ کښې کار وکړو او ډېر ستړے شو.",
          f: "wror me pu baagh ke kaar ookRo aw Der stúRey sho."
        },
        answer: [{
          p: "ورور مې په باغ کښې کار کولو او ډېر ستړے کېدلو.",
          f: "wror me pu baagh ke kaar kawulo aw Der stúRey kedulo."
        }, {
          p: "ورور مې په باغ کښې کار کاوهٔ او ډېر ستړے کېدو.",
          f: "wror me pu baagh ke kaar kaawu aw Der stúRey kedo."
        }]
      }, {
        q: {
          p: "ما کتاب خلاص کړو کله چې تۀ راغلې.",
          f: "maa kitáab khilaas kRo kala che raaghle."
        },
        answer: [{
          p: "ما کتاب خلاص کړو کله چې تۀ راغلې.",
          f: "maa kitáab khilaas kRo kala che raaghle."
        }, {
          p: "ما کتاب خلاص کولو کله چې تۀ راغلې.",
          f: "maa kitáab khilaas kawulo kala che raaghle."
        }, {
          p: "ما کتاب خلاص کاوهٔ کله چې تۀ راغلې.",
          f: "maa kitáab khilaas kaawu kala che raaghle."
        }]
      }, {
        q: {
          p: "ستا قيصه مې واؤريده او ډېره مې خوښه شوه.",
          f: "staa qeesá me waawreda aw Dera me khwaxa shwa."
        },
        answer: [{
          p: "ستا قيصه مې اورېدله او ډېره مې خوښېدله.",
          f: "staa qeesá me aawredúla aw Dera me khwaxedúla."
        }, {
          p: "ستا قيصه مې اورېده او ډېره مې خوښېده.",
          f: "staa qeesá me aawredá aw Dera me khwaxedá."
        }]
      }, {
        q: {
          p: "ماشُومانو د باغ نه مڼې پټې کړې.",
          f: "maashoomáano du baagh na maNe puTe kRe."
        },
        answer: [{
          p: "ماشُومانو د باغ نه مڼې پټولې.",
          f: "maashoomáano du baagh na maNe puTawúle."
        }, {
          p: "ماشُومانو د باغ نه مڼې پټوې.",
          f: "maashoomáano du baagh na maNe puTawé."
        }]
      }, {
        q: {
          p: "سخت باران وشو او ټولې مېوې خرابې شوې.",
          f: "sakht baaráan óosho aw Tóle mewé kharáabe shwe."
        },
        answer: [{
          p: "سخت باران کېدلو او ټولې مېوې خرابېدلې.",
          f: "sakht baaráan kedúlo aw Tóle mewé kharaabedúle."
        }, {
          p: "سخت باران کېدو او ټولې مېوې خرابېدې.",
          f: "sakht baaráan kedó aw Tóle mewé kharaabedé."
        }]
      }, {
        q: {
          p: "ډېر خفه شوم چې تا ما سره ډوډۍ ونۀ خوړه.",
          f: "Der khufa shwum che taa maa sara DoDúy oo-nu-khoRa."
        },
        answer: [{
          p: "ډېر خفه شوم چې تا ما سره ډوډۍ نهٔ خوړله.",
          f: "Der khufa shwum che taa maa sara DoDúy nu khoRúla."
        }, {
          p: "ډېر خفه شوم چې تا ما سره ډوډۍ نهٔ خوړه.",
          f: "Der khufa shwum che taa maa sara DoDúy nu khoRá."
        }]
      }, {
        q: {
          p: "ما خپل زوئ ته مڼه ورکړه.",
          f: "maa khpul zwee ta maNa wăr-kRa."
        },
        answer: [{
          p: "ما خپل زوئ ته مڼه ورکوله.",
          f: "maa khpul zwee ta maNa wăr-kawúla."
        }, {
          p: "ما خپل زوئ ته مڼه ورکوه.",
          f: "maa khpul zwee ta maNa wăr-kawá."
        }]
      }]}</Quiz>
    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;
export const tableOfContents = (components={}) => [
  {
    id: "dialogue-twenty-two-friendship",
    level: 2,
    title: "Dialogue twenty-two: Friendship",
    children: [
        
      ]
  },
{
    id: "rhythm-and-stress",
    level: 2,
    title: "Rhythm and stress",
    children: [
        
      ]
  },
{
    id: "was-doing-the-past-progressive-tense",
    level: 2,
    title: "'was doing’: The past progressive tense",
    children: [
        {
              id: "intransitive-verbs-in-the-past-progressive",
              level: 3,
              title: "Intransitive verbs in the past progressive",
              children: [
                      
                    ]
            },
      {
              id: "intransitive-compounds-with-word-w-p-کېدل-f-kedul-e--a----in-the-past-progressive",
              level: 3,
              title: <React.Fragment>{`Intransitive compounds with `}<Word w={{ p: "کېدل", f: "kedul", e: "", a: "" }} />{` in the past progressive`}</React.Fragment>,
              children: [
                      
                    ]
            },
      {
              id: "word-w-p-راتلل-f-raatlúl-e--a----in-the-past-progressive-tense---was-coming",
              level: 3,
              title: <React.Fragment><Word w={{ p: "راتلل", f: "raatlúl", e: "", a: "" }} />{` in the past progressive tense - “was coming”`}</React.Fragment>,
              children: [
                      
                    ]
            },
      {
              id: "transitive-verbs-in-the-past-progressive",
              level: 3,
              title: "Transitive verbs in the past progressive",
              children: [
                      
                    ]
            },
      {
              id: "compound-verbs-in-the-past-progressive",
              level: 3,
              title: "Compound verbs in the past progressive",
              children: [
                      
                    ]
            },
      {
              id: "contrasting-simple-and-progressive-past-tenses",
              level: 3,
              title: "Contrasting simple and progressive past tenses",
              children: [
                      
                    ]
            },
      {
              id: "how-to-practice-this-tense",
              level: 3,
              title: "How to practice this tense?",
              children: [
                      
                    ]
            }
      ]
  },
{
    id: "prepositions-showing-location",
    level: 2,
    title: "Prepositions showing location",
    children: [
        
      ]
  },
{
    id: "comprehension-drill-laundry",
    level: 2,
    title: "Comprehension drill: Laundry",
    children: [
        
      ]
  },
{
    id: "memorising-moment",
    level: 2,
    title: "Memorising moment",
    children: [
        
      ]
  },
{
    id: "exercises-for-lesson-twenty-two",
    level: 2,
    title: "Exercises for Lesson Twenty-Two",
    children: [
        
      ]
  }
]

export const frontMatter = {
  "title": "Lesson Twenty-Two"
}

