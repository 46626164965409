import React from "react";
import PersistentBlank from "./persistent-blank";
import Examples from "../components/Examples";

export default ({ children, storeSet }) => (
    <>
        {children.map((q, i) => (
            <div key={q.f} style={{ marginBottom: "1rem" }}>
                <Examples>{[q]}</Examples>
                <div>Positive:</div>
                <PersistentBlank
                    store={`24${storeSet}-${i}-pos`}
                    width="100%"
                /> 
                <div>Negative:</div>
                <PersistentBlank
                    store={`24${storeSet}-${i}-neg`}
                    width="100%"
                /> 
            </div>
        ))}
    </>
);