import React from 'react';
// eslint-disable-next-line
import { BrowserRouter as Router, withRouter } from "react-router-dom";

class ScrollToTop extends React.Component {
    componentDidUpdate(prevProps) {
      if (this.props.location.pathname !== prevProps.location.pathname) {
        if (!this.props.location.hash) {
          window.scrollTo(0, 0);
          if (this.props.drawerOpen) {
            this.props.closeDrawer();
          }
        } 
      }
    }
  
    render() {
      return this.props.children;
    }
}
  
export default withRouter(ScrollToTop);