import React from 'react';
import SEO from '../seo/seo.js';
import Link from "../../components/link/link.js";

function Page404() {
    return (
        <>
            <SEO title="404 Not Found" />
            <div className="main-content">
                <main>
                    <h1>404 Not Found</h1>
                    <p>The link you're looking for is not here... <span role="img" aria-label="shrugging man">🤷‍♂️</span></p>
                    <p>Try looking in the <Link to="/">Table of Contentents</Link>.</p>
                </main>
            </div>
        </>
    )
}

export default Page404