
import React from 'react'
import { mdx } from '@mdx-js/react'
export const readingTime = {"text":"36 min read","minutes":35.595,"time":2135700,"words":7119}
/* @jsx mdx */
import Dialogue from "../components/Dialogue";
import Word from "../components/Word";
import Examples from "../components/Examples";
import Table from "../components/Table";
import Quiz from "../components/Quiz";
import Columns from "../components/Columns";
import memorizingMoments from "./memorizing-moments";
import fruit from "../assets/images/fruit.png";
import busTravellers from "../assets/images/bus-travellers.png";

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope")
  return <div {...props}/>
};

const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <h2 {...{
      "id": "dialogue-nineteen-making-the-most-of-a-monolingual-situation"
    }}>{`Dialogue nineteen: Making the most of a monolingual situation`}</h2>
    <p>{`Language skills will quickly multiply when you use Pukhto to elicit more information. Here are some useful phrases to memorise that will allow you to get help from anyone.`}</p>
    <Dialogue audio="19-dialogue" mdxType="Dialogue">{[{
        s: "L",
        p: "تۀ ما ته د مُختلِفو څيزُونو نُومُونه ښودلے شې؟",
        f: "tu maa ta du mUkhtalífo tseezóono noomóona xoduley she?",
        e: "Can you tell me the names of different things?",
        a: "19d1"
      }, {
        s: "L",
        p: "تۀ به ما ته د يو څيز نُوم وښايې، بيا به زۀ په ګوته ښايم چې تۀ څنګه نُوم واخلې.",
        f: "tu ba maa ta du yo tseez noom óoxaaye, byaa ba zu pu góota xaayim ch tu tsunga noom wáakhle.",
        e: "You tell me the name of something, then I will point to it when you say it.",
        a: "19d2"
      }, {
        s: "L",
        p: "اوس ما ته نوے نُوم وښايه!",
        f: "oos maa ta niwey noom óoxaaya!",
        e: "Now teach me a new name!",
        a: "19d3"
      }, {
        s: "L",
        p: "تۀ ما ته د بدن د اندامُونو نُومُونه ښودلے شې؟",
        f: "tu baa ta du badún andaamóonw noomóona xoduley she?",
        e: "Can you tell me the names of the parts of the body?",
        a: "19d4"
      }, {
        s: "L",
        p: "تۀ ما ته پښتو کښې شمېر ښودلے شې؟",
        f: "tu maa ta puxto ke shmer xoduley she?",
        e: "Can you teach me counting in Pukhto?",
        a: "19d5"
      }, {
        s: "L",
        p: "تۀ شمېر ووايه او زۀ به يې ليکم.",
        f: "tu shmer óowaaya aw zu ba ye leekum.",
        e: "You say numbers and I will write them.",
        a: "19d6"
      }, {
        s: "L",
        p: "تۀ ما ته پښتو کښې هدايت راکولے شې؟",
        f: "tu maa to puxto ke hidaayat raakawuley she?",
        e: "Can you give me some help with Pukhto?",
        a: "19d7"
      }, {
        s: "L",
        p: "تۀ ما ته ووايه او زۀ به يې کوم.",
        f: "tu maa ta óowaaya aw zu ba ye kawum.",
        e: "You tell me and I will do it.",
        a: "19d8"
      }, {
        s: "L",
        p: "ما ته د صحيح پښتو زده کولو د پاره د ډېر زيات ګردان ضرُورت دے.",
        f: "maa ta sahée puxto zda kawulo du paara du Der zyaat gardáan zaroorút dey.",
        e: "To learn correct Pukhto I need a lot of repetition.",
        a: "19d9"
      }]}</Dialogue>
    <h2 {...{
      "id": "pronunciation-drill"
    }}>{`Pronunciation drill`}</h2>
    <p>{`Practice this tongue twister:`}</p>
    <Examples memorizingMoment mdxType="Examples">{[{
        p: "قاصد هره ورځ د قصاب نه قاضى له مُفت قيمه راوړى.",
        f: "qaasád hăra wrudz du qasáab na qaazée la mUft qeemá raawRée.",
        e: "The judge's peon daily brings free mince from the butcher.",
        a: "19tt1"
      }]}</Examples>
    <h2 {...{
      "id": "an-intransitive-verb-in-different-tenses"
    }}>{`An intransitive verb in different tenses`}</h2>
    <h4 {...{
      "id": "from-imperfective-and-perfective-roots"
    }}>{`From imperfective and perfective roots`}</h4>
    <Columns mdxType="Columns">{[<>
        <h4 style={{
          paddingRight: "0.25rem"
        }}>With the Infinitive (Imperfective Root) <Word w={{
            p: "پوهېدل",
            f: "pohedul",
            e: "to understand",
            a: "pohedul-f"
          }} mdxType="Word" /></h4>
    </>, <>
        <h4>With the Perfective Root <Word w={{
            p: "پوه شول",
            f: "poh shwul",
            e: "to understand",
            a: "pooy-shwul-f"
          }} mdxType="Word" /></h4>
    </>]}</Columns>
    <Columns mdxType="Columns">{[<>
        <h5>Present</h5>
        <p>I understand, I am understanding, etc.</p>
    </>, <>
        <h5>Subjunctive</h5>
        <p>(so) I'll understand, should I understand? etc.</p>
    </>]}</Columns>
    <Columns mdxType="Columns">{[<Table bordered unset verticalContent mdxType="Table">{[[{
          e: "1st s."
        }, {
          p: "پوهېږم",
          f: "pohéGum",
          e: "",
          a: "poheggum"
        }], [{
          e: "2st s."
        }, {
          p: "پوهېږې",
          f: "pohéGe",
          e: "",
          a: "pohegge"
        }], [{
          e: "3rd s."
        }, {
          p: "پوهېږی",
          f: "pohéGee",
          e: "",
          a: "poheggee"
        }], [{
          e: "1st pl."
        }, {
          p: "پوهېږو",
          f: "pohéGoo",
          e: "",
          a: "poheggoo"
        }], [{
          e: "2nd pl."
        }, {
          p: "پوهېږئ",
          f: "pohéGeyy",
          e: "",
          a: "poheggeyy"
        }], [{
          e: "3rd pl."
        }, {
          p: "پوهېږی",
          f: "pohéGee",
          e: "",
          a: "poheggee"
        }]]}</Table>, <Table bordered unset verticalContent mdxType="Table">{[[{
          e: "1st s."
        }, {
          p: "پوه شم",
          f: "pooy shum",
          e: "",
          a: ""
        }], [{
          e: "2st s."
        }, {
          p: "پوه شې",
          f: "pooy she",
          e: "",
          a: ""
        }], [{
          e: "3rd s."
        }, {
          p: "پوه شی",
          f: "pooy shee",
          e: "",
          a: ""
        }], [{
          e: "1st pl."
        }, {
          p: "پوه شُو",
          f: "pooy shoo",
          e: "",
          a: ""
        }], [{
          e: "2nd pl."
        }, {
          p: "پوه شئ",
          f: "pooy sheyy",
          e: "",
          a: ""
        }], [{
          e: "3rd pl."
        }, {
          p: "پوه شی",
          f: "pooy shee",
          e: "",
          a: ""
        }]]}</Table>]}</Columns>
    <Columns mdxType="Columns">{[<>
        <h5>Imperfective Future (can be strong)</h5>
    </>, <>
        <h5>Perfective Future (can be weak)</h5>
    </>]}</Columns>
    <Columns mdxType="Columns">{[<Table bordered unset verticalContent mdxType="Table">{[[{
          e: "1st s."
        }, {
          p: "به پوهېږم",
          f: "ba pohéGum",
          e: "",
          a: "19if1"
        }], [{
          e: "2st s."
        }, {
          p: "به پوهېږې",
          f: "ba pohéGe",
          e: "",
          a: "19if2"
        }], [{
          e: "3rd s."
        }, {
          p: "به پوهېږی",
          f: "ba pohéGee",
          e: "",
          a: "19if3"
        }], [{
          e: "1st pl."
        }, {
          p: "به پوهېږو",
          f: "ba pohéGoo",
          e: "",
          a: "19if4"
        }], [{
          e: "2nd pl."
        }, {
          p: "به پوهېږئ",
          f: "ba pohéGeyy",
          e: "",
          a: "19if6"
        }], [{
          e: "3rd pl."
        }, {
          p: "به پوهېږی",
          f: "ba pohéGee",
          e: "",
          a: "19if7"
        }]]}</Table>, <Table bordered unset verticalContent mdxType="Table">{[[{
          e: "1st s."
        }, {
          p: "به پوه شم",
          f: "ba pooy shum",
          e: "",
          a: "19pf1"
        }], [{
          e: "2st s."
        }, {
          p: "به پوه شې",
          f: "ba pooy she",
          e: "",
          a: "19pf2"
        }], [{
          e: "3rd s."
        }, {
          p: "به پوه شی",
          f: "ba pooy shee",
          e: "",
          a: "19pf3"
        }], [{
          e: "1st pl."
        }, {
          p: "به پوه شُو",
          f: "ba pooy shoo",
          e: "",
          a: "19pf4"
        }], [{
          e: "2nd pl."
        }, {
          p: "به پوه شئ",
          f: "ba pooy sheyy",
          e: "",
          a: "19pf5"
        }], [{
          e: "3rd pl."
        }, {
          p: "به پوه شی",
          f: "ba pooy shee",
          e: "",
          a: "19pf6"
        }]]}</Table>]}</Columns>
    <Columns mdxType="Columns">{[<>
        <h5>Imperfective Imperative</h5>
        <p>Understand! (ongoing)</p>
    </>, <>
        <h5>Perfective Imperative</h5>
        <p>Understand! (one moment)</p>
    </>]}</Columns>
    <Columns mdxType="Columns">{[<Table bordered unset verticalContent mdxType="Table">{[[{
          e: "2nd. s."
        }, {
          p: "پوهېږه",
          f: "pohéGa",
          e: "",
          a: ""
        }], [{
          e: "2nd. pl."
        }, {
          p: "پوهېږئ",
          f: "pohéGeyy",
          e: "",
          a: ""
        }]]}</Table>, <Table bordered unset verticalContent mdxType="Table">{[[{
          e: "2nd. s."
        }, {
          p: "پوه شه",
          f: "pooy sha",
          e: "",
          a: "pooy-sha"
        }], [{
          e: "2nd. pl."
        }, {
          p: "پوه شئ",
          f: "pooy sheyy",
          e: "",
          a: "pooy-sheyy"
        }]]}</Table>]}</Columns>
    <Columns mdxType="Columns">{[<>
        <h5>Past progressive/continuous</h5>
        <p style={{
          paddingRight: "0.25rem"
        }}>I was understanding, I would understand, etc.</p>
    </>, <>
        <h5>Simple past</h5>
        <p>I understood, etc.</p>
    </>]}</Columns>
    <Columns mdxType="Columns">{[<Table bordered unset verticalContent mdxType="Table">{[[{
          e: "1st s."
        }, {
          p: "پوهېدلم",
          f: "pohedúlm",
          e: "",
          a: "19ip1"
        }], [{
          e: "2st s."
        }, {
          p: "پوهېدلې",
          f: "pohedúle",
          e: "",
          a: "19ip2"
        }], [{
          e: "3rd m.s."
        }, {
          p: "پوهېدلو",
          f: "pohedúlo",
          e: "",
          a: "19ip3"
        }], [{
          e: "3rd m.f."
        }, {
          p: "پوهېدله",
          f: "pohedúla",
          e: "",
          a: "19ip4"
        }], [{
          e: "1st pl."
        }, {
          p: "پوهېدلُو",
          f: "pohedúloo",
          e: "",
          a: "19ip5"
        }], [{
          e: "2nd pl."
        }, {
          p: "پوهېدلئ",
          f: "pohedúleyy",
          e: "",
          a: "19ip6"
        }], [{
          e: "2nd m.pl."
        }, {
          p: "پوهېدل",
          f: "pohedúl",
          e: "",
          a: "19ip7"
        }], [{
          e: "2nd f.pl."
        }, {
          p: "پوهېدلې",
          f: "pohedúle",
          e: "",
          a: "19ip8"
        }]]}</Table>, <Table bordered unset verticalContent mdxType="Table">{[[{
          e: "1st s."
        }, {
          p: "پوه شوم",
          f: "pooy shwum",
          e: "",
          a: "19pp1"
        }], [{
          e: "2st s."
        }, {
          p: "پوه شوې",
          f: "pooy shwe",
          e: "",
          a: "19pp2"
        }], [{
          e: "3rd m.s."
        }, {
          p: "پوه شی",
          f: "pooy sho",
          e: "",
          a: "19pp3"
        }], [{
          e: "3rd m.f."
        }, {
          p: "پوه شوه",
          f: "pooy shwa",
          e: "",
          a: "19pp4"
        }], [{
          e: "1st pl."
        }, {
          p: "پوه شُو",
          f: "pooy shoo",
          e: "",
          a: "19pp5"
        }], [{
          e: "2nd pl."
        }, {
          p: "پوه شوئ",
          f: "pooy shweyy",
          e: "",
          a: "19pp6"
        }], [{
          e: "3rd m.pl."
        }, {
          p: "پوه شُو",
          f: "pooy shoo",
          e: "",
          a: "19pp7"
        }], [{
          e: "3rd f.pl."
        }, {
          p: "پوه شُولې",
          f: "pooy shwule",
          e: "",
          a: ""
        }]]}</Table>]}</Columns>
    <Columns mdxType="Columns">{[<>
        <h5>Habitual past progressive/continuous</h5>
        <p style={{
          paddingRight: "0.25rem"
        }}>I used to/would be understanding (imperfective event repeated) etc.</p>
    </>, <>
        <h5>Habitual simple past</h5>
        <p>I used to/would understand (simple event repeated), etc.</p>
    </>]}</Columns>
    <Columns mdxType="Columns">{[<Table bordered unset verticalContent mdxType="Table">{[[{
          e: "1st s."
        }, {
          p: "به پوهېدلم",
          f: "ba pohedúlm",
          e: "",
          a: "19hip1"
        }], [{
          e: "2st s."
        }, {
          p: "به پوهېدلې",
          f: "ba pohedúle",
          e: "",
          a: "19hip2"
        }], [{
          e: "3rd m.s."
        }, {
          p: "به پوهېدلو",
          f: "ba pohedúlo",
          e: "",
          a: "19hip3"
        }], [{
          e: "3rd m.f."
        }, {
          p: "به پوهېدله",
          f: "ba pohedúla",
          e: "",
          a: "19hip4"
        }], [{
          e: "1st pl."
        }, {
          p: "به پوهېدلُو",
          f: "ba pohedúloo",
          e: "",
          a: "19hip5"
        }], [{
          e: "2nd pl."
        }, {
          p: "به پوهېدلئ",
          f: "ba pohedúleyy",
          e: "",
          a: "19hip6"
        }], [{
          e: "2nd m.pl."
        }, {
          p: "به پوهېدل",
          f: "ba pohedúl",
          e: "",
          a: "19hip7"
        }], [{
          e: "2nd f.pl."
        }, {
          p: "به پوهېدلې",
          f: "ba pohedúle",
          e: "",
          a: "19hip8"
        }]]}</Table>, <Table bordered unset verticalContent mdxType="Table">{[[{
          e: "1st s."
        }, {
          p: "به پوه شوم",
          f: "ba poh shwum",
          e: "",
          a: ""
        }], [{
          e: "2st s."
        }, {
          p: "به پوه شوې",
          f: "ba poh shwe",
          e: "",
          a: ""
        }], [{
          e: "3rd m.s."
        }, {
          p: "به پوه شی",
          f: "ba poh sho",
          e: "",
          a: ""
        }], [{
          e: "3rd m.f."
        }, {
          p: "به پوه شوه",
          f: "ba poh shwa",
          e: "",
          a: ""
        }], [{
          e: "1st pl."
        }, {
          p: "به پوه شُو",
          f: "ba poh shoo",
          e: "",
          a: ""
        }], [{
          e: "2nd pl."
        }, {
          p: "به پوه شوئ",
          f: "ba poh shweyy",
          e: "",
          a: ""
        }], [{
          e: "3rd m.pl."
        }, {
          p: "به پوه شُو",
          f: "ba poh shoo",
          e: "",
          a: ""
        }], [{
          e: "3rd f.pl."
        }, {
          p: "به پوه شُولې",
          f: "ba poh shwule",
          e: "",
          a: ""
        }]]}</Table>]}</Columns>
    <h4 {...{
      "id": "note"
    }}>{`NOTE:`}</h4>
    <p>{`The continuous past verbs can also be said in a shorter form without the `}<Word w={{
        p: "ل",
        f: "l",
        e: "",
        a: "l-sound"
      }} mdxType="Word" />. For example, you can say either <Word w={{
        p: "پوهېدلم",
        f: "pohedúlum",
        e: "",
        a: "19ip1"
      }} mdxType="Word" /> or <Word w={{
        p: "پوهېدم",
        f: "pohedúm",
        a: ""
      }} mdxType="Word" />{`.`}</p>
    <h4 {...{
      "id": "ability--possibility"
    }}>{`Ability / Possibility`}</h4>
    <h5 {...{
      "id": "present-potential"
    }}>{`Present potential`}</h5>
    <p>{`I can understand, etc.`}</p>
    <Table bordered unset verticalContent mdxType="Table">{[[{
        e: "1st s."
      }, {
        p: "پوهېدے شم",
        f: "pohedéy shum",
        e: "",
        a: "19ab1"
      }], [{
        e: "2nd s."
      }, {
        p: "پوهېدے شې",
        f: "pohedéy she",
        e: "",
        a: "19ab2"
      }], [{
        e: "3rd s."
      }, {
        p: "پوهېدے شی",
        f: "pohedéy shee",
        e: "",
        a: "19ab3"
      }], [{
        e: "1st pl."
      }, {
        p: "پوهېدے شُو",
        f: "pohedéy shoo",
        e: "",
        a: "19ab4"
      }], [{
        e: "2nd pl."
      }, {
        p: "پوهېدے شئ",
        f: "pohedéy sheyy",
        e: "",
        a: "19ab5"
      }], [{
        e: "3rd pl."
      }, {
        p: "پوهېدے شی",
        f: "pohedéy shee",
        e: "",
        a: "19ab6"
      }]]}</Table>
    <h4 {...{
      "id": "from-the-past-participle-word-w-p-پوه-شوے-f-pooy-shíwey-e--a-pooy-shiwey--"
    }}>{`From the Past Participle `}<Word w={{
        p: "پوه شوے",
        f: "pooy shíwey",
        e: "",
        a: "pooy-shiwey"
      }} mdxType="Word" /></h4>
    <h5 {...{
      "id": "past-participle"
    }}>{`Past Participle`}</h5>
    <Table bordered verticalContent unset mdxType="Table">{[[{
        e: "m. s."
      }, {
        p: "پوه شوے",
        f: "pooy shíwey",
        e: "",
        a: "pooy-shiwey"
      }], [{
        e: "f. s."
      }, {
        p: "پوه شوې",
        f: "pooy shíwe",
        e: "",
        a: "pooy-shiwe"
      }], [{
        e: "m. pl."
      }, {
        p: "پوه شوی",
        f: "pooy shíwee",
        e: "",
        a: "pooy-shiwee"
      }], [{
        e: "f. pl."
      }, {
        p: "پوه شوې",
        f: "pooy shíwe",
        e: "",
        a: "pooy-shiwe"
      }]]}</Table>
    <Columns mdxType="Columns">{[<>
        <h5>Present perfect</h5>
        <p>I have understood, etc.</p>
    </>, <>
        <h5>Past perfect</h5>
        <p>I had understood, etc.</p>
    </>]}</Columns>
    <Columns mdxType="Columns">
    <Table bordered verticalContent unset mdxType="Table">{[[{
          e: "1st m. s."
        }, {
          p: "پوه شوے یم",
          f: "pooy shíwey yim",
          e: "",
          a: "19prp1"
        }], [{
          e: "1st f. s."
        }, {
          p: "پوه شوې یم",
          f: "pooy shíwe yim",
          e: "",
          a: "19prp2"
        }], [{
          e: "2nd m. s."
        }, {
          p: "پوه شوے یې",
          f: "pooy shíwey ye",
          e: "",
          a: "19prp3"
        }], [{
          e: "2nd f. s."
        }, {
          p: "پوه شوې یې",
          f: "pooy shíwe ye",
          e: "",
          a: "19prp4"
        }], [{
          e: "3rd m. s."
        }, {
          p: "پوه شوے دے",
          f: "pooy shíwey dey",
          e: "",
          a: "19prp5"
        }], [{
          e: "3rd f. s."
        }, {
          p: "پوه شوې ده",
          f: "pooy shíwe da",
          e: "",
          a: "19prp6"
        }], [{
          e: "1st m. pl."
        }, {
          p: "پوه شوی یو",
          f: "pooy shíwee yoo",
          e: "",
          a: "19prp7"
        }], [{
          e: "1st f. pl."
        }, {
          p: "پوه شوې یو",
          f: "pooy shíwe yoo",
          e: "",
          a: ""
        }], [{
          e: "2nd m. pl."
        }, {
          p: "پوه شوی یئ",
          f: "pooy shíwee yeyy",
          e: "",
          a: "19prp9"
        }], [{
          e: "2nd f. pl."
        }, {
          p: "پوه شوې یئ",
          f: "pooy shíwe yeyy",
          e: "",
          a: ""
        }], [{
          e: "3rd m. pl."
        }, {
          p: "پوه شوی دی",
          f: "pooy shíwee dee",
          e: "",
          a: "19prp11"
        }], [{
          e: "3rd f. pl."
        }, {
          p: "پوه شوې دی",
          f: "pooy shíwe dee",
          e: "",
          a: ""
        }]]}</Table>
    <Table bordered verticalContent unset mdxType="Table">{[[{
          e: "1st m. s."
        }, {
          p: "پوه شوے وم",
          f: "pooy shíwey wum",
          e: "",
          a: "19pap1"
        }], [{
          e: "1st f. s."
        }, {
          p: "پوه شوې وم",
          f: "pooy shíwe wum",
          e: "",
          a: "19pap2"
        }], [{
          e: "2nd m. s."
        }, {
          p: "پوه شوے وې",
          f: "pooy shíwey we",
          e: "",
          a: "19pap3"
        }], [{
          e: "2nd f. s."
        }, {
          p: "پوه شوې وې",
          f: "pooy shíwe we",
          e: "",
          a: "19pap4"
        }], [{
          e: "3rd m. s."
        }, {
          p: "پوه شوے وو",
          f: "pooy shíwey wo",
          e: "",
          a: "19pap5"
        }], [{
          e: "3rd f. s."
        }, {
          p: "پوه شوې وه",
          f: "pooy shíwe wa",
          e: "",
          a: "19pap6"
        }], [{
          e: "1st m. pl."
        }, {
          p: "پوه شوی وُو",
          f: "pooy shíwee woo",
          e: "",
          a: "19pap7"
        }], [{
          e: "1st f. pl."
        }, {
          p: "پوه شوې یو",
          f: "pooy shíwe woo",
          e: "",
          a: ""
        }], [{
          e: "2nd m. pl."
        }, {
          p: "پوه شوی وئ",
          f: "pooy shíwee weyy",
          e: "",
          a: "19pap9"
        }], [{
          e: "2nd f. pl."
        }, {
          p: "پوه شوې وئ",
          f: "pooy shíwe weyy",
          e: "",
          a: ""
        }], [{
          e: "3rd m. pl."
        }, {
          p: "پوه شوی وُو",
          f: "pooy shíwee woo",
          e: "",
          a: "19pap11"
        }], [{
          e: "3rd f. pl."
        }, {
          p: "پوه شوې وې",
          f: "pooy shíwe we",
          e: "",
          a: "19pap12"
        }]]}</Table>
    </Columns>
    <h4 {...{
      "id": "note-1"
    }}>{`NOTE:`}</h4>
    <p>{`The adjective `}<Word w={{
        p: "پوه",
        f: "poh",
        e: "",
        a: ""
      }} mdxType="Word" /> is often pronounced <Word w={{
        p: "پوی",
        f: "pooy",
        e: "",
        a: ""
      }} mdxType="Word" />{` in spoken language, and we’ve written it that way in the phonetics.`}</p>
    <h2 {...{
      "id": "more-intransitive-stative-compound-verbs"
    }}>{`More intransitive stative compound verbs`}</h2>
    <p>{`Here are some examples of other compound verbs built with `}<Word w={{
        p: "کېدل",
        f: "to become",
        e: "",
        a: ""
      }} mdxType="Word" /></p>
    <Table bordered unset wide titleRow={["", "Infinitive", "Perfective Root"]} mdxType="Table">{[[{
        e: "to become (auxillary verb)"
      }, {
        p: "کېدل",
        f: "kedul",
        e: "",
        a: "kedul-m-3"
      }, {
        p: "شول",
        f: "shwul",
        e: "",
        a: "shwul-m"
      }], [{
        e: "to wait, to stay, to be late"
      }, {
        p: "ایسارېدل [حصارېدل]",
        f: "eesaaredúl",
        e: "",
        a: "19iv1"
      }, {
        p: "ایسار شول [حصار شول]",
        f: "eesaar shwul",
        e: "",
        a: "19iv2"
      }], [{
        e: "to go to sleep"
      }, {
        p: "اُودۀ کېدل",
        f: "oodú kedul",
        e: "",
        a: "19iv3"
      }, {
        p: "اُودۀ شول",
        f: "oodú shwul",
        e: "",
        a: "19iv4"
      }], [{
        e: "to be destroyed"
      }, {
        p: "بربادېدل",
        f: "barbaadedúl",
        e: "",
        a: "19iv5"
      }, {
        p: "برباد شول",
        f: "barbaad shwul",
        e: "",
        a: "19iv6"
      }], [{
        e: "to hide oneself"
      }, {
        p: "پټېدل",
        f: "puTedúl",
        e: "",
        a: "19iv7"
      }, {
        p: "پټ شول",
        f: "puT shwul",
        e: "",
        a: "19iv8"
      }], [{
        e: "to be born"
      }, {
        p: "پېدا کېدل",
        f: "pedaa kedul",
        e: "",
        a: "19iv9"
      }, {
        p: "پېدا شول",
        f: "pedaa shwul",
        e: "",
        a: "19iv10"
      }], [{
        e: "to be known"
      }, {
        p: "پېژندے کېدل",
        f: "pejzundey kedul",
        e: "",
        a: "19iv11"
      }, {
        p: "پېژندے شول",
        f: "pejzundey shwul",
        e: "",
        a: "19iv12"
      }], [{
        e: "to happen, to meet"
      }, {
        p: "پېښېدل",
        f: "pexedul",
        e: "",
        a: "19iv13"
      }, {
        p: "پېښ شول",
        f: "pex shwul",
        e: "",
        a: "19iv14"
      }], [{
        e: "to pass by, be swallowed"
      }, {
        p: "تېرېدل",
        f: "teredul",
        e: "",
        a: "19iv15"
      }, {
        p: "تېر شول",
        f: "ter shwul",
        e: "",
        a: "19iv16"
      }], [{
        e: "to be spoiled"
      }, {
        p: "خرابېدل",
        f: "kharaabedul",
        e: "",
        a: "19iv17"
      }, {
        p: "خراب شول",
        f: "kharaab shwul",
        e: "",
        a: "19iv18"
      }], [{
        e: "to start out"
      }, {
        p: "روانېدل",
        f: "rawaanedul",
        e: "",
        a: "19iv19"
      }, {
        p: "روان شول",
        f: "rawaan shwul",
        e: "",
        a: "19iv20"
      }], [{
        e: "to be injured"
      }, {
        p: "زخمى کېدل",
        f: "zakhmee kedul",
        e: "",
        a: "19v21"
      }, {
        p: "زخمى شول",
        f: "zakhmee shwul",
        e: "",
        a: "19v22"
      }], [{
        e: "to be increased"
      }, {
        p: "زیاتېدل",
        f: "zyaatedul",
        e: "",
        a: "19v23"
      }, {
        p: "زیات شول",
        f: "zyaat shwul",
        e: "",
        a: "19v24"
      }], [{
        e: "to ride, to mount"
      }, {
        p: "سورېدل",
        f: "swaredul",
        e: "",
        a: "19v25"
      }, {
        p: "سوړ شول",
        f: "sor shwul",
        e: "",
        a: ""
      }], [{
        e: "to be shared"
      }, {
        p: "شريکېدل",
        f: "shareekedul",
        e: "",
        a: "19v27"
      }, {
        p: "شريک شول",
        f: "shareek shwul",
        e: "",
        a: "19v28"
      }], [{
        e: "to appear"
      }, {
        p: "ښکارېدل",
        f: "xkaaredul",
        e: "",
        a: "19v29"
      }, {
        p: "ښکاره شول",
        f: "xkaara shwul",
        e: "",
        a: "19v30"
      }], [{
        e: "to be decreased"
      }, {
        p: "کمېدل",
        f: "kumedul",
        e: "",
        a: "19v31"
      }, {
        p: "کم شول",
        f: "kum shwul",
        e: "",
        a: "19v32"
      }], [{
        e: "to get down"
      }, {
        p: "کُوزېدل",
        f: "koozedul",
        e: "",
        a: "19v33"
      }, {
        p: "کُوز شول",
        f: "kooz shwul",
        e: "",
        a: "19v34"
      }], [{
        e: "to be mixed"
      }, {
        p: "ګډوډېدل",
        f: "guDwuDedul",
        e: "",
        a: "19v35"
      }, {
        p: "ګډوډ شول",
        f: "guDwuD shwul",
        e: "",
        a: "19v36"
      }], [{
        e: "to be broken"
      }, {
        p: "ماتېدل",
        f: "maatedul",
        e: "",
        a: "19v37"
      }, {
        p: "مات شول",
        f: "maat shwul",
        e: "",
        a: "19v38"
      }]]}</Table>
    <h4 {...{
      "id": "note-2"
    }}>{`NOTE:`}</h4>
    <p>{`The word `}<Word w={{
        p: "سور",
        f: "sor",
        e: "riding, mounted",
        a: ""
      }} mdxType="Word" /> is irregualar. The masculine plural form is <Word w={{
        p: "سوارهٔ",
        f: "swaarú",
        e: "",
        a: ""
      }} mdxType="Word" /> and the feminine singual form is <Word w={{
        p: "سوره",
        f: "swara",
        e: "",
        a: ""
      }} mdxType="Word" />{`.`}</p>
    <p>{`Practice with these sentences:`}</p>
    <Examples mdxType="Examples">{[{
        p: "زۀ د هغۀ په خبره پوه شوم.",
        f: "zu du haghú pu khabura pooy shwum.",
        e: "I understood his talk.",
        a: ""
      }, {
        p: "هغه زما په خبره پوه شوه.",
        f: "haghá zmaa pu khabura pooy shwa.",
        e: "She understood my talk.",
        a: "19e2"
      }, {
        p: "تۀ پوه شوې؟",
        f: "tu poh shwe?",
        e: "Did you understand?",
        a: "19e3"
      }, {
        p: "ماشے يې تېر شو.",
        f: "máashey ye yer sho.",
        e: "He swallowed a fly.",
        a: "19e4"
      }, {
        p: "ثابته شپه اُودۀ شوم.",
        f: "saabuta shpa oodú shwum.",
        e: "I slept the whole night.",
        a: "19e5"
      }, {
        p: "ته په اس سور شوې؟",
        f: "tu pu aas sor shwe?",
        e: "Did you ride a horse?",
        a: "19e6"
      }, {
        p: "بلف مات شو.",
        f: "buluf maat sho.",
        e: "The light bulb broke.",
        a: "19e7"
      }, {
        p: "هغه راسره ناڅاپه مِلاؤ شو.",
        f: "haghá raasara naatsáapa miláaw sho.",
        e: "He suddenly met me.",
        a: "19e8"
      }, {
        p: "په صحيح وخت کښې راښکاره شوې.",
        f: "pu sahée wakht ke raaxkaara shwe.",
        e: "You appeared just at the right time.",
        a: "19e9"
      }, {
        p: "مالټې ټولې خرابې شوې.",
        f: "maalTé Tóle kharáabe shew.",
        e: "The oranges were all spoilt.",
        a: "19e10"
      }, {
        p: "ښار برباد شو.",
        f: "xaar barbáad sho.",
        e: "The city is destroyed.",
        a: "19e11"
      }, {
        p: "اوبۀ کمې شوې.",
        f: "oobú kume shwe.",
        e: "The water became less.",
        a: "19e12"
      }, {
        p: "ټول قيمتُونه زيات شو.",
        f: "Tol qeematóon zyaat shoo.",
        e: "All the prices increased.",
        a: "19e13"
      }, {
        p: "سترګه يې خوږ شوه.",
        f: "sturga ye khoG shwa.",
        e: "His eye was injured.",
        a: "19e14"
      }, {
        p: "شپږ کسان ژوبل شُو.",
        f: "shpuG kasáan jzobúl shoo.",
        e: "Six people are injured.",
        a: "19e15"
      }, {
        p: "ښځې نر ګډ وډ شُو.",
        f: "xudze nar guDwuD shoo.",
        e: "Females and males [animals] were mixed up.",
        a: "19e16"
      }, {
        p: "دوه ورځې وروستو جوړ شوم.",
        f: "dwa wrudze wroosto joR shwum.",
        e: "I got well after two days.",
        a: "19e17"
      }, {
        p: "هغه په دفتر کښې ايسار شو.",
        f: "haghá pu daftúr ke eesáar sho.",
        e: "He stayed at the office.",
        a: "19e18"
      }, {
        p: "تۀ چرته پټ شوې؟",
        f: "tu chărta puT shwe?",
        e: "Where were you hidden?",
        a: "19e19"
      }, {
        p: "زۀ د کټ لاندې پټ شوم.",
        f: "zu du kaT láandey puT shwum.",
        e: "I hid under the bed.",
        a: "19e20"
      }, {
        p: "ښځې په يوه بجه روانې شوې.",
        f: "xudze pu yuwa baja rawáane shwe.",
        e: "The ladies started out at one o'clock.",
        a: "19e21"
      }, {
        p: "هلک پېدا شو؟",
        f: "halúk pedáa sho?",
        e: "Was a boy born?",
        a: "19e22"
      }, {
        p: "نه، جينۍ پېدا شوه.",
        f: "na, jeenúy pedáa shwa.",
        e: "No, a girl was born.",
        a: "19e23"
      }]}</Examples>
    <h2 {...{
      "id": "regular-intransitive-verbs-that-take-an-word-w-p-و-f-oo--w-e--a-oo-sound---prefix-in-the-simple-past-tenses"
    }}>{`Regular intransitive verbs that take an `}<Word w={{
        p: "و",
        f: "oo / w",
        e: "",
        a: "oo-sound"
      }} mdxType="Word" />{` prefix in the simple past tenses`}</h2>
    <p>{`The verbs we saw above used the irregular perfective stem `}<Word w={{
        p: "شول",
        f: "swhul",
        e: "",
        a: "shwul-f"
      }} mdxType="Word" /> in the simple past tense. <Word w={{
        p: "شول",
        f: "shwul",
        e: "",
        a: "shwul-f"
      }} mdxType="Word" /> is an <em>irregular perfective stem</em> of <Word w={{
        p: "کېدل",
        f: "kedul",
        e: "to become",
        a: ""
      }} mdxType="Word" />{`.`}</p>
    <p>{`Other regular transitive verbs simply add a `}<Word w={{
        p: "و",
        f: "oo / w",
        e: "",
        a: "oo-sound"
      }} mdxType="Word" />{` prefix to the infinitive to make the perfective stem. Just like with the irregalar perfect stems we saw earlier, we use the perfective stems to make the simple past forms of the verb.`}</p>
    <p>{`The verb `}<Word w={{
        p: "رسېدل",
        f: "rasedul",
        e: "to reach, arrive",
        a: "rasedul-m"
      }} mdxType="Word" />{` provides a good example of one of these regular intransitive verbs.`}</p>
    <p>{`To make the `}<em parentName="p">{`perfective root`}</em>{` of a regular verb we simply:`}</p>
    <ol>
      <li parentName="ol">{`Add a `}<Word w={{
          p: "و",
          f: "oo / w",
          e: "",
          a: "oo-sound"
        }} mdxType="Word" />{` prefix.`}</li>
      <li parentName="ol">{`Shift the accent to the front of the word.`}</li>
    </ol>
    <Table bordered unset wide titleRow={["", "Infinitive", "Perfective Root"]} mdxType="Table">{[[{
        e: "to arrive / reach"
      }, {
        p: "رسېدل",
        f: "rasedúl",
        e: "",
        a: "rasedul-m"
      }, {
        p: "ورسېدل",
        f: "óorasedul",
        e: "",
        a: ""
      }]]}</Table>
    <p>{`Then to make the simple past forms, we add the past person endings onto the `}<em parentName="p">{`perfective root`}</em>{`.`}</p>
    <Table unset bordered verticalContent titleRow={["Meaning", "Short Form", "Long Form"]} mdxType="Table">{[[{
        e: "I arrived."
      }, {
        p: "زۀ ورسېدلم.",
        f: "zu óorasedulum.",
        a: ""
      }, {
        p: "زۀ ورسېدم.",
        f: "zu óorasedum.",
        a: "19ve1"
      }], [{
        e: "You arrived."
      }, {
        p: "تۀ ورسېدلې.",
        f: "tu óorasedule",
        a: ""
      }, {
        p: "تۀ ورسېدې.",
        f: "tu óorasede",
        a: "19ve2"
      }], [{
        e: "He arrived."
      }, {
        p: "هغه ورسېدلو.",
        f: "haghá óorasedulo",
        a: ""
      }, {
        p: "هغه ورسېدو.",
        f: "haghá óorasedo",
        a: "19ve3"
      }], [{
        e: "She arrived."
      }, {
        p: "هغه ورسېدله.",
        f: "haghá óorasedula",
        a: ""
      }, {
        p: "هغه ورسېده.",
        f: "haghá óoraseda",
        a: "19ve4"
      }], [{
        e: "She arrived."
      }, {
        p: "دا ورسېدله.",
        f: "daa óorasedula",
        e: "She arrived.",
        a: ""
      }, {
        p: "دا ورسېده.",
        f: "daa óoraseda",
        e: "She arrived.",
        a: "19ve5"
      }], [{
        e: "ُYou (pl.) arrived."
      }, {
        p: "تاسو ورسېدلئ.",
        f: "taaso óoraseduleyy",
        a: ""
      }, {
        p: "تاسو ورسېدئ.",
        f: "taaso óorasedeyy",
        a: "19ve6"
      }], [{
        e: "They (m.pl.) arrived."
      }, {
        p: "هغوى ورسېدل.",
        f: "haghwee óorasedul",
        a: ""
      }, {
        p: "هغوى ورسېدل.",
        f: "haghwee óorasedul",
        a: "19ve7"
      }], [{
        e: "They (f.pl.) arrived."
      }, {
        p: "دوئ ورسېدلې.",
        f: "dwee óorasedule",
        a: ""
      }, {
        p: "دوئ ورسېدې.",
        f: "dwee óorasede",
        a: "19ve8"
      }]]}</Table>
    <h4 {...{
      "id": "notes"
    }}>{`NOTES:`}</h4>
    <ol>
      <li parentName="ol">{`Like other verbs that imply direction, the directional prefixes are used:`}</li>
    </ol>
    <Examples mdxType="Examples">{[{
        p: <>ستا ښځه پرُون <strong>را</strong>ورسېده؟</>,
        f: <>staa xudza paróon <strong>raa</strong>-óoraseda?</>,
        e: "Did your wife arrive (here) yesterday?",
        a: "19e24"
      }, {
        p: <>ستا ښځه پرُون <strong>ور</strong>ورسېده؟</>,
        f: <>staa xudza paróon <strong>wăr</strong>-óoraseda?</>,
        e: "Did your wife arrive (there) yesterday?",
        a: "19e25"
      }, {
        p: <>ستا ښځه پرُون <strong>در</strong>ورسېده؟</>,
        f: <>staa xudza paróon <strong>dăr</strong>-óoraseda?</>,
        e: "Did your wife reach you yesterday?",
        a: "19e26"
      }, {
        p: <>هغوى په حفاظت در <strong>ور</strong>سېدل؟</>,
        f: <>haghwée pu hifaazút dăr-óorasedul?</>,
        e: "Did they reach you safely?",
        a: "19e27"
      }]}</Examples>
    <ol {...{
      "start": 2
    }}>
      <li parentName="ol">{`Notice the important differences between the sentences below. You have arrived late to work: a) would be used in a discussion in the office; b) would be used when you are out of the office; and c) is what the boss says to you the next day:`}</li>
    </ol>
    <Examples mdxType="Examples">{[{
        p: <>زۀ کار ته ناوخته <strong>را</strong>ورسېدم.</>,
        f: <>zu kaar ta naawákhta <strong>raa</strong>-óorasedum</>,
        e: "a. I came to work late.",
        a: "19e28"
      }, {
        p: <>زۀ کار ته لېټ <strong>ور</strong>ورسېدم.</>,
        f: <>zu kaar ta leT <strong>wăr</strong>-óorasedum.</>,
        e: "b. I arrived late to work.",
        a: "19e29"
      }, {
        p: <>تۀ پرُون ناوخته <strong>را</strong>ورسېدې.</>,
        f: <>tu paróon naawákhta <strong>raa</strong>-óorasede.</>,
        e: "c. You came late yesterday.",
        a: "19e30"
      }]}</Examples>
    <h4 {...{
      "id": "some-more-examples-of-verbs-that-add-word-w-p-و-f-oo--w-e--a-oo-sound---for-the-simple-past"
    }}>{`Some more examples of verbs that add `}<Word w={{
        p: "و",
        f: "oo / w",
        e: "",
        a: "oo-sound"
      }} mdxType="Word" />{` for the simple past`}</h4>
    <Table bordered unset wide titleRow={["", "Infinitive", "Perfective Root"]} mdxType="Table">{[[{
        e: "to walk, go about"
      }, {
        p: "ګرځېدل",
        f: "gurdzedúl",
        e: "",
        a: "gurdzedul-m"
      }, {
        p: "وګرځېدل",
        f: "óogurdzedul",
        e: "",
        a: ""
      }], [{
        e: "to go out"
      }, {
        p: "وتل",
        f: "watúl",
        e: "",
        a: "watul-m"
      }, {
        p: "ووتل",
        f: "óowatul",
        e: "",
        a: ""
      }], [{
        e: "to go out (outside)"
      }, {
        p: "بهر وتل",
        f: "bahár watúl",
        e: "",
        a: ""
      }, {
        p: "بهر ووتل",
        f: "bahár óowatul",
        e: "",
        a: ""
      }], [{
        e: "to climb"
      }, {
        p: "ختل",
        f: "khatúl",
        e: "",
        a: "khatul-m"
      }, {
        p: "وختل",
        f: "óokhatul",
        e: "",
        a: ""
      }], [{
        e: "to be fixed, to touch"
      }, {
        p: "لګېدل",
        f: "lugedúl",
        e: "",
        a: "lugedul-m"
      }, {
        p: "ولګېدل",
        f: "óolugedul",
        e: "",
        a: ""
      }], [{
        e: "to be in motion"
      }, {
        p: "چلېدل",
        f: "chaledúl",
        e: "",
        a: "chaledul-m"
      }, {
        p: "وچلېدل",
        f: "óochaledul",
        e: "",
        a: ""
      }], [{
        e: "to be boiled"
      }, {
        p: "یشېدل",
        f: "yashedúl",
        e: "",
        a: "yashedul-m"
      }, {
        p: "ویشېدل",
        f: "óoyashedul",
        e: "",
        a: ""
      }], [{
        e: "to run away"
      }, {
        p: "تښتېدل",
        f: "taxtedúl",
        e: "",
        a: "taxtedul"
      }, {
        p: "وتښتېدل",
        f: "óotaxtedul",
        e: "",
        a: ""
      }], [{
        e: "to drip"
      }, {
        p: "څڅېدل",
        f: "tsatsedul",
        e: "",
        a: "tsatsedul"
      }, {
        p: "وڅڅېدل",
        f: "óotsatsedul",
        e: "",
        a: ""
      }]]}</Table>
    <h4 {...{
      "id": "examples-of-simple-past-sentences-with-regular-intrasnitive-verbs-that-take-the-word-w-p-و-f-oo--w-e--a-oo-sound---prefix"
    }}>{`Examples of simple past sentences with regular intrasnitive verbs that take the `}<Word w={{
        p: "و",
        f: "oo / w",
        e: "",
        a: "oo-sound"
      }} mdxType="Word" />{` prefix`}</h4>
    <Examples mdxType="Examples">{[{
        p: "ته د کور نه راوتې؟",
        f: "tu du kor na raa-óowate?",
        e: "Did you get out of the house?",
        a: "19e31"
      }, {
        p: "آو، زۀ د کور نه راوتم،...",
        f: "aw, zu du kor na raa-óowatum ...",
        e: "Yes, I got out, ...",
        a: "19e32"
      }, {
        p: "... لېکن هغه راونۀ وتو.",
        f: "lekin haghá raa-oo-nú-wato.",
        e: "... but he did not get out.",
        a: "19e33"
      }, {
        p: "هغه غر ته وخته؟",
        f: "haghá ghur ta óokhata?",
        e: "Did she climb the hill?",
        a: "19e34"
      }, {
        p: "اوبۀ ويشېدې؟",
        f: "oobú óoyashede?",
        e: "Did the water boil?",
        a: "19e35"
      }, {
        p: "مجرمه وتښتېده.",
        f: "mUjrima óotaxteda",
        e: "The criminal ran away. (f.)",
        a: "19e36"
      }, {
        p: "بېټک وڅڅېدۀ.",
        f: "beTúk óotsatsedú",
        e: "The guest room leaked.",
        a: "19e37"
      }, {
        p: "هلک وغورځېدو.",
        f: "halúk óoghoordzedo",
        e: "The boy fell down.",
        a: "19e38"
      }, {
        p: "روپۍ نالۍ کښې وغورځېده.",
        f: "roopúy naalúy ke óoghoordzeda.",
        e: "The rupee fell in the drain.",
        a: "19e39"
      }, {
        p: "بس څۀ وخت دلته ودرېدو؟",
        f: "bus tsu wakht dălta óodredo?",
        e: "When did the bus stop here?",
        a: "19e40"
      }, {
        p: "لس منټه مخکښې ودرېدو.",
        f: "lus minTa makhke óodredo.",
        e: "It stopped here ten minutes ago.",
        a: "19e41"
      }]}</Examples>
    <h4 {...{
      "id": "notes-1"
    }}>{`NOTES:`}</h4>
    <ol>
      <li parentName="ol">
        <p parentName="li">{`The verb `}<Word w={{
            p: "ودرېدل",
            f: "oodredúl",
            e: "",
            a: "oodredul-m"
          }} mdxType="Word" /> already starts with a <Word w={{
            p: "و",
            f: "oo",
            e: "",
            a: "oo-sound"
          }} mdxType="Word" /> so we can't add a <Word w={{
            p: "و",
            f: "oo",
            e: "",
            a: "oo-sound"
          }} mdxType="Word" />{` prefix. We still do move the emphasis to the beginning of the word though.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`To make simple past sentences negative, add the negative marker `}<Word w={{
            p: "نهٔ",
            f: "nu",
            e: "",
            a: "nu"
          }} mdxType="Word" /> between the <Word w={{
            p: "و",
            f: "oo",
            e: "",
            a: "oo-sound"
          }} mdxType="Word" />{` and ethe verb, for example:`}</p>
      </li>
    </ol>
    <Table bordered verticalContent unset titleRow={["", "و - oo", "نهٔ - nu", "verb"]} mdxType="Table">{[[{
        p: "هغه را",
        f: "haghá raa",
        e: "",
        a: "hagha-raa-oo-nu-wat"
      }, {
        p: "و",
        f: "oo",
        e: "",
        a: ""
      }, {
        p: "نهٔ",
        f: "nu",
        e: "",
        a: ""
      }, {
        p: "وت",
        f: "wat",
        e: "",
        a: ""
      }]]}</Table>
    <Examples mdxType="Examples">{[{
        p: "هغه را ونهٔ وت.",
        f: "haghá raa-oo-nú-wat.",
        e: "He didn't come out.",
        a: "hagha-raa-oo-nu-wat"
      }]}</Examples>
    <p>{`👆 This takes some practice, so have your helper make simple past sentences for you to make negative.`}</p>
    <h2 {...{
      "id": "irregular-intransitive-verbs"
    }}>{`Irregular intransitive verbs`}</h2>
    <p>{`We’ve seen that simple past forms are based on the `}<em parentName="p">{`perfective roots`}</em>{` of verbs.`}</p>
    <p>{`We’ve seen verbs like `}<Word w={{
        p: "پوهېدل",
        f: "pohedul",
        e: "to understand",
        a: "pohedul-m"
      }} mdxType="Word" /> or <Word w={{
        p: "ماتېدل",
        f: "maatedul",
        e: "to break",
        a: "maatedul2"
      }} mdxType="Word" /> which are compound verbs based on <Word w={{
        p: "کېدل",
        f: "kedul",
        e: "to become",
        a: "kedul-m"
      }} mdxType="Word" />, which uses the perfective root <Word w={{
        p: "شول",
        f: "shwul",
        e: "",
        a: "shwul-m"
      }} mdxType="Word" />{`.`}</p>
    <p>{`We’ve alse seen regular verbs like `}<Word w={{
        p: "رسېدل",
        f: "rasedul",
        e: "",
        a: "rasedul-m"
      }} mdxType="Word" /> and <Word w={{
        p: "ګرځېدل",
        f: "gurdzedul",
        e: "",
        a: "gurdzedul-m"
      }} mdxType="Word" /> that add a <Word w={{
        p: "و",
        f: "oo",
        e: "",
        a: "oo-sound"
      }} mdxType="Word" />{` to the infinitive and move the accent forword to make the perfective root.`}</p>
    <p>{`But some verbs have completely irregular perfective roots. For example:`}</p>
    <ol>
      <li parentName="ol">{`“To go”`}</li>
    </ol>
    <p>{`The verb `}<Word w={{
        p: "تلل",
        f: "tlul",
        e: "to go",
        a: "tlul-m"
      }} mdxType="Word" /> has an irregular perfective root: <Word w={{
        p: "لاړل",
        f: "laaRul",
        e: "",
        a: "laarrul-f"
      }} mdxType="Word" />{`.`}</p>
    <Examples mdxType="Examples">{[{
        p: "پرُون زۀ هسپتال ته لاړم.",
        f: "paróon zu hapspatáal ta láaRum.",
        e: "Yesterday I went to hospital.",
        a: "19e42"
      }, {
        p: "پرُون زنانه اټک ته لاړې.",
        f: "paróon zanaaná aTuk ta láaRe.",
        e: "Yesterday the ladies went to Attock.",
        a: "19e43"
      }, {
        p: "زۀ سکُول ته لا نۀ ړم.",
        f: "zu skool to laa-nu-Rum.",
        e: "I did not go to the school.",
        a: "19e44"
      }, {
        p: "ښځې بازار ته لا نۀ ړې.",
        f: "xudze baazáar ta laa-nu-Re.",
        e: "The ladies did not go to the bazaar.",
        a: "19e45"
      }, {
        p: "بجلى لاړه.",
        f: "bijlúy láaRa.",
        e: "The electricity just left.",
        a: "19e46"
      }]}</Examples>
    <h4 {...{
      "id": "note-3"
    }}>{`NOTE:`}</h4>
    <p>{`To make the verb negative, use the construction `}<Word w={{
        p: "لا نهٔ ړل",
        f: "laa-nu-Rul",
        e: "",
        a: "laa-nu-rrul"
      }} mdxType="Word" />{` as in the example 'I did not go to school’.`}</p>
    <ol {...{
      "start": 2
    }}>
      <li parentName="ol">{`“To come”`}</li>
    </ol>
    <p>{`The infinitive `}<Word w={{
        p: "راتلل",
        f: "raatlul",
        e: "to come",
        a: "raatlul"
      }} mdxType="Word" /> uses the perfective root <Word w={{
        p: "راغلل",
        f: "raaghlul",
        e: "",
        a: "raaghlul"
      }} mdxType="Word" />{` in the simple past.`}</p>
    <p>{`To say “He came” an irregular ending is used: `}<Word w={{
        p: "راغے",
        f: "ráaghey",
        e: "",
        a: "raaghey"
      }} mdxType="Word" />{`.`}</p>
    <Table bordered unset titleRow={["Person", "Singular", "Plural"]} mdxType="Table">{[[{
        e: "1st"
      }, {
        p: "راغلم",
        f: "ráaghlum",
        e: "I came",
        a: "19r1"
      }, {
        p: "راغلو",
        f: "ráaghloo",
        e: "we came",
        a: "19r2"
      }], [{
        e: "2nd"
      }, {
        p: "راغلې",
        f: "ráaghle",
        e: "you came",
        a: "19r3"
      }, {
        p: "راغلئ",
        f: "ráaghleyy",
        e: "you came",
        a: "19r4"
      }], [{
        e: "3rd m."
      }, {
        p: "راغے",
        f: "ráaghey",
        e: "he came",
        a: "19r5"
      }, {
        p: "راغلل",
        f: "ráaghlul",
        e: "they came",
        a: "19r6"
      }], [{
        e: "3rd f."
      }, {
        p: "راغله",
        f: "ráaghla",
        e: "she came",
        a: "19r7"
      }, {
        p: "راغلې",
        f: "ráaghle",
        e: "they came",
        a: "19r8"
      }]]}</Table>
    <Examples mdxType="Examples">{[{
        p: "هغۀ څۀ وخت کور ته راغے او تۀ څۀ وخت راغلې؟",
        f: "haghá tsu wakht kor ta ráaghey aw tu tsu wakht raaghle?",
        e: "When did he come home and when did you come?",
        a: "19e47"
      }, {
        p: "هغه کور ته ډېر ناوخته راغے، خو زۀ وختى راغلم.",
        f: "haghá kor ta Der naa-wakhta ráaghey, kho zu wakhtee ráaghlum.",
        e: "He came home very late, but I came early.",
        a: "19e48"
      }, {
        p: "تۀ رانۀ غلې.",
        f: "tu raa-nu-ghle.",
        e: "You did not come.",
        a: "19e49"
      }]}</Examples>
    <h2 {...{
      "id": "the-use-of-adjectives-in-the-comparative-degree"
    }}>{`The use of adjectives in the comparative degree`}</h2>
    <p>{`An adjective in the “comparative degree” is used when comparing two things or persons. In English grammar this often done by adding / -er / or / -ier / to the adjective, as in the example, “Today’s weather is warm-er than yesterday’s.”`}</p>
    <p>{`Practice the sentences below to become familiar with the way comparisons are made in Pukhto.`}</p>
    <Examples mdxType="Examples">{[{
        p: "کُوم يو کتاب ښۀ دے؟ هغه کتاب او کۀ دا کتاب؟",
        f: "kum yo kitáab xu dey? hágha kitáab aw ku daa kitáab?",
        e: "Which book is good? That book or this book?",
        a: "19e50"
      }, {
        p: "هغه کتاب د دې کتاب نه ښۀ دے.",
        f: "hágha kitáab du de kitáab nu xu dey.",
        e: "That book is better than this one.",
        a: "19e51"
      }, {
        p: "دا جينۍ د هغې نه بدرنګه ده.",
        f: "daa jeenúy du haghe nu badranga da.",
        e: "This girl is uglier than that one.",
        a: "19e52"
      }, {
        p: "دا صندُوق د هغې نه درُوند دے.",
        f: "daa sandóoq du haghé na dróond dey.",
        e: "This trunk is heavier than that one.",
        a: "19e53"
      }, {
        p: "تاسو سره د دې نه ارزان قلم نشته؟",
        f: "taaso sara du de na arzáan qalúm níshta?",
        e: "Don't you have a cheaper pen than this?",
        a: "19e54"
      }, {
        p: "نه، د دې نه ارزان قلم نشته.",
        f: "na, du de na arzáan qalúm níshta.",
        e: "No, there is no cheaper pen than this.",
        a: "19e55"
      }, {
        p: "نننے موسم د پرُون نه ګرم دے.",
        f: "nununéy mosúm du paróon na gárum dey.",
        e: "Today's weather is warmer than yesterday's.",
        a: "19e56"
      }, {
        p: "زما په خيال فارسى د پښتو نه آسانه ده.",
        f: "zmaa pu khyaal faarsée du puxtó na aasáana da.",
        e: "I think Farsi is easier than Pukhto.",
        a: "19e57"
      }, {
        p: "تۀ د خپل ورور نه مشر يې؟",
        f: "tu du khpul wroor na múshar ye?",
        e: "Are you older than your brother?",
        a: "19e58"
      }, {
        p: "نه، د هغۀ نه کشر يم.",
        f: "na, du haghu na kúshar yim.",
        e: "No, I am younger than him.",
        a: "19e59"
      }]}</Examples>
    <h2 {...{
      "id": "the-use-of-adjectives-in-the-superlative-degree"
    }}>{`The use of adjectives in the superlative degree`}</h2>
    <img src={fruit} style={{
      maxWidth: "10rem",
      float: "right",
      marginLeft: "0.5rem"
    }} />
    <p>{`An adjective in the “superlative degree” is used when comparing three or more things or persons. In English grammar this often done by adding / -est / to the adjective, as in the example, “Of all of your children, which is the young-est?” Drill the sentences below and experiment with different adjectives to familiarise yourself with how the superlative degree is used in Pukhto.`}</p>
    <Examples mdxType="Examples">{[{
        p: "د ټولو نه ښۀ مېوه کُومه يوه ده؟",
        f: "du Tólo na xu mewá kúma yúwa da?",
        e: "Which is the best fruit of all?",
        a: "19e60"
      }, {
        p: "مڼه په ټولو کښې ښۀ مېوه ده.",
        f: "maNá pu Tólo ke xu mewá da.",
        e: "The apple is the best fruit of all.",
        a: "19e61"
      }, {
        p: "ستا ماشُومانو کښې د ټولو نه کشر کُوم يو دے؟",
        f: "staa maashoomáano ke du Tólo na kúshar kúm yo dey?",
        e: "Of all of your children which is the youngest?",
        a: "19e62"
      }, {
        p: "ستا مېښو کښې کُومه يوه د ټولو نه ډېر پۍ کوى؟",
        f: "staa méxo ke kUma yúwa du Tólo na Der puy kawée?",
        e: "Of all of your buffaloes, which gives the most milk?",
        a: "19e63"
      }, {
        p: "هغه چرګۍ مېښه د ټولو نه ډېر پئ کوى.",
        f: "hágha churgúy méxa du Tólo na Der puy kawée.",
        e: "That one with a white forelock gives the most milk of all.",
        a: "19e64"
      }]}</Examples>
    <h2 {...{
      "id": "general-comparisons"
    }}>{`General comparisons`}</h2>
    <Examples mdxType="Examples">{[{
        p: "دا څپلۍ د دې بلې نه بدله ده.",
        f: "daa tsuplúy du de búle na badúla da.",
        e: "That sandal is different from the other.",
        a: "19e65"
      }, {
        p: "دا جامې په ما برابرې دى.",
        f: "daa jaamé pu băraabăre dee.",
        e: "These clothes fit me.",
        a: "19e66"
      }, {
        p: "دواړه يو شے دى.",
        f: "dwaaRa yo shey dee.",
        e: "Both of them are the same.",
        a: "19e67"
      }, {
        p: "شپږ واړه يو برابر دى.",
        f: "shpuG waaRu yo băraabăr dee.",
        e: "All six of them are same.",
        a: "19e68"
      }, {
        p: "ټول يو شے دى.",
        f: "Tol yo shey dee.",
        e: "They are all the same.",
        a: "19e69"
      }, {
        p: "ټول يو همره دى.",
        // check pronunciation
        f: "Tol yo 'Úmra dee",
        e: "They are all the same size.",
        a: "19e70"
      }, {
        p: "ما ته خو په ګُل رسول او اشفاق کښې هېڅ فرق نۀ ښکارى.",
        f: "maa ta kho pu gUl rasóol aw ishfáaq ke hets farq nu xkaaree.",
        e: "To me there is no difference between Gul Rasoul and Ishfaq.",
        a: "19e71"
      }]}</Examples>
    <img src={busTravellers} style={{
      maxWidth: "10rem"
    }} />
    <h2 {...{
      "id": "memorising-moments"
    }}>{`Memorising moments`}</h2>
    <h4 {...{
      "id": "matalóona"
    }}>{`matalóona`}</h4>
    <Examples memorizingMoment mdxType="Examples">{memorizingMoments[19]}</Examples>
    <h2 {...{
      "id": "exercises-for-lesson-nineteen"
    }}>{`Exercises for Lesson Nineteen`}</h2>
    <ol>
      <li parentName="ol">{`Change the following sentences into the simple past tense:`}</li>
    </ol>
    <Quiz mdxType="Quiz">{[{
        q: {
          p: "ښځه دې چرته ځى؟",
          f: "xudza de chărta dzee?"
        },
        answer: [{
          p: "ښځه دې چرته لاړه؟",
          f: "xudza de chărta láaRa?"
        }, {
          p: "ښځه دې چرته لاړله؟",
          f: "xudza de chărta láaRula?"
        }]
      }, {
        q: {
          p: "تۀ څۀ وخت راځې؟",
          f: "tu tsu wakht raadzé?"
        },
        answer: [{
          p: "ته څه وخت راغلې؟",
          f: "tu tsu wakht ráaghle?"
        }, {
          p: "ته څه وخت راغې؟",
          f: "tu tsu wakht ráaghe?"
        }]
      }, {
        q: {
          p: "لُوڼه يې نن هلته رسېږى؟",
          f: "lóoNa ye nun hálta raséGee?"
        },
        answer: [{
          p: "لوڼه یې نن هلته ورسېدلې?",
          f: "looNa ye nun halta óorasedule?"
        }, {
          p: "لوڼه یې نن هلته ورسېدې?",
          f: "looNa ye nun halta óorasede?"
        }]
      }, {
        q: {
          p: "دُوکان ته ځئ؟",
          f: "dookáan ta dzeyy?"
        },
        answer: [{
          p: "دوکان ته لاړلئ؟",
          f: "dookáan ta laaRuleyy?"
        }, {
          p: "دوکان ته لاړئ؟",
          f: "dookáan ta laaReyy?"
        }]
      }, {
        q: {
          p: "هلکان څۀ وخت سکُول ته ځى؟",
          f: "halukáan tsu wakht skool ta dzee?"
        },
        answer: [{
          p: "هلکان څهٔ وخت سکول ته لاړل",
          f: "halukáan tsu wakht skoot ta láaRul?"
        }]
      }, {
        q: {
          p: "زما په خبرو پوهېدې؟",
          f: "zmaa pu khaburo pohede?"
        },
        answer: [{
          p: "زما په خبرو پوه شوې؟",
          f: "zmaa pu khaburo poh shwe?"
        }, {
          p: "زما په خبرو پوه شوې؟",
          f: "zmaa pu khaburo pooy shwe?"
        }]
      }]}</Quiz>
    <h4 {...{
      "id": "note-4"
    }}>{`NOTE:`}</h4>
    <p><p style={{
        fontSize: "smaller"
      }}>The sentence in the last question <Word w={{
          p: "زما په خبرو پوهېدې؟",
          f: "zmaa pu khaburo pohede?",
          e: "",
          a: ""
        }} mdxType="Word" />{`, is in the continuous (imperfective) past tense, meaning roughly “Were you understanding my words?”`}</p></p>
    <ol {...{
      "start": 2
    }}>
      <li parentName="ol">{`Using the verbs listed earlier on in this lesson, have your helper call out a verb in the infinitive. You should then make positive and negative sentences in the simple past tense.`}</li>
    </ol>
    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;
export const tableOfContents = (components={}) => [
  {
    id: "dialogue-nineteen-making-the-most-of-a-monolingual-situation",
    level: 2,
    title: "Dialogue nineteen: Making the most of a monolingual situation",
    children: [
        
      ]
  },
{
    id: "pronunciation-drill",
    level: 2,
    title: "Pronunciation drill",
    children: [
        
      ]
  },
{
    id: "an-intransitive-verb-in-different-tenses",
    level: 2,
    title: "An intransitive verb in different tenses",
    children: [
        
      ]
  },
{
    id: "more-intransitive-stative-compound-verbs",
    level: 2,
    title: "More intransitive stative compound verbs",
    children: [
        
      ]
  },
{
    id: "regular-intransitive-verbs-that-take-an-word-w-p-و-f-oo--w-e--a-oo-sound---prefix-in-the-simple-past-tenses",
    level: 2,
    title: <React.Fragment>{`Regular intransitive verbs that take an `}<Word w={{ p: "و", f: "oo / w", e: "", a: "oo-sound" }} />{` prefix in the simple past tenses`}</React.Fragment>,
    children: [
        
      ]
  },
{
    id: "irregular-intransitive-verbs",
    level: 2,
    title: "Irregular intransitive verbs",
    children: [
        
      ]
  },
{
    id: "the-use-of-adjectives-in-the-comparative-degree",
    level: 2,
    title: "The use of adjectives in the comparative degree",
    children: [
        
      ]
  },
{
    id: "the-use-of-adjectives-in-the-superlative-degree",
    level: 2,
    title: "The use of adjectives in the superlative degree",
    children: [
        
      ]
  },
{
    id: "general-comparisons",
    level: 2,
    title: "General comparisons",
    children: [
        
      ]
  },
{
    id: "memorising-moments",
    level: 2,
    title: "Memorising moments",
    children: [
        
      ]
  },
{
    id: "exercises-for-lesson-nineteen",
    level: 2,
    title: "Exercises for Lesson Nineteen",
    children: [
        
      ]
  }
]

export const frontMatter = {
  "title": "Lesson Nineteen"
}

