
import React from 'react'
import { mdx } from '@mdx-js/react'
export const readingTime = {"text":"20 min read","minutes":19.45,"time":1167000,"words":3890}
/* @jsx mdx */
import Dialogue from "../components/Dialogue";
import Word from "../components/Word";
import Examples from "../components/Examples";
import Table from "../components/Table";
import Quiz from "../components/Quiz";
import memorizingMoments from "./memorizing-moments";
import mortar from "../assets/images/mortar.png";
import burqa from "../assets/images/burqa.png";
import chicken from "../assets/images/chicken.png";
import boysStick from "../assets/images/boys-stick.png";
import loom from "../assets/images/loom.png";
import knife from "../assets/images/knife.png";
import lor from "../assets/images/lor.png";
import tukuz from "../assets/images/tukuz.png";
import trunk from "../assets/images/trunk.png";
import needle from "../assets/images/needle.png";
import kettle from "../assets/images/kettle.png";
import brush from "../assets/images/brush.png";
import saw from "../assets/images/saw.png";
import deg from "../assets/images/deg.png";
import potter from "../assets/images/potter.png";
import boyCook from "../assets/images/boy-cook.png";
export const imgFlexRowStyle = { display: "flex", justifyContent: "space-between", margin: "1rem 0" };
export const items = [
    [knife, lor, tukuz],
    [trunk, needle, kettle],
    [brush, saw, deg],
];
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope")
  return <div {...props}/>
};

const layoutProps = {
  imgFlexRowStyle
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <img src={mortar} style={{
      float: "right"
    }} />
    <h2 {...{
      "id": "dialogue-nine-multiplying-language-learning"
    }}>{`Dialogue nine: Multiplying language learning`}</h2>
    <Dialogue audio="dialogue-9" mdxType="Dialogue">{[{
        s: "L",
        p: "دا څۀ دى؟",
        f: "daa tsu dee?",
        e: "What is this?",
        a: "9d1"
      }, {
        s: "H",
        p: "دا لنګرۍ ده.",
        f: "daa langrúy da.",
        e: "It is a mortar.",
        a: "9d2"
      }, {
        s: "L",
        p: "دا د څۀ د پاره پکار راځى؟",
        f: "daa du tsu du paara pukaar raadzee?",
        e: "What is it needed for?",
        a: "9d3"
      }, {
        s: "H",
        p: "دا د لاچى ماتولو او د چټنئ جوړولو د پاره پکار راځى.",
        f: "daa du laachée maatawúlo aw du chuTnúy joRawúlo du paara pukáar raadzée",
        e: "This is needed for crushing cardamom and making chutney.",
        a: "9d4"
      }, {
        s: "L",
        p: "دا د کُوم ځائ جوړه ده؟",
        f: "daa du kum dzaay joRa da?",
        e: "Where is it made?",
        a: "9d5"
      }, {
        s: "H",
        p: "دا د لاهور جوړه ده.",
        f: "daa du laahór joRa da.",
        e: "It's made in Lahore",
        a: "9d6"
      }, {
        s: "L",
        p: "دا د څۀ نه جوړه ده؟",
        f: "daa du tsu na jóRa da?",
        e: "What is it made of?",
        a: "9d7"
      }, {
        s: "H",
        p: "دا د ګټې نه جوړه ده.",
        f: "daa du gaTé na jóRa da.",
        e: "It's made of stone.",
        a: "9d8"
      }, {
        s: "L",
        p: "چټنى څۀ شے دے؟",
        f: "chuTnée tsu shey dey?",
        e: "What is chutney?",
        a: "9d9"
      }, {
        s: "H",
        p: ["چټنى د خوراک څيز دے، او خلق يې د هضمې د پاره خورى.", "ډېره ترسکُونه وى."],
        f: ["chuTnée du khwaraak tseez dey, aw khalak ye du hazme du páara khoree.", "Dera traskóona wee."],
        e: ["Chutney is a kind of food and people eat it for digestion.", "It's very tasty."],
        a: "9d10"
      }, {
        s: "L",
        p: "په دې چټنى څنګه جوړېږى؟",
        f: "pu de chuTnée tsínga joReGee?",
        e: "How is chutney made (lit. 'made by it')?",
        a: "9d11"
      }]}</Dialogue>
    <h4 {...{
      "id": "note"
    }}>{`NOTE:`}</h4>
    <p>{`👆 Memorise a few of these learners’ questions and take a small recorder to your friend’s house. Record a few replies. When you get home transcribe the answers in phonetics and then get your helper to explain the answers to you. Try to do this weekly with new topics.`}</p>
    <p>{`You might get a reply like this from the last question in the Dialogue above:`}</p>
    <Examples mdxType="Examples">{[{
        p: "اول به مرچکے راواخلې.",
        f: "awál ba marchakéy raawaakhle.",
        e: "First you will bring some peppers.",
        a: "9a1"
      }, {
        p: "او په دې کښې به يې کېږدې.",
        f: "aw pu du ke ba ye keGde.",
        e: "And you will put them in it.",
        a: "9a2"
      }, {
        p: "بيا به يې په چټو کښې وټکې.",
        f: "byaa ba ye pu chaTóo ke óoTuke.",
        e: "Then you will hit them with a pestle.",
        a: "9a3"
      }, {
        p: "بيا به په کښې ټماټر واچوې.",
        f: "byaa ba pu ke TamaaTúr waachawe.",
        e: "Then you will put tomatoes in it.",
        a: "9a4"
      }, {
        p: "او هغه هم کټ کړه.",
        f: "aw hagha hum kuT kRa.",
        e: "And grind them also.",
        a: "9a5"
      }, {
        p: "لږ ساعت پس به چټنى تياره وى.",
        f: "lug saat pas ba chuTnée tayaara wee.",
        e: "A little time later chutney will be ready.",
        a: "9a6"
      }, {
        p: "دا کار څوک کوى؟",
        f: "daa kaar tsok kawee?",
        e: "Who does this work?",
        a: "9a7"
      }, {
        p: "دا د زنانوو کار دے.",
        f: "daa du zanaanawo kaar dey.",
        e: "It is ladies' work.",
        a: "9a8"
      }]}</Examples>
    <p>{`Here are two useful phrases to prompt people to say things for the recorder:`}</p>
    <Examples mdxType="Examples">{[{
        p: "بيا ووايه!",
        f: "byaa óowaaya!",
        e: "Say it again!",
        a: "9a9"
      }, {
        p: "دا جواب به په ټېپ ووائې؟",
        f: "daa jawáab ba pu Tep óowaaye?",
        e: "Will you put that reply on tape?",
        a: "9a10"
      }]}</Examples>
    <h4 {...{
      "id": "note-1"
    }}>{`NOTE:`}</h4>
    <p>{`The word `}<Word w={{
        p: "ټېپ",
        f: "Tep",
        a: "ttep"
      }} mdxType="Word" />{` is used of all forms of recording devices from audio cassettes to digital devices like MP3s.`}</p>
    <h2 {...{
      "id": "pronunciation-drill"
    }}>{`Pronunciation drill`}</h2>
    <p>{`In this drill pay special attention to the `}<Word w={{
        p: "غ",
        f: "gh",
        a: "gh-sound"
      }} mdxType="Word" />{` sound:`}</p>
    <Examples memorizingMoment mdxType="Examples">{[{
        p: "د هغه غټ هلک غټه غوښه خوښه ده.",
        f: "du hágha ghaT halúk gháTa ghwáxa khwáxa da.",
        e: "That fat boy likes beef.",
        a: "9p1"
      }]}</Examples>
    <p>{`👆 Ask your helper to correct your pronunciation. Keep drilling until you have the same rhythm and pronunciation.  `}</p>
    <p>{`Try this tongue twister:`}</p>
    <Examples memorizingMoment mdxType="Examples">{[{
        p: "چې غل غلا کوله نو غلې نوکرې په غُسلخانه کښې غوښې او غلې خوړې.",
        f: "che ghul ghlaa kawula no ghule nokare pu ghosalkhaaná ke ghwaxe aw ghalé khwaRe",
        e: "When the thief was stealing, the silent servant was eating meat and food in the bathroom.",
        a: "9p2"
      }]}</Examples>
    <h2 {...{
      "id": "the-preposition-word-w-p-د-f-du-e-of-a-du---with-feminine-nouns-and-adjectives"
    }}>{`The preposition `}<Word w={{
        p: "د",
        f: "du",
        e: "of",
        a: "du"
      }} mdxType="Word" />{` with feminine nouns and adjectives`}</h2>
    <Table bordered unset wide titleRow={["Possessive noun", "Subject", "Adjective", "Verb"]} mdxType="Table">{[[{
        f: "du jeenúy"
      }, {
        f: "mor"
      }, {
        f: "xkÚle"
      }, {
        f: "da."
      }, {
        span: 3,
        p: "د جينۍ مور ښُکلې ده.",
        e: "The girl's mother is beautiful.",
        a: "9s1"
      }], [{
        f: "du spoGmúy"
      }, {
        f: "raNáa"
      }, {
        f: "téza"
      }, {
        f: "da."
      }, {
        span: 3,
        p: "د سپوږمۍ رڼا تېزه ده.",
        e: "The light of the moon is bright.",
        a: "9s2"
      }], [{
        f: "du wúne"
      }, {
        f: "páaNe"
      }, {
        f: "shne"
      }, {
        f: "dee."
      }, {
        span: 3,
        p: "د ونې پاڼې شنې دى.",
        e: "The leaves of the tree are green.",
        a: "9s3"
      }], [{
        f: "du puxtaanó"
      }, {
        f: "hayáa"
      }, {
        f: "mushahóora"
      }, {
        f: "da."
      }, {
        span: 3,
        p: "د پښتنو حيا مشهُوره ده.",
        e: <>The <em>haya</em> of Pukhtuns is famous.</>,
        a: "9s4"
      }]]}</Table>
    <h4 {...{
      "id": "note-2"
    }}>{`NOTE:`}</h4>
    <img src={burqa} style={{
      float: "right"
    }} />
    <p>{`haya `}<Word w={{
        p: "حیا",
        f: "hayáa",
        a: "hayaa"
      }} mdxType="Word" />, like <Word w={{
        p: "غیرت",
        f: "gheyrát",
        a: "gheyrat"
      }} mdxType="Word" />, has no exact English equivalent; and also, like <Word w={{
        p: "غیرت",
        f: "gheyrát",
        a: "gheyrat"
      }} mdxType="Word" />{`, it has great cultural significance. `}<em parentName="p">{`hayaa`}</em>{` includes the idea of modesty, respectful behaviour and the seclusion of women from public view.`}</p>
    <h2 {...{
      "id": "feminine-nouns-and-adjectives-in-the-oblique-case"
    }}>{`Feminine nouns and adjectives in the oblique case`}</h2>
    <ol>
      <li parentName="ol">{`Feminine singular nouns and adjectives ending in `}<Word w={{
          p: "ـه",
          f: "-a",
          a: "a-sound-longer"
        }} mdxType="Word" /> are inflected to <Word w={{
          p: "ـې",
          f: "-e",
          a: "e-sound"
        }} mdxType="Word" /> in the oblique case, while their plural forms are inflected from <Word w={{
          p: "ـې",
          f: "-e",
          a: "e-sound"
        }} mdxType="Word" /> to <Word w={{
          p: "ـو",
          f: "-o",
          a: "o-sound-2"
        }} mdxType="Word" />; nouns and adjectives ending in <Word w={{
          p: "ـۍ",
          f: "-uy",
          a: "uy-sound-2"
        }} mdxType="Word" /> are inflected to <Word w={{
          p: "ـو",
          f: "-o",
          a: "o-sound-2"
        }} mdxType="Word" />{` in the plural form only. See the following examples:`}</li>
    </ol>
    <h4 {...{
      "id": "nouns"
    }}>{`Nouns`}</h4>
    <Table bordered unset titleRow={["", "Direct", "Oblique", "Direct", "Oblique"]} mdxType="Table">{[[{
        e: "f.s."
      }, {
        p: "سپۍ",
        f: "spuy",
        a: "spuy"
      }, {
        p: "سپۍ",
        f: "spuy",
        a: "spuy"
      }, {
        p: "ښځه",
        f: "xúdza",
        a: "xudza"
      }, {
        p: "ښځې",
        f: "xúdze",
        a: "xudze"
      }], [{
        e: "f.pl."
      }, {
        p: "سپۍ",
        f: "spuy",
        a: "spuy"
      }, {
        p: "سپیو",
        f: "spuyo"
      }, {
        p: "ښځې",
        f: "xúdze",
        a: "xudze"
      }, {
        p: "ښځو",
        f: "xúdzo",
        a: "xudzo"
      }]]}</Table>
    <h4 {...{
      "id": "adjectives"
    }}>{`Adjectives`}</h4>
    <Table bordered unset titleRow={["", "Direct", "Oblique", "Direct", "Oblique"]} mdxType="Table">{[[{
        e: "f.s."
      }, {
        p: "سپینه",
        f: "spéena",
        a: "speena"
      }, {
        p: "سپینې",
        f: "spéene",
        a: "speene"
      }, {
        p: "ستړې",
        f: "stúRe",
        a: "sturre"
      }, {
        p: "ستړې",
        f: "stúRe",
        a: "sturre"
      }], [{
        e: "f.pl."
      }, {
        p: "سپینې",
        f: "spéene",
        a: "speene"
      }, {
        p: "سپینو",
        f: "spéeno",
        a: "speeno"
      }, {
        p: "ستړې",
        f: "stúRe",
        a: "sturre"
      }, {
        p: "ستړو",
        f: "stúRo",
        a: "sturro"
      }], [{
        e: "f.s."
      }, {
        p: "نرۍ",
        f: "narúy",
        a: "naruy"
      }, {
        p: "نرۍ",
        f: "narúy",
        a: "naruy"
      }], [{
        e: "f.pl."
      }, {
        p: "نرۍ",
        f: "narúy",
        a: "naruy"
      }, {
        p: "نرو",
        f: "naró",
        a: "naro"
      }]]}</Table>
    <img src={chicken} />
    <Examples mdxType="Examples">{[{
        p: "دا زړه ونه ده.",
        f: "daa zaRá wúna da.",
        e: "This is an old tree.",
        a: "9e1"
      }, {
        p: "د زړې ونې مېوه خوږه ده.",
        f: "du zaRé wúne mewá khwaGá da.",
        e: "The old tree's fruit is sweet.",
        a: "9e2"
      }, {
        p: "هغه مشرې ښځې هسپتال ته ځى.",
        f: "hágha múshre xúdze haspatáal ta dzee.",
        e: "Those old ladies are going to the hospital.",
        a: "9e3"
      }, {
        p: "هغوى هغو مشرو ښځو سره ښار ته ځى.",
        f: "haghwée hágho múshro xúdzo sara xaar ta dzee.",
        e: "They are going to the city with those old ladies.",
        a: "9e4"
      }, {
        p: "هغه زمُونږ سپينه چرګه ده.",
        f: "hágha zmoonGa spéena chúrga da.",
        e: "That is our white hen.",
        a: "9e5"
      }, {
        p: "هغه زمُونږ د سپينې چرګې اګۍ ده.",
        f: "hágha zmoonG du spéene chúrge agúy da.",
        e: "That is our white hen's egg.",
        a: "9e6"
      }, {
        p: "د خندانې څلور چرګې دى.",
        f: "du khandaané tsalór chúrge dee.",
        e: "Khandana has four hens.",
        a: "9e7"
      }, {
        p: "د څلورو چرګو اګۍ غټې دى.",
        f: "du tsalóro chúrgo agúy gháTe dee.",
        e: "The eggs of the four hens are large.",
        a: "9e8"
      }]}</Examples>
    <ol {...{
      "start": 2
    }}>
      <li parentName="ol">{`Feminine singular nouns and adjectives ending in the suffix `}<Word w={{
          p: "ـې",
          f: "-e",
          a: "e-sound"
        }} mdxType="Word" /> are not inflected in the oblique case, while their plural is inflected to <Word w={{
          p: "ـو",
          f: "-o",
          a: "o-sound-2"
        }} mdxType="Word" />{`.`}</li>
    </ol>
    <Examples mdxType="Examples">{[{
        p: "زما ملګرې وږې ده.",
        f: "zmaa malúgre wúGe da.",
        e: "My (female) friend is hungry.",
        a: "9e9"
      }, {
        p: "دا موټر زما د ملګرو دے.",
        f: "daa moTúr zmaa du malgúro dey.",
        e: "This car belongs to my friends.",
        a: "9e10"
      }, {
        p: "د دې طالبعلمانې ستړې دى.",
        f: "du de taalibilmáane stúRe dee.",
        e: "Her (female) students are tired.",
        a: "9e11"
      }, {
        p: "د ستړو طالبعلمانو اُستاذه هم ستړې ده.",
        f: "du stúRo taalibilimáano Ustáaza hum stúRe da.",
        e: "The tired students' teacher is also tired.",
        a: "9e12"
      }]}</Examples>
    <h4 {...{
      "id": "note-3"
    }}>{`NOTE:`}</h4>
    <p>{`In the oblique case, numbers two through to ten change with the addition of the suffix `}<Word w={{
        p: "ـو",
        f: "-o",
        a: "o-sound-2"
      }} mdxType="Word" />; and <Word w={{
        p: "یو",
        f: "yo",
        a: "yo"
      }} mdxType="Word" /> changes to <Word w={{
        p: "یوې",
        f: "yúwe",
        a: "yuwe"
      }} mdxType="Word" />{`. For example:`}</p>
    <Examples mdxType="Examples">{[{
        p: "د یوې ښځې",
        f: "du yúwe xúdze",
        e: "one woman's",
        a: "9e13"
      }, {
        p: "د درېو چرګو",
        f: "du dréo chúrgo",
        e: "three hens'",
        a: "9e14"
      }, {
        p: "د لسو اُستاذانو",
        f: "du lúso Ustaazáano",
        e: "ten teachers'",
        a: "9e15"
      }]}</Examples>
    <h2 {...{
      "id": "the-transitive-verbs-word-w-p-خوړل-f-khorúl-e-to-eat---and-word-w-p-څښل-f-skul-e-to-drink--"
    }}>{`The transitive verbs `}<Word w={{
        p: "خوړل",
        f: "khoRúl",
        e: "to eat"
      }} mdxType="Word" /> and <Word w={{
        p: "څښل",
        f: "skul",
        e: "to drink"
      }} mdxType="Word" /></h2>
    <ol>
      <li parentName="ol"><Word w={{
          p: "خوړل",
          f: "khoRul",
          e: "to eat, to consume",
          a: "khorrul"
        }} mdxType="Word" /></li>
    </ol>
    <Examples mdxType="Examples">{[{
        p: "تۀ سحر ډېره ډوډۍ خورې؟",
        f: "tu saháar Déra DoDúy khoré?",
        e: "Do you eat a lot of food in the morning?",
        a: "9e16"
      }, {
        p: "نه، سحر بس يوه پراټه خورم.",
        f: "na, saháar bas yúwa paraaTá khorúm.",
        e: <>No, in the morning I eat just a single <em>paratha</em>.</>,
        a: "9e17"
      }, {
        p: "ستاسو ملګرى غوښه خورى؟",
        f: "staaso malgúree ghwáxa khoree?",
        e: "Do your friends eat meat?",
        a: "9e18"
      }, {
        p: "نه، هغوى غوښه نۀ خورى.",
        f: "na, haghwée ghwáxa nú-khoree.",
        e: "No, they don't eat meat.",
        a: "9e19"
      }, {
        p: "د شپې څۀ وخت ډوډۍ خورئ؟",
        f: "du shpe tsu wakht DoDúy khoréyy?",
        e: "When do you eat food at night?",
        a: "9e20"
      }, {
        p: "مُونږ اتۀ بجې ډوډۍ خورُو.",
        f: "moonG aatú bajé DoDúy khoróo.",
        e: "We eat food at eight o'clock.",
        a: "9e21"
      }, {
        p: "هغه وريژې هېڅ کله نۀ خورى؟",
        f: "haghá wreejze hets-kula nú-khoree?",
        e: "Does she never eat rice?",
        a: "9e22"
      }, {
        p: "نه، هغه صرف آلُوګان خورى.",
        f: "na, haghá síraf aloogáan khorée.",
        e: "No, she only eats potatoes.",
        a: "9e23"
      }]}</Examples>
    <ol {...{
      "start": 2
    }}>
      <li parentName="ol"><Word w={{
          p: "څښل",
          f: "skul",
          e: "to drink, smoke",
          a: "skul"
        }} mdxType="Word" /></li>
    </ol>
    <Examples mdxType="Examples">{[{
        p: "تۀ د شپې چائې څښې؟",
        f: "tu du shpe chaay ske?",
        e: "Do you drink tea at night?",
        a: "9e24"
      }, {
        p: "او، د شپې قهوه څښم.",
        f: "aw, du shpe qawá skum.",
        e: "Yes, I drink green tea at night.",
        a: "9e25"
      }, {
        p: "تاسو ما سره چائې څښئ؟",
        f: "táaso maa sara chaay skeyy?",
        e: "Will you drink tea with me?",
        a: "9e26"
      }, {
        p: "مِهربانى، صرف اوبۀ څکم.",
        f: "mirabaanée, síraf oobú skum.",
        e: "Thanks, I just drink water.",
        a: "9e27"
      }, {
        p: "تاسو به څۀ څښئ تورې چائې کۀ قهوه؟",
        f: "táaso ba tsu skeyy, tóre chaay ku kawá?",
        e: "What will you drink, black tea or green tea?",
        a: "9e28"
      }, {
        p: "خیر، تورې چائې به څښُو.",
        f: "kheyr, tóre chaay ba skóo.",
        e: "Fine, I'll drink black tea.",
        a: "9e29"
      }, {
        p: "دے په يوه ورځ کښې څومره سګرټې څښى؟",
        f: "dey pu yúwawrudz ke tsóomra sigríTe skee?",
        e: "How many cigarettes does he smoke a day?",
        a: "9e30"
      }]}</Examples>
    <h4 {...{
      "id": "note-4"
    }}>{`NOTE:`}</h4>
    <p><em parentName="p">{`kawa`}</em>{` is green tea. It is a favourite in Khyber Pukhtunkhwa.`}</p>
    <p>{`👆 Ask your language helper to show you the art of preparing kawa and have some together.`}</p>
    <h2 {...{
      "id": "the-preposition-word-w-p-د--نه-f-du--na-e-from-a-du-na--"
    }}>{`The preposition `}<Word w={{
        p: "د ... نه",
        f: "du ... na",
        e: "from",
        a: "du-na"
      }} mdxType="Word" /></h2>
    <Examples mdxType="Examples">{[{
        p: "ستا پلار د کُوم ځائ نه په ټيليفون خبرې کوى؟",
        f: "staa plaar du kum dzaay na pu Telefóon khabúre kawee?",
        e: "Where is your father calling from on the phone?",
        a: "9e31"
      }, {
        p: "هغه د ټانک نه خبرې کوى.",
        f: "haghá du Taank na khabúre kawee.",
        e: "He is calling from Tank.",
        a: "9e32"
      }, {
        p: "ستا زوئ څۀ وخت د سکُول نه کور ته راځى؟",
        f: "staa zooy tsu wakht du skool na kor ta raadzée?",
        e: "What time does your son come home from school?",
        a: "9e33"
      }, {
        p: "هغه دا وخت د سکُول نه راځى.",
        f: "haghá daa wakht du skool na raadzée.",
        e: "He comes from school at this time.",
        a: "9e34"
      }, {
        p: "ستا ملګرے د کُوم ملک نه دلته راځى؟",
        f: "staa malgúrey du kum mulk na dălta raadzée?",
        e: "Which country does your friend come here from?",
        a: "9e35"
      }, {
        p: "هغه د اِنګلستان نه راځى.",
        f: "haghá du inglistáan na raadzee.",
        e: "He comes from England.",
        a: "9e36"
      }]}</Examples>
    <h2 {...{
      "id": "the-interrogatives-word-w-p-کُوم-f-kum-e-which-a-kum---and-word-w-p-کُوم-یو-f-kum-yo-e-which-one-a-kum-yo--"
    }}>{`The interrogatives `}<Word w={{
        p: "کُوم",
        f: "kum",
        e: "which",
        a: "kum"
      }} mdxType="Word" /> and <Word w={{
        p: "کُوم یو",
        f: "kum yo",
        e: "which one",
        a: "kum-yo"
      }} mdxType="Word" /></h2>
    <Examples mdxType="Examples">{[{
        p: "کُوم سړے ډېر کار کوى؟",
        f: "kum saRéy Der kaar kawée?",
        e: "Which man works a lot?",
        a: "9e37"
      }, {
        p: "کُوم هلک ورانے کوى؟",
        f: "kum halúk wraanéy kawée?",
        e: "Which boy is naughty?",
        a: "9e38"
      }, {
        p: "کُومه ښځه ډاکټره ده؟",
        f: "kúma xúdza DakTúra da?",
        e: "Which woman is a doctor?",
        a: "9e39"
      }, {
        p: "کُومه جينۍ ښۀ پخلے کوى؟",
        f: "kúma jeenúy xu pakhléy kawée?",
        e: "Which of the girls cooks well?",
        a: "9e40"
      }, {
        p: "کُوم يو ډېر وران دے؟",
        f: "kum yo Der wraan dey?",
        e: "Which one is really naughty?",
        a: "9e41"
      }, {
        p: "کُومه يوه جوړه غواړې؟",
        f: "kúma yúwa joRá ghwaaRe?",
        e: "Which pair do you want?",
        a: "9e42"
      }]}</Examples>
    <h2 {...{
      "id": "note-5"
    }}>{`NOTE:`}</h2>
    <p>{`You will also hear the word `}<Word w={{
        p: "کُوم",
        f: "kum",
        a: "kum"
      }} mdxType="Word" />{` pronounced as `}<strong parentName="p">{`kUm`}</strong>{` or `}<strong parentName="p">{`koom`}</strong>{`.`}</p>
    <h2 {...{
      "id": "comprehension-drill-more-commands-to-obey"
    }}>{`Comprehension drill: More commands to obey`}</h2>
    <p>{`👆 Try to obey the following commands as fast as possible. Have your helper mix up the order.`}</p>
    <Examples mdxType="Examples">{[{
        p: "کُوز شه!",
        f: "kooz sha!",
        e: "Get down!",
        a: "9c1"
      }, {
        p: "وخېژه!",
        f: "ookhejza!",
        e: "Climb in!",
        a: "9c2"
      }, {
        p: "دننه لاړ شه!",
        f: "dunúna laaRsha!",
        e: "Go in! (male)",
        a: "9c3"
      }, {
        p: "هلته ودرېږه!",
        f: "hálta óo-dréGa!",
        e: "Stop there!",
        a: "9c4"
      }, {
        p: "څمله!",
        f: "tsúmla!",
        e: "Lie down!",
        a: "9c5"
      }, {
        p: "پاڅه!",
        f: "páatsa!",
        e: "Stand up!",
        a: "9c6"
      }, {
        p: "لاړ شه!",
        f: "laaR sha!",
        e: "Go away! (male)",
        a: "9c7"
      }]}</Examples>
    <p>{`Now add these to the commands of the drill.`}</p>
    <h2 {...{
      "id": "memorising-moments"
    }}>{`Memorising moments`}</h2>
    <h4 {...{
      "id": "word-w-p-متلونه-f-matalóona-a-matuloona--"
    }}><Word w={{
        p: "متلونه",
        f: "matalóona",
        a: "matuloona"
      }} mdxType="Word" /></h4>
    <Examples memorizingMoment mdxType="Examples">{memorizingMoments[9]}</Examples>
    <img src={boysStick} />
    <h2 {...{
      "id": "exercises-for-lesson-nine"
    }}>{`Exercises for Lesson Nine`}</h2>
    <ol>
      <li parentName="ol">{`Make sentences in the possessive case from the following pairs of words.`}</li>
    </ol>
    <p>{`For example, for the pair `}<Word w={{
        p: "احمد / کور",
        f: "ahmad / kor",
        e: ""
      }} mdxType="Word" />{`, you would write:`}</p>
    <Examples mdxType="Examples">{[{
        p: "دا د احمد کور دے.",
        f: "daa du ahmad kor dey."
      }]}</Examples>
    <Quiz mdxType="Quiz">{[{
        q: {
          p: "حینۍ / کتاب",
          f: "jeenúy / kitáab"
        },
        answer: [{
          p: "دا د جینۍ کتاب دے.",
          f: "daa du jeenúy kitáab dey."
        }]
      }, {
        q: {
          p: "ماشومه / مور",
          f: "maashooma / mor"
        },
        answer: [{
          p: "دا د ماشومې مور ده.",
          f: "daa du maashoome mor da."
        }]
      }, {
        q: {
          p: "هلک / څپلۍ",
          f: "halúk / tsaplúy"
        },
        answer: [{
          p: "دا د هلک څپلۍ دی.",
          f: "daa du halúk tsaplúy dee."
        }, {
          p: "دا د هلک څپلۍ ده.",
          f: "daa du halúk tsaplúy da."
        }]
      }, {
        q: {
          p: "ډاکټره / لور",
          f: "DakTúra / loor"
        },
        answer: [{
          p: "دا د ډکټرې لور ده.",
          f: "daa du DakTúre loor da."
        }]
      }, {
        q: {
          p: "سړے / کار",
          f: "saRey / kaar"
        },
        answer: [{
          p: "دا د سړی کار دے.",
          f: "daa du saRee kaar dey."
        }]
      }, {
        q: {
          p: "سړی / کار",
          f: "saRee / kaar"
        },
        answer: [{
          p: "دا د سړو کار دے.",
          f: "daa du saRo kaar dey."
        }, {
          p: "دا د سړیو کار دے.",
          f: "daa du saRiyo kaar dey."
        }]
      }, {
        q: {
          p: "ونه / پاڼې",
          f: "wúna / páaNe"
        },
        answer: [{
          p: "دا د ونې پاڼې دی.",
          f: "daa du wúne paaNe dee."
        }]
      }, {
        q: {
          p: "خوېندې / ورور",
          f: "khwende / wror"
        },
        answer: [{
          p: "دا د خوېندو ورور دے.",
          f: "daa du khwendo wroor dey."
        }]
      }]}</Quiz>
    <ol {...{
      "start": 2
    }}>
      <li parentName="ol">{`Fill in the blanks in the following sentences with the present tense form of the infinitive listed above the sentence.`}</li>
    </ol>
    <Quiz mdxType="Quiz">{[{
        pref: {
          p: "تلل",
          f: "tlul"
        },
        beg: {
          p: "مُونږ د دې سره ښار ته",
          f: "moonG du de sara xaar ta"
        },
        end: {
          p: ".",
          f: "."
        },
        answer: [{
          p: "ځُو",
          f: "dzoo"
        }]
      }, {
        pref: {
          p: "خوړل",
          f: "khoRúl"
        },
        beg: {
          p: "زۀ شپږ بجې ډوډۍ",
          f: "zu shpuG báje DoDúy"
        },
        end: {
          p: ".",
          f: "."
        },
        answer: [{
          p: "خورم",
          f: "khorum"
        }]
      }, {
        pref: {
          p: "څښل",
          f: "skul"
        },
        beg: {
          p: "دا هېڅ چائې نۀ",
          f: "daa hets chaay nu"
        },
        end: {
          p: "يواځې اوبۀ.",
          f: "yawáaze oobú."
        },
        answer: [{
          p: "څښی",
          f: "skee"
        }, {
          p: "سکی",
          f: "skee"
        }]
      }, {
        pref: {
          p: "کول",
          f: "kawúl"
        },
        beg: {
          p: "دے هره ورځ په ټيليفون خبرې",
          f: "dey hărawrudz pu Teeleefóon khabure"
        },
        end: {
          p: ".",
          f: "."
        },
        answer: [{
          p: "کوی",
          f: "kawee"
        }]
      }, {
        pref: {
          p: "راتلل",
          f: "raatlúl"
        },
        beg: {
          p: "تاسو څۀ وخت زمُونږ کور ته",
          f: "táaso tsu wakht zmoonG kor ta"
        },
        end: {
          p: "؟",
          f: "?"
        },
        answer: [{
          p: "راځئ",
          f: "radzeyy"
        }]
      }, {
        pref: {
          p: "کېدل",
          f: "kedúl"
        },
        beg: {
          p: "زما ملګرے مړ",
          f: "zmaa malgúrey muR"
        },
        end: {
          p: ".",
          f: "."
        },
        answer: [{
          p: "کېږی",
          f: "keGee"
        }]
      }, {
        pref: {
          p: "کېدل",
          f: "kedúl"
        },
        beg: {
          p: "زمُونږ ماشُومان اوس اُودهٔ",
          f: "zmoonG maashoomáan oos oodú"
        },
        end: {
          p: ".",
          f: "."
        },
        answer: [{
          p: "کېږی",
          f: "keGee"
        }]
      }]}</Quiz>
    <ol {...{
      "start": 3
    }}>
      <li parentName="ol">{`Say the following sentences and alter the numbers and pronouns:`}</li>
    </ol>
    <Examples mdxType="Examples">{[{
        p: "زۀ د اوؤ خوېندو ورور يم.",
        e: "I am the brother of seven sisters.",
        a: "9q1"
      }, {
        p: "زۀ د دولسو بچو پلار يم.",
        e: "I am the father of twelve children.",
        a: "9q2"
      }, {
        p: "زۀ د څلورو ښځو خاوند يم.",
        e: "I am the husband of four wives.",
        a: "9q3"
      }]}</Examples>
    <h2 {...{
      "id": "finding-out-more-about-things-and-activities"
    }}>{`Finding out more about things and activities`}</h2>
    <p>{`👆 You can multiply language learning easily using pictures. The following pages can be photocopied and taken with you. Each picture can be a trigger for conversation. Record some of the responses and analyse them later with your helper. You can also use the illustrations in `}<a parentName="p" {...{
        "href": "https://www.interlitfoundation.org/learning-dari/picture-it-in-dari-and-pashto"
      }}>{`Picture It in Dari and Pashto`}</a>{` to generate more conversations with your helper.`}</p>
    <ol>
      <li parentName="ol">{`Some useful phrases to find out about things are:`}</li>
    </ol>
    <Examples mdxType="Examples">{[{
        p: "دې ته پښتو کښې څۀ وائې؟",
        f: "de ta puxtó ke tsu waaye?",
        e: "What is this called in Pukhto?",
        a: "9e44"
      }, {
        p: "دا ___ د څۀ په کار راځى؟",
        f: "da ___ du tsu pu kaar raadzee?",
        e: "What is ___ used for?",
        a: "9e45"
      }, {
        p: "تۀ په خپله ___ استعمالولے شې؟",
        f: "tu pu khpula ___ istimaalawúley she?",
        e: "Do you know how to use a ___?",
        a: "9e46"
      }, {
        p: "دا ___ ګران دے؟",
        f: "da ___ graan dey / graana da?",
        e: "Is ___ (masc) expensive?",
        a: "9e47"
      }, {
        p: "دا ___  ګرانه ده؟",
        f: "da ___ graan dey / graana da?",
        e: "Is ___ (fem) expensive?",
        a: "9e48"
      }, {
        p: "___ څوک استعمالوى؟",
        f: "___ tsok istimaalawée?",
        e: "Who uses a ___?",
        a: "9e49"
      }]}</Examples>

    <div style={{
      maxWidth: "80%"
    }}>
    {items.map(row => <div style={imgFlexRowStyle}>
            {row.map(item => <img src={item} style={{
          maxHeight: "6rem"
        }} />)}
        </div>)}
    </div>
    <ol {...{
      "start": 2
    }}>
      <li parentName="ol">{`Some useful phrases to explore activities:`}</li>
    </ol>
    <Examples mdxType="Examples">{[{
        p: "هغه څۀ کوى؟",
        f: "haghá tsu kawée?",
        e: "What is he doing?",
        a: "9e50"
      }, {
        p: "تۀ دا کار څنګه کوې؟",
        f: "tu daa kar tsínga kawe?",
        e: "How do you do this work?",
        a: "9e51"
      }, {
        p: "ما ته دا کار کول وښايه.",
        f: "maa ta daa kaar kawúl óoxaaya.",
        e: "Show me how to do this work.",
        a: "9e52"
      }, {
        p: "دې کار ته څۀ وائې؟",
        f: "de kaar ta tsu wáaye?",
        e: "What is this job called?",
        a: "9e53"
      }]}</Examples>
    <div style={imgFlexRowStyle}>
    <img src={potter} style={{
        maxHeight: "10rem"
      }} />
    <img src={boyCook} style={{
        maxHeight: "10rem"
      }} />
    </div>
    <img src={loom} />
    <h4 {...{
      "id": "note-6"
    }}>{`NOTE:`}</h4>
    <p>{`It is best to keep on using the name of a new thing in your questions. The more you use it, the more it “sticks” in your memory.`}</p>
    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;
export const tableOfContents = (components={}) => [
  {
    id: "dialogue-nine-multiplying-language-learning",
    level: 2,
    title: "Dialogue nine: Multiplying language learning",
    children: [
        
      ]
  },
{
    id: "pronunciation-drill",
    level: 2,
    title: "Pronunciation drill",
    children: [
        
      ]
  },
{
    id: "the-preposition-word-w-p-د-f-du-e-of-a-du---with-feminine-nouns-and-adjectives",
    level: 2,
    title: <React.Fragment>{`The preposition `}<Word w={{ p: "د", f: "du", e: "of", a: "du" }} />{` with feminine nouns and adjectives`}</React.Fragment>,
    children: [
        
      ]
  },
{
    id: "feminine-nouns-and-adjectives-in-the-oblique-case",
    level: 2,
    title: "Feminine nouns and adjectives in the oblique case",
    children: [
        
      ]
  },
{
    id: "the-transitive-verbs-word-w-p-خوړل-f-khorúl-e-to-eat---and-word-w-p-څښل-f-skul-e-to-drink--",
    level: 2,
    title: <React.Fragment>{`The transitive verbs `}<Word w={{ p: "خوړل", f: "khoRúl", e: "to eat" }} /> and <Word w={{ p: "څښل", f: "skul", e: "to drink" }} /></React.Fragment>,
    children: [
        
      ]
  },
{
    id: "the-preposition-word-w-p-د--نه-f-du--na-e-from-a-du-na--",
    level: 2,
    title: <React.Fragment>{`The preposition `}<Word w={{ p: "د ... نه", f: "du ... na", e: "from", a: "du-na" }} /></React.Fragment>,
    children: [
        
      ]
  },
{
    id: "the-interrogatives-word-w-p-کُوم-f-kum-e-which-a-kum---and-word-w-p-کُوم-یو-f-kum-yo-e-which-one-a-kum-yo--",
    level: 2,
    title: <React.Fragment>{`The interrogatives `}<Word w={{ p: "کُوم", f: "kum", e: "which", a: "kum" }} /> and <Word w={{ p: "کُوم یو", f: "kum yo", e: "which one", a: "kum-yo" }} /></React.Fragment>,
    children: [
        
      ]
  },
{
    id: "note-5",
    level: 2,
    title: "NOTE:",
    children: [
        
      ]
  },
{
    id: "comprehension-drill-more-commands-to-obey",
    level: 2,
    title: "Comprehension drill: More commands to obey",
    children: [
        
      ]
  },
{
    id: "memorising-moments",
    level: 2,
    title: "Memorising moments",
    children: [
        
      ]
  },
{
    id: "exercises-for-lesson-nine",
    level: 2,
    title: "Exercises for Lesson Nine",
    children: [
        
      ]
  },
{
    id: "finding-out-more-about-things-and-activities",
    level: 2,
    title: "Finding out more about things and activities",
    children: [
        
      ]
  }
]

export const frontMatter = {
  "title": "Lesson Nine"
}

