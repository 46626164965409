
import React from 'react'
import { mdx } from '@mdx-js/react'
export const readingTime = {"text":"16 min read","minutes":15.165,"time":909900,"words":3033}
/* @jsx mdx */
import Dialogue from "../components/Dialogue";
import girlsHead from "../assets/images/girls-head.png";
import littleGirl from "../assets/images/little-girl.png";
import manLookingDown from "../assets/images/man-looking-down.png";
import carryingWater from "../assets/images/carrying-water.png";
import tree from "../assets/images/tree.png";
import Word from "../components/Word";
import Examples from "../components/Examples";
import Table from "../components/Table";
import Quiz from "../components/Quiz";
import examples from "./lesson-five-examples.js";
import memorizingMoment from "./memorizing-moments";

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope")
  return <div {...props}/>
};

const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <h2 {...{
      "id": "dialogue-five-ladies-talk"
    }}>{`Dialogue five: Ladies’ talk`}</h2>
    <Dialogue audio="l5-ld" mdxType="Dialogue">{[{
        s: "L",
        p: "وادٔ شوې یې؟",
        f: "wáadu shiwe ye?",
        e: "Are you married?",
        a: "l5-ld-1"
      }, {
        s: "H",
        p: "او، شپږ کاله کېږی چې وادهٔ شوې یم.",
        f: "aw, shpuG kaala keGee che waadu shiwe yim.",
        e: "Yes, it is six years since I have been married.",
        a: "l5-ld-2"
      }, {
        s: "L",
        p: "بچی دې ښهٔ دی؟",
        f: "bachee de xu dee?",
        e: "Are your children well?",
        a: "l5-ld-3"
      }, {
        s: "H",
        p: "او ښهٔ دی.",
        f: "aw, xu dee.",
        e: "Yes, they are well.",
        a: "l5-ld-4"
      }, {
        s: "L",
        p: "ستا څو ماشُومان دی؟",
        f: "staa tso maashoomáan dee?",
        e: "How many children do you have?",
        a: "l5-ld-5"
      }, {
        s: "H",
        p: "ماشاءالله، زما څلور ماشُومان دی.",
        f: "masha'allah zmaa tsalór maashoomáan dee.",
        e: "I have four children, thank God.",
        a: "l5-ld-6"
      }, {
        s: "H",
        p: "یوه لُور او درې زامن دی.",
        f: "yawa loor aw dre zaamún dee.",
        e: "One daughter and three sonse.",
        a: "l5-ld-7"
      }, {
        s: "L",
        p: "شکر دے.",
        f: "shUkur dey!",
        e: "Be grateful!",
        a: "l5-ld-8"
      }, {
        s: "L",
        p: "ستا خاوند څهٔ کار کوی؟",
        f: "staa khaawúnd tsu kaar kawee?",
        e: "What job does your husband do?",
        a: "l5-ld-9"
      }, {
        s: "H",
        p: "هغه دُوکاندار دے.",
        f: "haghá dUkaandáar dey.",
        e: "He is a shopkeeper.",
        a: "l5-ld-10"
      }]}</Dialogue>
    <img src={girlsHead} style={{
      float: "right"
    }} />
    <h4 {...{
      "id": "note"
    }}>{`NOTE:`}</h4>
    <p>{`The greetings used in previous lessons are left out in this and future dialogues – be sure to use them though.`}</p>
    <h2 {...{
      "id": "mens-talk"
    }}>{`Men’s talk`}</h2>
    <Dialogue audio="l5-md" mdxType="Dialogue">{[{
        s: "L",
        p: "تا وادهٔ کړے دے؟",
        f: "taa waadú kúRey dey?",
        e: "Are you married?",
        a: "l5-md-1"
      }, {
        s: "H",
        p: "او، وادهٔ مې کړے دے.",
        f: "aw, waadu me kúRey dey.",
        e: "Yes, I'm married.",
        a: "l5-md-2"
      }, {
        s: "L",
        p: "ستا څو ماشُومان دی؟",
        f: "staa tso maashoomáan dee?",
        e: "How many children do you have?",
        a: "l5-md-3"
      }, {
        s: "H",
        p: "زما يو زوئ او دوه جينکۍ دى.",
        f: "zmaa yo zooy aw dwa jeenaakúy dee.",
        e: "I have one son and two daughters.",
        a: "l5-md-4"
      }, {
        s: "H",
        p: "او ستا؟",
        f: "aw staa?",
        e: "And you?",
        a: "l5-md-5"
      }, {
        s: "L",
        p: "نه، وادۀ مې نۀ دے کړے.",
        f: "na, waadú me nu dey kúRey.",
        e: "No, I haven't married.",
        a: "l5-md-6"
      }, {
        s: "L",
        p: "تاسو څهٔ کار کوئ؟",
        f: "taaso tsu kaar kaweyy?",
        e: "What work do you do?",
        a: "l5-md-7"
      }, {
        s: "H",
        p: "زهٔ دُوکاندار یم.",
        f: "zu dookaandáar yim.",
        e: "I'm a shopkeeper.",
        a: "l5-md-8"
      }, {
        s: "L",
        p: "کار څنګه روان دے؟",
        f: "kaar tsínga rawáan dey?",
        e: "How is business going?",
        a: "l5-md-9"
      }, {
        s: "H",
        p: "ښه دے.",
        f: "xu dey.",
        e: "It's good.",
        a: "l5-md-10"
      }]}</Dialogue>
    <h4 {...{
      "id": "note-1"
    }}>{`NOTE:`}</h4>
    <img src={manLookingDown} style={{
      float: "right"
    }} />
    <p>{`When discussing marriage status (i.e., whether someone is married or not), men talk about “getting married” with a transitive verb `}<Word w={{
        p: "وادهٔ کول",
        f: "waadu kawul"
      }} mdxType="Word" />, while females talk about becoming married with an intransitive verb <Word w={{
        p: "واده کېدل",
        f: "waadu kedul"
      }} mdxType="Word" />{`.`}</p>
    <h2 {...{
      "id": "some-professions"
    }}>{`Some professions`}</h2>
    <Examples twoColumns mdxType="Examples">{[{
        p: "نانبائی",
        f: "naanbaa`ee",
        e: "bread maker",
        a: "naanbaaee"
      }, {
        p: "نائی",
        f: "naa`ee",
        e: "barber",
        a: "naaee"
      }, {
        p: "مزدُور",
        f: "mazdóor",
        e: "labourer",
        a: "mazdoor"
      }, {
        p: "زمیدار",
        f: "zameedáar",
        e: "farmer",
        a: "zameedaar"
      }, {
        p: "څوکیدار",
        f: "tsokeedáar",
        e: "watchman",
        a: "tsokeedaar"
      }, {
        p: "بېنک والا",
        f: "benk waalaa",
        e: "banker",
        a: "benkwaalaa"
      }, {
        p: "قصاب",
        f: "qasáab",
        e: "butcher",
        a: "qasaab"
      }, {
        p: "خانسامه",
        f: "khaansaamá",
        e: "cook",
        a: "khaansaama"
      }, {
        p: "درزی",
        f: "darzée",
        e: "tailor",
        a: "darzee"
      }, {
        p: "ډاکی",
        f: "Daakée",
        e: "postman",
        a: "ddaakee"
      }, {
        p: "فوجی",
        f: "fojée",
        e: "soldier",
        a: "fojee"
      }, {
        p: "سبائی",
        f: "sipaa`ee",
        e: "policeman",
        a: "sipaaee"
      }, {
        p: "مولانا",
        f: "molaanáa",
        e: "religious scholar",
        a: "molaanaa"
      }, {
        p: "تبلیغی",
        f: "tableeghée",
        e: "preacher",
        a: "tableeghee"
      }, {
        p: "لوهار",
        f: "loháar",
        e: "blacksmith",
        a: "lohaar"
      }, {
        p: "زرګر",
        f: "zargúr",
        e: "goldsmith",
        a: "zargur"
      }, {
        p: "چمیار",
        f: "chamyáar",
        e: "cobbler",
        a: "chamyaar"
      }, {
        p: "ګِلکار",
        f: "gilkáar",
        e: "mason",
        a: "gilkaar"
      }, {
        p: "ترکاڼ",
        f: "tarkáaN",
        e: "carpenter",
        a: "tarkaann"
      }, {
        p: "شپون",
        f: "shpoon",
        e: "shepherd",
        a: "shpoon"
      }]}</Examples>
    <p>{`In some cases, the addition of the word `}<Word w={{
        p: "توب",
        f: "tob",
        a: "tob"
      }} mdxType="Word" />{` to the profession describes the activity undertaken. See the examples below:`}</p>
    <Examples twoColumns mdxType="Examples">{[{
        p: "درزی",
        f: "darzee",
        e: "tailor",
        a: "darzee"
      }, {
        p: "مالی",
        f: "maalée",
        e: "gardener",
        a: "maalee"
      }, {
        p: "مُلا",
        f: "mUláa",
        e: "mullah",
        a: "muulaa"
      }, {
        p: "فوجی",
        f: "fojée",
        e: "soldier",
        a: "fojee"
      }, {
        p: "شپون",
        f: "shpoon",
        e: "shepherd",
        a: "shpoon"
      }, {
        p: "درزی توب",
        f: "darzeetób",
        e: "tailoring",
        a: "darzeetob"
      }, {
        p: "مالی توب",
        f: "maaleetób",
        e: "gardening",
        a: "maaleetob"
      }, {
        p: "مُلا توب",
        f: "mUlaatób",
        e: "work of a mullah",
        a: "muulaatob"
      }, {
        p: "فوجی توب",
        f: "fojeetób",
        e: "soldiering",
        a: "fojeetob"
      }, {
        p: "شپُون توب",
        f: "shpoontób",
        e: "shepherding",
        a: "shpoontob"
      }]}</Examples>
    <h2 {...{
      "id": "pronunciaton-drill"
    }}>{`Pronunciaton Drill`}</h2>
    <ol>
      <li parentName="ol">{`Contrasting `}<Word w={{
          p: "ډ",
          f: "D",
          a: "dd-sound"
        }} mdxType="Word" /> and <Word w={{
          p: "د",
          f: "d",
          a: "d-sound"
        }} mdxType="Word" /></li>
    </ol>
    <Examples mdxType="Examples">{[{
        p: "ډوډۍ شته؟",
        f: "DoDuy shta?",
        e: "Is food available?",
        a: "5-pd-1"
      }, {
        p: "د دۀ څۀ نُوم دے؟",
        f: "du dú tsu noom dey?",
        e: "What is his name?",
        a: "5-pd-2"
      }, {
        p: "ډک راوړه.",
        f: "Duk ráawRa.",
        e: "Bring it full.",
        a: "5-pd-3"
      }, {
        p: "دا ښهٔ دے.",
        f: "daa xu dey.",
        e: "This is good.",
        a: "5-pd-4"
      }, {
        p: "زړۀ مې ډاډه شو.",
        f: "zRu me DaDa sho.",
        e: "My heart became bold.",
        a: "5-pd-5"
      }, {
        p: "زړۀ مې بد شو.",
        f: "zRu me bud sho.",
        e: "I became upset.",
        a: "5-pd-6"
      }]}</Examples>
    <ol {...{
      "start": 2
    }}>
      <li parentName="ol">{`Contrasting `}<Word w={{
          p: "و",
          f: "oo",
          a: "oo-sound"
        }} mdxType="Word" /> and <Word w={{
          p: "",
          f: "u",
          a: "u-sound"
        }} mdxType="Word" /></li>
    </ol>
    <Examples mdxType="Examples">{[{
        p: "تۀ مزدُور يې؟",
        f: "tu mazdóor ye?",
        e: "Are you a labourer?",
        a: "5-pd-7"
      }, {
        p: "تاسو زرګر يئ؟",
        f: "táaso zargúr yeyy?",
        e: "Are you [polite] a goldsmith?",
        a: "5-pd-8"
      }, {
        p: "په پُل پُوره دوه موټرې ځائېږى.",
        f: "pu pUl poora dwa moTure dzaayeGee.",
        e: "There is room for two cars on the bridge.",
        a: "5-pd-9"
      }, {
        p: "په هفته کښې پُوره اووۀ ورځې دى.",
        f: "pu haftá ke poora oowú wrudze dee.",
        e: "There are seven days in a week.",
        a: "5-pd-10"
      }]}</Examples>
    <h2 {...{
      "id": "feminine-pronouns"
    }}>{`Feminine pronouns`}</h2>
    <p>{`There are two feminine singular pronouns. For people in sight, `}<Word w={{
        p: "دا",
        f: "daa",
        a: "daa"
      }} mdxType="Word" /> is used. For people who are 'over there' (i.e. far away or out of sight) <Word w={{
        p: "هغه",
        f: "haghá",
        a: "hagha-pronoun"
      }} mdxType="Word" />{` is used.`}</p>
    <p>{`The equative becomes `}<Word w={{
        p: "ده",
        f: "da",
        a: "da"
      }} mdxType="Word" /> with 3rd person singular objects. For example, 'she is' becomes <Word w={{
        p: "دا ده",
        f: "daa da",
        a: "daa-da"
      }} mdxType="Word" /> or <Word w={{
        p: "هغه ده",
        f: "haghá da",
        a: "hagha-da"
      }} mdxType="Word" />{` depending on where the person is, in relation to you.`}</p>
    <h2 {...{
      "id": "feminine-singular-nouns"
    }}>{`Feminine singular nouns`}</h2>
    <Table titleRow={["Pronoun", "Feminine noun", "Verb"]} bordered mdxType="Table">{[[{
        f: "zu"
      }, {
        f: "DakTúra"
      }, {
        f: "yim."
      }, {
        p: "زهٔ ډاکټره یم.",
        e: "I am a doctor.",
        a: "5-fs-1"
      }], [{
        f: "tu"
      }, {
        f: "Ustáaza"
      }, {
        f: "ye?"
      }, {
        p: "تۀ اُستاذه يې؟",
        e: "Are you a teacher?",
        a: "5-fs-2"
      }], [{
        f: "daa"
      }, {
        f: "afghaaná"
      }, {
        f: "da?"
      }, {
        p: "دا افغانه ده؟",
        e: "Is she an Afghan?",
        a: "5-fs-3"
      }], [{
        f: "haghá"
      }, {
        f: "puxtaná"
      }, {
        f: "da."
      }, {
        p: "هغه پښتنه ده.",
        e: "She is a Pukhutn.",
        a: "5-fs-4"
      }]]}</Table>
    <p>{`NOTE:
The addition of `}<Word w={{
        p: "ه",
        f: "a",
        a: "a-sound"
      }} mdxType="Word" /> to a noun indicates that it is feminine. You may also hear an extra <Word w={{
        p: "ه",
        f: "a",
        a: "a-sound"
      }} mdxType="Word" />{` added at the end of verbs to make a rhyme:`}</p>
    <Examples mdxType="Examples">{[{
        p: "زهٔ ډاکټره یمه.",
        f: "zu DaakTúra yima",
        e: "I (f.) am a doctor."
      }]}</Examples>
    <h2 {...{
      "id": "feminine-singular-adjectives"
    }}>{`Feminine singular adjectives`}</h2>
    <Table titleRow={["Pronoun", "Feminine adjective", "Verb"]} bordered mdxType="Table">{[[{
        f: "zu"
      }, {
        f: "stúRe"
      }, {
        f: "yim."
      }, {
        p: "زۀ ستړې يم.",
        e: "I am tired.",
        a: "5-fa-1"
      }], [{
        f: "tu"
      }, {
        f: "jóRa"
      }, {
        f: "ye?"
      }, {
        p: "تۀ جوړه يې؟",
        e: "Are you well?",
        a: "5-fa-2"
      }], [{
        f: "daa"
      }, {
        f: "narúy"
      }, {
        f: "da."
      }, {
        p: "دا نرۍ ده.",
        e: "She is thin.",
        a: "5-fa-3"
      }], [{
        f: "daa"
      }, {
        f: "drana"
      }, {
        f: "da."
      }, {
        p: "دا درنه ده.",
        e: "daa drana da.",
        a: "5-fa-4"
      }], [{
        f: "haghá"
      }, {
        f: "xkÚle"
      }, {
        f: "da."
      }, {
        p: "هغه ښکُلې ده.",
        e: "She is beautiful.",
        a: "5-fa-5"
      }], [{
        f: "haghá"
      }, {
        f: "gháTa"
      }, {
        f: "da."
      }, {
        p: "هغه غټه ده.",
        e: "She is big.",
        a: "5-fa-6"
      }]]}</Table>
    <h4 {...{
      "id": "note-2"
    }}>{`NOTE:`}</h4>
    <p>{`Adjectives are declined in one of the following ways: (Adjectives ending in `}<Word w={{
        p: "ے",
        f: "ey",
        a: "ey-sound"
      }} mdxType="Word" />{` are declined differently depending if the accent is on the end of the word or not.)`}</p>
    <Table titleRow={["", "Big", "Tired", "Thin"]} bordered mdxType="Table">{[[{
        e: "m.s."
      }, {
        f: "ghaT",
        p: "غټ",
        a: "ghatt"
      }, {
        p: "ستړی",
        f: "stúRey",
        a: "sturrey"
      }, {
        p: "نرے",
        f: "naréy",
        a: "narey"
      }], [{
        e: "f.s."
      }, {
        f: "gháTa",
        p: "غټه",
        a: "ghatta"
      }, {
        p: "ستړې",
        f: "stúRe",
        a: "sturre"
      }, {
        p: "نرۍ",
        f: "narúy",
        a: "naruy"
      }]]}</Table>
    <h2 {...{
      "id": "recognising-feminine-nouns-and-adjectives"
    }}>{`Recognising feminine nouns and adjectives`}</h2>
    <h4 {...{
      "id": "the-all-purpose-word-w-p-ه-f-a-a-a-sound--"
    }}>{`The all-purpose `}<Word w={{
        p: "ه",
        f: "a",
        a: "a-sound"
      }} mdxType="Word" /></h4>
    <Examples mdxType="Examples">{examples.slice(0, 6)}</Examples>
    <h4 {...{
      "id": "some-nouns-are-always-feminine"
    }}>{`Some nouns are always feminine`}</h4>
    <Examples mdxType="Examples">{examples.slice(6, 9)}</Examples>
    <img src={littleGirl} />
    <h4 {...{
      "id": "nouns-ending-with-word-w-p-ۍ-f-uy-a-uy-sound--"
    }}>{`Nouns ending with `}<Word w={{
        p: "ۍ",
        f: "uy",
        a: "uy-sound"
      }} mdxType="Word" /></h4>
    <Examples mdxType="Examples">{examples.slice(9, 14)}</Examples>
    <h4 {...{
      "id": "nouns-ending-in-word-w-p-ې-f-e-a-e-sound--"
    }}>{`Nouns ending in `}<Word w={{
        p: "ې",
        f: "e",
        a: "e-sound"
      }} mdxType="Word" /></h4>
    <Examples mdxType="Examples">{examples.slice(14, 18)}</Examples>
    <h4 {...{
      "id": "note-3"
    }}>{`NOTE:`}</h4>
    <p>{`A masculine noun ending in `}<Word w={{
        p: "ے",
        f: "ey",
        a: "ey-sound"
      }} mdxType="Word" /> is made feminine by changing the ending to <Word w={{
        p: "ې",
        f: "e",
        a: "e-sound"
      }} mdxType="Word" />, or to <Word w={{
        p: "ۍ",
        f: "uy",
        a: "uy-sound"
      }} mdxType="Word" />{` if the accent is on the last syllable.`}</p>
    <h4 {...{
      "id": "endings-in-word-w-p-و-f-o-a-o-sound---and-word-w-p-ا-f-aa-a-aa-sound---are-feminine"
    }}>{`Endings in `}<Word w={{
        p: "و",
        f: "o",
        a: "o-sound"
      }} mdxType="Word" /> and <Word w={{
        p: "ا",
        f: "aa",
        a: "aa-sound"
      }} mdxType="Word" />{` are feminine`}</h4>
    <Examples mdxType="Examples">{examples.slice(18, 24)}</Examples>
    <h4 {...{
      "id": "feminine-because-of-gender"
    }}>{`Feminine because of gender`}</h4>
    <Examples mdxType="Examples">{examples.slice(24, 28)}</Examples>
    <p>{`NOTES:`}</p>
    <ol>
      <li parentName="ol">
        <p parentName="li">{`All these nouns end in a consonant, and strictly speaking should be masculine – but natural gender overrules.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`The adjective `}<Word w={{
            p: "ښائسته",
            f: "xaaysta",
            e: "pretty",
            a: "xaaysta"
          }} mdxType="Word" />{` can also be used with masculine nouns.  `}</p>
      </li>
    </ol>
    <img src={carryingWater} />
    <h4 {...{
      "id": "feminine-nouns-ending-in-word-w-p-ی-f-ee-a-ee-sound--"
    }}>{`Feminine nouns ending in `}<Word w={{
        p: "ی",
        f: "ee",
        a: "ee-sound"
      }} mdxType="Word" /></h4>
    <Examples mdxType="Examples">{examples.slice(28, 30)}</Examples>
    <h2 {...{
      "id": "numbers-eleven-to-twenty"
    }}>{`Numbers eleven to twenty`}</h2>
    <p>{`👆 Write down the numbers as the teacher says them to you.`}</p>
    <p>{`You may write them in English, or may wish to learn how to write the numbers in Pukhto. Don’t repeat the sentence, just listen and obey the command. Start with 1–10, and then add numbers up to 20.  `}</p>
    <Examples mdxType="Examples">{[{
        p: "یوولس ولیکه! ۱۱",
        f: "yowulus óoleeka!",
        e: "Write eleven!",
        a: "5-num-11"
      }, {
        p: "دوولس ولیکه! ۱۲",
        f: "dwolus óoleeka!",
        e: "Write twelve!",
        a: "5-num-12"
      }]}</Examples>
    <Examples twoColumns mdxType="Examples">{[{
        p: "دیارلس ۱۳",
        f: "dyáarlus 13",
        a: "5-num-13"
      }, {
        p: "څوارلس ۱۳",
        f: "tswáarlus 14",
        a: "5-num-14"
      }, {
        p: "پینځلس ۱۵",
        f: "peendzúlus 15",
        a: "5-num-15"
      }, {
        p: "شپاړس ۱۶",
        f: "shpáaRus 16",
        a: "5-num-16"
      }, {
        p: "اووهٔ لس ۱۷",
        f: "oowúlus 17",
        a: "5-num-17"
      }, {
        p: "اتهٔ لس ۱۸",
        f: "atúlus 18",
        a: "5-num-18"
      }, {
        p: "نُولس ۱۹",
        f: "nóolus 19",
        a: "5-num-19"
      }, {
        p: "شل ۲۰",
        f: "shul 20",
        a: "5-num-20"
      }]}</Examples>
    <h2 {...{
      "id": "memorizing-moment"
    }}>{`Memorizing moment`}</h2>
    <h4 {...{
      "id": "word-w-p-شعر-f-shiir-e-poem-a-shiir--"
    }}><Word w={{
        p: "شعر",
        f: "shi'ir",
        e: "poem",
        a: "shiir"
      }} mdxType="Word" /></h4>
    <Examples memorizingMoment mdxType="Examples">{[memorizingMoment[5][0]]}</Examples>
    <h4 {...{
      "id": "note-4"
    }}>{`NOTE:`}</h4>
    <p>{`A `}<Word w={{
        p: "شعر",
        f: "shi'ir",
        e: "poem",
        a: "shiir"
      }} mdxType="Word" />{` is rhyming poetry. The shi’ir selected for memory work are so well known that they act like proverbs in Pukhtun society.`}</p>
    <h4 {...{
      "id": "word-w-p-متلونه-f-matalóona-a---"
    }}><Word w={{
        p: "متلونه",
        f: "matalóona",
        a: ""
      }} mdxType="Word" /></h4>
    <Examples memorizingMoment mdxType="Examples">{memorizingMoment[5].slice(1)}</Examples>
    <h2 {...{
      "id": "exercises-for-lesson-five"
    }}>{`Exercises for Lesson Five`}</h2>
    <ol>
      <li parentName="ol">{`Replace the `}<strong parentName="li">{`bold`}</strong>{` word in the question:`}</li>
    </ol>
    <Examples mdxType="Examples">{[{
        p: <>ستا څو <strong>بچی</strong> دی؟</>,
        f: <>staa tso <strong>bachee</strong> dee?</>,
        a: "5-q-1"
      }]}</Examples>
    <Examples substitutions mdxType="Examples">{[{
        p: "زامن",
        f: "zaamun",
        a: "zaamun"
      }, {
        p: "کتابونه",
        f: "kitaaboona",
        a: "kitaaboona"
      }, {
        p: "قلمُونه",
        f: "qalamóona",
        a: "qalamoona"
      }, {
        p: "مېلمانهْ",
        f: "melmaanú",
        a: "melmaanu"
      }, {
        p: "ورُوڼه",
        f: "wróoNa",
        a: "wroonna"
      }, {
        p: "ملکری",
        f: "malgúree",
        a: "malguree"
      }]}</Examples>
    <ol {...{
      "start": 2
    }}>
      <li parentName="ol">{`Change the following sentences into feminine ones:`}</li>
    </ol>
    <Quiz mdxType="Quiz">{[{
        q: {
          p: "تۀ نوے طالبعلم يې؟",
          f: "tu núwey taalibilum ye?"
        },
        answer: [{
          p: "تهٔ نوې طالبعلمه یې؟",
          f: "tu núwe taalibiluma ye?"
        }]
      }, {
        q: {
          p: "هغه لوړ اُوښ دے.",
          f: "hágha lwuR oox dey."
        },
        answer: [{
          p: "هغه لوړه اوښه ده.",
          f: "hágha lwuRa ooxa da."
        }]
      }, {
        q: {
          p: "دے یو درُوند پښتُون دے.",
          f: "dey yo droond puxtóon dey."
        },
        answer: [{
          p: "دا یوه درنه پښتنه ده.",
          f: "daa yawa drana puxtana da"
        }, {
          p: "دا یوه درنه پښتنه ده.",
          f: "daa yuwa drana puxtana da"
        }]
      }, {
        q: {
          p: "زهٔ ډېر ستړے یم.",
          f: "zu Der stuRey yim."
        },
        answer: [{
          p: "زه ډېره ستړې یم.",
          f: "zu Dera stuRe yim."
        }]
      }, {
        q: {
          p: "دا چرګ سپین نهٔ دے؟",
          f: "daa churg speen nu dey?"
        },
        answer: [{
          p: "دا چرګه سپینه نه ده؟",
          f: "daa churga speena nu da?"
        }]
      }, {
        q: {
          p: "دغه شېطان ماشُوم دے.",
          f: "dágha shetáan maashóom dey."
        },
        answer: [{
          p: "دغه شېطانه ماشومه ده.",
          f: "dágha shetaana maashooma da."
        }]
      }, {
        q: {
          p: "تهٔ ډېر ښهٔ ملګرے یې.",
          f: "tu Der xu malgúrey ye."
        },
        answer: [{
          p: "ته ډېره ښه ملګرې یې.",
          f: "tu Dera xa mulgúre ye."
        }]
      }, {
        q: {
          p: "تهٔ ډېر ښهٔ استاذ یې!",
          f: "tu Der xu Ustáaz ye!"
        },
        answer: [{
          p: "تهٔ ډېره ښه استاذ یې!",
          f: "tu Dera xa Ustáaza ye!"
        }]
      }]}</Quiz>
    <ol {...{
      "start": 3
    }}>
      <li parentName="ol">{`Substitute the `}<strong parentName="li">{`bold`}</strong>{` word in this sentence, using the words listed below:`}</li>
    </ol>
    <Examples mdxType="Examples">{[{
        p: <>هغه <strong>مېز</strong> دے.</>,
        f: <>haghá <strong>mez</strong> dey.</>,
        a: "5-q-2"
      }]}</Examples>
    <Quiz mdxType="Quiz">{[{
        q: {
          p: "ښځه",
          f: "xúdza"
        },
        answer: [{
          p: "هغه ښځه ده.",
          f: "haghá xúdza da."
        }]
      }, {
        q: {
          p: "سړے",
          f: "saRéy"
        },
        answer: [{
          p: "هغه سړے دے.",
          f: "haghá saRey dey."
        }]
      }, {
        q: {
          p: "سړے",
          f: "saRéy"
        },
        answer: [{
          p: "هغه سړے دے.",
          f: "haghá saRey dey."
        }]
      }, {
        q: {
          p: "شپونکے",
          f: "shpoonkéy"
        },
        answer: [{
          p: "هغه شپونکے دے.",
          f: "haghá shpoonkey dey."
        }]
      }, {
        q: {
          p: "اس",
          f: "aas"
        },
        answer: [{
          p: "هغه اس دے.",
          f: "haghá aas dey."
        }, {
          p: "هغه آس دے.",
          f: "haghá aas dey."
        }]
      }, {
        q: {
          p: "ډوډۍ",
          f: "DoDúy"
        },
        answer: [{
          p: "هغه ډوډۍ ده.",
          f: "haghá DoDúy da."
        }]
      }, {
        q: {
          p: "کړکۍ",
          f: "kiRkúy"
        },
        answer: [{
          p: "هغه کړکۍ ده.",
          f: "haghá kiRkúy da."
        }]
      }, {
        q: {
          p: "پیشو",
          f: "peeshó"
        },
        answer: [{
          p: "هغه بیشو ده.",
          f: "haghá peeshó da."
        }]
      }, {
        q: {
          p: "ونه",
          f: "wúna"
        },
        answer: [{
          p: "هغه ونه ده.",
          f: "haghá wúna da."
        }]
      }, {
        q: {
          p: "نرس",
          f: "nars"
        },
        answer: [{
          p: "هغه نرس دے.",
          f: "haghá nars dey."
        }]
      }, {
        q: {
          p: "سپے",
          f: "spey"
        },
        answer: [{
          p: "هغه سپے دے.",
          f: "haghá spey dey."
        }]
      }, {
        q: {
          p: "المارۍ",
          f: "almaarúy"
        },
        answer: [{
          p: "هغه امارۍ ده.",
          f: "haghá almaarúy da."
        }]
      }, {
        q: {
          p: "غل",
          f: "ghul"
        },
        answer: [{
          p: "هغه غل دے.",
          f: "haghá ghul dey."
        }]
      }]}</Quiz>
    <ol {...{
      "start": 4
    }}>
      <li parentName="ol">{`Replace the `}<strong parentName="li">{`bold`}</strong>{` word to make sentences:`}</li>
    </ol>
    <Examples mdxType="Examples">{[{
        p: <>زما په کور کښې یوه <strong>ښځه</strong> ده.</>,
        f: <>zmaa pu kor ke yuwa <strong>xudza</strong> da.</>,
        a: "5-q-3"
      }]}</Examples>
    <Quiz mdxType="Quiz">{[{
        q: {
          p: "سړے",
          f: "saRéy"
        },
        answer: [{
          p: "زما په کور کښې یو سړے دے.",
          f: "zmaa pu kor ke yo saRéy dey."
        }]
      }, {
        q: {
          p: "مېلمه",
          f: "melmá"
        },
        answer: [{
          p: "زما په کور یو مېلمه دے.",
          f: "zmaa pu kor ke yo melmá dey."
        }]
      }, {
        q: {
          p: "ګاونډی",
          f: "gaawunDée"
        },
        answer: [{
          p: "زما په کور ګاونډی دی.",
          f: "zmaa pu kor ke gaawunDee dee."
        }, {
          p: "زما په کور ډېر ګاونډی دی.",
          f: "zmaa pu kor ke Der gaawunDee dee."
        }, {
          p: "زما په کور څو ګاونډی دی.",
          f: "zmaa pu kor ke tso gaawunDee dee."
        }]
      }, {
        q: {
          p: "ماشُوم",
          f: "maashóom"
        },
        answer: [{
          p: "زما په کور کښې یو سړے دے.",
          f: "zmaa pu kor ke yo maashóom dey."
        }]
      }, {
        q: {
          p: "چرګ",
          f: "churg"
        },
        answer: [{
          p: "زما په کور کښې یو چرګ دے.",
          f: "zmaa pu kor ke yo churg dey."
        }]
      }, {
        q: {
          p: "سپے",
          f: "spéy"
        },
        answer: [{
          p: "زما په کور کښې یو سپے دے.",
          f: "zmaa pu kor ke yo spey dey."
        }]
      }]}</Quiz>
    <img src={tree} />
    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;
export const tableOfContents = (components={}) => [
  {
    id: "dialogue-five-ladies-talk",
    level: 2,
    title: "Dialogue five: Ladies’ talk",
    children: [
        
      ]
  },
{
    id: "mens-talk",
    level: 2,
    title: "Men’s talk",
    children: [
        
      ]
  },
{
    id: "some-professions",
    level: 2,
    title: "Some professions",
    children: [
        
      ]
  },
{
    id: "pronunciaton-drill",
    level: 2,
    title: "Pronunciaton Drill",
    children: [
        
      ]
  },
{
    id: "feminine-pronouns",
    level: 2,
    title: "Feminine pronouns",
    children: [
        
      ]
  },
{
    id: "feminine-singular-nouns",
    level: 2,
    title: "Feminine singular nouns",
    children: [
        
      ]
  },
{
    id: "feminine-singular-adjectives",
    level: 2,
    title: "Feminine singular adjectives",
    children: [
        
      ]
  },
{
    id: "recognising-feminine-nouns-and-adjectives",
    level: 2,
    title: "Recognising feminine nouns and adjectives",
    children: [
        
      ]
  },
{
    id: "numbers-eleven-to-twenty",
    level: 2,
    title: "Numbers eleven to twenty",
    children: [
        
      ]
  },
{
    id: "memorizing-moment",
    level: 2,
    title: "Memorizing moment",
    children: [
        
      ]
  },
{
    id: "exercises-for-lesson-five",
    level: 2,
    title: "Exercises for Lesson Five",
    children: [
        
      ]
  }
]

export const frontMatter = {
  "title": "Lesson  Five"
}

