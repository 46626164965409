import React from "react";
import standardizeF from "../lib/standardize-f";
import classNames from "classnames";

function getLetter(i) {
    return (i + 10).toString(36);
}

function sanitize(s) {
    return s.replace(/[~!@#$%^&*()_|+\-=?;:,.<>{}[]\\\/]/gi, "");
}

function fuzzify(s) {
    const punctuation = "['|`|.|?|؟|!|\"]";
    if (!s) return undefined;
    const logic = sanitize(standardizeF(s)).trim()
        // remove punctuation
        .replace(new RegExp(punctuation, "g"), "")
        // Replace آ two-character version with آ
        .replace(/آ/g, "آ")
        // Remove diacritics
        .replace(/[\u064e-\u0652\u0654\u065f]/g, "")
        // Replace ي in middle with ی
        .replace(/\u064a(?=[\u0600-\u060b\u060d-\u06ff])/g, "ی")
        .split("").join("[\u064e-\u0652\u0654\u065f]? *" + punctuation + "?")
        .replace(/q|k/gi, "[q|k]")
        .replace(/a|á|ă/gi, "[a|á|ă]")
        .replace(/u|ú/gi, "[u|ú]")
        .replace(/e|é/gi, "[e|é]")
        .replace(/i|í/gi, "[i|í]")
        .replace(/o|ó/gi, "[o|ó]")
        .replace(/g/gi, "[G|g]")
        // Arabic ى replace with Farsi ی
        .replace(/\u0649/g, '\u06cc')
        // Replace Arabic k with farsi k
        .replace(/\u0643/g, '\u06a9')
        // .replace(/ه(?=\[)/g, "(?:هٔ|ه)")
        .replace(/ه$/g, "(?:هٔ|ه)") + punctuation + "?";
    return new RegExp(`^${logic}$`);
}

class Quiz extends React.Component {
    constructor(props) {
        super(props);
        const questionsState = {};

        this.questions = this.props.children.map((question, i) => ({
            ...question,
            letter: getLetter(i),
        }));

        this.questions.forEach((q) => {
            this[q.letter + "RefP"] = React.createRef();
            this[q.letter + "RefF"] = React.createRef();
            questionsState[q.letter] = false;
        });
        this.state = {
            checked: false,
            ...questionsState,
        };

        this.checkQuestions = this.checkQuestions.bind(this);
        this.clearQuestions = this.clearQuestions.bind(this);
        this.showAnswers = this.showAnswers.bind(this);
    }

    checkQuestion(pAnswer, fAnswer, answer) {
        for (const a of answer) {
            const answersArray = [pAnswer, fAnswer].filter(a => (a));
            // filtered out the empty blanks, if both blanks are filled both have to be correct
            if (answersArray.length && answersArray.every(rx => rx.test(a.p) || rx.test(standardizeF(a.f)))) {
                return true;
            }
        }
        return false;
    }

    checkQuestions() {
        const toUpdate = {};
        // Go through and check each question
        let gotOneWrong = false;
        this.questions.forEach((q) => {
            const pAnswer = fuzzify(this[q.letter + "RefP"].current.value);
            const fAnswer = (q.q) ? "" : fuzzify(this[q.letter + "RefF"].current.value);
            const result = this.checkQuestion(pAnswer, fAnswer, q.answer)
            if (!result) {
                gotOneWrong = true;
            }
            toUpdate[q.letter] = result;
        });
        if (gotOneWrong) {
            navigator.vibrate(150);
        }
        this.setState({
            checked: true,
            ...toUpdate,
        });
    }

    clearQuestions() {
        const toUpdate = {};
        this.questions.forEach((q) => {
            const seperateBlanks = (q.beg || q.end);
            this[q.letter + "RefP"].current.value = "";
            if (seperateBlanks) {
                this[q.letter + "RefF"].current.value = "";
            }
            toUpdate[q.letter] = false;
        });
        this.setState({
            checked: false,
            ...toUpdate,
        });
    }

    showAnswers() {
        const toUpdate = {};
        this.questions.forEach((q) => {
            if (!this.state[q.letter]) {
                const seperateBlanks = (q.beg || q.end);
                // only show the answer if the question was wrong
                this[q.letter + "RefP"].current.value = !seperateBlanks ? `${q.answer[0].f} / ${q.answer[0].p}` : q.answer[0].p;
                if (seperateBlanks) {
                    this[q.letter + "RefF"].current.value = q.answer[0].f;
                }
                toUpdate[q.letter] = "showingAnswer";
            };
        });
        this.setState(toUpdate);
    }

    render() {
        const numberCorrect = this.questions.reduce((correct, q) => (correct + (this.state[q.letter] === true ? 1 : 0)), 0);
        return (
            <>
                <div style={{ marginBottom: "1rem" }} >
                    {this.questions.map((q, i) => {
                        const qState = this.state[q.letter];
                        const blankClass = classNames("blank", { longBlank: q.q }, (this.state.checked ? (qState === true ? "correct " : "incorrect ") : ""));
                        if (q.q) {
                            return (
                                <div key={i}>
                                    {q.q.e ?
                                        <div style={{ padding: "0.5rem" }}>{q.q.e}</div>
                                    :
                                        <div style={{ display: "flex", justifyContent: "space-between", padding: "0.5rem 0" }}>
                                            <div>{standardizeF(q.q.f)}</div>
                                            <div dir="rtl">{q.q.p}</div>
                                        </div>
                                    }
                                    <div>
                                        <input 
                                            className={blankClass}
                                            ref={this[q.letter + "RefP"]}
                                            type="text"
                                            autoComplete="off"
                                            autoCapitalize="off"
                                            autoCorrect="off"
                                            dir="auto"
                                        /> 
                                    </div>
                                </div>
                            );
                        }
                        return (
                            <div key={i}>
                                {q.pref && <div>{q.pref.p} - {standardizeF(q.pref.f)}</div>}
                                <div key={q.letter} style={{ display: "flex", justifyContent: "space-between", padding: "0.5rem 0" }}>
                                    <div>
                                        {q.beg && standardizeF(q.beg.f)} 
                                        <input
                                            className={blankClass} 
                                            ref={this[q.letter + "RefF"]}
                                            type="text"
                                            autoComplete="off"
                                            autoCapitalize="off"
                                            autoCorrect="off"
                                        /> 
                                        {q.end && standardizeF(q.end.f)}
                                    </div>
                                    <div dir="rtl" style={{ textAlign: "right" }}>
                                        {q.beg && q.beg.p} 
                                        <input
                                            ref={this[q.letter + "RefP"]}
                                            className={blankClass}
                                            type="text"
                                            autoComplete="off"
                                            autoCapitalize="off"
                                            autoCorrect="off"
                                        /> 
                                        {q.end && q.end.p}
                                    </div>
                                </div>
                            </div>
                        );
                    })}
                </div>
                <div style={{ marginBottom: "2rem", display: "flex", justifyContent: "space-between" }}>
                    <div>
                        <button onClick={this.checkQuestions}>CHECK</button>
                    </div>
                    {this.state.checked &&
                        <>
                            <div>
                                <strong>
                                    {numberCorrect}
                                    / 
                                    {this.questions.length}
                                </strong>
                                {numberCorrect < this.questions.length ? <span> - <span className="clickable" style={{ color: "grey" }} onClick={this.showAnswers}>Show Answers</span></span> : " ✔"}
                            </div>
                            <div>
                                {this.state.checked && <button onClick={this.clearQuestions}>CLEAR</button>}
                            </div>
                        </>
                    }
                </div>
            </>
        );
    }
}

export default Quiz;
