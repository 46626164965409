import React from "react";
import standardizeF from "../lib/standardize-f";
import playAudio from "../lib/play-audio";
// import useInView from "../lib/useInView";

const Word = ({ w }) => {
    // const ref = useRef();
    // const inView = useInView(ref);
    // let audio = undefined;
    // useEffect(() => {
    //     if (!w.a) return;
    //     if (inView) {
    //         audio = new Audio(`/audio/${w.a}.mp3`);
    //     }
    //     if (!inView) {
    //         audio = undefined;
    //     }
    // }, [inView]);
    // useEffect(() => {
    //     return () => audio = undefined;
    // }, [])
    return (
        <span
            onClick={() => playAudio(w.a)}
        >
            <strong>
                <span dir="rtl">{w.p}</span>{w.f && `${(w.p && w.f) ? " - " : ""}${standardizeF(w.f)}`}
            </strong>
            {w.e && ` (${w.e})`}
        </span>
    );
};

export default Word;