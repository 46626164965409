// INTRO IMPORTS
/* eslint-disable import/no-webpack-loader-syntax */
import * as preface from '!babel-loader!mdx-loader!./preface.mdx'; 
import * as introduction from '!babel-loader!mdx-loader!./introduction.mdx';
import * as lessonOne from '!babel-loader!mdx-loader!./lessonOne.mdx';
import * as lessonTwo from '!babel-loader!mdx-loader!./lessonTwo.mdx';
import * as lessonThree from '!babel-loader!mdx-loader!./lessonThree.mdx';
import * as lessonFour from '!babel-loader!mdx-loader!./lessonFour.mdx';
import * as lessonFive from '!babel-loader!mdx-loader!./lessonFive.mdx';
import * as lessonSix from '!babel-loader!mdx-loader!./lessonSix.mdx';
import * as lessonSeven from '!babel-loader!mdx-loader!./lessonSeven.mdx';
import * as lessonEight from '!babel-loader!mdx-loader!./lessonEight.mdx';
import * as lessonNine from '!babel-loader!mdx-loader!./lessonNine.mdx';
import * as lessonTen from '!babel-loader!mdx-loader!./lessonTen.mdx';
import * as lessonEleven from '!babel-loader!mdx-loader!./lessonEleven.mdx';
import * as lessonTwelve from '!babel-loader!mdx-loader!./lessonTwelve.mdx';
import * as lessonThirteen from '!babel-loader!mdx-loader!./lessonThirteen.mdx';
import * as lessonFourteen from '!babel-loader!mdx-loader!./lessonFourteen.mdx';
import * as lessonFifteen from '!babel-loader!mdx-loader!./lessonFifteen.mdx';
import * as lessonSixteen from '!babel-loader!mdx-loader!./lessonSixteen.mdx';
import * as lessonSeventeen from '!babel-loader!mdx-loader!./lessonSeventeen.mdx';
import * as lessonEighteen from '!babel-loader!mdx-loader!./lessonEighteen.mdx';
import * as lessonNineteen from '!babel-loader!mdx-loader!./lessonNineteen.mdx';
import * as lessonTwenty from '!babel-loader!mdx-loader!./lessonTwenty.mdx';
import * as lessonTwentyOne from '!babel-loader!mdx-loader!./lessonTwentyOne.mdx';
import * as lessonTwentyTwo from '!babel-loader!mdx-loader!./lessonTwentyTwo.mdx';
import * as lessonTwentyThree from '!babel-loader!mdx-loader!./lessonTwentyThree.mdx';
import * as lessonTwentyFour from '!babel-loader!mdx-loader!./lessonTwentyFour.mdx';
import * as lessonTwentyFive from '!babel-loader!mdx-loader!./lessonTwentyFive.mdx';
import * as lessonTwentySix from '!babel-loader!mdx-loader!./lessonTwentySix.mdx';
import * as lessonTwentySeven from '!babel-loader!mdx-loader!./lessonTwentySeven.mdx';
import * as memorisingMomentsPage from '!babel-loader!mdx-loader!./memorisingMomentsPage.mdx';

const chapters = [
    {
        import: preface,
        slug: "preface"
    },
    {
        import: introduction,
        slug: "introduction"
    },
    {
        import: lessonOne,
        slug: "lesson-one",
    },
    {
        import: lessonTwo,
        slug: "lesson-two",
    },
    {
        import: lessonThree,
        slug: "lesson-three",
    },
    {
        import: lessonFour,
        slug: "lesson-four",
    },
    {
        import: lessonFive,
        slug: "lesson-five",
    },
    {
        import: lessonSix,
        slug: "lesson-six",
    },
    {
        import: lessonSeven,
        slug: "lesson-seven",
    },
    {
        import: lessonEight,
        slug: "lesson-eight",
    },
    {
        import: lessonNine,
        slug: "lesson-nine",
    },
    {
        import: lessonTen,
        slug: "lesson-ten",
    },
    {
        import: lessonEleven,
        slug: "lesson-eleven",
    },
    {
        import: lessonTwelve,
        slug: "lesson-twelve",
    },
    {
        import: lessonThirteen,
        slug: "lesson-thirteen",
    },
    {
        import: lessonFourteen,
        slug: "lesson-fourteen",
    },
    {
        import: lessonFifteen,
        slug: "lesson-fifteen",
    },
    {
        import: lessonSixteen,
        slug: "lesson-sixteen",
    },
    {
        import: lessonSeventeen,
        slug: "lesson-seventeen",
    },
    {
        import: lessonEighteen,
        slug: "lesson-eighteen",
    },
    {
        import: lessonNineteen,
        slug: "lesson-nineteen",
    },
    {
        import: lessonTwenty,
        slug: "lesson-twenty",
    },
    {
        import: lessonTwentyOne,
        slug: "lesson-twenty-one",
    },
    {
        import: lessonTwentyTwo,
        slug: "lesson-twenty-two",
    },
    {
        import: lessonTwentyThree,
        slug: "lesson-twenty-three",
    },
    {
        import: lessonTwentyFour,
        slug: "lesson-twenty-four",
    },
    {
        import: lessonTwentyFive,
        slug: "lesson-twenty-five",
    },
    {
        import: lessonTwentySix,
        slug: "lesson-twenty-six",
    },
    {
        import: lessonTwentySeven,
        slug: "lesson-twenty-seven",
    },
    {
        import: memorisingMomentsPage,
        slug: "memorising-moments",
    },
];

// Prepare the content object for easier use in the app

// STEP #1
chapters.forEach((chapter) => {
    // Make paths for Links for Each Chapter
    chapter.path = `/${chapter.slug}/`;
    // extract and distribute import object
    chapter.component = chapter.import.default;
    chapter.frontMatter = chapter.import.frontMatter;
    chapter.tableOfContents = chapter.import.tableOfContents();
    delete chapter.import;
});

// Now that the paths are made, go through and make the next and previous page information for each chapter
// STEP #2
chapters.forEach((chapter, j) => {
    // See if there's a next chapter
    let nextChapter = j === chapters.length - 1 ? null : chapters[j + 1];
    if (nextChapter) {
        chapter.next = { frontMatter: nextChapter, path: nextChapter.path };
    }
    // See if there's a previous chapter
    let prevChapter = j === 0 ? null : chapters[j - 1];
    if (prevChapter) {
        chapter.previous = { frontMatter: prevChapter, path: prevChapter.path };
    }
});

export default chapters;
