import React from "react"
import Helmet from "react-helmet"

function SEO({ 
  // description, 
  lang, meta, keywords, title }) {

  // const metaDescription = description;
  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      defer={false}
      title={title}
      titleTemplate={`%s | Speaking Pakistani Pukhto`}
      meta={[
        {
          property: `og:title`,
          content: title,
        },
        {
          name: `twitter:card`,
          content: `summary_large_image`,
        },
        {
          name: `twitter:title`,
          content: title,
        },
      ]
        .concat(
          keywords.length > 0
            ? {
                name: `keywords`,
                content: keywords.join(`, `),
              }
            : []
        )
        .concat(meta)}
    >
    </Helmet>
  )
}

SEO.defaultProps = {
  lang: `en`,
  title: "Speaking Pakistani Pukhto",
  meta: [],
  keywords: ["Pashto", "Pukhto", "Learning", "Language", "Pakhtunkhwa"],
  description: `A practical guide to learning the language of Khyber Pukhtunkhwa`,
}

export default SEO
