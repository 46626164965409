export default [
    {
        p: "تا سره سپين چرګ شته؟",
        f: "taa sara speen churg shta?",
        e: "Do you have a white cock?",
        a: "5-ex-1",
    },
    {
        p: "تا سره سپينه چرګه شته؟",
        f: "taa sara speena chúrga shta?",
        e: "Do you have a white hen?",
        a: "5-ex-2",
    },
    {
        p: "زما نرس پښتُون دے.",
        f: "zmaa nars puxtóon dey.",
        e: "My nurse (male) is a Pukhtun.",
        a: "5-ex-3",
    },
    {
        p: "زما نرسه پښتنه ده.",
        f: "zmaa narsa puxtaná da",
        e: "My nurse (female) is a Pukhtun.",
        a: "5-ex-4",
    },
    {
        p: "دے خطرناک غل دے.",
        f: "dey khatarnáak ghul dey.",
        e: "He is a dangerous thief.",
        a: "5-ex-5",
    },
    {
        p: "دا خطرناکه غله ده.",
        f: "daa khatarnáaka ghla da.",
        e: "She is a dangerous thief.",
        a: "5-ex-6",
    },
    {
        p: "دا ډېره مضبُوطه ونه ده.",
        f: "daa Dera mazbóota wúna da.",
        e: "This is a very strong tree.",
        a: "5-ex-7",
    },
    {
        p: "دا ډېره خرابه مڼه ده.",
        f: "daa Dera kharaaba maNa da.",
        e: "This is a very rotten apple.",
        a: "5-ex-8",
    },
    {
        p: "هغه جینۍ ښکُلې ده.",
        f: "hagha jeenúy xkÚle da.",
        e: "That girl is beautiful.",
        a: "5-ex-9",
    },
    {
        p: "دا المارۍ غټه ده.",
        f: "daa almaarúy ghaTa da.",
        e: "This wardrobe is big.",
        a: "5-ex-10",
    },
    {
        p: "دا کُرسۍ اُوچته ده.",
        f: "daa kUrsúy oochúta da.",
        e: "This chair is tall.",
        a: "5-ex-11",
    },
    {
        p: "هغه ورکوټې سپۍ ده.",
        f: "hágha warkóTe spuy da.",
        e: "That is a small bitch.",
        a: "5-ex-12",
    },
    {
        p: "دا ډوډۍ مزېداره ده.",
        f: "daa DoDúy mazedáara da.",
        e: "This food is delicious.",
        a: "5-ex-13",
    },
    {
        p: "دغه کړکۍ پلنه ده.",
        f: "dágha kiRkúy plúna da.",
        e: "This window is wide.",
        a: "5-ex-14",
    },
    {
        p: "زۀ نوے اُستاذ يم.",
        f: "zu núwey Ustáaz yim.",
        e: "I am a new (male) teacher.",
        a: "5-ex-15",
    },
    {
        p: "زۀ نوې اُستاذه يم.",
        f: "zu núwe Ustáaza yim.",
        e: "I am a new (female) teacher.",
        a: "5-ex-16",
    },
    {
        p: "ستا ملګرے هوښيار دے.",
        f: "staa malgúrey ooxyáar dey.",
        e: "Your (male) friend is wise.",
        a: "5-ex-17",
    },
    {
        p: "ستا ملګرې هوښياره ده.",
        f: "staa malgúre ooxyáara da.",
        e: "Your (female) friend is wise.",
        a: "5-ex-18",
    },
    {
        p: "زما يوه ښُکلې پيشو شته.",
        f: "zmaa yawa xkÚle peesho shta.",
        e: "I have one beautiful cat.",
        a: "5-ex-19",
    },
    {
        p: "زما نيا هلته ده.",
        f: "zmaa niyaa hálta da.",
        e: "My grandmother is there.",
        a: "5-ex-20",
    },
    {
        p: "هغه غوا غټه ده.",
        f: "hágha ghwaa gháTa da.",
        e: "That cow is big.",
        a: "5-ex-21",
    },
    {
        p: "زما ببو دلته ده.",
        f: "zmaa babó dălta da.",
        e: "My aunty is here.",
        a: "5-ex-22",
    },
    {
        p: "ډېره خندا ښۀ نۀ ده.",
        f: "Dera khandáa xu nu da.",
        e: "Much laughter is not good.",
        a: "5-ex-23",
    },
    {
        p: "ډېره ژړا ښۀ نۀ ده.",
        f: "Dera jzaRáa xu nu da.",
        e: "Much crying is not good.",
        a: "5-ex-24",
    },
    {
        p: "زما مور مهربانه ده.",
        f: "zmaa mor mihribáana da.",
        e: "My mother is kind.",
        a: "5-ex-25",
    },
    {
        p: "ستا ترور بُوډۍ ده.",
        f: "staa tror booDúy da.",
        e: "Your aunt is old.",
        a: "5-ex-26",
    },
    {
        p: "زما خور مشره ده.",
        f: "zmaa khor múshra da.",
        e: "My sister is older.",
        a: "5-ex-27",
    },
    {
        p: "ستا لُور ډېره ښائسته ده.",
        f: "staa loor Dera xaaysta da.",
        e: "Your daughter is very pretty.",
        a: "5-ex-28",
    },
    {
        p: "دوستی ښه ده.",
        f: "dostée xa da.",
        e: "Friendship is good.",
        a: "5-ex-29",
    },
    {
        p: "دشمنی ښه نهٔ ده.",
        f: "dUshmanée xa nu da.",
        e: "Enmity is not good.",
        a: "5-ex-30",
    },
];